import React, { useContext, useEffect, useState } from "react";
import { AppLayout, Spinner } from "@amzn/awsui-components-react";
import { Button, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";
import { CreateUseCaseStep, SIDE_MENU_IS_OPEN, USECASE_NOT_FOUNT_ID } from "assets/js/const";
import {
  GroupInput,
  GroupSvcInput,
  Industry,
  IndustryInput,
  SubmitMode,
  UseCaseInput,
  UseCaseWorkload,
  UserRole,
} from "API";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { submitUseCase } from "graphql/mutations";
import AppContext from "context/AppContext";
import { getUseCaseById } from "graphql/queries";
import StepUseCaseDetails from '../comps/step/StepUseCaseDetails';
import StepAccountInfo from '../comps/step/StepAccountInfo';
import StepUseCaseAttachements from '../comps/step/StepUseCaseAttachements';


interface EditUseCaseProps {
  useCaseId: string;
}

const EditUseCaseContent: React.FC<EditUseCaseProps> = (props: EditUseCaseProps) => {
  const {useCaseId} = props;
  const history = useHistory();
  const appConfig = useContext(AppContext);
  const [activeStepIndex, setActiveStepIndex] = useState(CreateUseCaseStep.UseCaseDetailStepIndex);
  const [editUseCaseInfo, setEditUseCaseInfo] = useState<UseCaseInput>({
    useCaseId: "",
    mode: SubmitMode.Draft,
    contributorId: appConfig.userId,
    useCaseName: "",
    useCaseDesc: "",
    sfdcAccountId: "",
    svcInUseList: [],
    groupInUseList: [],
    industryInUseList: [],
    architectDesc: "",
    architectDiagrams: [],
    attachments: [],
  });
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [showWorkloadRequireError, setShowWorkloadRequireError] = useState(false);
  const [showGroupError, setShowGroupError] = useState(false);
  const [showIndustryError, setShowIndustryError] = useState(false);
  const [showServiceError, setShowServiceError] = useState(false);
  const [showNameEmptyError, setShowNameEmptyError] = useState(false);
  const [showArchImgRequireError, setShowArchImgRequireError] = useState(false);

  const [workloadIsLoading, setWorkloadIsLoading] = useState(false);
  const [groupIsLoading, setGroupIsLoading] = useState(false);
  const [svcIsLoading, setSvcIsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [industryIsLoading, setIndustryIsLoading] = useState(false);
  const [serviceIsLoading, setServiceIsLoading] = useState(false);

  const [useCaseInputErrorArr, setUseCaseInputErrorArr] = useState<string[]>([]);

  const updateUseCase = async (mode: SubmitMode) => {
    if (useCaseInputErrorArr && useCaseInputErrorArr.length > 0) {
      return;
    }

    if (!editUseCaseInfo?.useCaseName?.trim()) {
      setShowNameEmptyError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    // Validate Architecture Diagram
    if (!editUseCaseInfo.architectDiagrams || editUseCaseInfo.architectDiagrams?.length <= 0) {
      setShowArchImgRequireError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    // Validate Workload
    if (!editUseCaseInfo.groupInUseList || editUseCaseInfo.groupInUseList?.length <= 0) {
      setShowWorkloadRequireError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    if (!editUseCaseInfo.svcInUseList || editUseCaseInfo.svcInUseList?.length <= 0) {
      setShowServiceError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
      return;
    }

    if (!editUseCaseInfo.industryInUseList || editUseCaseInfo.industryInUseList?.length <= 0) {
      setShowIndustryError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
      return;
    }

    const updateUseCaseInfoParam = JSON.parse(JSON.stringify(editUseCaseInfo));
    updateUseCaseInfoParam.mode = mode;
    console.info("updateUseCaseInfoParam:", updateUseCaseInfoParam);

    // encode assets input
    updateUseCaseInfoParam.useCaseName = encodeURIComponent(updateUseCaseInfoParam.useCaseName);
    updateUseCaseInfoParam.useCaseDesc = encodeURIComponent(updateUseCaseInfoParam.useCaseDesc);

    const useCaseArchList: any = [];
    editUseCaseInfo?.architectDiagrams?.forEach((element) => {
      useCaseArchList.push({
        architectFileName: encodeURIComponent(element?.architectFileName || ""),
        architectFileUrl: element?.architectFileUrl,
      });
    });
    updateUseCaseInfoParam.architectDiagrams = useCaseArchList;

    // Attachment Refine
    const updateAttachments: any = [];
    editUseCaseInfo?.attachments?.forEach((element) => {
      updateAttachments.push({
        attachmentFilename: encodeURIComponent(
            element?.attachmentFilename || ""
        ),
        attachmentUrl: element?.attachmentUrl,
        attachmentSize: element?.attachmentSize,
        attachmentDesc: encodeURIComponent(element?.attachmentDesc || ""),
      });
    });
    updateUseCaseInfoParam.attachments = updateAttachments;

    // Add Contributor to parameter
    updateUseCaseInfoParam.contributorId = appConfig.userId;

    // Remove useless properties
    delete updateUseCaseInfoParam?.useCaseStatus;
    delete updateUseCaseInfoParam?.contributor;
    delete updateUseCaseInfoParam?.createDateTime;
    delete updateUseCaseInfoParam?.submitDateTime;
    delete updateUseCaseInfoParam?.updateDateTime;
    delete updateUseCaseInfoParam?.workload;
    delete updateUseCaseInfoParam?.industries;

    setLoadingSubmit(true);
    try {
      const createUseCaseRes = await appSyncRequest(submitUseCase, {
        input: updateUseCaseInfoParam,
      });
      console.info("createUseCaseRes:", createUseCaseRes);
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      history.push({
        pathname:
            appConfig.userRole === UserRole.SO_TFC_leader
                ? "/my-usecase"
                : appConfig.userRole === UserRole.Manager
                    ? "/usecase-all"
                    : "/usecase-my-team",
      });
    } catch (error) {
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      console.error(error);
    }
  };

  const getUseCaseInfoById = async () => {
    try {
      setLoadingData(true);
      const useCaseData: any = await appSyncRequest(getUseCaseById, {
        useCaseId: useCaseId,
      });
      console.info("useCaseData:", useCaseData);
      const useCaseInfo = useCaseData?.data?.getUseCaseById;
      if (
          useCaseInfo.useCaseId === USECASE_NOT_FOUNT_ID ||
          useCaseInfo.contributor?.userId !== appConfig.userId
      ) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        console.info("useCaseInfo:", useCaseInfo);
        if (useCaseInfo) {
          useCaseInfo.useCaseName = decodeURIComponent(
              useCaseInfo.useCaseName.replace(/\+/g, " ")
          );
          useCaseInfo.useCaseDesc = decodeURIComponent(
              useCaseInfo.useCaseDesc.replace(/\+/g, " ")
          );
          useCaseInfo.architectDesc = decodeURIComponent(
              useCaseInfo.architectDesc.replace(/\+/g, " ")
          );
          useCaseInfo.architectDiagrams.forEach((element: any) => {
            element.architectFilename = decodeURIComponent(
                element.architectFilename.replace(/\+/g, " ")
            );
          });
          useCaseInfo.attachments.forEach((element: any) => {
            element.attachmentFilename = decodeURIComponent(
                element.attachmentFilename.replace(/\+/g, " ")
            );
            element.attachmentDesc = decodeURIComponent(
                element.attachmentDesc.replace(/\+/g, " ")
            );
          });

          // set tagList
          const tmpIndustryList: IndustryInput[] = [];
          useCaseInfo.industries.forEach((element: Industry) => {
            tmpIndustryList.push({
              industryId: element.industryId,
              industryName: element.industryName,
            });
          });
          useCaseInfo.industryInUseList = tmpIndustryList;

          const tmpWorkloadList: GroupInput[] = [];
          useCaseInfo.workload.forEach((element: UseCaseWorkload) => {
            tmpWorkloadList.push({
              groupId: element.groupId,
              groupName: element.groupName,
            });
          });
          useCaseInfo.groupInUseList = tmpWorkloadList;

          // set service list
          const tmpServiceList: GroupSvcInput[] = [];
          useCaseInfo.workload.forEach((element: UseCaseWorkload) => {
            element.serviceList?.forEach((svc) => {
              tmpServiceList.push({
                svcId: svc?.svcId,
                svcName: svc?.svcName,
                groupId: svc?.groupId,
              });
            });
          });
          useCaseInfo.svcInUseList = tmpServiceList;

          delete useCaseInfo?.__typename;

          setEditUseCaseInfo(useCaseInfo);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.info("editUseCaseInfo:", editUseCaseInfo);
  }, [editUseCaseInfo]);

  useEffect(() => {
    getUseCaseInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div>
        {loadingData ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div className="gsal-review-page">
              <div className="gsal-step-nav">
                <nav>
                  <ul>
                    <li>
                      <small>Step 1</small>
                      <div className="step-name">
                        <span
                            onClick={() => {
                              if (
                                  loadingSubmit ||
                                  loadingSaveDraft ||
                                  isFileUploading
                              ) {
                                return;
                              }
                              setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                            }}
                            className={activeStepIndex !== CreateUseCaseStep.UseCaseDetailStepIndex ? "link" : ""}
                        >
                          Use Case details
                        </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 2</small>
                      <div className="step-name">
                        <span
                            onClick={() => {
                              if (!editUseCaseInfo.useCaseId) {
                                return;
                              }
                              if (svcIsLoading) {
                                return;
                              }
                              setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
                            }}
                            className={activeStepIndex !== CreateUseCaseStep.UseCaseInfoStepIndex ? "link" : ""}
                        >
                          Services
                        </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 3</small>
                      <div className="step-name">
                        <span
                            onClick={() => {
                              if (!editUseCaseInfo.useCaseId) {
                                return;
                              }
                              if (
                                  loadingSubmit ||
                                  loadingSaveDraft ||
                                  isFileUploading ||
                                  workloadIsLoading ||
                                  svcIsLoading
                              ) {
                                return;
                              }
                              setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
                            }}
                            className={activeStepIndex !== CreateUseCaseStep.UseCaseAttachmentStepIndex ? "link" : ""}
                        >
                          Attachements
                        </span>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="gsal-review-content">
                {activeStepIndex === CreateUseCaseStep.UseCaseDetailStepIndex && (
                    <StepUseCaseDetails
                        curUseCase={editUseCaseInfo}
                        showNameEmptyError={showNameEmptyError}
                        showArchDiagramRequireError={showArchImgRequireError}
                        showGroupError={showGroupError}
                        setGroupIsLoading={(loading) => {
                          setGroupIsLoading(loading);
                        }}
                        changeGroupInUse={(groupList) => {
                          if (groupList.length > 0) {
                            setShowGroupError(false);
                          }
                          setEditUseCaseInfo((prev) => {
                            return {...prev, groupInUseList: groupList};
                          });
                        }}
                        addInputError={(error) => {
                          if (!useCaseInputErrorArr.includes(error)) {
                            setUseCaseInputErrorArr((prev) => {
                              return [...prev, error];
                            });
                          }
                        }}
                        removeInputError={(error) => {
                          setUseCaseInputErrorArr((prev) => {
                            return prev.filter((e) => e!==error);
                          });
                        }}
                        changeIsUploading={(uploading) => {
                          setIsFileUploading(uploading);
                        }}
                        setShowNameEmptyError={(showError) => {
                          setShowNameEmptyError(showError);
                        }}
                        changeUseCaseName={(useCaseName) => {
                          setShowNameEmptyError(false);
                          setEditUseCaseInfo((prev) => {
                            return {...prev, useCaseName: useCaseName};
                          });
                        }}
                        changeUseCaseDesc={(useCaseDesc) => {
                          setEditUseCaseInfo((prev) => {
                            return {...prev, useCaseDesc: useCaseDesc};
                          });
                        }}
                        changeArchImgList={(archList) => {
                          if (archList.length > 0) {
                            setShowArchImgRequireError(false);
                          }
                          setEditUseCaseInfo((prev) => {
                            return {
                              ...prev,
                              architectDiagrams: archList,
                            };
                          });
                        }}
                        changeArchDesc={(archDesc) => {
                          setEditUseCaseInfo((prev) => {
                            return {...prev, architectDesc: archDesc};
                          });
                        }}
                    />
                )}
                {activeStepIndex === CreateUseCaseStep.UseCaseInfoStepIndex && (
                    <StepAccountInfo
                        curUseCase={editUseCaseInfo}
                        showServiceError={showServiceError}
                        changeServiceList={(serviceList) => {
                          setEditUseCaseInfo((prev) => {
                            return {...prev, svcInUseList: serviceList};
                          });
                        }}
                        setServiceIsLoading={(loading) => {
                          setServiceIsLoading(loading);
                        }}
                    />
                )}
                {activeStepIndex === CreateUseCaseStep.UseCaseAttachmentStepIndex && (
                    <StepUseCaseAttachements
                        curUseCase={editUseCaseInfo}
                        showIndustryError={showIndustryError}
                        changeIndustryInUse={(industryList) => {
                          setEditUseCaseInfo((prev) => {
                            return {...prev, industryInUseList: industryList};
                          });
                        }}
                        setIndustryIsLoading={(loading) => {
                          setIndustryIsLoading(loading);
                        }}
                        addNewAttachment={(item) => {
                          setEditUseCaseInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            const prevAttachment = prevObj.attachments;
                            prevAttachment.push(item);
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        removeAttachment={(index) => {
                          if (
                              editUseCaseInfo.attachments &&
                              editUseCaseInfo.attachments.length > 0
                          ) {
                            const tmpAttacment = [...editUseCaseInfo.attachments];
                            tmpAttacment.splice(index, 1);
                            setEditUseCaseInfo((prev: any) => {
                              const prevObj = JSON.parse(JSON.stringify(prev));
                              return {
                                ...prevObj,
                                ...{
                                  attachments: tmpAttacment,
                                },
                              };
                            });
                          }
                        }}
                        changeAttachmentDesc={(index, desc) => {
                          setEditUseCaseInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            prevObj.attachments[index].attachmentDesc = desc;
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        uploadAttachment={(upProgress, index) => {
                          setEditUseCaseInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            if (upProgress >= 100) {
                              prevObj.attachments[index].progress = upProgress;
                              prevObj.attachments[index].showProgress = false;
                            } else {
                              prevObj.attachments[index].progress = upProgress;
                              prevObj.attachments[index].showProgress = true;
                            }
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        uploadAttachmentDone={(index, name, size, key) => {
                          setEditUseCaseInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            prevObj.attachments[index].attachmentFilename = name;
                            prevObj.attachments[index].attachmentSize = size;
                            prevObj.attachments[index].attachmentUrl = key;
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        addInputError={(error) => {
                          if (!useCaseInputErrorArr.includes(error)) {
                            setUseCaseInputErrorArr((prev) => {
                              return [...prev, error];
                            });
                          }}
                        }
                        removeInputError={(error) => {
                          setUseCaseInputErrorArr((prev) => {
                            return prev.filter((e) => e !== error);
                          });
                        }}
                        changeIsUploading={(uploading) => {
                          setIsFileUploading(uploading);
                        }}
                    />
                )}
                <div className="mt-20 pb-30 clearfix">
                  <SpaceBetween className="fr" direction="horizontal" size="xs">
                    <Button
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: `/my-usecase/detail/${useCaseId}`,
                          });
                        }}
                    >
                      Cancel
                    </Button>
                    {activeStepIndex === CreateUseCaseStep.UseCaseDetailStepIndex && editUseCaseInfo.useCaseId && (
                        <Button
                            disabled={
                                loadingSubmit || loadingSaveDraft || isFileUploading
                            }
                            variant="primary"
                            onClick={() => {
                              if (!editUseCaseInfo?.useCaseName?.trim()) {
                                setShowNameEmptyError(true);
                                setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                                return;
                              }

                              if (!editUseCaseInfo.architectDiagrams || editUseCaseInfo.architectDiagrams?.length <=0) {
                                setShowArchImgRequireError(true);
                                setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                                return;
                              }

                              if (!editUseCaseInfo.groupInUseList || editUseCaseInfo.groupInUseList?.length !== 1 ) {
                                setShowGroupError(true);
                                setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                                return;
                              }

                              setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
                            }}
                        >
                          Next
                        </Button>
                    )}

                    {activeStepIndex > CreateUseCaseStep.UseCaseDetailStepIndex && (
                        <Button
                            disabled={loadingSubmit || loadingSaveDraft || isFileUploading}
                            onClick={() => {
                              if (useCaseInputErrorArr.length > 0) {
                                return;
                              }

                              setActiveStepIndex((prev) => {
                                return prev - 1;
                              });
                            }}
                        >
                          Previous
                        </Button>
                    )}

                    {activeStepIndex === CreateUseCaseStep.UseCaseInfoStepIndex && (
                        <Button
                            disabled={svcIsLoading}
                            variant="primary"
                            onClick={() => {
                              if (!editUseCaseInfo.svcInUseList || editUseCaseInfo.svcInUseList?.length <= 0) {
                                setShowServiceError(true);
                                setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
                                return;
                              }
                              setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
                            }}
                        >
                          Next
                        </Button>
                    )}
                    {activeStepIndex === CreateUseCaseStep.UseCaseAttachmentStepIndex && (
                        <Button
                            disabled={industryIsLoading || isFileUploading}
                            loading={loadingSubmit}
                            variant="primary"
                            onClick={() => {
                              if (!editUseCaseInfo.industryInUseList || editUseCaseInfo.industryInUseList?.length <= 0) {
                                setShowIndustryError(true);
                                setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
                                return;
                              }
                              updateUseCase(SubmitMode.Submit);
                            }}
                        >
                          Submit
                        </Button>
                    )}
                  </SpaceBetween>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

interface MatchParams {
  id: string;
}

const EditUseCase: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setnavigationOpen] = useState(false);
  // const [helpInfo, setHelpInfo] = useState();
  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const id: string = props.match.params.id;

  const useCaseEditBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: id.toUpperCase(),
      href: "/usecase/detail/" + id,
    },
    {
      text: "Edit",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={useCaseEditBreadcrumbs}/>}
          content={<EditUseCaseContent useCaseId={id}/>}
          toolsOpen={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default EditUseCase;
