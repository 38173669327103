/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const assignReviewer = /* GraphQL */ `
  mutation AssignReviewer($input: ReviewerInput!) {
    assignReviewer(input: $input) {
      status
      __typename
    }
  }
`;
export const assignSWAReviewer = /* GraphQL */ `
  mutation AssignSWAReviewer($input: SWAReviewerInput!) {
    assignSWAReviewer(input: $input) {
      status
      __typename
    }
  }
`;
export const nominatePoC = /* GraphQL */ `
  mutation NominatePoC($input: NominateInput!) {
    nominatePoC(input: $input) {
      status
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview($input: ReviewInput!) {
    updateReview(input: $input) {
      status
      __typename
    }
  }
`;
export const updateSWAReview = /* GraphQL */ `
  mutation UpdateSWAReview($input: SWAReviewInput!) {
    updateSWAReview(input: $input) {
      status
      __typename
    }
  }
`;
export const updateSWAStatus = /* GraphQL */ `
  mutation UpdateSWAStatus($input: SWAStatusInput!) {
    updateSWAStatus(input: $input) {
      status
      __typename
    }
  }
`;
export const updateBestPracticeAssets = /* GraphQL */ `
  mutation UpdateBestPracticeAssets($input: BestPracticeAssetsInput!) {
    updateBestPracticeAssets(input: $input) {
      status
      __typename
    }
  }
`;
export const submitPoC = /* GraphQL */ `
  mutation SubmitPoC($input: PoCInput!) {
    submitPoC(input: $input) {
      status
      pocId
      __typename
    }
  }
`;
export const submitGsalConf = /* GraphQL */ `
  mutation SubmitGsalConf(
    $techTagLimit: Int!
    $industryTagLimit: Int!
    $firstLineManagerNominateCountPerMonth: Int!
  ) {
    submitGsalConf(
      techTagLimit: $techTagLimit
      industryTagLimit: $industryTagLimit
      firstLineManagerNominateCountPerMonth: $firstLineManagerNominateCountPerMonth
    ) {
      status
      __typename
    }
  }
`;
export const createReviewTask = /* GraphQL */ `
  mutation CreateReviewTask($input: ReviewTaskInput!) {
    createReviewTask(input: $input) {
      status
      reviewTaskId
      errMsg
      __typename
    }
  }
`;
export const createSwaReviewTask = /* GraphQL */ `
  mutation CreateSwaReviewTask($input: SwaReviewTaskInput!) {
    createSwaReviewTask(input: $input) {
      status
      reviewTaskId
      errMsg
      __typename
    }
  }
`;
export const withdrawPoC = /* GraphQL */ `
  mutation WithdrawPoC($input: WithdrawPoCInput!) {
    withdrawPoC(input: $input) {
      status
      __typename
    }
  }
`;
export const submitSWA = /* GraphQL */ `
  mutation SubmitSWA($input: SWAInput!) {
    submitSWA(input: $input) {
      status
      swaId
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CategoryInput!) {
    createCategory(input: $input) {
      status
      __typename
    }
  }
`;
export const createSvc = /* GraphQL */ `
  mutation CreateSvc($input: SvcInput!) {
    createSvc(input: $input) {
      status
      __typename
    }
  }
`;
export const submitUseCase = /* GraphQL */ `
  mutation SubmitUseCase($input: UseCaseInput!) {
    submitUseCase(input: $input) {
      status
      useCaseId
      __typename
    }
  }
`;
export const submitInterestOrNot = /* GraphQL */ `
  mutation SubmitInterestOrNot($input: ResultInput!) {
    submitInterestOrNot(input: $input) {
      status
      resultId
      __typename
    }
  }
`;
export const updateUseCaseStatus = /* GraphQL */ `
  mutation UpdateUseCaseStatus($input: UseCaseStatusInput!) {
    updateUseCaseStatus(input: $input) {
      status
      __typename
    }
  }
`;
