import React, { useEffect, useState } from "react";
import {
  AppLayout,
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";
import { appSyncRequest } from "assets/js/request";
import { createReviewTask } from "graphql/mutations";
import { useHistory } from "react-router-dom";
import { isValidYearMonth, lastDayInYearAndMonth } from "assets/js/utils";

const buildDefaultDate = () => {
  const curDate = new Date();
  if (curDate.getMonth() === 0) {
    // if currrent month is Jan. return last year 202x-12
    return curDate.getFullYear() - 1 + "-12";
  } else {
    return (
        new Date().getFullYear() +
        "-" +
        curDate.getMonth().toString().padStart(2, "0")
    );
  }
};

const CreateCrossTeamReviewContent: React.FC = () => {
  const history = useHistory();
  const [startDate, setStartDate] = useState(buildDefaultDate());
  const [endDate, setEndDate] = useState(buildDefaultDate());
  const [numberOfPoCs, setNumberOfPoCs] = useState("2");
  const [creating, setCreating] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [startDateFormatError, setStartDateFormatError] = useState(false);
  const [endDateFormatError, setEndDateFormatError] = useState(false);

  const createCrossTeamReview = async () => {
    try {
      setCreating(true);
      const createCrossTeamRes = await appSyncRequest(createReviewTask, {
        input: {
          startDate: startDate,
          endDate: endDate,
          numberOfPoCs: numberOfPoCs,
        },
      });
      console.info("createCrossTeamRes:", createCrossTeamRes);
      setCreating(false);
      setShowConfirm(false);
      history.push({
        pathname: "/admin/cross-team-review",
      });
    } catch (error) {
      setCreating(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (isValidYearMonth(startDate)) {
      setStartDateFormatError(false);
    } else {
      setStartDateFormatError(true);
    }
    if (isValidYearMonth(endDate)) {
      setEndDateFormatError(false);
    } else {
      setEndDateFormatError(true);
    }
  }, [startDate, endDate]);

  return (
      <div>
        <Modal
            onDismiss={() => setShowConfirm(false)}
            visible={showConfirm}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                      variant="link"
                      onClick={() => {
                        setShowConfirm(false);
                      }}
                  >
                    Cancel
                  </Button>
                  <Button
                      loading={creating}
                      variant="primary"
                      onClick={() => {
                        createCrossTeamReview();
                      }}
                  >
                    Create
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Create cross-team review"
        >
          {`Select ${numberOfPoCs} ${
                  parseInt(numberOfPoCs) > 1 ? "PoCs" : "PoC"
          } within date range ${startDate}-01 ~ ${endDate}-${lastDayInYearAndMonth(
                  parseInt(endDate.split("-")[0]),
                  parseInt(endDate.split("-")[1])
          )}
            from each Senior SA manger
                to review.This is
          create
          a bunch of emails to reviewers. Are you sure to proceed?`}
        </Modal>
        <Container header={<Header variant="h2">Cross-team review</Header>}>
          <FormField
              description="Specify the start of the PoC submitted date."
              label="Start date"
              errorText={
                startDateFormatError
                    ? "Start date show be a date format like yyyy-MM."
                    : ""
              }
          >
            <Input
                className="m-w-45"
                value={startDate}
                onChange={(event) => {
                  setStartDate(event.detail.value);
                }}
            />
          </FormField>

          <FormField
              className="mt-20"
              description="Specify the end of the PoC submitted date."
              label="End date"
              errorText={
                endDateFormatError
                    ? "End date show be a date format like yyyy-MM."
                    : ""
              }
          >
            <Input
                className="m-w-45"
                value={endDate}
                onChange={(event) => {
                  setEndDate(event.detail.value);
                }}
            />
          </FormField>

          <FormField
              className="mt-20"
              label="Number of PoCs"
              description="Input the number of PoCs to select from each Senior SA manager."
          >
            <Input
                className="m-w-45"
                type="number"
                value={numberOfPoCs}
                onChange={(event) => {
                  setNumberOfPoCs(event.detail.value);
                }}
            />
          </FormField>
        </Container>

        <div className="mt-10">
          <div className="mt-20 pb-30 clearfix">
            <SpaceBetween className="fr" direction="horizontal" size="xs">
              <Button
                  variant="link"
                  onClick={() => {
                    history.push({
                      pathname: "/admin/cross-team-review",
                    });
                  }}
              >
                Cancel
              </Button>
              <Button
                  onClick={() => {
                    if (startDateFormatError || endDateFormatError) {
                      return;
                    }
                    setShowConfirm(true);
                  }}
                  variant="primary"
              >
                Create
              </Button>
            </SpaceBetween>
          </div>
        </div>
      </div>
  );
};

const CreateCrossTeamReview: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const pocReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Cross-team reviews",
      href: "/admin/cross-team-review",
    },
    {
      text: "Create a cross-team review",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocReviewBreadcrumbs}/>}
          content={<CreateCrossTeamReviewContent/>}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav defaultActiveHref="/admin/cross-team-review"/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CreateCrossTeamReview;
