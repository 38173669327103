import React, { useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { AppLayout } from "@amzn/awsui-components-react";

import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";

import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import DetailCompSWA from "./DetailCompSWA";

interface MatchParams {
  id: string;
  ctReviewId: string;
}

const SWADetail: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const id: string = props.match.params.id;

  const param = useLocation();
  const type: string = param.pathname.split("/")[1];

  let swaDetailBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];
  if (type === "swa-all") {
    swaDetailBreadcrumbs = [
      ...swaDetailBreadcrumbs,
      {
        text: "SWA",
        href: "/swa-all",
      },
    ];
  }
  if (type === "swa-assign-to-me") {
    swaDetailBreadcrumbs = [
      ...swaDetailBreadcrumbs,
      {
        text: "Assigned to me(SWA)",
        href: "/swa-assign-to-me",
      },
    ];
  }
  if (type === "swa-my-team") {
    swaDetailBreadcrumbs = [
      ...swaDetailBreadcrumbs,
      {
        text: "My team's SWA",
        href: "/swa-my-team",
      },
    ];
  }
  if (type === "my-swa") {
    swaDetailBreadcrumbs = [
      ...swaDetailBreadcrumbs,
      {
        text: "My SWA",
        href: "/my-swa",
      },
    ];
  }

  swaDetailBreadcrumbs = [
    ...swaDetailBreadcrumbs,
    {
      text: id.toUpperCase(),
      href: "",
    },
  ];

  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={swaDetailBreadcrumbs}/>}
          content={<DetailCompSWA swaId={id}/>}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default SWADetail;
