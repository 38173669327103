import React, { useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { AppLayout } from "@amzn/awsui-components-react";

import { Flashbar, FlashbarProps } from "@amzn/awsui-components-react/polaris";

import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";

import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import DetailComp from "./DetailComp";
import { PageType } from '../../types'

interface MatchParams {
  id: string;
  tab: string;
}

const PocDetail: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [notificationList, setNotificationList] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const id: string = props.match.params.id;
  const tab: string = props.match.params.tab;

  const param = useLocation();
  const type: string = param.pathname.split("/")[1];

  let pocDetailBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];

  if (type === PageType.ALL_POC) {
    pocDetailBreadcrumbs = [
      ...pocDetailBreadcrumbs,
      {
        text: "PoC",
        href: "/all-poc",
      },
    ];
  }
  if (type === PageType.ASSIGN_TO_ME) {
    pocDetailBreadcrumbs = [
      ...pocDetailBreadcrumbs,
      {
        text: "Assigned to me(PoC)",
        href: "/assign-to-me",
      },
    ];
  }
  if (type === "my-team") {
    pocDetailBreadcrumbs = [
      ...pocDetailBreadcrumbs,
      {
        text: "My team's PoC",
        href: "/my-team",
      },
    ];
  }
  if (type === "my-poc") {
    pocDetailBreadcrumbs = [
      ...pocDetailBreadcrumbs,
      {
        text: "My PoC",
        href: "/my-poc",
      },
    ];
  }

  pocDetailBreadcrumbs = [
    ...pocDetailBreadcrumbs,
    {
      text: id.toUpperCase(),
      href: "",
    },
  ];

  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocDetailBreadcrumbs}/>}
          content={
            <DetailComp
                addFlashNotification={(notification: any) => {
                  setNotificationList(notification);
                }}
                tabId={tab}
                pocId={id}
            />
          }
          notifications={<Flashbar items={notificationList}/>}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default PocDetail;
