import React, { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { AppLayout, FlashbarProps } from "@amzn/awsui-components-react";
import { format } from "date-fns";
import { appSyncRequest } from "assets/js/request";
import { getCustomers, getGroups } from "graphql/queries";
import {
  Box,
  Button,
  CollectionPreferences,
  Flashbar,
  Header,
  Pagination,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react/polaris";

import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";

import { CustomerAbstract, Group, GroupInput } from "API";
import { customerPageConfig, customerVisibleConfig } from "./customerListConfig";
import {
  DEFAULT_PAGE_SIZE,
  SIDE_MENU_IS_OPEN,
  CUSTOMER_LIST_PAGE_SIZE_ID,
  CUSTOMER_LIST_VISIBLE_COLUMNS_ID,
} from "assets/js/const";
import AppContext from "context/AppContext";
import CustomerAdvancedFilter from './comps/CustomerAdvancedFilter';
import { FilterTypes, TimeRangeTypes } from './comps/CustomerAdvancedFilter';

interface CustomerListContentProps {
  useCaseId: string;
  groupId: string;
  addFlashNotification: (notification: any[]) => void;
}

const CustomerListContent: React.FC<CustomerListContentProps> = (
    props: CustomerListContentProps
): JSX.Element => {
  let isItemSelected = false;
  const [loadingData, setLoadingData] = useState(false);
  const [customerList, setCustomerList] = useState<CustomerAbstract[]>([]);
  const [groupMap, setGroupMap] = useState(new Map());

  const [viewDetailDisable, setViewDetailDisable] = useState(true);
  const [selectedItems, setSelectedItems] = useState<CustomerAbstract[]>([]);
  const [selectedOneItem, setSelectedOneItem] = useState<CustomerAbstract>();

  const [advancedSearch, setAdvancedSearch] = useState<FilterTypes>();
  const [timeRangeSearch, setTimeRangeSearch] = useState<TimeRangeTypes>();
  // Pagination
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(
      parseInt(
          localStorage.getItem(CUSTOMER_LIST_PAGE_SIZE_ID) || DEFAULT_PAGE_SIZE.toString()) || DEFAULT_PAGE_SIZE
  );
  const [totalCount, setTotalCount] = useState(0);

  const [visibleColumnsList, setVisibleColumnsList] = useState<any>(
      localStorage.getItem(CUSTOMER_LIST_VISIBLE_COLUMNS_ID)?.split(",") || [
        "accountId",
        "accountName",
        "territory",
        "groupId",
        "createDateTime",
      ]
  );

  const history = useHistory();

  useEffect(() => {
    console.info("selectedItems:", selectedItems);
    if (selectedItems && selectedItems.length === 1) {
      setViewDetailDisable(false);
      setSelectedOneItem(selectedItems[0]);
    } else {
      setViewDetailDisable(true);
      setSelectedOneItem(undefined);
    }
  }, [selectedItems]);

  const goToEngage = () => {
    history.push({
      pathname: `/usecase-all/${props.useCaseId}/${selectedOneItem?.accountName}/engage`,
      state: {
        useCaseId: props.useCaseId,
        groupId: props.groupId,
        customerId: selectedOneItem?.accountId,
        customerName: selectedOneItem?.accountName
      },
    });
  };

  const getCustomerList = useCallback(async () => {
    try {
      setCustomerList([]);
      setLoadingData(true);

      const customerListDta: any = await appSyncRequest(getCustomers, {
        input: {
          groupId: props.groupId,
          accountIds: advancedSearch?.accountIds || undefined,
          startDate:
              (timeRangeSearch?.startTime &&
                  format(
                      new Date(timeRangeSearch?.startTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          endDate:
              (timeRangeSearch?.endTime &&
                  format(
                      new Date(timeRangeSearch?.endTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          curPage: curPage,
          pageSize: pageSize,
        },
      });
      setCustomerList(customerListDta?.data?.getCustomers?.customers || []);
      setTotalCount(customerListDta?.data?.getCustomers?.totalCount || 0);

      const groupData: any = await appSyncRequest(getGroups, {});
      console.info("groupData:", groupData);
      const tmpGroupList: GroupInput[] = groupData?.data?.getGroups || [];
      let tmpGroupMap = new Map();
      tmpGroupList.forEach((element) => {
        tmpGroupMap.set(element.groupId, element.groupName);
      });
      setGroupMap(tmpGroupMap);
      console.info("setGroupMap: ", groupMap.get('DB01'));
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, advancedSearch, timeRangeSearch]);


  useEffect(() => {
    getCustomerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, timeRangeSearch]);

  useEffect(() => {
    console.info("advancedSearch:", advancedSearch);
    if (advancedSearch) {
      if (curPage === 1) {
        getCustomerList();
      } else {
        setCurPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedSearch]);

  return (
      <>
        <Table
            trackBy="accountId"
            stickyHeader={true}
            resizableColumns={true}
            ariaLabels={{
              selectionGroupLabel: "Items selection",
              allItemsSelectionLabel: ({selectedItems}) =>
                  `${selectedItems?.length} ${
                      selectedItems?.length === 1 ? "item" : "items"
                  } selected`,
              itemSelectionLabel: ({selectedItems}, item) => {
                isItemSelected = selectedItems.filter(
                    (i) => i.accountId === item.accountId
                )?.length > 0;
                return `${item.accountId} is ${isItemSelected ? "" : "not"} selected`;
              },
            }}
            columnDefinitions={[
              {
                minWidth: 80,
                id: "accountId",
                header: "Customer ID",
                cell: (item) => (
                    <Button
                      href=""
                      variant="link"
                      onClick={() => {
                        history.push({
                          pathname: `/usecase-all/${props.useCaseId}/${item.accountName}/engage`,
                          state: {
                            useCaseId: props.useCaseId,
                            groupId: props.groupId,
                            customerId: item.accountId,
                            customerName: item.accountName
                          },
                        });
                      }}
                      >
                      {item.accountId}
                    </Button>
                ),
              },
              {
                minWidth: 120,
                id: "accountName",
                header: "Customer Name",
                cell: (e) => e.accountName,
              },
              {
                minWidth: 120,
                id: "groupId",
                header: "Service Group",
                cell: (e) => {
                  if (e.groupId !== undefined && e.groupId !== null) {
                    let st = e.groupId.split(',').map((cele) => (groupMap.get(cele))).join(',');
                    return st;
                  }
                },
              },
              {
                id: "createDateTime",
                header: "Create Time",
                minWidth: 120,
                cell: (e) => {
                  return e.createDateTime
                      ? format(
                          new Date(e.createDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
            ]}
            onSelectionChange={({detail}) =>
                setSelectedItems(detail.selectedItems)
            }
            loading={loadingData}
            selectedItems={selectedItems}
            items={customerList}
            loadingText="Loading Customers"
            selectionType="single"
            visibleColumns={visibleColumnsList}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No Customers</b>
                <Box padding={{bottom: "s"}} variant="p" color="inherit">
                  No Customers to display.
                </Box>
              </Box>
            }
            filter={
              <CustomerAdvancedFilter
                  groupId={props.groupId}
                  changeFilters={(filters) => {
                    setAdvancedSearch(filters);
                    console.info("filters:", filters);
                  }}
                  setStartLoading={(loading) => {
                    setLoadingData(loading);
                  }}
                  changeTimeRange={(timeRange) => {
                    setTimeRangeSearch(timeRange);
                  }}
              />
            }
            header={
              <Header
                  counter={
                    selectedItems?.length
                        ? "(" + selectedItems?.length + "/" + customerList?.length + ")"
                        : "(" + customerList?.length + ")"
                  }
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                          loading={loadingData}
                          disabled={loadingData}
                          iconName="refresh"
                          onClick={() => {
                            getCustomerList();
                          }}
                      ></Button>
                      <Button
                          disabled={viewDetailDisable}
                          variant="primary"
                          onClick={() => {
                            goToEngage();
                          }}
                      >
                        Input Interested or Not Interested
                      </Button>
                    </SpaceBetween>
                  }
              >
                Search Customer
              </Header>
            }
            pagination={
              <Pagination
                  disabled={loadingData}
                  currentPageIndex={curPage}
                  pagesCount={Math.ceil(totalCount / pageSize)}
                  onChange={(e) => {
                    setCurPage(e.detail.currentPageIndex);
                  }}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                  }}
              />
            }
            preferences={
              <CollectionPreferences
                  title="Preferences"
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
                  preferences={{
                    pageSize: pageSize,
                    visibleContent: visibleColumnsList,
                  }}
                  onConfirm={(event) => {
                    localStorage.setItem(
                        CUSTOMER_LIST_VISIBLE_COLUMNS_ID,
                        event.detail.visibleContent?.join(",") || ""
                    );
                    localStorage.setItem(
                        CUSTOMER_LIST_PAGE_SIZE_ID,
                        event.detail.pageSize?.toString() || DEFAULT_PAGE_SIZE.toString()
                    );
                    setCurPage(1);
                    setPageSize(event.detail.pageSize || DEFAULT_PAGE_SIZE);
                    setVisibleColumnsList(event.detail.visibleContent);
                  }}
                  pageSizePreference={customerPageConfig}
                  visibleContentPreference={customerVisibleConfig}
              />
            }
        />
      </>
  );
};

interface MatchParams {
  id: string;
  group_id: string;
}

const CustomerList: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const id: string = props.match.params.id;
  const group_id: string = props.match.params.group_id;

  console.info("group_id: ", group_id);

  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [notificationList, setNotificationList] = useState<FlashbarProps.MessageDefinition[]>([]);

  const param = useLocation();
  const type: string = param.pathname.replace("/", "");
  const pathStr = param.pathname;

  console.info("pathStr", pathStr);

  let useCaseListBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];

  useCaseListBreadcrumbs = [
    ...useCaseListBreadcrumbs,
    {
      text: id.toUpperCase(),
      href: "/usecase-all/detail/" + id.toUpperCase(),
    },
    {
      text: "Search Customer",
      href: ""
    }
  ];

  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={useCaseListBreadcrumbs}/>}
          notifications={<Flashbar items={notificationList}/>}
          content={
            <CustomerListContent
                useCaseId={id}
                groupId={group_id}
                addFlashNotification={(notification: any) => {
                  setNotificationList(notification);
                }}
            />
          }
          navigation={
            <SideNav
                defaultActiveHref={
                  pathStr === "/my-usecase" ? "/my-usecase" : type ? "/" + type : "/"
                }
            />
          }
          navigationOpen={navigationOpen}
          stickyNotifications
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CustomerList;
