import React, { useEffect, useState } from "react";
import { Checkbox, FormField, Spinner, } from "@amzn/awsui-components-react/polaris";
import { appSyncRequest } from "assets/js/request";
import { getAllTags } from "graphql/queries";

export interface TagType {
  // industryCheckedList: string[];
  // technologyCheckedList: string[];
  checked: boolean;
  tagId: string;
  tagName: string;
}

// export interface TechIndustryType: string;
interface TechIndustryProps {
  updateIndustryList: (industryList: any) => void;
  updateTechList: (techList: any) => void;
  industryCheckedList: any[];
  technologyCheckedList: any[];
  hideTech?: boolean;
}

const TechIndustry: React.FC<TechIndustryProps> = (
    props: TechIndustryProps
) => {
  const {
    updateIndustryList,
    updateTechList,
    industryCheckedList,
    technologyCheckedList,
    hideTech,
  } = props;
  const [industryList, setIndustryList] = useState<TagType[]>([]);
  const [technicalList, setTechnicalList] = useState<TagType[]>([]);
  // const [industryChecked, setIndustryChecked] = useState(false);
  // const [technicalChecked, setTechnicalChecked] = useState(false);
  const [loadingData, setloadingData] = useState(false);

  const getTagList = async () => {
    try {
      setloadingData(true);
      const tagList: any = await appSyncRequest(getAllTags);
      console.info("tagList:", tagList);
      setloadingData(false);
      // Set Industry Default Checked
      const tmpCheckIndustry = industryCheckedList.map(
          (checkItem) => checkItem.tagId
      );
      const tmpCheckTech = technologyCheckedList.map(
          (checkItem) => checkItem.tagId
      );
      if (
          tagList &&
          tagList.data &&
          tagList.data.getAllTags &&
          tagList.data.getAllTags.industryTagList &&
          tagList.data.getAllTags.industryTagList.length > 0
      ) {
        tagList.data.getAllTags.industryTagList.forEach((element: TagType) => {
          // element.tagId
          if (tmpCheckIndustry.includes(element.tagId)) {
            element.checked = true;
          }
        });
      }

      if (
          tagList &&
          tagList.data &&
          tagList.data.getAllTags &&
          tagList.data.getAllTags.technicalTagList &&
          tagList.data.getAllTags.technicalTagList.length > 0
      ) {
        tagList.data.getAllTags.technicalTagList.forEach((element: TagType) => {
          // element.tagId
          if (tmpCheckTech.includes(element.tagId)) {
            element.checked = true;
          }
        });
      }

      // Set Technical Default Checked
      setIndustryList(tagList?.data?.getAllTags?.industryTagList || []);
      setTechnicalList(tagList?.data?.getAllTags?.technicalTagList || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTagList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const updateParamentTagChecked = () => {

  // }
  useEffect(() => {
    const parentTechList: any = [];
    if (technicalList && technicalList.length > 0) {
      technicalList.forEach((element) => {
        if (element.checked) {
          parentTechList.push({
            tagId: element.tagId,
            tagName: element.tagName,
          });
        }
      });
      updateTechList(parentTechList);
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicalList]);

  useEffect(() => {
    const parentIndustryList: any = [];
    if (industryList && industryList.length > 0) {
      industryList.forEach((element) => {
        if (element.checked) {
          parentIndustryList.push({
            tagId: element.tagId,
            tagName: element.tagName,
          });
        }
      });
      updateIndustryList(parentIndustryList);
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryList]);

  const handleTechCheckElement = (element: TagType, event: any) => {
    const tmpTechList = JSON.parse(JSON.stringify(technicalList));
    tmpTechList.forEach((tech: TagType) => {
      if (tech.tagId === element.tagId) tech.checked = event.detail.checked;
    });
    // updateTechList(tmpTechList);
    setTechnicalList(tmpTechList);
  };

  const handleIndustryCheckElement = (element: TagType, event: any) => {
    const tmpIndustryList = JSON.parse(JSON.stringify(industryList));
    tmpIndustryList.forEach((tech: TagType) => {
      if (tech.tagId === element.tagId) tech.checked = event.detail.checked;
    });
    // updateIndustryList(tmpIndustryList);
    setIndustryList(tmpIndustryList);
  };

  return (
      <>
        {!hideTech && (
            <FormField
                // className="mt-20"
                label={
                  <span>
              Technology Category<i>- optional</i>
            </span>
                }
            >
              {loadingData ? (
                  <Spinner size="normal"/>
              ) : (
                  <div className="flex flex-wrap">
                    {technicalList.map((element, index) => {
                      return (
                          <div key={index} className="mt-5 w-25">
                            <Checkbox
                                onChange={(event) =>
                                    // setTechnicalChecked(detail.checked)
                                    handleTechCheckElement(element, event)
                                }
                                checked={element.checked}
                            >
                              <div className="char-capital">{element.tagName}</div>
                            </Checkbox>
                          </div>
                      );
                    })}
                  </div>
              )}
            </FormField>
        )}

        <FormField
            className={hideTech ? "" : "mt-20"}
            label={
              <span>
            Industry Category<i>- optional</i>
          </span>
            }
        >
          {loadingData ? (
              <Spinner size="normal"/>
          ) : (
              <div className="flex flex-wrap">
                {industryList.map((element, index) => {
                  return (
                      <div key={index} className="mt-5 w-25">
                        <Checkbox
                            onChange={(event) =>
                                handleIndustryCheckElement(element, event)
                            }
                            checked={element.checked}
                        >
                          {element.tagName}
                        </Checkbox>
                      </div>
                  );
                })}
              </div>
          )}
        </FormField>
      </>
  );
};

export default TechIndustry;
