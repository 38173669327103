import { IndustryInput, UseCaseInput } from 'API';
import React, { useEffect, useRef, useState } from 'react';
import {
  ATTACHMENT_FILE_TYPES,
  LengthLimit,
  LengthLimitSWA,
  MAX_FILE_SIZE,
  SWAAttachmentTypeList
} from 'assets/js/const';
import Swal from 'sweetalert2';
import { Storage } from 'aws-amplify';
import { Header } from '@amzn/awsui-components-react/polaris';
import {
  Autosuggest,
  Button, Checkbox,
  Container,
  FormField,
  Input,
  ProgressBar, Spinner,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { humanFileSize, isValidUrl } from 'assets/js/utils';
import { IndustryType } from './StepAccountInfo';
import { appSyncRequest } from 'assets/js/request';
import { getIndustries } from 'graphql/queries';

interface StepAttachementsProps {
  curUseCase: UseCaseInput;
  showIndustryError: boolean;
  changeIndustryInUse: (industry: IndustryInput[]) => void;
  setIndustryIsLoading: (loading: boolean) => void;
  addNewAttachment: (item: any) => void;
  removeAttachment: (index: number) => void;
  changeAttachmentDesc: (index: number, desc: string) => void;
  uploadAttachment: (progress: any, index: number) => void;
  uploadAttachmentDone: (
      index: number,
      name: string,
      size: string,
      key: string
  ) => void;
  changeIsUploading: (uploading: boolean) => void;
  addInputError: (error: string) => void;
  removeInputError: (error: string) => void;
}

const StepUseCaseAttachements: React.FC<StepAttachementsProps> = (props: StepAttachementsProps) => {
  const {
    curUseCase,
    showIndustryError,
    changeIndustryInUse,
    setIndustryIsLoading,
    addNewAttachment,
    removeAttachment,
    changeAttachmentDesc,
    uploadAttachment,
    uploadAttachmentDone,
    changeIsUploading,
    addInputError,
    removeInputError,
  } = props;

  const [industryList, setIndustryList] = useState<IndustryType[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  const getIndustry = async () => {
    try {
      setLoadingData(true);
      setIndustryIsLoading(true);
      const industryList: any = await appSyncRequest(getIndustries);
      console.info("industryList:", industryList);
      const tmpIndustryList: IndustryType[] = industryList?.data?.getIndustries || [];
      const industryInUseList = curUseCase.industryInUseList || [];
      tmpIndustryList.forEach((element) => {
        if (industryInUseList.map((cele) => cele?.industryId).indexOf(element.industryId) >= 0) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      setIndustryList(industryList?.data?.getIndustries || []);
      setLoadingData(false);
      setIndustryIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getIndustry();
  }, []);

  useEffect(() => {
    console.info("industryList:", industryList);
    const tmpIndustryArr: IndustryInput[] = [];
    industryList.forEach((element) => {
      if (element.checked) {
        tmpIndustryArr.push({
          industryId: element.industryId,
          industryName: element.industryName,
        });
      }
    });
    changeIndustryInUse(tmpIndustryArr);
  }, [industryList]);


  const [attachmentIsUploading, setAttachmentIsUploading] = useState(false);
  const attachementRefs = useRef<any[]>(
      Array.from(
          {length: curUseCase?.attachments?.length || 0},
          (any) => React.createRef() as any
      )
  );

  const downloadFile = async (fileKey: string) => {
    const downloadUrl = await Storage.get(fileKey, {expires: 600});
    window.open(downloadUrl.toString(), "_blank");
  };

  const handleAttachmentFileClick = (event: any, index: number) => {
    console.info("event:", event);
    console.info("attachementRefs:", attachementRefs);
    attachementRefs.current[index].click();
  };

  const handleAttachmentFileChange = (event: any, index: number) => {
    console.info("handleAttachmentFileChange event:", event);
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > MAX_FILE_SIZE) {
      Swal.fire("Oops...", "File size must less than 100MB", "warning");
      return;
    }
    if (fileUploaded) {
      const s3FileKey = `${curUseCase.useCaseId}/assets/${fileUploaded.name}`;
      // setAttachmentIsUploading
      setAttachmentIsUploading(true);
      console.info("fileUploaded:", fileUploaded);
      Storage.put(s3FileKey, fileUploaded, {
        contentType: fileUploaded.type,
        progressCallback(progress: any) {
          const upProgress = (progress.loaded / progress.total) * 100;
          uploadAttachment(upProgress, index);
        },
      })
          .then((result: any) => {
            uploadAttachmentDone(
                index,
                fileUploaded.name,
                fileUploaded.size,
                result.key
            );
            setAttachmentIsUploading(false);
          })
          .catch((err) => {
            setAttachmentIsUploading(false);
            console.error("err:", err);
          });
    }
  };

  useEffect(() => {
    console.info("attachmentIsUploading:", attachmentIsUploading);
    changeIsUploading(attachmentIsUploading);
  }, [attachmentIsUploading]);

  return (
      <div>
        <Header variant="h1">Industry &amp; attachments</Header>
        <div className="mt-20">
          <Container header={<Header variant="h2">Industry Category</Header> }>
            {loadingData ? (
                <Spinner/>
            ) : (
                <FormField
                    label="Choose Industry"
                    description= "Should be multiple choices since it is possible that for one industry, more than 1 industry is adopted."
                    errorText={
                      showIndustryError ? "Please select at least one industry" : ""
                    }
                >
                  <div className="mt-10 mb-10">
                    <div className="flex flex-wrap">
                      {industryList.map((element, index) => {
                        return (
                            <div key={index} className="mt-5 w-25">
                              <Checkbox
                                  onChange={(event) => {
                                    console.info("event:", event);
                                    setIndustryList((prev) => {
                                      const tmpList = JSON.parse(JSON.stringify(prev));
                                      tmpList[index].checked = event.detail.checked;
                                      return tmpList;
                                    });
                                  }}
                                  checked={element.checked || false}
                              >
                                <div className="char-capital">
                                  {element.industryName}
                                </div>
                              </Checkbox>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                </FormField>
            )}
          </Container>
        </div>
        <div className="mt-20">
          <Container
              header={
                <Header
                    variant="h2"
                    description={`You can upload up to 5 attachments, and the size of each attachment must less than 100M. File format support: ${ATTACHMENT_FILE_TYPES}.`}
                >
                  Attachments
                </Header>
              }
          >
            <div>
              <div className="mt-20 flex attachment">
                <div className="upload">Attachment</div>
                <div className="select">Name - optional</div>
                <div className="remove"></div>
              </div>
              {curUseCase?.attachments?.map((element: any, index: number) => {
                return (
                    <div key={index} className="mt-10 flex attachment">
                      <div className="upload m-h-50 pt-4">
                        <div className={element?.showProgress ? "show" : "hide"}>
                          <ProgressBar
                              status="in-progress"
                              value={element?.progress}
                              additionalInfo=""
                              description=""
                              label=""
                          />
                        </div>
                        <div className={!element?.showProgress ? "show" : "hide"}>
                          <Button
                              onClick={(event) => {
                                handleAttachmentFileClick(event, index);
                              }}
                              iconAlign="left"
                              iconName="upload"
                              variant="normal"
                          >
                            Choose File
                          </Button>
                          <input
                              ref={(el) => (attachementRefs.current[index] = el)}
                              onChange={(event) => {
                                handleAttachmentFileChange(event, index);
                              }}
                              accept={ATTACHMENT_FILE_TYPES}
                              type="file"
                              style={{display: "none"}}
                          />
                          <div>
                            {element?.attachmentFilename && (
                                <div
                                    onClick={() => {
                                      downloadFile(element?.attachmentUrl || "");
                                    }}
                                    className="file-name"
                                >
                                  <StatusIndicator type="success">
                                    {element?.attachmentFilename}
                                  </StatusIndicator>
                                </div>
                            )}
                            {element?.attachmentSize && (
                                <div className="file-size">
                                  {humanFileSize(element?.attachmentSize)}
                                </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="select">
                        <FormField
                            errorText={
                              (element?.attachmentDesc?.length || 0) >
                              LengthLimit.attachmentDesc
                                  ? `Name must be less than ${LengthLimit.attachmentDesc} characters`
                                  : ""
                            }
                        >
                          <Autosuggest
                              onChange={({detail}) => {
                                if (
                                    detail.value.length > LengthLimit.attachmentDesc
                                ) {
                                  addInputError("swaAttachmentDescError_" + index);
                                } else {
                                  removeInputError("swaAttachmentDescError_" + index);
                                }
                                changeAttachmentDesc(index, detail.value);
                              }}
                              value={element?.attachmentDesc || ""}
                              options={SWAAttachmentTypeList}
                              enteredTextLabel={(value) => `${value}`}
                              ariaLabel="Autosuggest example with suggestions"
                              placeholder="Enter value"
                              empty="No matches found"
                          />
                        </FormField>
                      </div>
                      <div className="remove  pt-4">
                        <Button
                            disabled={element?.showProgress}
                            onClick={() => {
                              if (curUseCase && curUseCase.attachments) {
                                removeAttachment(index);
                              }
                            }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                );
              })}

              {curUseCase && curUseCase.attachments && curUseCase.attachments.length < 5 && (
                  <div className="add-new">
                    <Button
                        onClick={() => {
                          addNewAttachment({
                            attachmentFilename: "",
                            attachmentUrl: "",
                            attachmentSize: "",
                            attachmentDesc: "",
                          });
                        }}
                    >
                      Add Attachment
                    </Button>
                  </div>
              )}
            </div>
          </Container>
        </div>
      </div>
  );
};

export default StepUseCaseAttachements;