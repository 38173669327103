import React from "react";
import { StatusIndicator } from "@amzn/awsui-components-react/polaris";
import { SubmitMode } from "API";

interface UseCaseStatusProp {
  UseCaseStatus: SubmitMode | undefined | null;
}

const UseCaseStatus: React.FC<UseCaseStatusProp> = (props: UseCaseStatusProp) => {
  const {UseCaseStatus} = props;
  return (
      <div>
        {UseCaseStatus === SubmitMode.Submit && (
            <StatusIndicator type="success">Submit</StatusIndicator>
        )}
        {UseCaseStatus === SubmitMode.Draft && (
            <StatusIndicator type="stopped">Draft</StatusIndicator>
        )}
        {UseCaseStatus === SubmitMode.Abandon && (
            <StatusIndicator type="warning">Abandon</StatusIndicator>
        )}
      </div>
  );
};

export default UseCaseStatus;
