import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, ButtonDropdown, Checkbox, Modal, SpaceBetween, } from "@amzn/awsui-components-react";
import { Auth } from "aws-amplify";

import SignOut from "./SignOut";
import AppContext from "context/AppContext";
import { UserRole } from "API";
import { useHistory } from "react-router-dom";
import { USER_ACK_SWA_DEFININATION } from "assets/js/const";

const Header: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const [showError, setShowError] = useState(false);
  const appConfig = useContext(AppContext);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
        .then((user) => {
          setUserName(`${user.attributes.given_name}`);
        })
        .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (history.location.pathname === "/swa/create") {
      const userACK = window.localStorage.getItem(USER_ACK_SWA_DEFININATION);
      if (!userACK) {
        setShowConfirm(true);
        setAcknowledge(false);
        setShowError(false);
        setIsAcknowledge(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
      <header
          id="h"
          className={
            process.env.REACT_APP_SHOW_TOP_BANNER === "show" ? "top-banner" : ""
          }
      >
        <div className="poc-header">
          <div className="logo">GCR SA Assets Library</div>
          <div className="top-user">
            Welcome, <b>{userName}</b>
            {process.env.REACT_APP_SHOW_ROLE === "show" && (
                <span> [{appConfig.userRole}] </span>
            )}
          </div>
          <SignOut className="logout"/>
          {appConfig.userRole === UserRole.Contributor && (
              <ButtonDropdown

                  onItemClick={(item) => {
                    console.info(item);
                    if (item.detail.id === "poc") {
                      history.push({
                        pathname: "/poc/create",
                      });
                    } else {
                      setShowConfirm(true);
                      setAcknowledge(false);
                      setShowError(false);
                      setIsAcknowledge(false);
                    }
                  }}
                  items={[
                    {
                      text: "New PoC",
                      id: "poc"
                    },
                    {
                      text: "New SWA",
                      id: "swa",
                    },
                  ]}
                  variant="primary"
              >
                New Asset
              </ButtonDropdown>
          )}
          {appConfig.userRole === UserRole.SO_TFC_leader && (
              <ButtonDropdown

                  onItemClick={(item) => {
                    console.info(item);
                    if (item.detail.id === "usecase") {
                      history.push({
                        pathname: "/usecase/create",
                      });
                    } else {
                      setShowConfirm(true);
                      setAcknowledge(false);
                      setShowError(false);
                      setIsAcknowledge(false);
                    }
                  }}
                  items={[
                    {
                      text: "New Use Case",
                      id: "usecase",
                    },
                  ]}
                  variant="primary"
              >
                New Asset
              </ButtonDropdown>
          )}
        </div>
        <Modal
            onDismiss={(event) => {
              if (history.location.pathname === "/swa/create") {
                if (event.detail.reason === "closeButton") {
                  setShowConfirm(false);
                  window.location.href = "/";
                }
              } else {
                setShowConfirm(false);
              }
              console.info("AAA:", event.detail.reason);
              // setShowConfirm(false);
            }}
            visible={showConfirm}
            closeAriaLabel="Close modal"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                      variant="link"
                      onClick={() => {
                        if (history.location.pathname === "/swa/create") {
                          setShowConfirm(false);
                          window.location.href = "/";
                        } else {
                          setShowConfirm(false);
                        }
                      }}
                  >
                    Cancel
                  </Button>
                  <Button
                      variant="primary"
                      onClick={() => {
                        if (!isAcknowledge) {
                          setShowError(true);
                        } else {
                          window.localStorage.setItem(
                              USER_ACK_SWA_DEFININATION,
                              "acknowledged"
                          );
                          setShowConfirm(false);
                          history.push({
                            pathname: "/swa/create",
                          });
                        }
                      }}
                  >
                    Create
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Create SWA"
        >
          <Alert visible={true} header="Definition of SWA">
            <div>
              A workload is an application in <b className="orange">production</b>{" "}
              in customer account. Such as HR, eCommerce, Supply Chain. It
              contains a purely new workload migrating from competitors, or an
              existing workload that optimized to adopt managed service by a SA.
            </div>
            <div className="mt-20">
              <Checkbox
                  onChange={({detail}) => {
                    setAcknowledge(detail.checked);
                    setIsAcknowledge(detail.checked);
                    setShowError(false);
                  }}
                  checked={acknowledge}
              >
                <b>
                  I acknowledge the application is deployed in production
                  enviroment.
                </b>
              </Checkbox>
            </div>
          </Alert>
          <Alert className="mt-10" visible={showError} type="error">
            Please acknowledge all checkboxes before proceeding.
          </Alert>
        </Modal>
      </header>
  );
};

export default Header;
