import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import {
  AppLayout,
  Button,
  Container,
  DatePicker,
  FormField,
  Header,
  SegmentedControl,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { AMPLIFY_CONFIG_JSON, SIDE_MENU_IS_OPEN } from "assets/js/const";
import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";
import { appSyncRequest } from "assets/js/request";
import { exportPoCsReportResolver, exportResultReportResolver, exportSWAsReportResolver } from "graphql/queries";
import { SelectType } from '../../types'

const getDateTime = (dateStr: string) => {
  return new Date(dateStr).getTime();
};

const ReportDownloadContent: React.FC = () => {
  const [selectedId, setSelectedId] = useState("PoC");

  const [startDate, setStartDate] = useState(
      format(new Date(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
      format(new Date(new Date()), "yyyy-MM-dd")
  );
  const [downloading, setDownloading] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  useEffect(() => {
    if (getDateTime(startDate) > getDateTime(endDate)) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
      if (getDateTime(startDate) > new Date().getTime()) {
        setStartDateError(true);
      } else {
        setStartDateError(false);
      }
      if (getDateTime(endDate) > new Date().getTime()) {
        setEndDateError(true);
      } else {
        setEndDateError(false);
      }
    }
  }, [startDate, endDate]);

  const downloadReport = async () => {
    const configJsonStr = localStorage.getItem(AMPLIFY_CONFIG_JSON) || "";
    const configObj = JSON.parse(configJsonStr);
    if (!configObj.bucket) {
      Swal.fire("Oops...", "Report download error", "warning");
      return;
    }
    console.info("bucket: ", configObj.bucket);
    if (startDateError || endDateError) {
      return;
    }
    try {
      setDownloading(true);

      switch (selectedId) {
        case SelectType.PoC:
          const exportPocReportRes = await appSyncRequest(exportPoCsReportResolver, {
            input: {
              bucketName: configObj.bucket,
              prefix: "public/report-download",
              startDateTime: startDate,
              endDateTime: endDate,
            },
          });
          if (
              exportPocReportRes &&
              exportPocReportRes.data &&
              exportPocReportRes.data.exportPoCsReportResolver &&
              exportPocReportRes.data.exportPoCsReportResolver.url
          ) {
            let reportKey = exportPocReportRes.data.exportPoCsReportResolver.url;
            if (reportKey.startsWith("public/")) {
              reportKey = reportKey.replace("public/", "");
            }
            const downloadUrl = await Storage.get(reportKey, {expires: 600});
            window.open(downloadUrl.toString(), "_blank");
          } else {
            Swal.fire("Oops...", "PoC Report download error", "warning");
          }
          break;
        case SelectType.SWA:
          const exportSWAReportRes = await appSyncRequest(exportSWAsReportResolver, {
            input: {
              bucketName: configObj.bucket,
              prefix: "public/report-download",
              startDateTime: startDate,
              endDateTime: endDate,
            },
          });
          if (
              exportSWAReportRes &&
              exportSWAReportRes.data &&
              exportSWAReportRes.data.exportSWAsReportResolver &&
              exportSWAReportRes.data.exportSWAsReportResolver.url
          ) {
            let reportKey = exportSWAReportRes.data.exportSWAsReportResolver.url;
            if (reportKey.startsWith("public/")) {
              reportKey = reportKey.replace("public/", "");
            }
            const downloadUrl = await Storage.get(reportKey, {expires: 600});
            window.open(downloadUrl.toString(), "_blank");
          } else {
            Swal.fire("Oops...", "SWA Report download error", "warning");
          }
          break;
        case SelectType.Engagement:
          const exportEngagementReportRes = await appSyncRequest(exportResultReportResolver, {
            input: {
              bucketName: configObj.bucket,
              prefix: "public/report-download",
              startDateTime: startDate,
              endDateTime: endDate,
            },
          });
          if (
              exportEngagementReportRes &&
              exportEngagementReportRes.data &&
              exportEngagementReportRes.data.exportResultReportResolver &&
              exportEngagementReportRes.data.exportResultReportResolver.url
          ) {
            let reportKey = exportEngagementReportRes.data.exportResultReportResolver.url;
            if (reportKey.startsWith("public/")) {
              reportKey = reportKey.replace("public/", "");
            }
            const downloadUrl = await Storage.get(reportKey, {expires: 600});
            window.open(downloadUrl.toString(), "_blank");
          } else {
            Swal.fire("Oops...", "SWA Report download error", "warning");
          }
          break;
      }
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error(error);
    }
  };

  return (
      <div>
        <Container header={<Header variant="h2">Report Download</Header>}>
          <div className="m-h-50">
            <SegmentedControl
                selectedId={selectedId}
                onChange={({detail}) => setSelectedId(detail.selectedId)}
                options={[
                  {text: SelectType.PoC.toString(), id: SelectType.PoC.toString()},
                  {text: SelectType.SWA.toString(), id: SelectType.SWA.toString()},
                  {text: SelectType.Engagement.toString(), id: SelectType.Engagement.toString()}
                ]}
            />
          </div>
          <FormField
              description="Specify the start of the asset submitted date."
              label="Start date"
              errorText={
                startDateError
                    ? "Start date cannot be later than current date and end date."
                    : ""
              }
          >
            <DatePicker
                isDateEnabled={(date) => {
                  return new Date(date).getTime() < new Date().getTime();
                }}
                onChange={({detail}) => setStartDate(detail.value)}
                value={startDate}
                openCalendarAriaLabel={(selectedDate) =>
                    "Choose Date" +
                    (selectedDate ? `, selected date is ${selectedDate}` : "")
                }
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
            />
          </FormField>

          <FormField
              className="mt-20"
              description="Specify the end of the asset submitted date."
              label="End date"
              errorText={
                endDateError ? "End date cannot be later than current date." : ""
              }
          >
            <DatePicker
                isDateEnabled={(date) => {
                  return new Date(date).getTime() < new Date().getTime();
                }}
                onChange={({detail}) => setEndDate(detail.value)}
                value={endDate}
                openCalendarAriaLabel={(selectedDate) =>
                    "Choose Date" +
                    (selectedDate ? `, selected date is ${selectedDate}` : "")
                }
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
            />
          </FormField>
        </Container>

        <div className="mt-10">
          <div className="mt-20 pb-30 clearfix">
            <SpaceBetween className="fr" direction="horizontal" size="xs">
              <Button
                  loading={downloading}
                  onClick={() => {
                    downloadReport();
                  }}
                  variant="primary"
              >
                Download
              </Button>
            </SpaceBetween>
          </div>
        </div>
      </div>
  );
};

const ReportDownload: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const pocReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Report Download",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocReviewBreadcrumbs}/>}
          content={<ReportDownloadContent/>}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav defaultActiveHref="/admin/report-download"/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default ReportDownload;
