import React from "react";
import { Container, FormField, Header, RadioGroup, Textarea, } from "@amzn/awsui-components-react/polaris";
import { ReviewResponse } from "API";

interface StepOverviewProps {
  currentReview: ReviewResponse | undefined;
  changeReviewOverview: (item: any) => void;
  setLengthError: (isError: boolean) => void;
  pocId: string;
}

const StepOverview: React.FC<StepOverviewProps> = (
    props: StepOverviewProps
) => {
  const {currentReview, changeReviewOverview, setLengthError} = props;

  return (
      <div className="mt-10">
        <Container header={<Header variant="h2">PoC Overview</Header>}>
          <FormField
              description="Choose Yes if it is necessary for the SA to do the PoC."
              label="Is the PoC necessary?"
          >
            <RadioGroup
                className="gsal-radio-group"
                onChange={({detail}) => {
                  if (currentReview) {
                    const tmpData = JSON.parse(JSON.stringify(currentReview));
                    tmpData.isNecessary = detail.value;
                    changeReviewOverview(tmpData);
                  }
                }}
                value={currentReview?.isNecessary?.toString() || null}
                items={[
                  {value: "true", label: "Yes"},
                  {value: "false", label: "No"},
                ]}
            />
          </FormField>

          <div className="mt-30">
            <FormField
                description="Choose Yes if the PoC can be used for other customers."
                label="Is the PoC repeatable?"
            >
              <RadioGroup
                  className="gsal-radio-group"
                  onChange={({detail}) => {
                    if (currentReview) {
                      const tmpData = JSON.parse(JSON.stringify(currentReview));
                      tmpData.isRepeatable = detail.value;
                      changeReviewOverview(tmpData);
                    }
                  }}
                  value={currentReview?.isRepeatable?.toString() || null}
                  items={[
                    {value: "true", label: "Yes"},
                    {value: "false", label: "No"},
                  ]}
              />
            </FormField>
          </div>

          <div className="mt-30">
            <FormField
                description="Choose Yes if the PoC has all steps & code and is automated (e.g. Amazon CloudFormation, AWS CDK, Terraform)."
                label="Is the PoC packaged up?"
            >
              <RadioGroup
                  className="gsal-radio-group"
                  onChange={({detail}) => {
                    if (currentReview) {
                      const tmpData = JSON.parse(JSON.stringify(currentReview));
                      tmpData.isPackageUp = detail.value;
                      changeReviewOverview(tmpData);
                    }
                  }}
                  value={currentReview?.isPackageUp?.toString() || null}
                  items={[
                    {value: "true", label: "Yes"},
                    {value: "false", label: "No"},
                  ]}
              />
            </FormField>
          </div>

          <div className="mt-30">
            <FormField
                description="The overall comment of the PoC."
                label="Overall Comment"
                errorText={
                  (currentReview?.comments?.length || 0) > 256
                      ? "Comments must be less than 256 characters."
                      : ""
                }
            >
              <Textarea
                  onChange={({detail}) => {
                    if (currentReview) {
                      const tmpData = JSON.parse(JSON.stringify(currentReview));
                      if (detail.value.length > 256) {
                        setLengthError(true);
                      } else {
                        setLengthError(false);
                      }
                      tmpData.comments = detail.value;
                      changeReviewOverview(tmpData);
                    }
                  }}
                  value={currentReview?.comments || ""}
                  placeholder="Input the overall comments of the PoC"
              />
            </FormField>
          </div>
        </Container>
      </div>
  );
};

export default StepOverview;
