import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";

import { ColumnLayout, Container, Header, Link, SpaceBetween, Spinner, } from "@amzn/awsui-components-react/polaris";
import ValueWithLabel from "common/ValueWithLabel";
import { PoC, Tag } from "API";
import { handleNullValue, isValidUrl } from "assets/js/utils";

interface AssetsProps {
  pocId: string;
  loading: boolean;
  pocInfo: PoC | undefined;
}

const buildTagDisplay = (list: any) => {
  if (list && list.length > 0) {
    const resList: string[] = [];
    list.forEach((element: Tag) => {
      const tagName = element.tagName || "";
      resList.push(tagName.slice(0, 1).toUpperCase() + tagName.slice(1));
    });
    return resList.join(", ");
  }
  return "";
};

const Assets: React.FC<AssetsProps> = (props: AssetsProps) => {
  const {pocInfo, loading} = props;
  const [archImgUrl, setArchImgUrl] = useState("");

  const downloadFile = async (fileKey: string) => {
    // Creates download url that expires in 5 minutes/ 300 seconds
    const downloadUrl = await Storage.get(fileKey, {expires: 600});
    // window.location.href = downloadUrl.toString();
    window.open(downloadUrl.toString(), "_blank");
  };

  // Show Arch Image When Archimage Change
  useEffect(() => {
    if (pocInfo?.architectImgUrl) {
      Storage.get(pocInfo.architectImgUrl, {expires: 300}).then((res) => {
        console.info("res:", res);
        setArchImgUrl(res.toString());
      });
    }
    return () => {
    };
  }, [pocInfo?.architectImgUrl]);

  return (
      <div>
        <Container header={<Header variant="h2">Overview</Header>}>
          {loading ? (
              <Spinner size="normal"/>
          ) : (
              <div>
                <ColumnLayout columns={4} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Technology Category">
                      {buildTagDisplay(pocInfo?.technicalTagList) || "-"}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Industry Category">
                      {buildTagDisplay(pocInfo?.industryTagList) || "-"}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Source Code">
                      {pocInfo?.sourceCodeUrl ? (
                          isValidUrl(pocInfo?.sourceCodeUrl) ? (
                              <Link href={`${pocInfo?.sourceCodeUrl}`} external={true}>
                                Link
                              </Link>
                          ) : (
                              <span>{pocInfo?.sourceCodeUrl}</span>
                          )
                      ) : (
                          "-"
                      )}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="User Guide">
                      {pocInfo?.userGuideUrl ? (
                          isValidUrl(pocInfo?.userGuideUrl) ? (
                              <Link href={`${pocInfo?.userGuideUrl}`} external={true}>
                                Link
                              </Link>
                          ) : (
                              <span>{pocInfo?.userGuideUrl}</span>
                          )
                      ) : (
                          "-"
                      )}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="What does this PoC Do?">
                      {handleNullValue(pocInfo?.userScenarioDesc)}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="What is the Scope of this Poc?">
                      {handleNullValue(pocInfo?.pocScope)}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Architect Diagram">
                      <div className="assets-arch mt-10">
                        <img
                            alt={pocInfo?.architectFilename || ""}
                            src={archImgUrl || ""}
                            className="arch-img"
                        />
                      </div>
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Architect Diagram Description">
                      <p>{handleNullValue(pocInfo?.architectDesc)}</p>
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Attachments">
                      {pocInfo?.attachments
                          ? pocInfo.attachments.map((element, index) => {
                            return (
                                <span
                                    key={index}
                                    onClick={() => {
                                      downloadFile(element?.attachmentUrl || "");
                                    }}
                                >
                            <Link key={index} className="mr-10">
                              {element?.attachmentFilename}
                            </Link>
                          </span>
                            );
                          })
                          : "-"}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>
              </div>
          )}
        </Container>
      </div>
  );
};

export default Assets;
