export const customerPageConfig = {
  title: "Select page size",
  options: [
    {value: 1, label: "1 Customer"},
    {value: 2, label: "2 Customers"},
    {value: 5, label: "5 Customers"},
    {value: 10, label: "10 Customers"},
    {value: 20, label: "20 Customers"},
  ],
};

export const customerVisibleConfig = {
  title: "Select visible content",
  options: [
    {
      label: "Customer Properties",
      options: [
        {
          id: "accountId",
          label: "Account ID",
          editable: false,
        },
        {id: "accountName", label: "Account Name"},
        {id: "groupId", label: "Group Ids"},
        {id: "createDateTime", label: "Create Time"},
      ],
    },
  ],
};

