import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, FormField, Modal, Select, SpaceBetween, } from '@amzn/awsui-components-react/polaris';
import { appSyncRequest } from '../../../assets/js/request';
import { getAssignees } from '../../../graphql/queries';
import AppContext from '../../../context/AppContext';
import { Amazonian, SWAAbstract } from '../../../API';
import { AutoSuggestStatus } from '../../../types';
import { assignSWAReviewer } from '../../../graphql/mutations'


interface AssignModalSWAProps {
  addSuccess: () => void;
  hideDialog: () => void;
  selectedItems: SWAAbstract[];
  showDialog: boolean;
}

const AssignModalSWA: React.FC<AssignModalSWAProps> = (props: AssignModalSWAProps) => {
  const appConfig = useContext(AppContext);
  const {showDialog, selectedItems, addSuccess, hideDialog} = props;
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [assigneeInvalid, setAssigneeInvalid] = useState(false);

  const [loadingStatus, setLoadingStatus] = useState<any>();
  const [assigneeList, setAssigneeList] = useState([]);
  const [swaManagerList, setSwaManagerList] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<any>(null);

  // Assign SWA to Reviewer
  const assignSWAReviewers = async () => {
    if (!selectedAssignee) {
      setAssigneeInvalid(true);
      return;
    }
    try {
      setLoadingAssign(true);
      const swaReviewData = await appSyncRequest(assignSWAReviewer, {
        input: {
          swaIds: selectedItems.map((item) => item.swaId),
          assignerId: appConfig.userId,
          assigneeId: selectedAssignee?.value,
        },
      });
      console.info("swaReviewData:", swaReviewData);
      setLoadingAssign(false);
      addSuccess();
      hideDialog();
    } catch (error) {
      setLoadingAssign(false);
      console.error(error);
    }
  };

  const getAssigneeList = useCallback(async () => {
    try {
      if (appConfig && appConfig.userRole) {
        setLoadingStatus(AutoSuggestStatus.loading);
        setAssigneeList([]);
        const assigneeList: any = await appSyncRequest(getAssignees, {
          role: appConfig.userRole,
          managerId: appConfig.userId,
        });
        if (assigneeList?.data?.getAssignees) {
          setLoadingStatus(AutoSuggestStatus.finished);
          const managerList: any = [];
          assigneeList.data.getAssignees.forEach((user: Amazonian) => {
            // hide if the manager in SWA's manager
            if (!swaManagerList.includes(user.userId)) {
              managerList.push({
                label: `${user.name}(${user.userId})`,
                value: user.userId,
              });
            }
          });
          setAssigneeList(managerList);
          console.info("managerList:", managerList);
        }
      }
    } catch (error) {
      setLoadingStatus(AutoSuggestStatus.error);
      console.info(error);
    }
  }, [appConfig, swaManagerList]);


  useEffect(() => {
    if (showDialog) {
      setAssigneeInvalid(false);
      setSelectedAssignee(null);
      getAssigneeList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  useEffect(() => {
    // build selected items manager list
    const tmpManagerList: string[] = [];
    selectedItems.forEach((element) => {
      tmpManagerList.push(element.contributor?.managerId || "");
    });
    setSwaManagerList(tmpManagerList);
  }, [selectedItems]);

  return (
      <>
        <Modal
            onDismiss={() => {
              hideDialog();
            }}
            visible={showDialog}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
              <Box float="right">
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                      variant="link"
                      onClick={() => {
                        hideDialog();
                      }}
                  >
                    Cancel
                  </Button>
                  <Button
                      loading={loadingAssign}
                      onClick={() => {
                        assignSWAReviewers();
                      }}
                      variant="primary"
                  >
                    Assign
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Assign Reviewer"
        >
          <FormField
              errorText={assigneeInvalid ? "Please select a reviewer." : ""}
          >
            <Select
                selectedOption={selectedAssignee}
                onChange={({detail}: any) => {
                  console.info("detail:", detail);
                  setAssigneeInvalid(false);
                  setSelectedAssignee(detail.selectedOption);
                }}
                filteringType="auto"
                statusType={loadingStatus}
                loadingText="loading"
                options={assigneeList}
                placeholder="Choose an assignee"
                selectedAriaLabel="Selected"
                invalid={assigneeInvalid}
                empty="No assignees found"
            />
          </FormField>

        </Modal>
      </>
  );
};

export default AssignModalSWA;









