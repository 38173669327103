import React, { useContext, useEffect, useState } from "react";
import { AppLayout, Spinner } from "@amzn/awsui-components-react";
import { Button, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";
import { POC_NOT_FOUND_ID, SIDE_MENU_IS_OPEN } from "assets/js/const";
import StepSWAAccount from "../comps/step/StepSWAAccount";
import StepSWADetails from "../comps/step/StepSWADetails";
import StepSWATags from "../comps/step/StepSWATags";
import { CategoryInput, SubmitMode, SvcInput, SWAInput, UserRole, Workload, } from "API";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { submitSWA } from "graphql/mutations";
import AppContext from "context/AppContext";
import { isValidAccountId, isValidOppId } from "assets/js/utils";
import StepSWAWorkLoad from "../comps/step/StepSWAWorkLoad";
import Swal from "sweetalert2";
import { getSWAById } from "graphql/queries";

interface EditSWAProps {
  swaId: string;
}

const EditSWAContent: React.FC<EditSWAProps> = (props: EditSWAProps) => {
  const {swaId} = props;
  const history = useHistory();
  const appConfig = useContext(AppContext);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [editSWAInfo, setEditSWAInfo] = useState<SWAInput>({
    swaId: "",
    mode: SubmitMode.Submit,
    sfdcAccountId: "",
    opptyId: "",
    swaSubject: "",
    contributorId: appConfig.userId,
    efforts: 0,
    launchDate: "",
    categoryInUseList: [],
    svcInUseList: [],
    swaDesc: "",
    linkUrl: "",
    industryTagList: [],
    architectDesc: "",
    architectDiagrams: [],
    attachments: [],
  });
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [showOppIdError, setShowOppIdError] = useState(false);
  const [showAccountIdError, setShowAccountIdError] = useState(false);

  const [showWorkloadRequireError, setShowWorkloadRequireError] =
      useState(false);
  const [workloadIsLoading, setWorkloadIsLoading] = useState(false);
  const [svcIsLoading, setSvcIsLoading] = useState(false);
  const [showLaunchDateError, setShowLaunchDateError] = useState(false);

  const [showNameEmptyError, setShowNameEmptyError] = useState(false);
  const [showEffortsInvalidError, setShowEffortsInvalidError] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [swaInputErrorArr, setSwaInputErrorArr] = useState<string[]>([]);

  const [showArchImgRequireError, setShowArchImgRequireError] = useState(false);
  const [showBenefitRequireError, setShowBenefitRequireError] = useState(false);

  const updateSWA = async (mode: SubmitMode) => {
    if (swaInputErrorArr && swaInputErrorArr.length > 0) {
      return;
    }

    // validate AccountId first
    if (!isValidAccountId(editSWAInfo.sfdcAccountId || "")) {
      setShowAccountIdError(true);
      setActiveStepIndex(0);
      return;
    }

    // validate Opp ID second
    if (
        !isValidOppId(editSWAInfo.opptyId || "")
    ) {
      setShowOppIdError(true);
      setActiveStepIndex(0);
      return;
    }

    // Validate Input
    // setShowNameEmptyError while swa Name is Empty
    if (!editSWAInfo?.swaSubject?.trim()) {
      setShowNameEmptyError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate Benefit Error
    if (!editSWAInfo?.customerBenefit?.trim()) {
      setShowBenefitRequireError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate efforts
    if ((editSWAInfo.efforts || 0) < 1) {
      setShowEffortsInvalidError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate Launch Date
    if (!editSWAInfo.launchDate) {
      setShowLaunchDateError(true);
      setActiveStepIndex(2);
      return;
    }
    if (
        editSWAInfo.launchDate &&
        new Date(editSWAInfo.launchDate).getTime() > new Date().getTime()
    ) {
      setShowLaunchDateError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate Workload
    if (
        !editSWAInfo.categoryInUseList ||
        editSWAInfo.categoryInUseList?.length <= 0
    ) {
      setShowWorkloadRequireError(true);
      setActiveStepIndex(1);
      return;
    }

    // Validate Architecture Diagram
    if (
        !editSWAInfo.architectDiagrams ||
        editSWAInfo.architectDiagrams?.length <= 0
    ) {
      setShowArchImgRequireError(true);
      setActiveStepIndex(2);
      return;
    }

    const updateSWAInfoParam = JSON.parse(JSON.stringify(editSWAInfo));
    updateSWAInfoParam.mode = mode;
    console.info("updateSWAInfoParam:", updateSWAInfoParam);

    // encode assets input
    updateSWAInfoParam.swaSubject = encodeURIComponent(
        updateSWAInfoParam.swaSubject
    );

    updateSWAInfoParam.swaDesc = encodeURIComponent(updateSWAInfoParam.swaDesc);

    const swaArchList: any = [];
    editSWAInfo?.architectDiagrams?.forEach((element) => {
      swaArchList.push({
        architectFileName: encodeURIComponent(element?.architectFileName || ""),
        architectFileUrl: element?.architectFileUrl,
      });
    });
    updateSWAInfoParam.architectDiagrams = swaArchList;

    // Attachment Refine
    const updateAttachments: any = [];
    editSWAInfo?.attachments?.forEach((element) => {
      updateAttachments.push({
        attachmentFilename: encodeURIComponent(
            element?.attachmentFilename || ""
        ),
        attachmentUrl: element?.attachmentUrl,
        attachmentSize: element?.attachmentSize,
        attachmentDesc: encodeURIComponent(element?.attachmentDesc || ""),
      });
    });
    updateSWAInfoParam.attachments = updateAttachments;

    // Add Contributor to parameter
    updateSWAInfoParam.contributorId = appConfig.userId;

    // Remove useless properties
    delete updateSWAInfoParam?.swaStatus;
    delete updateSWAInfoParam?.contributor;
    delete updateSWAInfoParam?.createDateTime;
    delete updateSWAInfoParam?.submitDateTime;
    delete updateSWAInfoParam?.updateDateTime;
    delete updateSWAInfoParam?.workload;
    delete updateSWAInfoParam?.tagList;
    delete updateSWAInfoParam?.assigner;
    delete updateSWAInfoParam?.assignee;
    delete updateSWAInfoParam?.assignDateTime;
    delete updateSWAInfoParam?.reviewStatus;
    delete updateSWAInfoParam?.isPass;
    delete updateSWAInfoParam?.__typename;

    setLoadingSubmit(true);
    try {
      const createSWARes = await appSyncRequest(submitSWA, {
        input: updateSWAInfoParam,
      });
      console.info("createSWARes:", createSWARes);
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      history.push({
        pathname:
            appConfig.userRole === UserRole.Contributor
                ? "/my-swa"
                : appConfig.userRole === UserRole.Administrator
                    ? "/swa-all"
                    : "/swa-my-team",
      });
    } catch (error) {
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      console.error(error);
    }
  };

  // check user check service
  const checkServiceChecked = () => {
    const catetoryInUseArr = editSWAInfo.categoryInUseList?.map(
        (element) => element?.categoryId
    );
    const tmpServiceCategoryArr =
        editSWAInfo.svcInUseList?.map((element) => element?.categoryId) || [];
    const serviceCategoryArr = tmpServiceCategoryArr.filter(
        (element, index) => {
          return tmpServiceCategoryArr.indexOf(element) === index;
        }
    );
    return catetoryInUseArr?.length === serviceCategoryArr.length;
  };

  const getSWAInfoById = async () => {
    try {
      setLoadingData(true);
      const swaData: any = await appSyncRequest(getSWAById, {
        swaId: swaId,
      });
      console.info("swaData:", swaData);
      const swaInfo = swaData?.data?.getSWAById;
      if (
          swaInfo.swaId === POC_NOT_FOUND_ID ||
          swaInfo.contributor?.userId !== appConfig.userId
      ) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        console.info("swaInfo:", swaInfo);
        if (swaInfo) {
          swaInfo.swaSubject = decodeURIComponent(
              swaInfo.swaSubject.replace(/\+/g, " ")
          );
          swaInfo.swaDesc = decodeURIComponent(
              swaInfo.swaDesc.replace(/\+/g, " ")
          );
          swaInfo.architectDesc = decodeURIComponent(
              swaInfo.architectDesc.replace(/\+/g, " ")
          );
          swaInfo.architectDiagrams.forEach((element: any) => {
            element.architectFilename = decodeURIComponent(
                element.architectFilename.replace(/\+/g, " ")
            );
          });
          swaInfo.attachments.forEach((element: any) => {
            element.attachmentFilename = decodeURIComponent(
                element.attachmentFilename.replace(/\+/g, " ")
            );
            element.attachmentDesc = decodeURIComponent(
                element.attachmentDesc.replace(/\+/g, " ")
            );
          });

          // set tagList
          swaInfo.industryTagList = swaInfo.tagList;
          // set workload list

          const tmpWorkloadList: CategoryInput[] = [];
          swaInfo.workload.forEach((element: Workload) => {
            tmpWorkloadList.push({
              categoryId: element.categoryId,
              categoryName: element.workload,
            });
          });
          swaInfo.categoryInUseList = tmpWorkloadList;

          // set service list
          const tmpServiceList: SvcInput[] = [];
          swaInfo.workload.forEach((element: Workload) => {
            element.serviceList?.forEach((svc) => {
              tmpServiceList.push({
                svcId: svc?.svcId,
                svcName: svc?.svcName,
              });
            });
          });
          swaInfo.svcInUseList = tmpServiceList;

          setEditSWAInfo(swaInfo);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.info("editSWAInfo:", editSWAInfo);
  }, [editSWAInfo]);

  useEffect(() => {
    getSWAInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div>
        {loadingData ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div className="gsal-review-page">
              <div className="gsal-step-nav">
                <nav>
                  <ul>
                    <li>
                      <small>Step 1</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          if (
                              loadingSubmit ||
                              loadingSaveDraft ||
                              isFileUploading ||
                              workloadIsLoading ||
                              svcIsLoading
                          ) {
                            return;
                          }
                          setActiveStepIndex(0);
                        }}
                        className={activeStepIndex !== 0 ? "link" : ""}
                    >
                      Account Info
                    </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 2</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          if (
                              !isValidAccountId(editSWAInfo.sfdcAccountId || "")
                          ) {
                            setShowAccountIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (
                              !isValidOppId(editSWAInfo.opptyId || "")
                          ) {
                            setShowOppIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (!editSWAInfo.swaId) {
                            return;
                          }
                          if (
                              loadingSubmit ||
                              loadingSaveDraft ||
                              isFileUploading ||
                              workloadIsLoading ||
                              svcIsLoading
                          ) {
                            return;
                          }
                          setActiveStepIndex(1);
                        }}
                        className={activeStepIndex !== 1 ? "link" : ""}
                    >
                      Strategical workload
                    </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 3</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          if (
                              !isValidAccountId(editSWAInfo.sfdcAccountId || "")
                          ) {
                            setShowAccountIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (
                              !isValidOppId(editSWAInfo.opptyId || "")
                          ) {
                            setShowOppIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (!editSWAInfo.swaId) {
                            return;
                          }
                          if (
                              loadingSubmit ||
                              loadingSaveDraft ||
                              isFileUploading ||
                              workloadIsLoading ||
                              svcIsLoading
                          ) {
                            return;
                          }
                          setActiveStepIndex(2);
                        }}
                        className={activeStepIndex !== 2 ? "link" : ""}
                    >
                      SWA details
                    </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 4</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          if (
                              !isValidAccountId(editSWAInfo.sfdcAccountId || "")
                          ) {
                            setShowAccountIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (
                              !isValidOppId(editSWAInfo.opptyId || "")
                          ) {
                            setShowOppIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (!editSWAInfo.swaId) {
                            return;
                          }
                          if (
                              loadingSubmit ||
                              loadingSaveDraft ||
                              isFileUploading ||
                              workloadIsLoading ||
                              svcIsLoading
                          ) {
                            return;
                          }
                          setActiveStepIndex(3);
                        }}
                        className={activeStepIndex !== 3 ? "link" : ""}
                    >
                      Tags &amp; attachments
                    </span>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="gsal-review-content">
                {activeStepIndex === 0 && (
                    <StepSWAAccount
                        curSWA={editSWAInfo}
                        showOppIdError={showOppIdError}
                        showAccountIdError={showAccountIdError}
                        changeAccountId={(id) => {
                          setShowAccountIdError(false);
                          setEditSWAInfo((prev) => {
                            return {...prev, sfdcAccountId: id};
                          });
                        }}
                        changeSWAOppId={(id) => {
                          setShowOppIdError(false);
                          setEditSWAInfo((prev) => {
                            return {...prev, opptyId: id};
                          });
                        }}
                    />
                )}
                {activeStepIndex === 1 && (
                    <StepSWAWorkLoad
                        curSWA={editSWAInfo}
                        showWorkloadError={showWorkloadRequireError}
                        setWorkloadIsLoading={(loading) => {
                          setWorkloadIsLoading(loading);
                        }}
                        changeCategoryInUse={(categoryList) => {
                          if (categoryList.length > 0) {
                            setShowWorkloadRequireError(false);
                          }
                          setEditSWAInfo((prev) => {
                            return {...prev, categoryInUseList: categoryList};
                          });
                        }}
                    />
                )}
                {activeStepIndex === 2 && (
                    <StepSWADetails
                        curSWA={editSWAInfo}
                        showNameEmptyError={showNameEmptyError}
                        showLaunchDateError={showLaunchDateError}
                        showArchDiagramRequireError={showArchImgRequireError}
                        benefitRequireError={showBenefitRequireError}
                        setShowLaunchDateError={(show) => {
                          setShowLaunchDateError(show);
                        }}
                        setServiceIsLoading={(loading) => {
                          setSvcIsLoading(loading);
                        }}
                        changeWorkloadSvcList={(svcList) => {
                          setEditSWAInfo((prev) => {
                            return {...prev, svcInUseList: svcList};
                          });
                        }}
                        addInputError={(error) => {
                          if (!swaInputErrorArr.includes(error)) {
                            setSwaInputErrorArr((prev) => {
                              return [...prev, error];
                            });
                          }
                        }}
                        removeInputError={(error) => {
                          setSwaInputErrorArr((prev) => {
                            return prev.filter((e) => e !== error);
                          });
                        }}
                        changeIsUploading={(uploading) => {
                          setIsFileUploading(uploading);
                        }}
                        setShowNameEmptyError={(showError) => {
                          setShowNameEmptyError(showError);
                        }}
                        showEffortsInvalidError={showEffortsInvalidError}
                        changeSWASubject={(swaSubject) => {
                          setShowNameEmptyError(false);
                          setEditSWAInfo((prev) => {
                            return {...prev, swaSubject: swaSubject};
                          });
                        }}
                        changeSWADesc={(swaDesc) => {
                          setEditSWAInfo((prev) => {
                            return {...prev, swaDesc: swaDesc};
                          });
                        }}
                        changeCustomerBenefit={(benefit) => {
                          setShowBenefitRequireError(false);
                          setEditSWAInfo((prev) => {
                            return {...prev, customerBenefit: benefit};
                          });
                        }}
                        changeLaunchDate={(launchDate: string) => {
                          if (
                              editSWAInfo.launchDate &&
                              new Date(launchDate).getTime() > new Date().getTime()
                          ) {
                            setShowLaunchDateError(true);
                          } else {
                            setShowLaunchDateError(false);
                          }
                          setEditSWAInfo((prev) => {
                            return {...prev, launchDate: launchDate};
                          });
                        }}
                        changeArchImgList={(archList) => {
                          if (archList.length > 0) {
                            setShowArchImgRequireError(false);
                          }
                          setEditSWAInfo((prev) => {
                            return {
                              ...prev,
                              architectDiagrams: archList,
                            };
                          });
                        }}
                        changeArchDesc={(archDesc) => {
                          setEditSWAInfo((prev) => {
                            return {...prev, architectDesc: archDesc};
                          });
                        }}
                        changeEfforts={(efforts) => {
                          if (efforts > 0) {
                            setShowEffortsInvalidError(false);
                          } else {
                            setShowEffortsInvalidError(true);
                          }
                          setEditSWAInfo((prev) => {
                            return {...prev, efforts: efforts};
                          });
                        }}
                    />
                )}
                {activeStepIndex === 3 && (
                    <StepSWATags
                        curSWA={editSWAInfo}
                        addInputError={(error) => {
                          if (!swaInputErrorArr.includes(error)) {
                            setSwaInputErrorArr((prev) => {
                              return [...prev, error];
                            });
                          }
                        }}
                        removeInputError={(error) => {
                          setSwaInputErrorArr((prev) => {
                            return prev.filter((e) => e !== error);
                          });
                        }}
                        changeSWALinkUrl={(link) => {
                          setEditSWAInfo((prev) => {
                            return {...prev, linkUrl: link};
                          });
                        }}
                        changeIsUploading={(uploading) => {
                          setIsFileUploading(uploading);
                        }}
                        changeIndustryTagList={(tags) => {
                          setEditSWAInfo((prev) => {
                            return {...prev, industryTagList: tags};
                          });
                        }}
                        changeTechTagList={(tags) => {
                          console.info("tags:", tags);
                        }}
                        addNewAttachment={(item) => {
                          setEditSWAInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            const prevAttachment = prevObj.attachments;
                            prevAttachment.push(item);
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        removeAttachment={(index) => {
                          if (
                              editSWAInfo.attachments &&
                              editSWAInfo.attachments.length > 0
                          ) {
                            const tmpAttacment = [...editSWAInfo.attachments];
                            tmpAttacment.splice(index, 1);
                            setEditSWAInfo((prev: any) => {
                              const prevObj = JSON.parse(JSON.stringify(prev));
                              return {
                                ...prevObj,
                                ...{
                                  attachments: tmpAttacment,
                                },
                              };
                            });
                          }
                        }}
                        changeAttachmentDesc={(index, desc) => {
                          setEditSWAInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            prevObj.attachments[index].attachmentDesc = desc;
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        uploadAttachment={(upProgress, index) => {
                          setEditSWAInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            if (upProgress >= 100) {
                              prevObj.attachments[index].progress = upProgress;
                              prevObj.attachments[index].showProgress = false;
                            } else {
                              prevObj.attachments[index].progress = upProgress;
                              prevObj.attachments[index].showProgress = true;
                            }
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        uploadAttachmentDone={(index, name, size, key) => {
                          setEditSWAInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            prevObj.attachments[index].attachmentFilename = name;
                            prevObj.attachments[index].attachmentSize = size;
                            prevObj.attachments[index].attachmentUrl = key;
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                    />
                )}
                <div className="mt-20 pb-30 clearfix">
                  <SpaceBetween className="fr" direction="horizontal" size="xs">
                    <Button
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: `/my-swa/detail/${swaId}`,
                          });
                        }}
                    >
                      Cancel
                    </Button>

                    {activeStepIndex === 0 && editSWAInfo.swaId && (
                        <Button
                            disabled={
                                loadingSubmit || loadingSaveDraft || isFileUploading
                            }
                            variant="primary"
                            onClick={() => {
                              // validate account id when click next
                              if (!isValidAccountId(editSWAInfo.sfdcAccountId || "")) {
                                setShowAccountIdError(true);
                                setActiveStepIndex(0);
                                return;
                              }
                              if (
                                  !isValidOppId(editSWAInfo.opptyId || "")
                              ) {
                                setShowOppIdError(true);
                                setActiveStepIndex(0);
                                return;
                              }
                              setActiveStepIndex(1);
                            }}
                        >
                          Next
                        </Button>
                    )}

                    {activeStepIndex > 0 && (
                        <Button
                            disabled={
                                loadingSubmit ||
                                loadingSaveDraft ||
                                isFileUploading ||
                                workloadIsLoading ||
                                svcIsLoading
                            }
                            onClick={() => {
                              if (activeStepIndex === 2) {
                                if (
                                    showNameEmptyError ||
                                    showEffortsInvalidError ||
                                    showLaunchDateError
                                ) {
                                  return;
                                }
                                if (swaInputErrorArr.length > 0) {
                                  return;
                                }
                              }
                              setActiveStepIndex((prev) => {
                                return prev - 1;
                              });
                            }}
                        >
                          Previous
                        </Button>
                    )}

                    {activeStepIndex === 1 && (
                        <Button
                            disabled={workloadIsLoading}
                            variant="primary"
                            onClick={() => {
                              if (
                                  !editSWAInfo.categoryInUseList ||
                                  editSWAInfo.categoryInUseList?.length <= 0
                              ) {
                                setShowWorkloadRequireError(true);
                                return;
                              }
                              setActiveStepIndex(2);
                            }}
                        >
                          Next
                        </Button>
                    )}
                    {activeStepIndex === 2 && (
                        <Button
                            disabled={svcIsLoading || isFileUploading}
                            variant="primary"
                            onClick={() => {
                              if (
                                  showNameEmptyError ||
                                  showEffortsInvalidError ||
                                  showLaunchDateError
                              ) {
                                return;
                              }
                              if (swaInputErrorArr.length > 0) {
                                return;
                              }
                              if (!checkServiceChecked()) {
                                Swal.fire(
                                    "Oops...",
                                    "Please make sure to select at least one service of each strategical workload",
                                    "warning"
                                );
                                return;
                              }
                              setActiveStepIndex(3);
                            }}
                        >
                          Next
                        </Button>
                    )}
                    {activeStepIndex === 3 && (
                        <Button
                            disabled={loadingSaveDraft || isFileUploading}
                            loading={loadingSubmit}
                            variant="primary"
                            onClick={() => {
                              updateSWA(SubmitMode.Submit);
                            }}
                        >
                          Submit
                        </Button>
                    )}
                  </SpaceBetween>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

interface MatchParams {
  id: string;
}

const EditSWA: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setnavigationOpen] = useState(false);
  // const [helpInfo, setHelpInfo] = useState();
  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const id: string = props.match.params.id;

  const swaEditBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: id,
      href: "/swa/detail/" + id,
    },
    {
      text: "Edit",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={swaEditBreadcrumbs}/>}
          content={<EditSWAContent swaId={id}/>}
          // tools={helpInfo}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default EditSWA;
