import React, { useContext, useEffect, useState } from "react";
import { DateRangePicker, PropertyFilter, PropertyFilterProps, } from "@amzn/awsui-components-react/polaris";
import { appSyncRequest } from "assets/js/request";
import {
  getAccountIds,
  getAssignees,
  getCategories,
  getContributors,
  getServices,
  getSWAsByAos
} from "graphql/queries";

import AppContext from "context/AppContext";
import {
  AdminSWAMutilFilterList,
  ContributorSWAMutilFilterList,
  ManagerSWAMutilFilterList,
  MultiSWASearchI18NString,
  TimePickI18NString,
} from "assets/js/const";
import {
  converAmazonianToFilter,
  converCategoryToFilter,
  converSvcToFilter,
  convertAccountIdToFilter,
  convertToAbsoluteRange,
  isValidRangeFunction,
} from "assets/js/utils";
import { QueryType, UserRole } from "API";
import { PageType } from "types";

export interface FilterTypes {
  supervisorId?: string;
  contributorId?: string[];
  managerId?: string;
  swaIds?: string;
  svcId?: string;
  categoryId?: string;
  accountId?: string;
}

export interface TimeRangeTypes {
  startTime: any;
  endTime: any;
}

interface AdvancedFilterProps {
  pageType: string;
  changeFilters: (filters: FilterTypes) => void;
  changeTimeRange: (time: TimeRangeTypes) => void;
  setStartLoading: (loading: boolean) => void;
}

const AdvancedFilterSWA: React.FC<AdvancedFilterProps> = ({
                                                            pageType,
                                                            changeFilters,
                                                            changeTimeRange,
                                                            setStartLoading,
                                                          }: AdvancedFilterProps) => {
  const appConfig = useContext(AppContext);
  const [timeRange, setTimeRange] = useState<any>();
  const [query, setQuery] = useState<any>({
    tokens: [],
    operation: "and",
  });
  const [managerList, setManagerList] = useState([]);
  const [contributorList, setContributorList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [accountIdList, setAccountIdList] = useState([]);
  // const [freeText, setfreeText] = useState("");

  // Get All Manager List
  const getManagerList = async () => {
    try {
      if (appConfig && appConfig.userRole) {
        const assigneeList: any = await appSyncRequest(getAssignees, {
          role: "Administrator",
          managerId: "",
        });
        setManagerList(assigneeList?.data?.getAssignees || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get All Contributer List
  const getContributorList = async () => {
    try {
      if (appConfig && appConfig.userRole) {
        const constributerList: any = await appSyncRequest(getContributors, {
          contributorName: "",
        });
        setContributorList(constributerList?.data?.getContributors || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get All service List
  const getServiceList = async () => {
    try {
      if (appConfig && appConfig.userRole) {
        const serviceList: any = await appSyncRequest(getServices, {});
        setServiceList(serviceList?.data?.getServices || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get All Category List
  const getCategoryList = async () => {
    try {
      if (appConfig && appConfig.userRole) {
        const categoryList: any = await appSyncRequest(getCategories, {});
        setCategoryList(categoryList?.data?.getCategories || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get All account List
  const getAccountIdList = async () => {
    try {
      if (appConfig && appConfig.userRole) {
        const accountIdList: any = await appSyncRequest(getAccountIds, {
          contributorId: pageType === "my-swa" ? appConfig.userId : "",
        });
        setAccountIdList(accountIdList?.data?.getAccountIds || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getManagerList();
    getContributorList();
    getServiceList();
    getCategoryList();
    getAccountIdList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertArrayToObject = (array: any, key: string) =>
      array.reduce(
          (obj: any, item: any) => ({
            ...obj,
            [item[key]]: item,
          }),
          {}
      );

  const getValueByKey = (list: any, key: string) => {
    const obj = convertArrayToObject(list, "propertyKey");
    return obj[key]?.value || "";
  };

  const changeSearchInputValue = (detail: PropertyFilterProps.Query) => {
    console.info("detail:", detail);
    // Make Every Condition Only One
    let contributorValues: string[] = [];
    let managerValue = "";
    let serviceValue = "";
    let categoryId = "";
    let accountId = "";
    if (detail.tokens) {
      managerValue = getValueByKey(detail.tokens, "managerId");
      serviceValue = getValueByKey(detail.tokens, "svcId");
      categoryId = getValueByKey(detail.tokens, "categoryId");
      accountId = getValueByKey(detail.tokens, "sfdcAccountId");
    }

    const searchCondition: any = {
      operation: "and",
      tokens: [],
    };

    let enteredText = "";
    if (detail.tokens) {
      detail.tokens.forEach((element) => {
        if (!element.propertyKey && element.value) {
          enteredText = element.value;
        } else if (element.propertyKey === "contributorId") {
          if (contributorValues.indexOf(element.value) === -1) {
            contributorValues.push(element.value)
            searchCondition.tokens.push({
              propertyKey: "contributorId",
              operator: "=",
              value: element.value,
            });
          }
        }
      });
    }

    if (managerValue) {
      searchCondition.tokens.push({
        propertyKey: "managerId",
        operator: "=",
        value: managerValue,
      });
    }
    if (serviceValue) {
      searchCondition.tokens.push({
        propertyKey: "svcId",
        operator: "=",
        value: serviceValue,
      });
    }
    if (categoryId) {
      searchCondition.tokens.push({
        propertyKey: "categoryId",
        operator: "=",
        value: categoryId,
      });
    }

    if (accountId) {
      searchCondition.tokens.push({
        propertyKey: "sfdcAccountId",
        operator: "=",
        value: accountId,
      });
    }

    if (enteredText) {
      searchCondition.tokens.push({
        propertyKey: "",
        operator: ":",
        value: enteredText,
      });
    }
    if (enteredText) {
      setStartLoading(true);
      changeHandler(enteredText);
    } else {
      changeFilters({
        contributorId: contributorValues,
        managerId: managerValue,
        svcId: serviceValue,
        categoryId: categoryId,
        accountId: accountId,
      });
    }
    setQuery(searchCondition);
  };

  const changeHandler = async (text: string) => {
    console.info("freeText:", text);
    const aosRes: any = await appSyncRequest(getSWAsByAos, {
      input: {
        queryType: QueryType.SWA,
        keyword: text,
      },
    });
    console.info("aosRes:", aosRes);
    changeFilters({
      swaIds:
          aosRes?.data?.getSWAsByAos?.swas
              ?.map((item: any) => item.swaId)
              .join(",") || "",
    });
  };

  return (
      <div className="search-action flex flex-row">
        <div className="poc-search flex-1">
          <PropertyFilter
              onChange={({detail}) => {
                changeSearchInputValue(detail);
              }}
              query={query}
              hideOperations={true}
              i18nStrings={MultiSWASearchI18NString}
              filteringOptions={[
                ...converAmazonianToFilter(managerList, "managerId"),
                ...converAmazonianToFilter(contributorList, "contributorId"),
                ...converSvcToFilter(serviceList, "svcId"),
                ...converCategoryToFilter(categoryList, "categoryId"),
                ...convertAccountIdToFilter(accountIdList, "sfdcAccountId"),
              ]}
              filteringProperties={
                pageType === PageType.ALL_SWA
                    ? AdminSWAMutilFilterList
                    : appConfig.userRole === UserRole.Contributor
                        ? ContributorSWAMutilFilterList
                        : appConfig.userRole === UserRole.Administrator
                            ? AdminSWAMutilFilterList
                            : ManagerSWAMutilFilterList
              }
          />
        </div>
        <div className="date-range">
          <DateRangePicker
              locale="en-US"
              isDateEnabled={(time) => {
                if (new Date().getTime() >= new Date(time).getTime()) {
                  return true;
                } else {
                  return false;
                }
              }}
              isValidRange={isValidRangeFunction}
              onChange={(event: any) => {
                console.info("event:", event);
                if (!event.detail.value) {
                  changeTimeRange({
                    startTime: "",
                    endTime: "",
                  });
                } else {
                  if (event.detail?.value?.type === "absolute") {
                    changeTimeRange({
                      startTime: event.detail.value.startDate,
                      endTime: event.detail.value.endDate,
                    });
                  } else {
                    const relativeTime = convertToAbsoluteRange(event.detail.value);
                    changeTimeRange({
                      startTime: relativeTime.start,
                      endTime: relativeTime.end,
                    });
                  }
                }
                setTimeRange(event.detail.value);
              }}
              value={timeRange}
              relativeOptions={[
                {
                  key: "previous-24-hours",
                  amount: 24,
                  unit: "hour",
                  type: "relative",
                },
                {
                  key: "previous-1-week",
                  amount: 7,
                  unit: "day",
                  type: "relative",
                },
                {
                  key: "previous-30-days",
                  amount: 30,
                  unit: "day",
                  type: "relative",
                },
              ]}
              i18nStrings={TimePickI18NString}
              placeholder="Filter by created date range"
          />
        </div>
      </div>
  );
};

export default AdvancedFilterSWA;
