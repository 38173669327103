import { Box, Button, Container, Spinner } from "@amzn/awsui-components-react";
import React from "react";
import Logo from "assets/images/logon-logo.png";

interface AuthProps {
  onSignIn: () => void;
  isAuthenticating: boolean;
  isLoadingState: boolean;
}

const Authentication: React.FC<AuthProps> = (props: AuthProps) => {
  const handleLogon = () => {
    props.onSignIn();
  };

  const showSpinner = props.isAuthenticating || props.isLoadingState;
  const showSignInButton = !props.isAuthenticating && !props.isLoadingState;

  return (
      <Container className="custom-auth__box">
        <Box textAlign="center" variant="h1">
          <div>
            <img src={Logo} width={100} height={50} alt=""/>
          </div>
          <div>
            <Box>GCR SA Assets Library</Box>
          </div>
          {showSignInButton && (
              <div>
                <Button variant="primary" onClick={handleLogon}>
                  Log In
                </Button>
              </div>
          )}
          {showSpinner && <Spinner size="large"/>}
        </Box>
      </Container>
  );
};

export default Authentication;
