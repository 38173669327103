import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Storage } from "aws-amplify";

import {
  Button,
  Checkbox,
  Container,
  DatePicker,
  FormField,
  Header,
  Input,
  ProgressBar,
  Spinner,
  Textarea,
} from "@amzn/awsui-components-react";
import { ArchitectDiagramsInput, Svc, SvcInput, SWAInput } from "API";
import { LengthLimitSWA, MAX_FILE_SIZE } from "assets/js/const";
import { SWAInptValidErrorType } from "types";
import { appSyncRequest } from "assets/js/request";
import { querySvc } from "graphql/queries";
import ArchImage from "./comps/ArchImage";

interface SvcType extends Svc {
  checked: boolean;
}

interface StepDetailProps {
  curSWA: SWAInput;
  showNameEmptyError: boolean;
  changeWorkloadSvcList: (svcList: SvcInput[]) => void;
  setShowNameEmptyError: (showError: boolean) => void;
  showEffortsInvalidError: boolean;
  changeSWASubject: (swaSubject: string) => void;
  changeSWADesc: (swaDesc: string) => void;
  changeLaunchDate: (launchDate: string) => void;
  changeArchImgList: (archImgList: ArchitectDiagramsInput[]) => void;
  changeArchDesc: (sourceCode: string) => void;
  changeCustomerBenefit: (benefit: string) => void;
  changeIsUploading: (uploading: boolean) => void;
  changeEfforts: (efforts: number) => void;
  addInputError: (error: string) => void;
  removeInputError: (error: string) => void;
  setServiceIsLoading: (loading: boolean) => void;
  showLaunchDateError: boolean;
  setShowLaunchDateError: (showError: boolean) => void;
  showArchDiagramRequireError: boolean;
  benefitRequireError: boolean;
}

interface WorkloadServiceListType {
  categoryId: string;
  categoryName: string;
  svcList: SvcType[];
}

const StepSWADetails: React.FC<StepDetailProps> = (props: StepDetailProps) => {
  const {
    curSWA,
    showNameEmptyError,
    changeWorkloadSvcList,
    setShowNameEmptyError,
    showEffortsInvalidError,
    changeSWASubject,
    changeSWADesc,
    changeLaunchDate,
    changeArchImgList,
    changeArchDesc,
    changeIsUploading,
    changeEfforts,
    addInputError,
    removeInputError,
    setServiceIsLoading,
    showLaunchDateError,
    setShowLaunchDateError,
    showArchDiagramRequireError,
    changeCustomerBenefit,
    benefitRequireError,
  } = props;

  const [loadingServiceList, setLoadingServiceList] = useState(false);
  const [archUploadProgress, setArchUploadProgress] = useState(0);
  const [showArchUploadProgress, setShowArchUploadProgress] = useState(false);
  const [workLoadServiceList, setWorkLoadServiceList] = useState<WorkloadServiceListType[]>([]);
  const [archImageList, setArchImageList] = useState(
      curSWA.architectDiagrams || []
  );

  const hiddenArchFileInput = React.useRef<any>(null);
  const handleArchFileClick = (event: any) => {
    console.info("event:", event);
    hiddenArchFileInput?.current?.click();
  };

  const handleArchFileChange = (event: any) => {
    console.info("event:", event);
    const fileUploaded = event.target.files[0];
    // file size must less than 100M
    if (fileUploaded?.size > MAX_FILE_SIZE) {
      Swal.fire("Oops...", "File size must less than 100MB", "warning");
      return;
    }
    changeIsUploading(true);
    console.info("fileUploaded:", fileUploaded);
    const s3FileKey = `${curSWA.swaId}/architect/${new Date().getTime()}_${
        fileUploaded.name
    }`;
    setShowArchUploadProgress(true);
    setArchUploadProgress(0);
    Storage.put(s3FileKey, fileUploaded, {
      contentType: fileUploaded.type,
      progressCallback(progress: any) {
        setArchUploadProgress((progress.loaded / progress.total) * 100);
        console.info(
            `Uploaded percentage: ${progress.loaded}/${progress.total}`
        );
        if (progress.loaded >= progress.total) {
          setShowArchUploadProgress(false);
        }
      },
    })
        .then((result: any) => {
          Storage.get(result.key, {expires: 300}).then((res) => {
            setArchImageList((prev) => {
              return [
                ...prev,
                {
                  architectFileName: fileUploaded.name,
                  architectFileUrl: result.key,
                },
              ];
            });
            changeIsUploading(false);
          });
        })
        .catch((err) => {
          changeIsUploading(false);
          console.error("err:", err);
        });
  };

  // Get System Settings
  const getServiceList = async () => {
    setLoadingServiceList(true);
    setServiceIsLoading(true);
    const serviceListData: any = await appSyncRequest(querySvc, {
      curPage: 1,
      pageSize: 500,
      categoryIds: curSWA.categoryInUseList?.map(
          (element) => element?.categoryId
      ),
    });
    console.info("serviceListData:", serviceListData);
    const tmpSvcList: Svc[] = serviceListData?.data?.querySvc?.svcList || [];
    const tmpCategoryInUseList = curSWA.categoryInUseList || [];
    const tmpCategoryServiceList: WorkloadServiceListType[] = [];
    const tmpSvcInUseList = curSWA.svcInUseList || [];
    tmpCategoryInUseList.forEach((category) => {
      const svcListInCat: SvcType[] = [];
      tmpSvcList.forEach((svc) => {
        if (svc.categoryId === category?.categoryId) {
          svcListInCat.push({
            __typename: "Svc",
            svcName: svc.svcName,
            svcId: svc.svcId,
            categoryId: svc.categoryId,
            checked:
                tmpSvcInUseList
                    .map((inUseSvc) => inUseSvc?.svcId)
                    .indexOf(svc.svcId) >= 0,
          });
        }
      });
      tmpCategoryServiceList.push({
        categoryId: category?.categoryId || "",
        categoryName: category?.categoryName || "",
        svcList: svcListInCat,
      });
    });
    console.info("tmpCategoryServiceList:", tmpCategoryServiceList);
    setWorkLoadServiceList(tmpCategoryServiceList);
    setLoadingServiceList(false);
    setServiceIsLoading(false);
  };

  useEffect(() => {
    if (curSWA.categoryInUseList && curSWA.categoryInUseList.length > 0) {
      getServiceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curSWA.categoryInUseList]);

  useEffect(() => {
    console.info("workLoadServiceList:", workLoadServiceList);
    const tmpSvcArr: SvcInput[] = [];
    workLoadServiceList.forEach((element) => {
      if (element.svcList) {
        element.svcList.forEach((svc) => {
          if (svc.checked) {
            tmpSvcArr.push({
              categoryId: element.categoryId,
              svcId: svc.svcId,
              svcName: svc.svcName,
            });
          }
        });
      }
    });
    changeWorkloadSvcList(tmpSvcArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workLoadServiceList]);

  useEffect(() => {
    console.info("archImageList:", archImageList);
    const tmpArchImgList: ArchitectDiagramsInput[] = [];
    archImageList.forEach((element) => {
      tmpArchImgList.push({
        architectFileName: element?.architectFileName,
        architectFileUrl: element?.architectFileUrl,
      });
    });
    changeArchImgList(tmpArchImgList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archImageList]);

  return (
      <div>
        <Header variant="h1">SWA Details</Header>
        <div className="mt-10">
          <Container header={<Header variant="h2">General</Header>}>
            <div>
              <FormField
                  description="Brief descriptions to the workload"
                  label="SWA subject"
                  errorText={
                    (curSWA?.swaSubject?.length || 0) > LengthLimitSWA.swaSubject
                        ? `Title must be less than ${LengthLimitSWA.swaSubject} characters.`
                        : showNameEmptyError
                            ? "Title can not be empty."
                            : ""
                  }
              >
                <Input
                    placeholder="Aurora benchmarking against TiDB"
                    value={curSWA?.swaSubject || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimitSWA.swaSubject) {
                        addInputError(SWAInptValidErrorType.swaNameLengthError);
                      } else {
                        removeInputError(SWAInptValidErrorType.swaNameLengthError);
                      }
                      setShowNameEmptyError(false);
                      changeSWASubject(event.detail.value);
                    }}
                />
              </FormField>
              <FormField
                  className="mt-20"
                  description="provide a detailed description to the workload(mandatory) include Customer scenario, the reason customer would like to migration to Strategical workload, what is the lesson and learn here."
                  label="SWA description"
                  errorText={
                    (curSWA?.swaDesc?.length || 0) > LengthLimitSWA.swaDesc
                        ? `SWA Description must be less than ${LengthLimitSWA.swaDesc} characters.`
                        : ""
                  }
              >
                <Textarea
                    placeholder="This is a long text."
                    value={curSWA?.swaDesc || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimitSWA.swaDesc) {
                        addInputError(SWAInptValidErrorType.swaDescLengthError);
                      } else {
                        removeInputError(SWAInptValidErrorType.swaDescLengthError);
                      }
                      changeSWADesc(event.detail.value);
                    }}
                />
              </FormField>

              <FormField
                  className="mt-20"
                  description="What benefits does this job bring to our customer?"
                  label="Customer Benefit"
                  errorText={
                    (curSWA?.customerBenefit?.length || 0) >
                    LengthLimitSWA.customerBenefit
                        ? `Customer Benefit must be less than ${LengthLimitSWA.customerBenefit} characters.`
                        : benefitRequireError
                            ? "Customer Benefit can not be empty."
                            : ""
                  }
              >
                <Textarea
                    placeholder="This is a long text."
                    value={curSWA?.customerBenefit || ""}
                    onChange={(event) => {
                      if (
                          event.detail.value.length > LengthLimitSWA.customerBenefit
                      ) {
                        addInputError(
                            SWAInptValidErrorType.customerBenefitLengthError
                        );
                      } else {
                        removeInputError(
                            SWAInptValidErrorType.customerBenefitLengthError
                        );
                      }
                      changeCustomerBenefit(event.detail.value);
                    }}
                />
              </FormField>

              <FormField
                  className="mt-20"
                  description="The hours you spend on this SWA"
                  label="Efforts (Hours)"
                  errorText={
                    showEffortsInvalidError ? "Efforts must be larger than 0" : ""
                  }
              >
                <div style={{width: 176}}>
                  <Input
                      placeholder="168"
                      value={curSWA?.efforts?.toString() || ""}
                      type="number"
                      onChange={(event) => {
                        changeEfforts(Number.parseFloat(event.detail.value));
                      }}
                  />
                </div>
              </FormField>

              <FormField
                  className="mt-20"
                  description="The workload launch date. This workload should have launched before you input this activity."
                  label="Launch Date"
                  errorText={
                    showLaunchDateError
                        ? "Invalid launch date, the workload launch date must before you input this activity."
                        : ""
                  }
              >
                <DatePicker
                    onChange={({detail}) => {
                      setShowLaunchDateError(false);
                      changeLaunchDate(detail.value);
                    }}
                    value={curSWA.launchDate || ""}
                    isDateEnabled={(date) => date.getTime() < new Date().getTime()}
                    openCalendarAriaLabel={(selectedDate) =>
                        "Choose Date" +
                        (selectedDate ? `, selected date is ${selectedDate}` : "")
                    }
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                />
              </FormField>
            </div>
          </Container>
        </div>
        <div className="mt-20">
          <Container header={<Header variant="h2">Architecture</Header>}>
            <div>
              <FormField
                  description="Select the Architect Diagram to upload. Accept format: image/*."
                  label="Architect Diagram"
                  errorText={
                    showArchDiagramRequireError
                        ? "Please upload an architect diagram."
                        : ""
                  }
              >
                {archImageList.map((element, index) => {
                  return (
                      <div className="mt-10 w-70p pr arch-img-item" key={index}>
                        <div className="remove-icon">
                          <Button
                              iconName="close"
                              onClick={() => {
                                setArchImageList((prev: any) => {
                                  return [...prev].filter((element, idx, array) => {
                                    return idx !== index;
                                  });
                                });
                              }}
                          >
                            Remove
                          </Button>
                        </div>
                        <ArchImage s3Key={element?.architectFileUrl || ""}/>
                      </div>
                  );
                })}

                <Button
                    className="mt-20"
                    onClick={handleArchFileClick}
                    iconAlign="left"
                    iconName="upload"
                    variant="normal"
                >
                  Choose File
                </Button>
                <input
                    ref={hiddenArchFileInput}
                    onChange={handleArchFileChange}
                    type="file"
                    accept="image/*"
                    style={{display: "none"}}
                />
                {showArchUploadProgress && (
                    <ProgressBar
                        status="in-progress"
                        value={archUploadProgress}
                        additionalInfo=""
                        description=""
                        label=""
                    />
                )}
              </FormField>
              <FormField
                  className="mt-20"
                  description="Use 2~ 5 paragraph to summary how these components work together."
                  label="Architect Diagram Description"
                  errorText={
                    (curSWA?.architectDesc?.length || 0) >
                    LengthLimitSWA.architectDesc
                        ? `Architect description must be less than ${LengthLimitSWA.architectDesc} characters.`
                        : ""
                  }
              >
                <Textarea
                    placeholder="This is a long text."
                    value={curSWA?.architectDesc || ""}
                    onChange={(event) => {
                      if (
                          event.detail.value.length > LengthLimitSWA.architectDesc
                      ) {
                        addInputError(
                            SWAInptValidErrorType.architectDescLengthError
                        );
                      } else {
                        removeInputError(
                            SWAInptValidErrorType.architectDescLengthError
                        );
                      }
                      changeArchDesc(event.detail.value);
                    }}
                />
              </FormField>
            </div>
          </Container>
        </div>
        <div className="mt-20">
          <Container header={<Header variant="h2">Strategical workload</Header>}>
            {loadingServiceList ? (
                <Spinner/>
            ) : (
                <div>
                  {workLoadServiceList.map((element, index) => {
                    return (
                        <div key={index}>
                          <div className="mt-10" style={{fontSize: 18}}>
                            {element.categoryName}
                          </div>
                          <div className="mb-10 flex flex-wrap">
                            {element.svcList.map((svc, svcIndex) => {
                              return (
                                  <div key={svcIndex} className="mt-5 w-25">
                                    <Checkbox
                                        onChange={(event) => {
                                          console.info("event:", event);
                                          setWorkLoadServiceList((prev) => {
                                            const tmpList = JSON.parse(
                                                JSON.stringify(prev)
                                            );
                                            tmpList[index].svcList[svcIndex].checked =
                                                event.detail.checked;
                                            return tmpList;
                                          });
                                        }}
                                        checked={svc.checked || false}
                                    >
                                      <div className="char-capital">{svc.svcName}</div>
                                    </Checkbox>
                                  </div>
                              );
                            })}
                          </div>
                        </div>
                    );
                  })}
                </div>
            )}
          </Container>
        </div>
      </div>
  );
};

export default StepSWADetails;
