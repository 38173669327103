import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppLayout, FlashbarProps } from "@amzn/awsui-components-react";
import { format } from "date-fns";
import { appSyncRequest } from "assets/js/request";
import { getEngagedCustomers } from "graphql/queries";
import {
  Box,
  Button,
  CollectionPreferences,
  Flashbar,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react/polaris";
import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";

import { EngageCustomerAbstract, EngageResult, UserRole } from "API";
import { usecasePageConfig, usecaseVisibleConfig } from "./useCaseListConfig";
import {
  DEFAULT_PAGE_SIZE,
  SIDE_MENU_IS_OPEN,
  USECASE_LIST_PAGE_SIZE_ID,
  USECASE_LIST_VISIBLE_COLUMNS_ID,
} from "assets/js/const";
import { PageType } from "types";
import AppContext from "context/AppContext";
import { TimeRangeTypes } from './comps/AdvancedFilter';
import EngagedCustomerAdvancedFilter from './comps/EngagedCustomerAdvancedFilter';
import CustomerEngageResults from '../comps/CustomerEngageResults';

interface CustomerListContentProps {
  isMyTeam?: boolean;
  isMyCustomer?: boolean;
  pageType: string;
  addFlashNotification: any;
}

const CustomerListContent: React.FC<CustomerListContentProps> = (
    props: CustomerListContentProps
): JSX.Element => {
  let isItemSelected = 1;
  const {isMyCustomer, isMyTeam, pageType } = props;
  const appConfig = useContext(AppContext);
  const [loadingData, setLoadingData] = useState(false);
  const [customerList, setCustomerList] = useState<EngageCustomerAbstract[]>([]);
  const [viewDetailDisable, setViewDetailDisable] = useState(true);
  const [customerEngageDisable, setCustomerEngageDisable] = useState(true);


  const [selectedItems, setSelectedItems] = useState<EngageCustomerAbstract[]>([]);
  const [selectedOneItem, setSelectedOneItem] = useState<EngageCustomerAbstract>();

  const [timeRangeSearch, setTimeRangeSearch] = useState<TimeRangeTypes>();
  // Pagination
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(
      parseInt(
          localStorage.getItem(USECASE_LIST_PAGE_SIZE_ID) || DEFAULT_PAGE_SIZE.toString()) || DEFAULT_PAGE_SIZE
  );
  const [totalCount, setTotalCount] = useState(0);

  const [visibleColumnsList, setVisibleColumnsList] = useState<any>(
      localStorage.getItem(USECASE_LIST_VISIBLE_COLUMNS_ID)?.split(",") || [
        "useCaseId",
        "useCaseName",
        "groupName",
        "contributorName",
        "customerId",
        "customerName",
        "isInterested",
        "reason",
        "createDateTime",
      ]
  );

  const history = useHistory();

  useEffect(() => {
    console.info("selectedItems:", selectedItems);
    if (selectedItems && selectedItems.length === 1) {
      setViewDetailDisable(false);
      setCustomerEngageDisable(false);
      setSelectedOneItem(selectedItems[0]);
    } else {
      setViewDetailDisable(true);
      setCustomerEngageDisable(true);
      setSelectedOneItem(undefined);
    }
  }, [selectedItems]);

  const goToDetail = () => {
    history.push({
      pathname: `/${pageType}/detail/${selectedOneItem?.useCaseId}`,
    });
  };

  const getUseCaseList = useCallback(async () => {
    try {
      setCustomerList([]);
      setLoadingData(true);
      let contributorValues: string[] = [];
      const engagedCustomerListDta: any = await appSyncRequest(getEngagedCustomers, {
        input: {
          teamId: pageType === PageType.MY_TEAM_ENGAGAMENT ? appConfig.userId : undefined,
          customerId: undefined,
          contributorId: pageType === PageType.MY_ENGAGEMENT ? appConfig.userId : undefined,
          managerId:
              isMyTeam && appConfig.userRole === UserRole.Manager
                  ? appConfig.userId
                  : undefined,
          startDate:
              (timeRangeSearch?.startTime &&
                  format(
                      new Date(timeRangeSearch?.startTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          endDate:
              (timeRangeSearch?.endTime &&
                  format(
                      new Date(timeRangeSearch?.endTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          curPage: curPage,
          pageSize: pageSize,
        },
      });
      setCustomerList(engagedCustomerListDta?.data?.getEngagedCustomers?.results || []);
      setTotalCount(engagedCustomerListDta?.data?.getEngagedCustomers?.totalCount || 0);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, pageType, timeRangeSearch]);

  useEffect(() => {
    console.info("pageType Changed:", pageType);
    setCurPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  useEffect(() => {
    getUseCaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, pageType, timeRangeSearch]);

  // @ts-ignore
  // @ts-ignore
  return (
      <>
        <Table
            stickyHeader={true}
            resizableColumns={true}
            ariaLabels={{
              selectionGroupLabel: "Items selection",
              allItemsSelectionLabel: ({selectedItems}) =>
                  `${selectedItems?.length} ${
                      selectedItems?.length === 1 ? "item" : "items"
                  } selected`,
              itemSelectionLabel: ({selectedItems}, item) => {
                isItemSelected = selectedItems.filter(
                    (i) => i.useCaseId === item.useCaseId
                )?.length;
                return `${item.useCaseId} is ${isItemSelected ? "" : "not"} selected`;
              },
            }}
            columnDefinitions={[
              {
                minWidth: 240,
                id: "useCaseId",
                header: "Use Case ID",
                cell: (item) => (
                    <Link href={`/${pageType}/detail/${item.useCaseId}`}>{item.useCaseId}</Link>
                ),
              },
              {
                minWidth: 80,
                id: "useCaseName",
                header: "Use Case Name",
                cell: (e) => e.useCaseName,
              },
              {
                minWidth: 80,
                id: "groupName",
                header: "Service Group",
                cell: (e) => e.groupName,
              },
              {
                id: "contributorName",
                header: "Contributor",
                cell: (e) => e.contributorName,
              },
              {
                id: "customerId",
                header: "Customer ID",
                cell: (e) => e.customerId,
              },
              {
                id: "customerName",
                header: "Customer Name",
                cell: (e) => e.customerName,
              },
              {
                id: "isInterested",
                header: "Is Interested",
                cell: (e) => (<CustomerEngageResults isInterested={e.isInterested || EngageResult.No}/>),
              },
              {
                id: "reason",
                header: "Reason",
                cell: (e) => e.reason,
              },
              {
                id: "createDateTime",
                header: "Create Time",
                minWidth: 120,
                cell: (e) => {
                  return e.createDateTime
                      ? format(
                          new Date(e.createDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
            ]}
            onSelectionChange={({detail}) =>
                setSelectedItems(detail.selectedItems)
            }
            loading={loadingData}
            selectedItems={selectedItems}
            items={customerList}
            loadingText="Loading Customer Engagement"
            selectionType="single"
            visibleColumns={visibleColumnsList}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No UseCases</b>
                <Box padding={{bottom: "s"}} variant="p" color="inherit">
                  No UseCases to display.
                </Box>
              </Box>
            }
            filter={
              <EngagedCustomerAdvancedFilter
                  pageType={pageType}
                  setStartLoading={(loading) => {
                    setLoadingData(loading);
                  }}
                  changeFilters={(filters) => {
                    //setAdvancedSearch(filters);
                    console.info("filters:", filters);
                  }}
                  changeTimeRange={(timeRange) => {
                    setTimeRangeSearch(timeRange);
                  }}
              />
            }
            header={
              <Header
                  counter={
                    selectedItems?.length
                        ? "(" + selectedItems?.length + "/" + customerList?.length + ")"
                        : "(" + customerList?.length + ")"
                  }
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                          loading={loadingData}
                          disabled={loadingData}
                          iconName="refresh"
                          onClick={() => {
                            getUseCaseList();
                          }}
                      ></Button>
                      {((appConfig.userRole === UserRole.Contributor ||
                          appConfig.userRole === UserRole.Manager ||
                          appConfig.userRole === UserRole.Reviewer) && (
                          <Button
                              disabled={customerEngageDisable}
                              variant="primary"
                              onClick={() => {
                                history.push({
                                  pathname: `/${pageType}/${selectedOneItem?.useCaseId}/${selectedOneItem?.customerName}/engage`,
                                  state: {
                                    useCaseId: selectedOneItem?.useCaseId,
                                    groupId: selectedOneItem?.groupId,
                                    customerId: selectedOneItem?.customerId,
                                    customerName: selectedOneItem?.customerName
                                  },
                                });
                              }}
                          >
                            Edit Engagement
                          </Button>
                      ))}
                    </SpaceBetween>
                  }
              >
                Customer Engagement
              </Header>
            }
            pagination={
              <Pagination
                  disabled={loadingData}
                  currentPageIndex={curPage}
                  pagesCount={Math.ceil(totalCount / pageSize)}
                  onChange={(e) => {
                    setCurPage(e.detail.currentPageIndex);
                  }}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                  }}
              />
            }
            preferences={
              <CollectionPreferences
                  title="Preferences"
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
                  preferences={{
                    pageSize: pageSize,
                    visibleContent: visibleColumnsList,
                  }}
                  onConfirm={(event) => {
                    localStorage.setItem(
                        USECASE_LIST_VISIBLE_COLUMNS_ID,
                        event.detail.visibleContent?.join(",") || ""
                    );
                    localStorage.setItem(
                        USECASE_LIST_PAGE_SIZE_ID,
                        event.detail.pageSize?.toString() || DEFAULT_PAGE_SIZE.toString()
                    );
                    setCurPage(1);
                    setPageSize(event.detail.pageSize || DEFAULT_PAGE_SIZE);
                    setVisibleColumnsList(event.detail.visibleContent);
                  }}
                  pageSizePreference={usecasePageConfig}
                  visibleContentPreference={usecaseVisibleConfig}
              />
            }
        />
      </>
  );
};

const EngagedCustomerList: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [notificationList, setNotificationList] = useState<FlashbarProps.MessageDefinition[]>([]);

  const param = useLocation();
  const type: string = param.pathname.replace("/", "");
  const pathStr = param.pathname;

  console.info("PageType: {}", type)

  let engagementListBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];
  if (type === PageType.MY_ENGAGEMENT) {
    engagementListBreadcrumbs = [
      ...engagementListBreadcrumbs,
      {
        text: "Customer Engagement",
        href: "/",
      },
    ];
  }
  if (type === PageType.MY_TEAM_ENGAGAMENT) {
    engagementListBreadcrumbs = [
      ...engagementListBreadcrumbs,
      {
        text: "My team’s Customer Engagement",
        href: "/",
      },
    ];
  }
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={engagementListBreadcrumbs}/>}
          notifications={<Flashbar items={notificationList}/>}
          content={
            <CustomerListContent
                isMyTeam={pathStr === "/engagement-my-team" ? true : false}
                isMyCustomer={pathStr === "/my-engagement" ? true : false}
                pageType={type}
                addFlashNotification={(notification: any) => {
                  setNotificationList(notification);
                }}
            />
          }
          navigation={
            <SideNav
                defaultActiveHref={
                  pathStr === "/my-engagement" ? "/my-engagement" : type ? "/" + type : "/"
                }
            />
          }
          navigationOpen={navigationOpen}
          stickyNotifications
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default EngagedCustomerList;
