import { Attachment, PoC } from "API";

export interface AppContextType {
  userRole: string;
  userId: string;
}

export enum SelectType {
  PoC = "PoC",
  SWA = "SWA",
  Engagement = "Engagement",
}

export enum PageType {
  ASSIGN_TO_ME = "assign-to-me",
  SWA_ASSIGN_TO_ME = "swa-assign-to-me",
  ALL_SWA = "swa-all",
  ALL_POC = "all-poc",
  ALL_USECASE = "usecase-all",
  MY_ENGAGEMENT = "my-engagement",
  MY_TEAM_ENGAGAMENT = "engagement-my-team",
}

export enum AutoSuggestStatus {
  pending = "pending",
  loading = "loading",
  finished = "finished",
  error = "error",
}

export enum PoCInptValidErrorType {
  pocNameEmptyError = "pocNameEmptyError",
  pocNameLengthError = "pocNameLengthError",
  userScenarioDescLengthError = "userScenarioDescLengthError",
  pocScopeLengthError = "pocScopeLengthError",
  userGuideUrlLengthError = "userGuideUrlLengthError",
  userGuideUrlFormatError = "userGuideUrlFormatError",
  sourceCodeUrlLengthError = "sourceCodeUrlLengthError",
  sourceCodeUrlFormatError = "sourceCodeUrlFormatError",
  architectDescLengthError = "architectDescLengthError",
  effortsFormatError = "effortsFormatError",
}

export enum SWAInptValidErrorType {
  swaNameEmptyError = "swaNameEmptyError",
  swaNameLengthError = "swaNameLengthError",
  swaDescLengthError = "swaDescLengthError",
  effortsFormatError = "effortsFormatError",
  architectDescLengthError = "architectDescLengthError",
  customerBenefitLengthError = "customerBenefitLengthError",
  linkUrlLengthError = "linkUrlLengthError",
  linkUrlFormatError = "linkUrlFormatError",
}

export enum UseCaseInptValidErrorType {
  useCaseNameEmptyError = "useCaseNameEmptyError",
  useCaseNameLengthError = "useCaseNameLengthError",
  useCaseDescLengthError = "useCaseDescLengthError",
  architectDescLengthError = "architectDescLengthError",
}

export interface ExPoC extends PoC {
  totalScore: number;
}

export interface ExAttachment extends Attachment {
  showProgress: boolean;
  progress: number;
}

export interface ExPoCAttach extends PoC {
  attachments?: Array<ExAttachment | null> | null;
}
