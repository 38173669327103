import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppLayout, FlashbarProps } from "@amzn/awsui-components-react";
import { format } from "date-fns";

import { appSyncRequest } from "assets/js/request";
import { getSWAs } from "graphql/queries";
import {
  Box,
  Button,
  CollectionPreferences,
  Flashbar,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react/polaris";

import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";
import AdvancedFilterSWA, { FilterTypes, TimeRangeTypes, } from "./comps/AdvancedFilterSWA";

import { ReviewStatus, SubmitMode, SWAAbstract, UserRole } from "API";
import { swaPageConfig, swaVisibleConfig } from "./pocListConfig";
import { SIDE_MENU_IS_OPEN, SWA_LIST_PAGE_SIZE_ID, SWA_LIST_VISIBLE_COLUMNS_ID, } from "assets/js/const";
// import AppContext from "context/AppContext";
import { PageType } from "types";
import AppContext from "context/AppContext";
import AssignModalSWA from './comps/AssignModalSWA'
import PoCReviewStatus from '../comps/PoCReviewStatus'
import SWAReviewResult from '../comps/SWAReviewResult'

interface SWAListContentProps {
  isMyTeam?: boolean;
  isMySWA?: boolean;
  pageType: string;
  addFlashNotification: any;
}

const SWAListContent: React.FC<SWAListContentProps> = (
    props: SWAListContentProps
): JSX.Element => {
  let isItemSelected = 1;
  const {isMySWA, isMyTeam, pageType, addFlashNotification} = props;
  const appConfig = useContext(AppContext);
  // console.info("pageType:", pageType);
  const [loadingData, setLoadingData] = useState(false);
  const [swaList, setSwaList] = useState<SWAAbstract[]>([]);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [viewDetailDisable, setViewDetailDisable] = useState(true);

  const [selectedItems, setSelectedItems] = useState<SWAAbstract[]>([]);
  const [selectedOneItem, setSelectedOneItem] = useState<SWAAbstract>();

  const [advancedSearch, setAdvancedSearch] = useState<FilterTypes>();
  const [timeRangeSearch, setTimeRangeSearch] = useState<TimeRangeTypes>();
  // Pagination
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(
      parseInt(localStorage.getItem(SWA_LIST_PAGE_SIZE_ID) || "10") || 10
  );
  const [totalCount, setTotalCount] = useState(0);

  const [assignDisable, setAssignDisable] = useState(false);
  const [visibleColumnsList, setVisibleColumnsList] = useState<any>(
      localStorage.getItem(SWA_LIST_VISIBLE_COLUMNS_ID)?.split(",") || [
        "swaId",
        "contributor",
        "managerName",
        "subject",
        "sfdcAccountId",
        "submitDateTime",
        "productGroup",
        "assignDateTime",
        "reviewStatus",
        "isPass",
      ]
  );

  const history = useHistory();

  const successAssignNotification: FlashbarProps.MessageDefinition = {
    type: "success",
    content: "SWA assigned successfully",
    dismissible: true,
    onDismiss: () => {
      addFlashNotification([]);
    },
  };

  useEffect(() => {
    console.info("selectedItems:", selectedItems);
    if (selectedItems && selectedItems.length === 1) {
      setViewDetailDisable(false);
      setSelectedOneItem(selectedItems[0]);
    } else {
      setViewDetailDisable(true);
      setSelectedOneItem(undefined);
    }
    if (selectedItems && selectedItems.length > 0) {
      let hasDone = false;
      selectedItems.forEach((element) => {
        if (element.reviewStatus === ReviewStatus.Done) {
          hasDone = true;
        }
      });
      setAssignDisable(hasDone);
    } else {
      setAssignDisable(true);
    }
  }, [selectedItems]);

  const goToDetail = () => {
    history.push({
      pathname: `/${pageType}/detail/${selectedOneItem?.swaId}`,
    });
  };

  const getSWAList = useCallback(async () => {
    try {
      setSwaList([]);
      setLoadingData(true);
      let contributorValues: string[] = [];
      advancedSearch?.contributorId?.forEach((elem) => {
        let contributorValue = elem.match(/\(([^()][a-z]+)\)/)?.[1]
        if (contributorValue !== undefined) {
          contributorValues.push(contributorValue)
        }
      });
      const swaListDta: any = await appSyncRequest(getSWAs, {
        input: {
          callFromAssignedToMe:
              pageType === PageType.SWA_ASSIGN_TO_ME ? true : undefined,
          assignee:
              pageType === PageType.SWA_ASSIGN_TO_ME ? appConfig.userId : undefined,
          teamId: pageType === "swa-my-team" ? appConfig.userId : undefined,
          reviewStatus:
              pageType === PageType.SWA_ASSIGN_TO_ME
                  ? ReviewStatus.Assigned
                  : undefined,
          swaStatus: pageType === "my-swa" ? undefined : SubmitMode.Submit,
          swaIds: advancedSearch?.swaIds?.split(",") || undefined,
          supervisorId:
              isMyTeam && appConfig.userRole === UserRole.Reviewer
                  ? appConfig.userId
                  : undefined,
          managerId:
              isMyTeam && appConfig.userRole === UserRole.Manager
                  ? appConfig.userId
                  : advancedSearch?.managerId?.match(/\(([^)]+)\)/)?.[1] ||
                  undefined,
          contributorId: isMySWA
              ? appConfig.userId
              : contributorValues ||
              undefined,
          svcId:
              advancedSearch?.svcId?.match(/\(([^()][a-zA-Z0-9]+)\)/)?.[1] ||
              undefined,
          categoryId:
              advancedSearch?.categoryId?.match(/\(([^()][a-zA-Z0-9]+)\)/)?.[1] ||
              undefined,
          sfdcAccountId:
              advancedSearch?.accountId ||
              undefined,
          startDate:
              (timeRangeSearch?.startTime &&
                  format(
                      new Date(timeRangeSearch?.startTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          endDate:
              (timeRangeSearch?.endTime &&
                  format(
                      new Date(timeRangeSearch?.endTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          curPage: curPage,
          pageSize: pageSize,
        },
      });
      setSwaList(swaListDta?.data?.getSWAs?.swas || []);
      setTotalCount(swaListDta?.data?.getSWAs?.totalCount || 0);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, pageType, advancedSearch, timeRangeSearch]);

  useEffect(() => {
    console.info("pageType Changed:", pageType);
    setCurPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  useEffect(() => {
    getSWAList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, pageType, timeRangeSearch]);

  useEffect(() => {
    console.info("advancedSearch:", advancedSearch);
    if (advancedSearch) {
      if (curPage === 1) {
        getSWAList();
      } else {
        setCurPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedSearch]);

  return (
      <>
        <AssignModalSWA
            addSuccess={() => {
              setSelectedItems([]);
              addFlashNotification([successAssignNotification]);
              getSWAList();
            }}
            hideDialog={() => {
              setShowAssignDialog(false);
            }}
            selectedItems={selectedItems}
            showDialog={showAssignDialog}
        />
        <Table
            trackBy="swaId"
            stickyHeader={true}
            resizableColumns={true}
            ariaLabels={{
              selectionGroupLabel: "Items selection",
              allItemsSelectionLabel: ({selectedItems}) =>
                  `${selectedItems?.length} ${
                      selectedItems?.length === 1 ? "item" : "items"
                  } selected`,
              itemSelectionLabel: ({selectedItems}, item) => {
                isItemSelected = selectedItems.filter(
                    (i) => i.swaId === item.swaId
                )?.length;
                return `${item.swaId} is ${isItemSelected ? "" : "not"} selected`;
              },
            }}
            columnDefinitions={[
              {
                minWidth: 240,
                id: "swaId",
                header: "SWA ID",
                cell: (item) => (
                    <Link href={`/${pageType}/detail/${item.swaId}`}>{item.swaId}</Link>
                ),
              },
              {
                minWidth: 80,
                id: "contributor",
                header: "SA",
                cell: (e) => e.contributor?.name,
              },
              {
                minWidth: 120,
                id: "managerName",
                header: "Manager",
                cell: (e) => e.contributor?.managerName,
              },
              {
                id: "subject",
                header: "Subject",
                cell: (e) => e.swaSubject,
              },
              {
                id: "productGroup",
                header: "Product Group",
                cell: (e) =>
                    e.workload?.map((element) => element?.workload).join(", "),
              },
              {
                minWidth: 180,
                id: "sfdcAccountId",
                header: "Account ID",
                cell: (e) => e.sfdcAccountId,
              },
              {
                id: "submitDateTime",
                header: "Created",
                width: 120,
                cell: (e) => {
                  return e.submitDateTime
                      ? format(
                          new Date(e.submitDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
              {
                id: "assignDateTime",
                header: "Assigned",
                width: 120,
                cell: (e) => {
                  return e.assignDateTime
                      ? format(
                          new Date(e.assignDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
              {
                id: "reviewStatus",
                header: "Review",
                width: 120,
                cell: (e) => (
                    <PoCReviewStatus
                        reviewStatus={e.reviewStatus || ReviewStatus.No}
                    />
                ),
              },
              {
                id: "isPass",
                header: "Review Result",
                width: 140,
                cell: (e) => {
                  return e.reviewStatus === ReviewStatus.Done ? (
                      <SWAReviewResult
                          isPass={e.isPass === true}
                      />
                  ) : "-";
                },
              },
            ]}
            onSelectionChange={({detail}) =>
                setSelectedItems(detail.selectedItems)
            }
            loading={loadingData}
            selectedItems={selectedItems}
            items={swaList}
            loadingText="Loading SWAs"
            selectionType="single"
            visibleColumns={visibleColumnsList}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No SWAs</b>
                <Box padding={{bottom: "s"}} variant="p" color="inherit">
                  No SWAs to display.
                </Box>
              </Box>
            }
            filter={
              <AdvancedFilterSWA
                  pageType={pageType}
                  setStartLoading={(loading) => {
                    setLoadingData(loading);
                  }}
                  changeFilters={(filters) => {
                    setAdvancedSearch(filters);
                    console.info("filters:", filters);
                  }}
                  changeTimeRange={(timeRange) => {
                    setTimeRangeSearch(timeRange);
                  }}
              />
            }
            header={
              <Header
                  counter={
                    selectedItems?.length
                        ? "(" + selectedItems?.length + "/" + swaList?.length + ")"
                        : "(" + swaList?.length + ")"
                  }
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                          loading={loadingData}
                          disabled={loadingData}
                          iconName="refresh"
                          onClick={() => {
                            getSWAList();
                          }}
                      ></Button>
                      <Button
                          disabled={viewDetailDisable}
                          variant="normal"
                          onClick={() => {
                            goToDetail();
                          }}
                      >
                        View details
                      </Button>
                      {(appConfig.userRole === UserRole.Administrator ||
                          (appConfig.userRole === UserRole.Manager && pageType === PageType.SWA_ASSIGN_TO_ME)) && (
                          <Button
                              disabled={assignDisable}
                              variant="primary"
                              onClick={() => {
                                setShowAssignDialog(true);
                              }}
                          >
                            Assign Reviewer
                          </Button>
                      )}
                    </SpaceBetween>
                  }
              >
                SWA
              </Header>
            }
            pagination={
              <Pagination
                  disabled={loadingData}
                  currentPageIndex={curPage}
                  pagesCount={Math.ceil(totalCount / pageSize)}
                  onChange={(e) => {
                    setCurPage(e.detail.currentPageIndex);
                  }}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                  }}
              />
            }
            preferences={
              <CollectionPreferences
                  title="Preferences"
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
                  preferences={{
                    pageSize: pageSize,
                    visibleContent: visibleColumnsList,
                  }}
                  onConfirm={(event) => {
                    localStorage.setItem(
                        SWA_LIST_VISIBLE_COLUMNS_ID,
                        event.detail.visibleContent?.join(",") || ""
                    );
                    localStorage.setItem(
                        SWA_LIST_PAGE_SIZE_ID,
                        event.detail.pageSize?.toString() || "10"
                    );
                    setCurPage(1);
                    setPageSize(event.detail.pageSize || 10);
                    setVisibleColumnsList(event.detail.visibleContent);
                  }}
                  pageSizePreference={swaPageConfig}
                  visibleContentPreference={swaVisibleConfig}
              />
            }
        />
      </>
  );
};

const SWAList: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [notificationList, setNotificationList] = useState<FlashbarProps.MessageDefinition[]>([]);

  const param = useLocation();
  const type: string = param.pathname.replace("/", "");
  const pathStr = param.pathname;

  console.info("PageType: {}", type)

  let swaListBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];
  if (type === PageType.ALL_SWA) {
    swaListBreadcrumbs = [
      ...swaListBreadcrumbs,
      {
        text: "SWA",
        href: "/",
      },
    ];
  }
  if (type === PageType.SWA_ASSIGN_TO_ME) {
    swaListBreadcrumbs = [
      ...swaListBreadcrumbs,
      {
        text: "Assigned to me(SWA)",
        href: "",
      },
    ];
  }
  if (type === "swa-my-team") {
    swaListBreadcrumbs = [
      ...swaListBreadcrumbs,
      {
        text: "My team’s SWA",
        href: "",
      },
    ];
  }
  // show my swa in breadcrumb when contributor
  if (pathStr === "/my-swa") {
    swaListBreadcrumbs = [
      ...swaListBreadcrumbs,
      {
        text: "My SWA",
        href: "/",
      },
    ];
  }
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={swaListBreadcrumbs}/>}
          notifications={<Flashbar items={notificationList}/>}
          content={
            <SWAListContent
                isMyTeam={pathStr === "/swa-my-team" ? true : false}
                isMySWA={pathStr === "/my-swa" ? true : false}
                pageType={type}
                addFlashNotification={(notification: any) => {
                  setNotificationList(notification);
                }}
            />
          }
          navigation={
            <SideNav
                defaultActiveHref={
                  pathStr === "/my-swa" ? "/my-swa" : type ? "/" + type : "/"
                }
            />
          }
          navigationOpen={navigationOpen}
          stickyNotifications
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default SWAList;
