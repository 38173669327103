import { HelpPanel } from "@amzn/awsui-components-react";

export const RequirementsSpecification = () => (
    <HelpPanel header={<h2>Requirements Spec.</h2>}>
      <p>Description of the customer requirements and the purpose of the PoC.</p>
      <h3>Score: 0</h3>
      <ul>
        <li>No provided.</li>
      </ul>
      <h3>Score: 1</h3>
      <ul>
        <li>Customer user scenario description.</li>
      </ul>
      <h3>Score: 2</h3>
      <ul>
        <li>Customer user scenario descripiton.</li>
        <li>Purpose description.</li>
      </ul>
      <h3>Score: 3</h3>
      <ul>
        <li>Customer user scenario descripiton.</li>
        <li>Purpose description.</li>
        <li>Quantified technical indicators.</li>
      </ul>
      <h3>Score: 4</h3>
      <ul>
        <li>Customer user scenario descripiton.</li>
        <li>Purpose description.</li>
        <li>Quantified technical indicators.</li>
        <li>
          Functional description. (Use multiple services for a specific user
          scenario)
        </li>
      </ul>
      <h3>Score: 5</h3>
      <ul>
        <li>Customer user scenario descripiton.</li>
        <li>Purpose description.</li>
        <li>Quantified technical indicators.</li>
        <li>
          Functional description. (Use multiple services for a specific user
          scenario)
        </li>
        <li>Key technical points description.</li>
      </ul>
    </HelpPanel>
);

export const PoCPlan = () => (
    <HelpPanel header={<h2>PoC Plan</h2>}>
      <p>Description of the PoC Plan of the PoC.</p>
      <h3>Score: 0</h3>
      <ul>
        <li>No provided.</li>
      </ul>
      <h3>Score: 1</h3>
      <ul>
        <li>Architecture diagram.</li>
      </ul>
      <h3>Score: 2</h3>
      <ul>
        <li>Architecture diagram.</li>
        <li>AWS service feature description.</li>
      </ul>
      <h3>Score: 3</h3>
      <ul>
        <li>Architecture diagram.</li>
        <li>AWS service feature description.</li>
        <li>Data source description and tools/scripts description.</li>
      </ul>
      <h3>Score: 4</h3>
      <ul>
        <li>Architecture diagram.</li>
        <li>AWS service feature description.</li>
        <li>Data source description and tools/scripts description.</li>
        <li>Timing/Resource planning.</li>
      </ul>
      <h3>Score: 5</h3>
      <ul>
        <li>Architecture diagram.</li>
        <li>AWS service feature description.</li>
        <li>Data source description and tools/scripts description.</li>
        <li>Timing/Resource planning.</li>
        <li>Assumptions/Dependecies/Constrains.</li>
      </ul>
    </HelpPanel>
);

export const TestReport = () => (
    <HelpPanel header={<h2>Test Report</h2>}>
      <p>Description of the Test Report of the PoC.</p>
      <h3>Score: 0</h3>
      <ul>
        <li>No provided.</li>
      </ul>
      <h3>Score: 1</h3>
      <ul>
        <li>Test scenario description.</li>
      </ul>
      <h3>Score: 2</h3>
      <ul>
        <li>Test scenario description.</li>
        <li>Test results description.</li>
      </ul>
      <h3>Score: 3</h3>
      <ul>
        <li>Test scenario description.</li>
        <li>Test results description.</li>
        <li>Test process description.</li>
      </ul>
      <h3>Score: 4</h3>
      <ul>
        <li>Test scenario description.</li>
        <li>Test results description.</li>
        <li>Test process description.</li>
        <li>Step-by-step reproduce guide.</li>
      </ul>
      <h3>Score: 5</h3>
      <ul>
        <li>Test scenario description.</li>
        <li>Test results description.</li>
        <li>Test process description.</li>
        <li>Step-by-step reproduce guide.</li>
        <li>Tools/Scripts and quantified benchmark.</li>
      </ul>
    </HelpPanel>
);

export const PoCConclusion = () => (
    <HelpPanel header={<h2>PoC Conclusion</h2>}>
      <p>Description of the PoC Conclusion of the PoC.</p>
      <h3>Score: 0</h3>
      <ul>
        <li>No provided.</li>
      </ul>
      <h3>Score: 1</h3>
      <ul>
        <li>A conclusion.</li>
      </ul>
      <h3>Score: 2</h3>
      <ul>
        <li>A conclusion.</li>
        <li>Related to customer’s user scenario.</li>
      </ul>
      <h3>Score: 3</h3>
      <ul>
        <li>A conclusion.</li>
        <li>Related to customer’s user scenario.</li>
        <li>Limitation description.</li>
      </ul>
      <h3>Score: 4</h3>
      <ul>
        <li>A conclusion.</li>
        <li>Related to customer’s user scenario.</li>
        <li>Limitation description.</li>
        <li>Can be a Best Pratice candidate.</li>
      </ul>
      <h3>Score: 5</h3>
      <ul>
        <li>A conclusion.</li>
        <li>Related to customer’s user scenario.</li>
        <li>Limitation description.</li>
        <li>Can be a Best Pratice candidate.</li>
        <li>Has technical depth or is innovative.</li>
      </ul>
    </HelpPanel>
);
