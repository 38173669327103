import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AppLayout } from "@amzn/awsui-components-react";

import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";

import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import DetailCompSWA from "../detail/DetailCompSWA";

interface MatchParams {
  id: string;
  ctReviewId: string;
}

const CrossTeamSWADetail: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const id: string = props.match.params.id;
  const ctReviewId: string = props.match.params.ctReviewId;

  const pocDetailBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Cross-team reviews(SWA)",
      href: "/admin/swa-cross-team-review",
    },
    {
      text: ctReviewId.toUpperCase(),
      href: "/admin/swa-create-cross-team-review/" + ctReviewId,
    },
    {
      text: id.toUpperCase(),
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocDetailBreadcrumbs}/>}
          content={<DetailCompSWA swaId={id}/>}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CrossTeamSWADetail;
