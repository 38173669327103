import React, { useContext, useEffect, useState } from "react";
import { AppLayout } from "@amzn/awsui-components-react";
import { Button, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";
import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import StepPoCOpp from "../comps/step/StepPoCOpp";
import StepPoCDetails from "../comps/step/StepPoCDetails";
import StepPoCTags from "../comps/step/StepPoCTags";
import { PoCInput, SubmitMode, UserRole } from "API";
import { useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { submitPoC } from "graphql/mutations";
import AppContext from "context/AppContext";
import { isValidOppId } from "assets/js/utils";

const CreatePoCContent: React.FC = () => {
  const history = useHistory();
  const appConfig = useContext(AppContext);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [createPoCInfo, setCreatePoCInfo] = useState<PoCInput>({
    // pocId: "",
    mode: SubmitMode.Draft,
    contributorId: appConfig.userId,
    opptyId: "",
    attachments: [],
    pocName: "",
    pocSubject: "",
    userScenarioDesc: "",
    pocScope: "",
    architectDesc: "",
    architectFilename: "",
    architectImgUrl: "",
    industryTagList: [],
    sourceCodeUrl: "",
    technicalTagList: [],
    userGuideUrl: "",
    efforts: 0,
  });
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [showOppIdError, setShowOppIdError] = useState(false);
  const [showNameEmptyError, setShowNameEmptyError] = useState(false);
  const [showEffortsInvalidError, setShowEffortsInvalidError] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [pocInputErrorArr, setPocInputErrorArr] = useState<string[]>([]);

  const saveDraftAndNext = async () => {
    const createPoCInfoParam = JSON.parse(JSON.stringify(createPoCInfo));
    if (!isValidOppId(createPoCInfoParam.opptyId)) {
      setShowOppIdError(true);
      setActiveStepIndex(0);
      return;
    }
    setLoadingCreate(true);
    try {
      const createPoCRes = await appSyncRequest(submitPoC, {
        input: createPoCInfoParam,
      });
      console.info("createPoCRes:", createPoCRes);
      setLoadingCreate(false);
      setActiveStepIndex(1);
      setCreatePoCInfo((prev) => {
        return {...prev, pocId: createPoCRes.data.submitPoC.pocId};
      });
    } catch (error) {
      setLoadingCreate(false);
      console.error(error);
    }
  };

  const updatePoC = async (mode: SubmitMode) => {
    if (pocInputErrorArr && pocInputErrorArr.length > 0) {
      return;
    }

    // validate oppId first
    if (!isValidOppId(createPoCInfo.opptyId)) {
      setShowOppIdError(true);
      setActiveStepIndex(0);
      return;
    }

    // Validate Input
    // setShowNameEmptyError while poc Name is Empty
    if (!createPoCInfo?.pocName?.trim()) {
      setShowNameEmptyError(true);
      setActiveStepIndex(1);
      return;
    }

    if (createPoCInfo.efforts < 1) {
      setShowEffortsInvalidError(true);
      setActiveStepIndex(1);
      return;
    }

    const updatePoCInfoParam = JSON.parse(JSON.stringify(createPoCInfo));
    updatePoCInfoParam.mode = mode;
    console.info("updatePoCInfoParam:", updatePoCInfoParam);

    // encode assets input
    updatePoCInfoParam.pocName = encodeURIComponent(updatePoCInfoParam.pocName);
    updatePoCInfoParam.pocSubject = encodeURIComponent(
        updatePoCInfoParam.pocSubject
    );
    updatePoCInfoParam.userScenarioDesc = encodeURIComponent(
        updatePoCInfoParam.userScenarioDesc
    );
    updatePoCInfoParam.pocScope = encodeURIComponent(
        updatePoCInfoParam.pocScope
    );
    updatePoCInfoParam.architectDesc = encodeURIComponent(
        updatePoCInfoParam.architectDesc
    );
    updatePoCInfoParam.architectFilename = encodeURIComponent(
        updatePoCInfoParam.architectFilename
    );

    // Attachment Refine
    const updateAttachments: any = [];
    createPoCInfo?.attachments?.forEach((element) => {
      updateAttachments.push({
        attachmentFilename: encodeURIComponent(
            element?.attachmentFilename || ""
        ),
        attachmentUrl: element?.attachmentUrl,
        attachmentSize: element?.attachmentSize,
        attachmentDesc: encodeURIComponent(element?.attachmentDesc || ""),
      });
    });
    updatePoCInfoParam.attachments = updateAttachments;
    if (mode === SubmitMode.Draft) {
      setLoadingSaveDraft(true);
    }
    if (mode === SubmitMode.Submit) {
      setLoadingSubmit(true);
    }
    try {
      const createPoCRes = await appSyncRequest(submitPoC, {
        input: updatePoCInfoParam,
      });
      console.info("createPoCRes:", createPoCRes);
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      history.push({
        pathname: appConfig.userRole === UserRole.Contributor ? "/my-poc" : "/",
      });
    } catch (error) {
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      console.error(error);
    }
  };

  useEffect(() => {
    console.info("createPoCInfo:", createPoCInfo);
  }, [createPoCInfo]);

  return (
      <div>
        <div className="gsal-review-page">
          <div className="gsal-step-nav">
            <nav>
              <ul>
                <li>
                  <small>Step 1</small>
                  <div className="step-name">
                  <span
                      onClick={() => {
                        // check efforts
                        if (createPoCInfo.efforts < 1) {
                          setShowEffortsInvalidError(true);
                          setActiveStepIndex(1);
                          return;
                        }
                        setActiveStepIndex(0);
                      }}
                      className={activeStepIndex !== 0 ? "link" : ""}
                  >
                    Opportunity info
                  </span>
                  </div>
                </li>
                <li>
                  <small>Step 2</small>
                  <div className="step-name">
                  <span
                      onClick={() => {
                        if (!isValidOppId(createPoCInfo.opptyId)) {
                          setShowOppIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                          return;
                        }
                        setActiveStepIndex(1);
                      }}
                      className={activeStepIndex !== 1 ? "link" : ""}
                  >
                    PoC details
                  </span>
                  </div>
                </li>
                <li>
                  <small>Step 3</small>
                  <div className="step-name">
                  <span
                      onClick={() => {
                        if (!isValidOppId(createPoCInfo.opptyId)) {
                          setShowOppIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        // check efforts
                        if (createPoCInfo.efforts < 1) {
                          setShowEffortsInvalidError(true);
                          setActiveStepIndex(1);
                          return;
                        }
                        if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                          return;
                        }
                        setActiveStepIndex(2);
                      }}
                      className={activeStepIndex !== 2 ? "link" : ""}
                  >
                    Tags & attachments
                  </span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className="gsal-review-content">
            {activeStepIndex === 0 && (
                <StepPoCOpp
                    curPoc={createPoCInfo}
                    showOppIdError={showOppIdError}
                    changePoCOppId={(id) => {
                      setShowOppIdError(false);
                      setCreatePoCInfo((prev) => {
                        return {...prev, opptyId: id};
                      });
                    }}
                />
            )}
            {activeStepIndex === 1 && (
                <StepPoCDetails
                    curPoc={createPoCInfo}
                    addInputError={(error) => {
                      if (!pocInputErrorArr.includes(error)) {
                        setPocInputErrorArr((prev) => {
                          return [...prev, error];
                        });
                      }
                    }}
                    removeInputError={(error) => {
                      setPocInputErrorArr((prev) => {
                        return prev.filter((e) => e !== error);
                      });
                    }}
                    changeIsUploading={(uploading) => {
                      setIsFileUploading(uploading);
                    }}
                    setShowNameEmptyError={(showError) => {
                      setShowNameEmptyError(showError);
                    }}
                    showEffortsInvalidError={showEffortsInvalidError}
                    showNameEmptyError={showNameEmptyError}
                    changePocName={(pocName) => {
                      setShowNameEmptyError(false);
                      setCreatePoCInfo((prev) => {
                        return {...prev, pocName: pocName, pocSubject: pocName};
                      });
                    }}
                    changePocUserSenario={(userSenario) => {
                      setCreatePoCInfo((prev) => {
                        return {...prev, userScenarioDesc: userSenario};
                      });
                    }}
                    changePocScope={(pocScope) => {
                      setCreatePoCInfo((prev) => {
                        return {...prev, pocScope: pocScope};
                      });
                    }}
                    changeUserGuide={(userGuide) => {
                      setCreatePoCInfo((prev) => {
                        return {...prev, userGuideUrl: userGuide};
                      });
                    }}
                    changeSourceCode={(sourceCode) => {
                      setCreatePoCInfo((prev) => {
                        return {...prev, sourceCodeUrl: sourceCode};
                      });
                    }}
                    changeArchImgInfo={(archInfo) => {
                      setCreatePoCInfo((prev) => {
                        return {
                          ...prev,
                          architectFilename: archInfo.architectFilename,
                          architectImgUrl: archInfo.architectImgUrl,
                        };
                      });
                    }}
                    changeArchDesc={(archDesc) => {
                      setCreatePoCInfo((prev) => {
                        return {...prev, architectDesc: archDesc};
                      });
                    }}
                    changeEfforts={(efforts) => {
                      if (efforts > 0) {
                        setShowEffortsInvalidError(false);
                      } else {
                        setShowEffortsInvalidError(true);
                      }
                      setCreatePoCInfo((prev) => {
                        return {...prev, efforts: efforts};
                      });
                    }}
                />
            )}
            {activeStepIndex === 2 && (
                <StepPoCTags
                    curPoc={createPoCInfo}
                    addInputError={(error) => {
                      if (!pocInputErrorArr.includes(error)) {
                        setPocInputErrorArr((prev) => {
                          return [...prev, error];
                        });
                      }
                    }}
                    removeInputError={(error) => {
                      setPocInputErrorArr((prev) => {
                        return prev.filter((e) => e !== error);
                      });
                    }}
                    changeIsUploading={(uploading) => {
                      setIsFileUploading(uploading);
                    }}
                    changeIndustryTagList={(tags) => {
                      setCreatePoCInfo((prev) => {
                        return {...prev, industryTagList: tags};
                      });
                    }}
                    changeTechTagList={(tags) => {
                      setCreatePoCInfo((prev) => {
                        return {...prev, technicalTagList: tags};
                      });
                    }}
                    addNewAttachment={(item) => {
                      setCreatePoCInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        const prevAttachment = prevObj.attachments;
                        prevAttachment.push(item);
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    removeAttachment={(index) => {
                      if (
                          createPoCInfo.attachments &&
                          createPoCInfo.attachments.length > 0
                      ) {
                        const tmpAttacment = [...createPoCInfo.attachments];
                        tmpAttacment.splice(index, 1);
                        setCreatePoCInfo((prev: any) => {
                          const prevObj = JSON.parse(JSON.stringify(prev));
                          return {
                            ...prevObj,
                            ...{
                              attachments: tmpAttacment,
                            },
                          };
                        });
                      }
                    }}
                    changeAttachmentDesc={(index, desc) => {
                      setCreatePoCInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        prevObj.attachments[index].attachmentDesc = desc;
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    uploadAttachment={(upProgress, index) => {
                      setCreatePoCInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        if (upProgress >= 100) {
                          prevObj.attachments[index].progress = upProgress;
                          prevObj.attachments[index].showProgress = false;
                        } else {
                          prevObj.attachments[index].progress = upProgress;
                          prevObj.attachments[index].showProgress = true;
                        }
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    uploadAttachmentDone={(index, name, size, key) => {
                      setCreatePoCInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        prevObj.attachments[index].attachmentFilename = name;
                        prevObj.attachments[index].attachmentSize = size;
                        prevObj.attachments[index].attachmentUrl = key;
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                />
            )}
            <div className="mt-20 pb-30 clearfix">
              <SpaceBetween className="fr" direction="horizontal" size="xs">
                <Button
                    variant="link"
                    onClick={() => {
                      history.push({
                        pathname: `/`,
                      });
                    }}
                >
                  Cancel
                </Button>

                {activeStepIndex === 0 && !createPoCInfo.pocId && (
                    <Button
                        loading={loadingCreate}
                        variant="primary"
                        onClick={() => {
                          saveDraftAndNext();
                        }}
                    >
                      Save Draft & Next
                    </Button>
                )}

                {activeStepIndex === 0 && createPoCInfo.pocId && (
                    <Button
                        disabled={loadingSubmit || isFileUploading}
                        loading={loadingSaveDraft}
                        onClick={() => {
                          updatePoC(SubmitMode.Draft);
                        }}
                    >
                      Save Draft
                    </Button>
                )}

                {activeStepIndex === 0 && createPoCInfo.pocId && (
                    <Button
                        disabled={
                            loadingSubmit || loadingSaveDraft || isFileUploading
                        }
                        variant="primary"
                        onClick={() => {
                          // validate opp id when click next
                          if (!isValidOppId(createPoCInfo.opptyId)) {
                            setShowOppIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                            return;
                          }
                          setActiveStepIndex(1);
                        }}
                    >
                      Next
                    </Button>
                )}

                {activeStepIndex > 0 && (
                    <Button
                        disabled={
                            loadingSubmit || loadingSaveDraft || isFileUploading
                        }
                        onClick={() => {
                          if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                            return;
                          }
                          // check efforts
                          if (createPoCInfo.efforts < 1) {
                            setShowEffortsInvalidError(true);
                            setActiveStepIndex(1);
                            return;
                          }
                          setActiveStepIndex((prev) => {
                            return prev - 1;
                          });
                        }}
                    >
                      Previous
                    </Button>
                )}
                {(activeStepIndex === 1 || activeStepIndex === 2) && (
                    <Button
                        disabled={loadingSubmit || isFileUploading}
                        loading={loadingSaveDraft}
                        onClick={() => {
                          updatePoC(SubmitMode.Draft);
                        }}
                    >
                      Save Draft
                    </Button>
                )}
                {activeStepIndex === 1 && (
                    <Button
                        disabled={
                            loadingSubmit || loadingSaveDraft || isFileUploading
                        }
                        variant="primary"
                        onClick={() => {
                          if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                            return;
                          }
                          // check efforts
                          if (createPoCInfo.efforts < 1) {
                            setShowEffortsInvalidError(true);
                            setActiveStepIndex(1);
                            return;
                          }
                          setActiveStepIndex(2);
                        }}
                    >
                      Next
                    </Button>
                )}
                {activeStepIndex === 2 && (
                    <Button
                        disabled={loadingSaveDraft || isFileUploading}
                        loading={loadingSubmit}
                        variant="primary"
                        onClick={() => {
                          updatePoC(SubmitMode.Submit);
                        }}
                    >
                      Submit
                    </Button>
                )}
              </SpaceBetween>
            </div>
          </div>
        </div>
      </div>
  );
};

const CreatePoC: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(false);
  // const [helpInfo, setHelpInfo] = useState();
  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const pocReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Create a PoC",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocReviewBreadcrumbs}/>}
          content={<CreatePoCContent/>}
          // tools={helpInfo}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CreatePoC;
