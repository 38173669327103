import React, { useState } from "react";
import { DateRangePicker, PropertyFilter, PropertyFilterProps, } from "@amzn/awsui-components-react/polaris";
import { appSyncRequest } from "assets/js/request";
import { getCustomersByAos } from "graphql/queries";

import {
  MultiCustomerSearchI18NString,
  TimePickI18NString,
} from "assets/js/const";
import {
  convertToAbsoluteRange,
  isValidRangeFunction,
} from "assets/js/utils";

export interface FilterTypes {
  accountIds?: string;
}

export interface TimeRangeTypes {
  startTime: any;
  endTime: any;
}

interface CustomerAdvancedFilterProps {
  groupId: string;
  changeFilters: (filters: FilterTypes) => void;
  changeTimeRange: (time: TimeRangeTypes) => void;
  setStartLoading: (loading: boolean) => void;
}

const CustomerAdvancedFilter: React.FC<CustomerAdvancedFilterProps> = ({
                                                            groupId,
                                                            changeFilters,
                                                            changeTimeRange,
                                                            setStartLoading,
                                                          }: CustomerAdvancedFilterProps) => {
  const [timeRange, setTimeRange] = useState<any>();
  const [query, setQuery] = useState<any>({
    tokens: [],
    operation: "and",
  });

  const changeSearchInputValue = (detail: PropertyFilterProps.Query) => {
    console.info("detail:", detail);

    const searchCondition: any = {
      operation: "and",
      tokens: [],
    };

    let enteredText = "";
    if (detail.tokens) {
      detail.tokens.forEach((element) => {
        if (!element.propertyKey && element.value) {
          enteredText = element.value;
        }
      });
    }
    console.info("detail.tokens", enteredText);

    if (enteredText) {
      searchCondition.tokens.push({
        propertyKey: "",
        operator: ":",
        value: enteredText,
      });
    }
    setStartLoading(true);
    changeHandler(enteredText);
    setQuery(searchCondition);
  };

  const changeHandler = async (text: string) => {
    console.info("freeText:", text);
    const aosRes: any = await appSyncRequest(getCustomersByAos, {
      input: {
        groupId: groupId,
        keyword: text,
      },
    });
    console.info("aosRes:", aosRes);
    changeFilters({
      accountIds:
          aosRes?.data?.getCustomersByAos?.customers
              ?.map((item: any) => item.accountId) || [String],
    });
  };

  return (
      <div className="search-action flex flex-row">
        <div className="poc-search flex-1">
          <PropertyFilter
              onChange={({detail}) => {
                changeSearchInputValue(detail);
              }}
              query={query}
              hideOperations={true}
              i18nStrings={MultiCustomerSearchI18NString}
              filteringOptions={[]}
              filteringProperties={[]}
          />
        </div>
        <div className="date-range">
          <DateRangePicker
              locale="en-US"
              isDateEnabled={(time) => {
                if (new Date().getTime() >= new Date(time).getTime()) {
                  return true;
                } else {
                  return false;
                }
              }}
              isValidRange={isValidRangeFunction}
              onChange={(event: any) => {
                console.info("event:", event);
                if (!event.detail.value) {
                  changeTimeRange({
                    startTime: "",
                    endTime: "",
                  });
                } else {
                  if (event.detail?.value?.type === "absolute") {
                    changeTimeRange({
                      startTime: event.detail.value.startDate,
                      endTime: event.detail.value.endDate,
                    });
                  } else {
                    const relativeTime = convertToAbsoluteRange(event.detail.value);
                    changeTimeRange({
                      startTime: relativeTime.start,
                      endTime: relativeTime.end,
                    });
                  }
                }
                setTimeRange(event.detail.value);
              }}
              value={timeRange}
              relativeOptions={[
                {
                  key: "previous-24-hours",
                  amount: 24,
                  unit: "hour",
                  type: "relative",
                },
                {
                  key: "previous-1-week",
                  amount: 7,
                  unit: "day",
                  type: "relative",
                },
                {
                  key: "previous-30-days",
                  amount: 30,
                  unit: "day",
                  type: "relative",
                },
              ]}
              i18nStrings={TimePickI18NString}
              placeholder="Filter by created date range"
          />
        </div>
      </div>
  );
};

export default CustomerAdvancedFilter;
