import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Storage } from "aws-amplify";

import { Button, Container, FormField, Header, Input, ProgressBar, Textarea, } from "@amzn/awsui-components-react";
import { PoCInput } from "API";
import { isValidUrl } from "assets/js/utils";
import { LengthLimit, MAX_FILE_SIZE } from "assets/js/const";
import { PoCInptValidErrorType } from "types";

interface ArchInfoProps {
  architectFilename: string;
  architectImgUrl: string;
}

interface StepDetailProps {
  curPoc: PoCInput;
  showNameEmptyError: boolean;
  setShowNameEmptyError: (showError: boolean) => void;
  showEffortsInvalidError: boolean;
  changePocName: (pocName: string) => void;
  changePocUserSenario: (userSenario: string) => void;
  changePocScope: (pocScope: string) => void;
  changeUserGuide: (userGuide: string) => void;
  changeSourceCode: (sourceCode: string) => void;
  changeArchImgInfo: (archInfo: ArchInfoProps) => void;
  changeArchDesc: (sourceCode: string) => void;
  changeIsUploading: (uploading: boolean) => void;
  changeEfforts: (efforts: number) => void;
  addInputError: (error: string) => void;
  removeInputError: (error: string) => void;
}

const StepPoCDetails: React.FC<StepDetailProps> = (props: StepDetailProps) => {
  const {
    curPoc,
    showNameEmptyError,
    setShowNameEmptyError,
    showEffortsInvalidError,
    changePocName,
    changePocUserSenario,
    changePocScope,
    changeUserGuide,
    changeSourceCode,
    changeArchImgInfo,
    changeArchDesc,
    changeIsUploading,
    changeEfforts,
    addInputError,
    removeInputError,
  } = props;

  const [archUploadProgress, setArchUploadProgress] = useState(0);
  const [showArchUploadProgress, setShowArchUploadProgress] = useState(false);
  const [archImgUrl, setArchImgUrl] = useState("");

  const hiddenArchFileInput = React.useRef<any>(null);
  const handleArchFileClick = (event: any) => {
    console.info("event:", event);
    hiddenArchFileInput?.current?.click();
  };

  const handleArchFileChange = (event: any) => {
    console.info("event:", event);
    const fileUploaded = event.target.files[0];
    // file size must less than 100M
    if (fileUploaded?.size > MAX_FILE_SIZE) {
      Swal.fire("Oops...", "File size must less than 100MB", "warning");
      return;
    }
    changeIsUploading(true);
    console.info("fileUploaded:", fileUploaded);
    const s3FileKey = `${curPoc.pocId}/architect/${fileUploaded.name}`;
    setShowArchUploadProgress(true);
    setArchUploadProgress(0);
    Storage.put(s3FileKey, fileUploaded, {
      contentType: fileUploaded.type,
      progressCallback(progress: any) {
        setArchUploadProgress((progress.loaded / progress.total) * 100);
        console.info(
            `Uploaded percentage: ${progress.loaded}/${progress.total}`
        );
      },
    })
        .then((result: any) => {
          Storage.get(result.key, {expires: 300}).then((res) => {
            console.info("showshowshowshowres:", res);
            changeArchImgInfo({
              architectFilename: fileUploaded.name,
              architectImgUrl: result.key,
            });
            setArchImgUrl(res.toString());
            changeIsUploading(false);
          });
        })
        .catch((err) => {
          changeIsUploading(false);
          console.error("err:", err);
        });
  };

  // useEffect(() => {
  //   console.info("assetsInputError:", assetsInputError);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [assetsInputError]);

  useEffect(() => {
    if (curPoc.architectImgUrl) {
      Storage.get(curPoc.architectImgUrl, {expires: 300}).then((res) => {
        console.info("showshowshowshowres:", res);
        setArchImgUrl(res.toString());
      });
    }
  }, [curPoc.architectImgUrl]);

  return (
      <div>
        <Header variant="h1">PoC Details</Header>
        <div className="mt-10">
          <Container header={<Header variant="h2">General</Header>}>
            <div>
              <FormField
                  description="Give your PoC a comprehensive title."
                  label="Title"
                  errorText={
                    (curPoc?.pocName?.length || 0) > LengthLimit.pocName
                        ? `Title must be less than ${LengthLimit.pocName} characters.`
                        : showNameEmptyError
                            ? "Title can not be empty."
                            : ""
                  }
              >
                <Input
                    placeholder="Aurora benchmarking against TiDB"
                    value={curPoc?.pocName || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimit.pocName) {
                        addInputError(PoCInptValidErrorType.pocNameLengthError);
                      } else {
                        removeInputError(PoCInptValidErrorType.pocNameLengthError);
                      }
                      setShowNameEmptyError(false);
                      changePocName(event.detail.value);
                    }}
                />
              </FormField>
              <FormField
                  className="mt-20"
                  description="Try to use one or two paragraph to summary the user scenario of this PoC?"
                  label="What does this PoC do?"
                  errorText={
                    (curPoc?.userScenarioDesc?.length || 0) >
                    LengthLimit.userScenarioDesc
                        ? `User scenario must be less than ${LengthLimit.userScenarioDesc} characters.`
                        : ""
                  }
              >
                <Textarea
                    placeholder="This is a long text."
                    value={curPoc?.userScenarioDesc || ""}
                    onChange={(event) => {
                      if (
                          event.detail.value.length > LengthLimit.userScenarioDesc
                      ) {
                        addInputError(
                            PoCInptValidErrorType.userScenarioDescLengthError
                        );
                      } else {
                        removeInputError(
                            PoCInptValidErrorType.userScenarioDescLengthError
                        );
                      }
                      changePocUserSenario(event.detail.value);
                    }}
                />
              </FormField>
              <FormField
                  className="mt-20"
                  description="Try to use one or two paragraph to summary the scope of this PoC?"
                  label="What is the Scope of the PoC?"
                  errorText={
                    (curPoc?.pocScope?.length || 0) > LengthLimit.pocScope
                        ? `PoC scope must be less than ${LengthLimit.pocScope} characters.`
                        : ""
                  }
              >
                <Textarea
                    placeholder="This is a long text."
                    value={curPoc?.pocScope || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimit.pocScope) {
                        addInputError(PoCInptValidErrorType.pocScopeLengthError);
                      } else {
                        removeInputError(PoCInptValidErrorType.pocScopeLengthError);
                      }
                      changePocScope(event.detail.value);
                    }}
                />
              </FormField>
              <FormField
                  className="mt-20"
                  description="Input the link to the user guide. Please make sure this link could be viewed by others."
                  label="User Guide"
                  errorText={
                    (curPoc?.userGuideUrl?.length || 0) > LengthLimit.userGuideUrl
                        ? `User Guide Url must be less than ${LengthLimit.userGuideUrl} characters.`
                        : curPoc?.userGuideUrl && !isValidUrl(curPoc?.userGuideUrl)
                            ? "User guide must be a URL format."
                            : ""
                  }
              >
                <Input
                    placeholder="https://amazon.awsapps.com/workdocs/...."
                    value={curPoc?.userGuideUrl || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimit.userGuideUrl) {
                        addInputError(
                            PoCInptValidErrorType.userGuideUrlLengthError
                        );
                      } else {
                        removeInputError(
                            PoCInptValidErrorType.userGuideUrlLengthError
                        );
                        if (event.detail.value && !isValidUrl(event.detail.value)) {
                          addInputError(
                              PoCInptValidErrorType.userGuideUrlFormatError
                          );
                        } else {
                          removeInputError(
                              PoCInptValidErrorType.userGuideUrlFormatError
                          );
                        }
                      }
                      changeUserGuide(event.detail.value);
                    }}
                />
              </FormField>

              <FormField
                  className="mt-20"
                  description="Input the link to the source code. It must be a git repository. Please make sure this code could be accessed by others. "
                  label="Source Code"
                  info=" - optional"
                  errorText={
                    (curPoc?.sourceCodeUrl?.length || 0) > LengthLimit.sourceCodeUrl
                        ? `Source Code must be less than ${LengthLimit.sourceCodeUrl} characters.`
                        : curPoc?.sourceCodeUrl && !isValidUrl(curPoc?.sourceCodeUrl)
                            ? "Source Code must be a URL format."
                            : ""
                  }
              >
                <Input
                    placeholder="https://github.com/aws-samples/smaple-project"
                    value={curPoc?.sourceCodeUrl || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimit.sourceCodeUrl) {
                        addInputError(
                            PoCInptValidErrorType.sourceCodeUrlLengthError
                        );
                      } else {
                        removeInputError(
                            PoCInptValidErrorType.sourceCodeUrlLengthError
                        );
                        if (event.detail.value && !isValidUrl(event.detail.value)) {
                          addInputError(
                              PoCInptValidErrorType.sourceCodeUrlFormatError
                          );
                        } else {
                          removeInputError(
                              PoCInptValidErrorType.sourceCodeUrlFormatError
                          );
                        }
                      }
                      changeSourceCode(event.detail.value);
                    }}
                />
              </FormField>

              <FormField
                  className="mt-20"
                  description="The hours you spend on this PoC"
                  label="Efforts (Hours)"
                  errorText={
                    showEffortsInvalidError ? "Efforts must be larger than 0" : ""
                  }
              >
                <Input
                    placeholder="168"
                    value={curPoc?.efforts?.toString() || ""}
                    type="number"
                    onChange={(event) => {
                      changeEfforts(Number.parseFloat(event.detail.value));
                    }}
                />
              </FormField>
            </div>
          </Container>
        </div>
        <div className="mt-20">
          <Container header={<Header variant="h2">Architecture</Header>}>
            <div>
              <FormField
                  description="Select the Architect Diagram to upload. Accept format: image/*."
                  label="Architect Diagram"
              >
                <Button
                    onClick={handleArchFileClick}
                    iconAlign="left"
                    iconName="upload"
                    variant="normal"
                >
                  Choose File
                </Button>
                <input
                    ref={hiddenArchFileInput}
                    onChange={handleArchFileChange}
                    type="file"
                    accept="image/*"
                    style={{display: "none"}}
                />
                {showArchUploadProgress && (
                    <ProgressBar
                        status="in-progress"
                        value={archUploadProgress}
                        additionalInfo=""
                        description=""
                        label=""
                    />
                )}

                <div className="mt-10">
                  {archImgUrl && (
                      <img
                          onLoad={() => {
                            setShowArchUploadProgress(false);
                          }}
                          alt=""
                          width="70%"
                          src={archImgUrl}
                      />
                  )}
                </div>
              </FormField>
              <FormField
                  className="mt-20"
                  description="Use 2~ 5 paragraph to summary how these components work together."
                  label="Architect Diagram Description"
                  errorText={
                    (curPoc?.architectDesc?.length || 0) > LengthLimit.architectDesc
                        ? `Architect description must be less than ${LengthLimit.architectDesc} characters.`
                        : ""
                  }
              >
                <Textarea
                    placeholder="This is a long text."
                    value={curPoc?.architectDesc || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimit.architectDesc) {
                        addInputError(
                            PoCInptValidErrorType.architectDescLengthError
                        );
                      } else {
                        removeInputError(
                            PoCInptValidErrorType.architectDescLengthError
                        );
                      }
                      changeArchDesc(event.detail.value);
                    }}
                />
              </FormField>
            </div>
          </Container>
        </div>
      </div>
  );
};

export default StepPoCDetails;
