import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AppLayout } from "@amzn/awsui-components-react";

import { Flashbar, FlashbarProps } from "@amzn/awsui-components-react/polaris";

import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";

import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import DetailComp from "pages/detail/DetailComp";

interface MatchParams {
  ctReviewId: string;
  id: string;
  tab: string;
}

const CrossTeamPocDetail: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [notificationList, setNotificationList] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const id: string = props.match.params.id;
  const tab: string = props.match.params.tab;
  const ctReviewId: string = props.match.params.ctReviewId;

  const pocDetailBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Cross-team reviews(PoC)",
      href: "/admin/cross-team-review",
    },
    {
      text: ctReviewId.toUpperCase(),
      href: "/admin/create-cross-team-review/" + ctReviewId,
    },
    {
      text: id.toUpperCase(),
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocDetailBreadcrumbs}/>}
          content={
            <DetailComp
                addFlashNotification={(notification: any) => {
                  setNotificationList(notification);
                }}
                tabId={tab}
                pocId={id}
                pageType="CROSS"
            />
          }
          notifications={<Flashbar items={notificationList}/>}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CrossTeamPocDetail;
