import React, { useContext, useEffect, useState } from "react";
import { AppLayout } from "@amzn/awsui-components-react";
import { Button, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";
import { SIDE_MENU_IS_OPEN, USER_ACK_SWA_DEFININATION } from "assets/js/const";
import StepSWAAccount from "../comps/step/StepSWAAccount";
import StepSWADetails from "../comps/step/StepSWADetails";
import StepSWATags from "../comps/step/StepSWATags";
import { SubmitMode, SWAInput, UserRole } from "API";
import { useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { submitSWA } from "graphql/mutations";
import AppContext from "context/AppContext";
import { isValidAccountId, isValidOppId } from "assets/js/utils";
import StepSWAWorkLoad from "../comps/step/StepSWAWorkLoad";
import Swal from "sweetalert2";

const CreateSWAContent: React.FC = () => {
  const history = useHistory();
  const appConfig = useContext(AppContext);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [createSWAInfo, setCreateSWAInfo] = useState<SWAInput>({
    // swaId: "",
    mode: SubmitMode.Draft,
    sfdcAccountId: "",
    opptyId: "",
    swaSubject: "",
    contributorId: appConfig.userId,
    efforts: 0,
    launchDate: "",
    categoryInUseList: [],
    svcInUseList: [],
    swaDesc: "",
    linkUrl: "",
    industryTagList: [],
    architectDesc: "",
    architectDiagrams: [],
    attachments: [],
  });
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [showOppIdError, setShowOppIdError] = useState(false);
  const [showAccountIdError, setShowAccountIdError] = useState(false);

  const [showWorkloadRequireError, setShowWorkloadRequireError] =
      useState(false);
  const [workloadIsLoading, setWorkloadIsLoading] = useState(false);
  const [svcIsLoading, setSvcIsLoading] = useState(false);
  const [showLaunchDateError, setShowLaunchDateError] = useState(false);

  const [showNameEmptyError, setShowNameEmptyError] = useState(false);
  const [showEffortsInvalidError, setShowEffortsInvalidError] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [swaInputErrorArr, setSwaInputErrorArr] = useState<string[]>([]);

  const [showArchImgRequireError, setShowArchImgRequireError] = useState(false);
  const [showBenefitRequireError, setShowBenefitRequireError] = useState(false);

  const saveDraftAndNext = async () => {
    const createSWAInfoParam = JSON.parse(JSON.stringify(createSWAInfo));
    // validate AccountId first
    if (!isValidAccountId(createSWAInfo.sfdcAccountId || "")) {
      setShowAccountIdError(true);
      setActiveStepIndex(0);
      return;
    }

    // validate Opp ID second
    if (!isValidOppId(createSWAInfo.opptyId || "")) {
      setShowOppIdError(true);
      setActiveStepIndex(0);
      return;
    }

    setLoadingCreate(true);
    try {
      const createSWARes = await appSyncRequest(submitSWA, {
        input: createSWAInfoParam,
      });
      console.info("createSWARes:", createSWARes);
      window.localStorage.removeItem(USER_ACK_SWA_DEFININATION);
      setLoadingCreate(false);
      setActiveStepIndex(1);
      setCreateSWAInfo((prev) => {
        return {...prev, swaId: createSWARes.data.submitSWA.swaId};
      });
    } catch (error) {
      setLoadingCreate(false);
      console.error(error);
    }
  };

  const updateSWA = async (mode: SubmitMode) => {
    if (swaInputErrorArr && swaInputErrorArr.length > 0) {
      return;
    }

    // validate AccountId first
    if (!isValidAccountId(createSWAInfo.sfdcAccountId || "")) {
      setShowAccountIdError(true);
      setActiveStepIndex(0);
      return;
    }

    // validate Opp ID second
    if (!isValidOppId(createSWAInfo.opptyId || "")) {
      setShowOppIdError(true);
      setActiveStepIndex(0);
      return;
    }

    // Validate Input
    // setShowNameEmptyError while swa Name is Empty
    if (!createSWAInfo?.swaSubject?.trim()) {
      setShowNameEmptyError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate Benefit Error
    if (!createSWAInfo?.customerBenefit?.trim()) {
      setShowBenefitRequireError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate efforts
    if ((createSWAInfo.efforts || 0) < 1) {
      setShowEffortsInvalidError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate Launch Date
    if (!createSWAInfo.launchDate) {
      setShowLaunchDateError(true);
      setActiveStepIndex(2);
      return;
    }

    if (
        createSWAInfo.launchDate &&
        new Date(createSWAInfo.launchDate).getTime() > new Date().getTime()
    ) {
      setShowLaunchDateError(true);
      setActiveStepIndex(2);
      return;
    }

    // Validate Workload
    if (
        !createSWAInfo.categoryInUseList ||
        createSWAInfo.categoryInUseList?.length <= 0
    ) {
      setShowWorkloadRequireError(true);
      setActiveStepIndex(1);
      return;
    }

    // Validate Architecture Diagram
    if (
        !createSWAInfo.architectDiagrams ||
        createSWAInfo.architectDiagrams?.length <= 0
    ) {
      setShowArchImgRequireError(true);
      setActiveStepIndex(2);
      return;
    }

    const updateSWAInfoParam = JSON.parse(JSON.stringify(createSWAInfo));
    updateSWAInfoParam.mode = mode;
    console.info("updateSWAInfoParam:", updateSWAInfoParam);

    // encode assets input
    updateSWAInfoParam.swaSubject = encodeURIComponent(
        updateSWAInfoParam.swaSubject
    );

    updateSWAInfoParam.swaDesc = encodeURIComponent(updateSWAInfoParam.swaDesc);

    const swaArchList: any = [];
    createSWAInfo?.architectDiagrams?.forEach((element) => {
      swaArchList.push({
        architectFileName: encodeURIComponent(element?.architectFileName || ""),
        architectFileUrl: element?.architectFileUrl,
      });
    });
    updateSWAInfoParam.architectDiagrams = swaArchList;

    // Attachment Refine
    const updateAttachments: any = [];
    createSWAInfo?.attachments?.forEach((element) => {
      updateAttachments.push({
        attachmentFilename: encodeURIComponent(
            element?.attachmentFilename || ""
        ),
        attachmentUrl: element?.attachmentUrl,
        attachmentSize: element?.attachmentSize,
        attachmentDesc: encodeURIComponent(element?.attachmentDesc || ""),
      });
    });
    updateSWAInfoParam.attachments = updateAttachments;
    setLoadingSubmit(true);
    try {
      const createSWARes = await appSyncRequest(submitSWA, {
        input: updateSWAInfoParam,
      });
      console.info("createSWARes:", createSWARes);
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      history.push({
        pathname: appConfig.userRole === UserRole.Contributor ? "/my-swa" : "/",
      });
    } catch (error) {
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      console.error(error);
    }
  };

  // check user check service
  const checkServiceChecked = () => {
    const catetoryInUseArr = createSWAInfo.categoryInUseList?.map(
        (element) => element?.categoryId
    );
    const tmpServiceCategoryArr =
        createSWAInfo.svcInUseList?.map((element) => element?.categoryId) || [];
    const serviceCategoryArr = tmpServiceCategoryArr.filter(
        (element, index) => {
          return tmpServiceCategoryArr.indexOf(element) === index;
        }
    );
    return catetoryInUseArr?.length === serviceCategoryArr.length;
  };

  useEffect(() => {
    console.info("createSWAInfo:", createSWAInfo);
  }, [createSWAInfo]);

  return (
      <div>
        <div className="gsal-review-page">
          <div className="gsal-step-nav">
            <nav>
              <ul>
                <li>
                  <small>Step 1</small>
                  <div className="step-name">
                  <span
                      onClick={() => {
                        if (
                            loadingSubmit ||
                            loadingSaveDraft ||
                            isFileUploading ||
                            workloadIsLoading ||
                            svcIsLoading
                        ) {
                          return;
                        }
                        setActiveStepIndex(0);
                      }}
                      className={activeStepIndex !== 0 ? "link" : ""}
                  >
                    Account Info
                  </span>
                  </div>
                </li>
                <li>
                  <small>Step 2</small>
                  <div className="step-name">
                  <span
                      onClick={() => {
                        if (
                            !isValidAccountId(createSWAInfo.sfdcAccountId || "")
                        ) {
                          setShowAccountIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        if (
                            !isValidOppId(createSWAInfo.opptyId || "")
                        ) {
                          setShowOppIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        if (!createSWAInfo.swaId) {
                          return;
                        }
                        if (
                            loadingSubmit ||
                            loadingSaveDraft ||
                            isFileUploading ||
                            workloadIsLoading ||
                            svcIsLoading
                        ) {
                          return;
                        }
                        setActiveStepIndex(1);
                      }}
                      className={activeStepIndex !== 1 ? "link" : ""}
                  >
                    Strategical workload
                  </span>
                  </div>
                </li>
                <li>
                  <small>Step 3</small>
                  <div className="step-name">
                  <span
                      onClick={() => {
                        if (
                            !isValidAccountId(createSWAInfo.sfdcAccountId || "")
                        ) {
                          setShowAccountIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        if (
                            !isValidOppId(createSWAInfo.opptyId || "")
                        ) {
                          setShowOppIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        if (!createSWAInfo.swaId) {
                          return;
                        }
                        if (
                            loadingSubmit ||
                            loadingSaveDraft ||
                            isFileUploading ||
                            workloadIsLoading ||
                            svcIsLoading
                        ) {
                          return;
                        }
                        setActiveStepIndex(2);
                      }}
                      className={activeStepIndex !== 2 ? "link" : ""}
                  >
                    SWA details
                  </span>
                  </div>
                </li>
                <li>
                  <small>Step 4</small>
                  <div className="step-name">
                  <span
                      onClick={() => {
                        if (
                            !isValidAccountId(createSWAInfo.sfdcAccountId || "")
                        ) {
                          setShowAccountIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        if (
                            !isValidOppId(createSWAInfo.opptyId || "")
                        ) {
                          setShowOppIdError(true);
                          setActiveStepIndex(0);
                          return;
                        }
                        if (!createSWAInfo.swaId) {
                          return;
                        }
                        if (
                            loadingSubmit ||
                            loadingSaveDraft ||
                            isFileUploading ||
                            workloadIsLoading ||
                            svcIsLoading
                        ) {
                          return;
                        }
                        setActiveStepIndex(3);
                      }}
                      className={activeStepIndex !== 3 ? "link" : ""}
                  >
                    Tags &amp; attachments
                  </span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className="gsal-review-content">
            {activeStepIndex === 0 && (
                <StepSWAAccount
                    curSWA={createSWAInfo}
                    showOppIdError={showOppIdError}
                    showAccountIdError={showAccountIdError}
                    changeAccountId={(id) => {
                      setShowAccountIdError(false);
                      setCreateSWAInfo((prev) => {
                        return {...prev, sfdcAccountId: id};
                      });
                    }}
                    changeSWAOppId={(id) => {
                      setShowOppIdError(false);
                      setCreateSWAInfo((prev) => {
                        return {...prev, opptyId: id};
                      });
                    }}
                />
            )}
            {activeStepIndex === 1 && (
                <StepSWAWorkLoad
                    curSWA={createSWAInfo}
                    showWorkloadError={showWorkloadRequireError}
                    setWorkloadIsLoading={(loading) => {
                      setWorkloadIsLoading(loading);
                    }}
                    changeCategoryInUse={(categoryList) => {
                      if (categoryList.length > 0) {
                        setShowWorkloadRequireError(false);
                      }
                      setCreateSWAInfo((prev) => {
                        return {...prev, categoryInUseList: categoryList};
                      });
                    }}
                />
            )}
            {activeStepIndex === 2 && (
                <StepSWADetails
                    curSWA={createSWAInfo}
                    showNameEmptyError={showNameEmptyError}
                    showLaunchDateError={showLaunchDateError}
                    showArchDiagramRequireError={showArchImgRequireError}
                    benefitRequireError={showBenefitRequireError}
                    setShowLaunchDateError={(show) => {
                      setShowLaunchDateError(show);
                    }}
                    setServiceIsLoading={(loading) => {
                      setSvcIsLoading(loading);
                    }}
                    changeWorkloadSvcList={(svcList) => {
                      setCreateSWAInfo((prev) => {
                        return {...prev, svcInUseList: svcList};
                      });
                    }}
                    addInputError={(error) => {
                      if (!swaInputErrorArr.includes(error)) {
                        setSwaInputErrorArr((prev) => {
                          return [...prev, error];
                        });
                      }
                    }}
                    removeInputError={(error) => {
                      setSwaInputErrorArr((prev) => {
                        return prev.filter((e) => e !== error);
                      });
                    }}
                    changeIsUploading={(uploading) => {
                      setIsFileUploading(uploading);
                    }}
                    setShowNameEmptyError={(showError) => {
                      setShowNameEmptyError(showError);
                    }}
                    showEffortsInvalidError={showEffortsInvalidError}
                    changeSWASubject={(swaSubject) => {
                      setShowNameEmptyError(false);
                      setCreateSWAInfo((prev) => {
                        return {...prev, swaSubject: swaSubject};
                      });
                    }}
                    changeSWADesc={(swaDesc) => {
                      setCreateSWAInfo((prev) => {
                        return {...prev, swaDesc: swaDesc};
                      });
                    }}
                    changeCustomerBenefit={(benefit) => {
                      setShowBenefitRequireError(false);
                      setCreateSWAInfo((prev) => {
                        return {...prev, customerBenefit: benefit};
                      });
                    }}
                    changeLaunchDate={(launchDate: string) => {
                      if (
                          createSWAInfo.launchDate &&
                          new Date(launchDate).getTime() > new Date().getTime()
                      ) {
                        setShowLaunchDateError(true);
                      } else {
                        setShowLaunchDateError(false);
                      }
                      setCreateSWAInfo((prev) => {
                        return {...prev, launchDate: launchDate};
                      });
                    }}
                    changeArchImgList={(archList) => {
                      if (archList.length > 0) {
                        setShowArchImgRequireError(false);
                      }
                      setCreateSWAInfo((prev) => {
                        return {
                          ...prev,
                          architectDiagrams: archList,
                        };
                      });
                    }}
                    changeArchDesc={(archDesc) => {
                      setCreateSWAInfo((prev) => {
                        return {...prev, architectDesc: archDesc};
                      });
                    }}
                    changeEfforts={(efforts) => {
                      if (efforts > 0) {
                        setShowEffortsInvalidError(false);
                      } else {
                        setShowEffortsInvalidError(true);
                      }
                      setCreateSWAInfo((prev) => {
                        return {...prev, efforts: efforts};
                      });
                    }}
                />
            )}
            {activeStepIndex === 3 && (
                <StepSWATags
                    curSWA={createSWAInfo}
                    addInputError={(error) => {
                      if (!swaInputErrorArr.includes(error)) {
                        setSwaInputErrorArr((prev) => {
                          return [...prev, error];
                        });
                      }
                    }}
                    removeInputError={(error) => {
                      setSwaInputErrorArr((prev) => {
                        return prev.filter((e) => e !== error);
                      });
                    }}
                    changeSWALinkUrl={(link) => {
                      setCreateSWAInfo((prev) => {
                        return {...prev, linkUrl: link};
                      });
                    }}
                    changeIsUploading={(uploading) => {
                      setIsFileUploading(uploading);
                    }}
                    changeIndustryTagList={(tags) => {
                      setCreateSWAInfo((prev) => {
                        return {...prev, industryTagList: tags};
                      });
                    }}
                    changeTechTagList={(tags) => {
                      console.info("tags:", tags);
                    }}
                    addNewAttachment={(item) => {
                      setCreateSWAInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        const prevAttachment = prevObj.attachments;
                        prevAttachment.push(item);
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    removeAttachment={(index) => {
                      if (
                          createSWAInfo.attachments &&
                          createSWAInfo.attachments.length > 0
                      ) {
                        const tmpAttacment = [...createSWAInfo.attachments];
                        tmpAttacment.splice(index, 1);
                        setCreateSWAInfo((prev: any) => {
                          const prevObj = JSON.parse(JSON.stringify(prev));
                          return {
                            ...prevObj,
                            ...{
                              attachments: tmpAttacment,
                            },
                          };
                        });
                      }
                    }}
                    changeAttachmentDesc={(index, desc) => {
                      setCreateSWAInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        prevObj.attachments[index].attachmentDesc = desc;
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    uploadAttachment={(upProgress, index) => {
                      setCreateSWAInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        if (upProgress >= 100) {
                          prevObj.attachments[index].progress = upProgress;
                          prevObj.attachments[index].showProgress = false;
                        } else {
                          prevObj.attachments[index].progress = upProgress;
                          prevObj.attachments[index].showProgress = true;
                        }
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    uploadAttachmentDone={(index, name, size, key) => {
                      setCreateSWAInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        prevObj.attachments[index].attachmentFilename = name;
                        prevObj.attachments[index].attachmentSize = size;
                        prevObj.attachments[index].attachmentUrl = key;
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                />
            )}
            <div className="mt-20 pb-30 clearfix">
              <SpaceBetween className="fr" direction="horizontal" size="xs">
                <Button
                    variant="link"
                    onClick={() => {
                      history.push({
                        pathname: `/`,
                      });
                    }}
                >
                  Cancel
                </Button>

                {activeStepIndex === 0 && !createSWAInfo.swaId && (
                    <Button
                        loading={loadingCreate}
                        variant="primary"
                        onClick={() => {
                          saveDraftAndNext();
                        }}
                    >
                      Create &amp; Next
                    </Button>
                )}

                {activeStepIndex === 0 && createSWAInfo.swaId && (
                    <Button
                        disabled={
                            loadingSubmit || loadingSaveDraft || isFileUploading
                        }
                        variant="primary"
                        onClick={() => {
                          // validate account id when click next
                          if (!isValidAccountId(createSWAInfo.sfdcAccountId || "")) {
                            setShowAccountIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (!isValidOppId(createSWAInfo.opptyId || "")) {
                            setShowOppIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          setActiveStepIndex(1);
                        }}
                    >
                      Next
                    </Button>
                )}

                {activeStepIndex > 0 && (
                    <Button
                        disabled={
                            loadingSubmit ||
                            loadingSaveDraft ||
                            isFileUploading ||
                            workloadIsLoading ||
                            svcIsLoading
                        }
                        onClick={() => {
                          if (activeStepIndex === 2) {
                            if (
                                showNameEmptyError ||
                                showEffortsInvalidError ||
                                showLaunchDateError
                            ) {
                              return;
                            }
                            if (swaInputErrorArr.length > 0) {
                              return;
                            }
                          }
                          setActiveStepIndex((prev) => {
                            return prev - 1;
                          });
                        }}
                    >
                      Previous
                    </Button>
                )}

                {activeStepIndex === 1 && (
                    <Button
                        disabled={workloadIsLoading}
                        variant="primary"
                        onClick={() => {
                          if (
                              !createSWAInfo.categoryInUseList ||
                              createSWAInfo.categoryInUseList?.length <= 0
                          ) {
                            setShowWorkloadRequireError(true);
                            return;
                          }
                          setActiveStepIndex(2);
                        }}
                    >
                      Next
                    </Button>
                )}
                {activeStepIndex === 2 && (
                    <Button
                        disabled={svcIsLoading || isFileUploading}
                        variant="primary"
                        onClick={() => {
                          if (
                              showNameEmptyError ||
                              showEffortsInvalidError ||
                              showLaunchDateError
                          ) {
                            return;
                          }
                          if (swaInputErrorArr.length > 0) {
                            return;
                          }
                          if (!checkServiceChecked()) {
                            Swal.fire(
                                "Oops...",
                                "Please make sure to select at least one service of each strategical workload",
                                "warning"
                            );
                            return;
                          }
                          setActiveStepIndex(3);
                        }}
                    >
                      Next
                    </Button>
                )}
                {activeStepIndex === 3 && (
                    <Button
                        disabled={loadingSaveDraft || isFileUploading}
                        loading={loadingSubmit}
                        variant="primary"
                        onClick={() => {
                          updateSWA(SubmitMode.Submit);
                        }}
                    >
                      Submit
                    </Button>
                )}
              </SpaceBetween>
            </div>
          </div>
        </div>
      </div>
  );
};

const CreateSWA: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(false);
  // const [helpInfo, setHelpInfo] = useState();
  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const swaReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Create a SWA",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={swaReviewBreadcrumbs}/>}
          content={<CreateSWAContent/>}
          // tools={helpInfo}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CreateSWA;
