import React from "react";
import { FormField, Header, Link, RadioGroup, Textarea, } from "@amzn/awsui-components-react/polaris";
import { CriteriaScore, CriteriaType } from "API";
import { PoCScoreMap } from "assets/js/const";
import { PoCConclusion, PoCPlan, RequirementsSpecification, TestReport, } from "../help/HelpPanels";

interface ScoreItemProp {
  clickItemTitleInfo: (title: any) => void;
  changeScore: (score: string) => void;
  changeComment: (comment: string) => void;
  title: string;
  scoreData: CriteriaScore | undefined | null;
  setLengthError: (isError: boolean) => void;
  showScoreError: boolean;
}

const ScoreItem: React.FC<ScoreItemProp> = (props: ScoreItemProp) => {
  const {
    title,
    scoreData,
    changeScore,
    changeComment,
    clickItemTitleInfo,
    setLengthError,
    showScoreError,
  } = props;

  return (
      <div className="mb-10">
        <Header
            variant="h3"
            info={
              <Link
                  onFollow={() => {
                    if (title === CriteriaType.RequirementsSpecification) {
                      clickItemTitleInfo(<RequirementsSpecification/>);
                    }
                    if (title === CriteriaType.PoCPlan) {
                      clickItemTitleInfo(<PoCPlan/>);
                    }
                    if (title === CriteriaType.TestReport) {
                      clickItemTitleInfo(<TestReport/>);
                    }
                    if (title === CriteriaType.PoCConclusion) {
                      clickItemTitleInfo(<PoCConclusion/>);
                    }
                  }}
                  variant="info"
              >
                Info
              </Link>
            }
        >
          {`${PoCScoreMap[title].showName} (${PoCScoreMap[title].weight * 100}%)`}
        </Header>
        <div>
          <FormField
              errorText={
                (scoreData?.score || -1) < 0 && showScoreError
                    ? "Please select a score."
                    : ""
              }
          >
            <RadioGroup
                onChange={({detail}) => {
                  changeScore(detail.value);
                }}
                value={scoreData?.score?.toString() || "0"}
                items={[
                  {
                    value: "0",
                    label: PoCScoreMap[title].level0,
                  },
                  {value: "1", label: PoCScoreMap[title].level1},
                  {
                    value: "2",
                    label: PoCScoreMap[title].level2,
                  },
                  {
                    value: "3",
                    label: PoCScoreMap[title].level3,
                  },
                  {
                    value: "4",
                    label: PoCScoreMap[title].level4,
                  },
                  {
                    value: "5",
                    label: PoCScoreMap[title].level5,
                  },
                ]}
            />
          </FormField>
        </div>
        <div className="mt-10">
          <FormField
              errorText={
                (scoreData?.comments?.length || 0) > 256
                    ? "Comments must be less than 256 characters."
                    : ""
              }
          >
            <Textarea
                onChange={({detail}) => {
                  changeComment(detail.value);
                  if (detail.value.length > 256) {
                    setLengthError(true);
                  } else {
                    setLengthError(false);
                  }
                }}
                value={scoreData?.comments || ""}
                placeholder="Please input your comments"
            />
          </FormField>
        </div>
      </div>
  );
};

export default ScoreItem;
