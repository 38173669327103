import React from "react";
import { StatusIndicator } from "@amzn/awsui-components-react/polaris";
import { BestPracticeStatus } from "API";

interface BPStatusProp {
  bestPracticeStatus: string;
}

const BPStatus: React.FC<BPStatusProp> = (props: BPStatusProp) => {
  const {bestPracticeStatus} = props;
  return (
      <div>
        {bestPracticeStatus === BestPracticeStatus.Filled && (
            <StatusIndicator type="stopped">Filled</StatusIndicator>
        )}
        {bestPracticeStatus === BestPracticeStatus.No && (
            <StatusIndicator type="stopped">No</StatusIndicator>
        )}
        {bestPracticeStatus === BestPracticeStatus.Nominated && (
            <StatusIndicator type="pending">Nominated</StatusIndicator>
        )}
        {bestPracticeStatus === BestPracticeStatus.Yes && (
            <StatusIndicator type="success">Yes</StatusIndicator>
        )}
      </div>
  );
};

export default BPStatus;
