import { API, graphqlOperation } from "aws-amplify";
import Swal from "sweetalert2";

export const appSyncRequest = (query: any, params?: any): any => {
  return new Promise(async (resolve, reject) => {
    try {
      const result: any = await API.graphql(graphqlOperation(query, params));
      console.info("result:", result);
      if (result.data) {
        for (const key in result.data) {
          // alert error when data goes fail
          if (result.data[key]?.status === "Fail") {
            Swal.fire("Oops...", result.data[key].errMsg, "error");
            reject("error");
            return;
          }
        }
      }
      resolve(result);
    } catch (err) {
      const error: any = err;
      Swal.fire("Oops...", error?.errors?.[0]?.message, "error");
      reject(error);
    }
  });
};
