import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import {
  Autosuggest,
  Button,
  Container,
  FormField,
  Header,
  Input,
  ProgressBar,
  Spinner,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { Storage } from "aws-amplify";
import TechIndustry from "pages/detail/comps/TechIndustry";
import {
  ATTACHMENT_FILE_TYPES,
  LengthLimit,
  LengthLimitSWA,
  MAX_FILE_SIZE,
  SWAAttachmentTypeList,
} from "assets/js/const";
import { SWAInput } from "API";
import { humanFileSize, isValidUrl } from "assets/js/utils";
import { getGsalConf } from "graphql/queries";
import { appSyncRequest } from "assets/js/request";
import { SystemSettingsProps } from "pages/admin/SystemSettings";
import { SWAInptValidErrorType } from "types";

interface StepTagsProps {
  curSWA: SWAInput;
  changeIndustryTagList: (industryTags: any) => void;
  changeTechTagList: (techTags: any) => void;
  addNewAttachment: (item: any) => void;
  removeAttachment: (index: number) => void;
  changeAttachmentDesc: (index: number, desc: string) => void;
  uploadAttachment: (progress: any, index: number) => void;
  uploadAttachmentDone: (
      index: number,
      name: string,
      size: string,
      key: string
  ) => void;
  changeIsUploading: (uploading: boolean) => void;
  addInputError: (error: string) => void;
  removeInputError: (error: string) => void;
  changeSWALinkUrl: (link: string) => void;
}

const StepPoCTags: React.FC<StepTagsProps> = (props: StepTagsProps) => {
  const {
    curSWA,
    changeIndustryTagList,
    changeTechTagList,
    addNewAttachment,
    removeAttachment,
    changeAttachmentDesc,
    uploadAttachment,
    uploadAttachmentDone,
    changeIsUploading,
    addInputError,
    removeInputError,
    changeSWALinkUrl,
  } = props;
  const [attachmentIsUploading, setAttachmentIsUploading] = useState(false);
  // const [assetsInputError, setAssetsInputError] = useState(false);
  const attachementRefs = useRef<any[]>(
      Array.from(
          {length: curSWA?.attachments?.length || 0},
          (a: any) => React.createRef() as any
      )
  );
  const [loadingSysSettings, setLoadingSysSettings] = useState(false);
  const [systemSettings, setSystemSettings] = useState<SystemSettingsProps>({
    techTagLimit: "2",
    industryTagLimit: "2",
    firstLineManagerNominateCountPerMonth: "2",
  });

  const downloadFile = async (fileKey: string) => {
    // Creates download url that expires in 5 minutes/ 300 seconds
    const downloadUrl = await Storage.get(fileKey, {expires: 600});
    // window.location.href = downloadUrl.toString();
    window.open(downloadUrl.toString(), "_blank");
  };

  const handleAttachmentFileClick = (event: any, index: number) => {
    console.info("event:", event);
    console.info("attachementRefs:", attachementRefs);
    attachementRefs.current[index].click();
  };

  const handleAttachmentFileChange = (event: any, index: number) => {
    console.info("handleAttachmentFileChange event:", event);
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > MAX_FILE_SIZE) {
      Swal.fire("Oops...", "File size must less than 100MB", "warning");
      return;
    }
    if (fileUploaded) {
      const s3FileKey = `${curSWA.swaId}/assets/${fileUploaded.name}`;
      // setAttachmentIsUploading
      setAttachmentIsUploading(true);
      console.info("fileUploaded:", fileUploaded);
      Storage.put(s3FileKey, fileUploaded, {
        contentType: fileUploaded.type,
        progressCallback(progress: any) {
          const upProgress = (progress.loaded / progress.total) * 100;
          uploadAttachment(upProgress, index);
        },
      })
          .then((result: any) => {
            uploadAttachmentDone(
                index,
                fileUploaded.name,
                fileUploaded.size,
                result.key
            );
            setAttachmentIsUploading(false);
          })
          .catch((err) => {
            setAttachmentIsUploading(false);
            console.error("err:", err);
          });
    }
  };

  // Get System Settings
  const getSystemSettings = async () => {
    setLoadingSysSettings(true);
    const settingsData: any = await appSyncRequest(getGsalConf, {});
    if (settingsData?.data?.getGsalConf) {
      setSystemSettings(settingsData.data.getGsalConf);
    }
    setLoadingSysSettings(false);
    console.info("settingsData:", settingsData);
  };

  useEffect(() => {
    console.info("attachmentIsUploading:", attachmentIsUploading);
    changeIsUploading(attachmentIsUploading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentIsUploading]);

  useEffect(() => {
    getSystemSettings();
  }, []);

  return (
      <div>
        <Header variant="h1">Tags &amp; attachments</Header>
        <div className="mt-10">
          <Container
              header={
                <Header
                    variant="h2"
                    description={
                      loadingSysSettings ? (
                          <Spinner/>
                      ) : (
                          <div>
                            {`Select at most ${systemSettings.industryTagLimit} ${
                                parseInt(systemSettings.industryTagLimit) > 1
                                    ? "tags "
                                    : "tag "
                            }
                        in industry category.`}
                          </div>
                      )
                    }
                >
                  Tags
                </Header>
              }
          >
            <div>
              <TechIndustry
                  updateIndustryList={(industryList: any) => {
                    changeIndustryTagList(industryList);
                  }}
                  updateTechList={(techList: any) => {
                    changeTechTagList(techList);
                  }}
                  technologyCheckedList={curSWA?.industryTagList || []}
                  industryCheckedList={curSWA?.industryTagList || []}
                  hideTech={true}
              />
            </div>
          </Container>
        </div>

        <div className="mt-20">
          <Container
              header={
                <Header
                    variant="h2"
                    description={`You can upload up to 5 attachments, and the size of each attachment must less than 100M. File format support: ${ATTACHMENT_FILE_TYPES}.`}
                >
                  Attachments
                </Header>
              }
          >
            <div>
              <FormField
                  description="If there is any other workdoc link/implementation guide/code/highspot ,etc, please provide the HTTP link here and this is optional"
                  label={
                    <span>
                  Http Link<i>- optional</i>
                </span>
                  }
                  errorText={
                    (curSWA?.linkUrl?.length || 0) > LengthLimitSWA.linkUrl
                        ? `Http Link must be less than ${LengthLimitSWA.linkUrl} characters.`
                        : curSWA?.linkUrl && !isValidUrl(curSWA?.linkUrl)
                            ? "Http Link must be a URL format."
                            : ""
                  }
              >
                <Input
                    placeholder="https://amazon.awsapps.com/workdocs/...."
                    value={curSWA?.linkUrl || ""}
                    onChange={(event) => {
                      if (event.detail.value.length > LengthLimit.userGuideUrl) {
                        addInputError(SWAInptValidErrorType.linkUrlLengthError);
                      } else {
                        removeInputError(SWAInptValidErrorType.linkUrlLengthError);
                        if (event.detail.value && !isValidUrl(event.detail.value)) {
                          addInputError(SWAInptValidErrorType.linkUrlFormatError);
                        } else {
                          removeInputError(
                              SWAInptValidErrorType.linkUrlFormatError
                          );
                        }
                      }
                      changeSWALinkUrl(event.detail.value);
                    }}
                />
              </FormField>
              <div className="mt-20 flex attachment">
                <div className="upload">Attachment</div>
                <div className="select">Name - optional</div>
                <div className="remove"></div>
              </div>
              {curSWA?.attachments?.map((element: any, index: number) => {
                return (
                    <div key={index} className="mt-10 flex attachment">
                      <div className="upload m-h-50 pt-4">
                        <div className={element?.showProgress ? "show" : "hide"}>
                          <ProgressBar
                              status="in-progress"
                              value={element?.progress}
                              additionalInfo=""
                              description=""
                              label=""
                          />
                        </div>
                        <div className={!element?.showProgress ? "show" : "hide"}>
                          <Button
                              onClick={(event) => {
                                handleAttachmentFileClick(event, index);
                              }}
                              iconAlign="left"
                              iconName="upload"
                              variant="normal"
                          >
                            Choose File
                          </Button>
                          <input
                              ref={(el) => (attachementRefs.current[index] = el)}
                              onChange={(event) => {
                                handleAttachmentFileChange(event, index);
                              }}
                              accept={ATTACHMENT_FILE_TYPES}
                              type="file"
                              style={{display: "none"}}
                          />
                          <div>
                            {element?.attachmentFilename && (
                                <div
                                    onClick={() => {
                                      downloadFile(element?.attachmentUrl || "");
                                    }}
                                    className="file-name"
                                >
                                  <StatusIndicator type="success">
                                    {element?.attachmentFilename}
                                  </StatusIndicator>
                                </div>
                            )}
                            {element?.attachmentSize && (
                                <div className="file-size">
                                  {humanFileSize(element?.attachmentSize)}
                                </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="select">
                        <FormField
                            errorText={
                              (element?.attachmentDesc?.length || 0) >
                              LengthLimit.attachmentDesc
                                  ? `Name must be less than ${LengthLimit.attachmentDesc} characters`
                                  : ""
                            }
                        >
                          <Autosuggest
                              onChange={({detail}) => {
                                if (
                                    detail.value.length > LengthLimit.attachmentDesc
                                ) {
                                  addInputError("swaAttachmentDescError_" + index);
                                } else {
                                  removeInputError("swaAttachmentDescError_" + index);
                                }
                                changeAttachmentDesc(index, detail.value);
                              }}
                              value={element?.attachmentDesc || ""}
                              options={SWAAttachmentTypeList}
                              enteredTextLabel={(value) => `${value}`}
                              ariaLabel="Autosuggest example with suggestions"
                              placeholder="Enter value"
                              empty="No matches found"
                          />
                        </FormField>
                      </div>
                      <div className="remove  pt-4">
                        <Button
                            disabled={element?.showProgress}
                            onClick={() => {
                              if (curSWA && curSWA.attachments) {
                                removeAttachment(index);
                              }
                            }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                );
              })}

              {curSWA && curSWA.attachments && curSWA.attachments.length < 5 && (
                  <div className="add-new">
                    <Button
                        onClick={() => {
                          addNewAttachment({
                            attachmentFilename: "",
                            attachmentUrl: "",
                            attachmentSize: "",
                            attachmentDesc: "",
                          });
                        }}
                    >
                      Add Attachment
                    </Button>
                  </div>
              )}
            </div>
          </Container>

          {/* <div className="mt-20">
          <Alert>
            Before you submit the SWA, please make sure the SWA content and the
            attachments without senstive information.
          </Alert>
        </div> */}
        </div>
      </div>
  );
};

export default StepPoCTags;
