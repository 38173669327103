import React, { useContext, useEffect, useState } from 'react';
import { Header } from '@amzn/awsui-components-react/polaris';
import {
  AppLayout,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Link,
  RadioGroup,
  SpaceBetween,
  Spinner,
  Textarea
} from '@amzn/awsui-components-react';
import { EngageResult, ResultAbstract, ResultInput, SubmitMode, UseCaseAbstract } from '../../../API';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import BreadCrumb from '../../../common/BreadCrumb';
import { appSyncRequest } from '../../../assets/js/request';
import { submitInterestOrNot } from '../../../graphql/mutations';
import SideNav from '../../../common/SideNav';
import { PHONE_TOOL_LINK, SIDE_MENU_IS_OPEN, USECASE_NOT_FOUNT_ID } from '../../../assets/js/const';
import { getEngagementById, getUseCaseById } from '../../../graphql/queries';
import AppContext from '../../../context/AppContext';
import ValueWithLabel from 'common/ValueWithLabel';
import { handleNullValue } from '../../../assets/js/utils';
import { PageType } from '../../../types';

interface CustomerEngageProps {
  pageType: string;
  useCaseId: string;
  customerId: string;
  customerName: string;
}

const CustomerEngageContent: React.FC<CustomerEngageProps> = (props: CustomerEngageProps) => {
  const {
    pageType,
    useCaseId,
    customerId,
    customerName,
  } = props;

  const history = useHistory();
  const appConfig = useContext(AppContext);

  const [interest, setInterest] = useState(EngageResult.Yes);
  const [isNotInterest, setIsNotInterest] = useState(false);
  const [showInputError, setShowInputError] = useState(false);

  const [curResult, setCurResult] = useState<ResultInput>({
    useCaseId: useCaseId,
    customerId: customerId,
    customerName: customerName,
    contributorId: appConfig.userId,
    isInterested: EngageResult.Yes,
    reason: "",
    resultStatus: SubmitMode.Submit,
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);


  const [loadingData, setLoadingData] = useState(false);
  const [curUseCase, setCurUseCase] = useState<UseCaseAbstract>();

  const getUseCaseInfoById = async () => {
    try {
      setLoadingData(true);
      const engagementData : any = await appSyncRequest(getEngagementById, {
        input: {
          useCaseId: useCaseId,
          customerId: customerId
        }
      })
      const engagementInfo: ResultAbstract = engagementData?.data?.getEngagementById;
      if (engagementInfo.isInterested !== undefined && engagementInfo.isInterested !== null) {
        setCurResult((prev) => {
          return {...prev, isInterested: engagementInfo.isInterested};
        });
        setInterest(engagementInfo.isInterested);
      }
      if (engagementInfo.reason !== undefined && engagementInfo.reason !== null) {
        setCurResult((prev) => {
          return {...prev, reason: engagementInfo.reason};
        });
      }

      const useCaseData: any = await appSyncRequest(getUseCaseById, {useCaseId: useCaseId});
      const useCaseInfo: UseCaseAbstract = useCaseData?.data?.getUseCaseById;
      if (useCaseInfo.useCaseId === USECASE_NOT_FOUNT_ID) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        console.info("useCaseInfo:", useCaseInfo);
        if (useCaseData?.data?.getUseCaseById) {
          const useCaseDataObj = useCaseData.data.getUseCaseById;
          useCaseDataObj.useCaseName = decodeURIComponent(
              useCaseDataObj.useCaseName.replace(/\+/g, " ") || ""
          );
          useCaseDataObj.useCaseDesc = decodeURIComponent(
              useCaseDataObj.useCaseDesc.replace(/\+/g, " ")
          );
          useCaseDataObj.architectDesc = decodeURIComponent(
              useCaseDataObj.architectDesc.replace(/\+/g, " ")
          );
          useCaseDataObj.architectDiagrams.forEach((element: any) => {
            element.architectFilename = decodeURIComponent(
                element.architectFilename.replace(/\+/g, " ")
            );
          });
          useCaseDataObj.attachments.forEach((element: any) => {
            element.attachmentFilename = decodeURIComponent(
                element.attachmentFilename.replace(/\+/g, " ")
            );
            element.attachmentDesc = decodeURIComponent(
                element.attachmentDesc.replace(/\+/g, " ")
            );
          });
          setCurUseCase(useCaseData.data.getUseCaseById);
          console.info("curUseCase:", curUseCase);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUseCaseInfoById();
  }, []);

  const updateResult = async () => {

    if (curResult.isInterested === "No" && curResult.reason === "") {
      setShowInputError(true);
      return;
    }

    const updateResultInfoParam = JSON.parse(JSON.stringify(curResult));

    setLoadingSubmit(true);
    try {
      const updateResultRes = await appSyncRequest(submitInterestOrNot, {
        input: updateResultInfoParam,
      });
      console.info("updateResultRes: ", updateResultRes);
      setLoadingSubmit(false);
      history.push({
        pathname: `/${pageType}`,
      });
    } catch (error) {
     setLoadingSubmit(false);
     console.error(error);
    }
  };

  return (
      <div>
        <Header variant="h1">Customer Interested or Not Interested</Header>
        <div className="mt-10">
          <Container header={<Header variant="h2">Use Case Info</Header> }>
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween size="l">
                <ValueWithLabel label="Use Case Name">
                  {handleNullValue(curUseCase?.useCaseName)}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label="WWSO GTM">
                  {curUseCase?.contributor?.name} (
                  <Link
                      href={`${PHONE_TOOL_LINK}/${curUseCase?.contributor?.userId}`}
                      external={true}
                  >
                    {handleNullValue(curUseCase?.contributor?.userId)}
                  </Link>
                  )
                </ValueWithLabel>
              </SpaceBetween>
            </ColumnLayout>
            <ColumnLayout columns={1} variant="text-grid">
              <SpaceBetween size="l">
                <ValueWithLabel label="Use Case Description">
                  {handleNullValue(curUseCase?.useCaseDesc)}
                </ValueWithLabel>
              </SpaceBetween>
            </ColumnLayout>
          </Container>
        </div>
        <div className="mt-10">
          <Container header={<Header variant="h2">Customer Info</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween size="l">
                <ValueWithLabel label="Customer ID">
                  {handleNullValue(props.customerId)}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label="Customer Name">
                  {handleNullValue(props.customerName)}
                </ValueWithLabel>
              </SpaceBetween>
            </ColumnLayout>
          </Container>
        </div>
        <div className="mt-10">
          <Container header={<Header variant="h2">Enter interested or not interested</Header>}>
            <FormField
              description="Whether customer interest about the Use Case?"
              >
              <div className="flex flex-wrap">
                <div className="mt-5 w-25">
                  <RadioGroup
                      value={interest}
                      onChange={({detail}) => {
                        // @ts-ignore
                        setInterest(detail.value);
                        setCurResult((prev) => {
                          return {...prev, isInterested: detail.value};
                        });
                        if (detail.value === EngageResult.Yes) {
                          setShowInputError(false);
                        }
                      }}
                      items={[
                        {value: "Yes", label: "Yes"},
                        {value: "No", label: "No"}
                      ]}
                  />
                </div>
              </div>
            </FormField>
            {isNotInterest ? (
                <Spinner/>
            ) : (
                <div className="mt-20">
                  <FormField
                      description="If the user is not interested, please explain why."
                      errorText={
                        showInputError ? "Reason can not be empty" : ""
                      }
                  >
                    <Textarea
                        placeholder="why is the customer not interested?"
                        value={curResult?.reason || ""}
                        onChange={(event) => {
                          if (interest === EngageResult.No) {
                            if (event.detail.value.length === 0) {
                              setShowInputError(true);
                            }else {
                              setShowInputError(false);
                            }
                          }
                          setCurResult((prev) => {
                            return {...prev, reason: event.detail.value};
                          });
                        }}
                    />
                  </FormField>
                </div>
            )}
          </Container>
        </div>
        <div className="mt-20 pb-30 clearfix">
          <SpaceBetween className="fr" direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={()=> {
                history.push({
                  pathname: `/${pageType}`,
                });
              }}
              >
              Cancel
            </Button>
            <Button
              disabled={showInputError || loadingData}
              loading={loadingSubmit}
              variant="primary"
              onClick={() => {
                updateResult();
              }}
              >
              Submit
            </Button>
          </SpaceBetween>
        </div>
      </div>
  );
};

const CustomerEngage: React.FC<RouteComponentProps> = (
    props: RouteComponentProps
) => {
  const history = useHistory<{useCaseId: string, groupId: string, customerId: string, customerName: string}>();
  const usacase_id: string = history.location.state.useCaseId;
  const customer_name: string = history.location.state.customerName;
  const customer_id: string = history.location.state.customerId;
  const group_id: string = history.location.state.groupId;
  const param = useLocation();
  const type: string = param.pathname.split('/')[1];

  console.info("type:", type);

  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );

  let customerEngageBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];

  if (type === PageType.ALL_USECASE) {
    customerEngageBreadcrumbs = [
      ...customerEngageBreadcrumbs,
      {
        text: usacase_id.toUpperCase(),
        href: "/usecase-all/detail/" + usacase_id.toUpperCase(),
      },
      {
        text: customer_name,
        href: "/usecase-all/" + usacase_id.toUpperCase() + "/" + group_id + "/customer",
      },
      {
        text: "Input interest",
        href: ""
      }
    ];
  }

  if (type === PageType.MY_ENGAGEMENT) {
    customerEngageBreadcrumbs = [
      ...customerEngageBreadcrumbs,
      {
        text: "Customer Engagement",
        href: "/my-engagement"
      },
      {
        text: "Edit Engagement",
        href: ""
      }
    ];
  }

  if (type === PageType.MY_TEAM_ENGAGAMENT) {
    customerEngageBreadcrumbs = [
      ...customerEngageBreadcrumbs,
      {
        text: "My team’s Customer Engagement",
        href: "/engagement-my-team"
      },
      {
        text: "Edit Engagement",
        href: ""
      }
    ];
  }

  return (
      <AppLayout
        breadcrumbs={<BreadCrumb resourcesBreadcrumbs={customerEngageBreadcrumbs}/>}
        content={
          <CustomerEngageContent
              pageType={type}
              useCaseId={usacase_id}
              customerId={customer_id}
              customerName={customer_name}
          />
        }
        navigation={<SideNav/>}
        stickyNotifications
        navigationOpen={navigationOpen}
        onNavigationChange={() => {
          localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
          setnavigationOpen(!navigationOpen);
        }}
      />
  );
};

export default CustomerEngage;