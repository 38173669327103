import { ArchitectDiagramsInput, Group, GroupInput, UseCaseInput } from 'API';
import React, { useEffect, useState } from 'react';
import { LengthLimitUseCase, MAX_FILE_SIZE } from 'assets/js/const';
import Swal from 'sweetalert2';
import { Storage } from 'aws-amplify';
import { appSyncRequest } from 'assets/js/request';
import { getGroups } from 'graphql/queries';
import { Header } from '@amzn/awsui-components-react/polaris';
import {
  Button,
  Container,
  FormField,
  Input,
  ProgressBar, RadioGroup,
  Spinner,
  Textarea
} from '@amzn/awsui-components-react';
import { UseCaseInptValidErrorType } from 'types';
import ArchImage from '../../../swa/comps/step/comps/ArchImage';

interface RadioIteam {
  value: string;
  label: string;
}

interface StepDetailProps {
  curUseCase: UseCaseInput;
  showNameEmptyError: boolean;
  setShowNameEmptyError: (showError: boolean) => void;
  changeUseCaseName: (useCaseName: string) => void;
  changeUseCaseDesc: (useCaseDesc: string) => void;
  changeArchImgList: (archImgList: ArchitectDiagramsInput[]) => void;
  changeArchDesc: (sourceCode: string) => void;
  changeIsUploading: (uploading: boolean) => void;
  addInputError: (error: string) => void;
  removeInputError: (error: string) => void;
  showGroupError: boolean;
  setGroupIsLoading: (loading: boolean) => void;
  changeGroupInUse: (group: GroupInput[]) => void;
  showArchDiagramRequireError: boolean;
}

const StepUseCaseDetails: React.FC<StepDetailProps> = (props: StepDetailProps) => {
  const {
    curUseCase,
    showNameEmptyError,
    setShowNameEmptyError,
    changeUseCaseName,
    changeUseCaseDesc,
    changeArchImgList,
    changeArchDesc,
    changeIsUploading,
    addInputError,
    removeInputError,
    showGroupError,
    setGroupIsLoading,
    changeGroupInUse,
    showArchDiagramRequireError
  } = props;

  const [loadingGroup, setLoadingGroup] = useState(false);
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [chosenGroup, setChosenGroup] = useState<GroupInput>({});
  const [radioItemList, setRadioItemList] = useState<RadioIteam[]>([]);

  const [archUploadProgress, setArchUploadProgress] = useState(0);
  const [showArchUploadProgress, setShowArchUploadProgress] = useState(false);
  //const [workLoadServiceList, setWorkLoadServiceList] = useState<WorkloadServiceListType[]>([]);
  const [archImageList, setArchImageList] = useState(
      curUseCase.architectDiagrams || []
  );

  const getGroupList = async () => {
    setLoadingGroup(true);
    setGroupIsLoading(true);
    const groupData: any = await appSyncRequest(getGroups, {});
    console.info("groupData:", groupData);
    const tmpGroupList: Group[] = groupData?.data?.getGroups || [];
    const groupInUseList = curUseCase.groupInUseList || [];
    const radioList: RadioIteam[] = [];
    tmpGroupList.forEach((element) => {
      radioList.push({
        value: element.groupId || "",
        label: element.groupName || "",
      });
      if (chosenGroup===undefined) {
        setChosenGroup({
          groupId: element.groupId,
          groupName: element.groupName,
          categoryId: element.categoryId
        });
      }
      if (groupInUseList.map((cele) => cele?.groupId).indexOf(element.groupId) >= 0) {
        setChosenGroup({
          groupId: element.groupId,
          groupName: element.groupName,
          categoryId: element.categoryId
        });
      }
    });

    setRadioItemList(radioList);
    setGroupList(groupData?.data?.getGroups || []);
    setLoadingGroup(false);
    setGroupIsLoading(false);
  };

  useEffect(() => {
    getGroupList();
  }, []);

  useEffect(() => {
    console.info("chosenGroup:", chosenGroup);
    const tmpGroupArr: GroupInput[] = [];
    tmpGroupArr.push(chosenGroup);
    changeGroupInUse(tmpGroupArr);
  }, [chosenGroup]);

  const hiddenArchFileInput = React.useRef<any>(null);
  const handleArchFileClick = (event: any) => {
    console.info("event:", event);
    hiddenArchFileInput?.current?.click();
  };

  const handleArchFileChange = (event: any) => {
    console.info("event:", event);
    const fileUploaded = event.target.files[0];
    // file size must less than 100M
    if (fileUploaded?.size > MAX_FILE_SIZE) {
      Swal.fire("Oops...", "File size must less than 100MB", "warning");
      return;
    }
    changeIsUploading(true);
    console.info("fileUpload:", fileUploaded);
    const s3FileKey = `${curUseCase.useCaseId}/architect/${new Date().getTime()}_${
      fileUploaded.name
    }`;
    setShowArchUploadProgress(true);
    setArchUploadProgress(0);
    Storage.put(s3FileKey, fileUploaded, {
      contentType: fileUploaded.type,
      progressCallback(progress: any) {
        setArchUploadProgress((progress.loaded / progress.total) * 100);
        console.info(
            `Uploaded percentage: ${progress.loaded}/${progress.total}`
        );
        if (progress.loaded >= progress.total) {
          setShowArchUploadProgress(false);
        }
      },
    }).then((result: any) => {
      Storage.get(result.key, {expires: 300}).then((res) => {
        setArchImageList((prev) => {
          return [
            ...prev,
            {
              architectFileName: fileUploaded.name,
              architectFileUrl: result.key,
            },
          ];
        });
        changeIsUploading(false);
      });
    }).catch((err) => {
      changeIsUploading(false);
      console.error("err:", err);
    });
  };

  useEffect(() => {
    console.info("archImageList:", archImageList);
    const tmpArchImgList: ArchitectDiagramsInput[] = [];
    archImageList.forEach((element) => {
      tmpArchImgList.push({
        architectFileName: element?.architectFileName,
        architectFileUrl: element?.architectFileUrl,
      });
    });
    changeArchImgList(tmpArchImgList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archImageList]);

  return (
      <div>
        <Header variant="h1">Use Case Details</Header>
        <div className="mt-10">
          <Container header={<Header variant="h2">General</Header>}>
            <div>
              <FormField
                description="Brief descriptions to the Use Case"
                label="Use Case Campaign Name"
                errorText={
                  (curUseCase?.useCaseName?.length || 0) > LengthLimitUseCase.useCaseName
                    ? `Title must be less than ${LengthLimitUseCase.useCaseName} characters.`
                      : showNameEmptyError
                        ? "Title can not be empty."
                        : ""
                }
                >
                <Input
                  placeholder="Aurora benchmarking against TiDB"
                  value={curUseCase?.useCaseName || ""}
                  onChange={(event) => {
                    if (event.detail.value.length > LengthLimitUseCase.useCaseName) {
                      addInputError(UseCaseInptValidErrorType.useCaseNameLengthError);
                    } else {
                      removeInputError(UseCaseInptValidErrorType.useCaseNameLengthError);
                    }
                    setShowNameEmptyError(false);
                    changeUseCaseName(event.detail.value);
                  }}
                />
              </FormField>
              <FormField
                className="mt-20"
                description="provide a detailed description to the Use Case(mandatory) include Customer scenario, the reason customer would like to migration to Campaign, what is the lesson and learn here."
                label="Use Case description"
                errorText={
                  (curUseCase?.useCaseDesc?.length || 0) > LengthLimitUseCase.useCaseDesc
                    ? `Use Case Description must be less than ${LengthLimitUseCase.useCaseDesc} characters.`
                      : ""
                }
                >
                <Textarea
                  placeholder="This is a long text."
                  value={curUseCase?.useCaseDesc || ""}
                  onChange={(event) => {
                    if (event.detail.value.length > LengthLimitUseCase.useCaseDesc) {
                      addInputError(UseCaseInptValidErrorType.useCaseDescLengthError);
                    } else {
                      removeInputError(UseCaseInptValidErrorType.useCaseDescLengthError);
                    }
                    changeUseCaseDesc(event.detail.value);
                  }}
                  />
              </FormField>
            </div>
          </Container>
        </div>
        <div className="mt-20">
          <Container header={<Header variant="h2">First call Deck</Header>}>
            <div>
              <FormField
                description="Select the Architect Diagram to upload. Accept format: image/*."
                label="First call Deck"
                errorText={
                  showArchDiagramRequireError
                    ? "Please upload an architect diagram."
                      : ""
                }
                >
                {archImageList.map((element, index) => {
                  return (
                      <div className="mt-10 w-70p pr arch-img-item" key={index}>
                        <div className="remove-icon">
                          <Button
                            iconName="close"
                            onClick={() => {
                              setArchImageList((prev: any) => {
                                return [...prev].filter((element, idx, ) => {
                                  return idx !== index;
                                });
                              });
                            }}
                            >
                            Remove
                          </Button>
                        </div>
                        <ArchImage s3Key={element?.architectFileUrl || ""}/>
                      </div>
                  );
                })}

                <Button
                  className="mt-20"
                  onClick={handleArchFileClick}
                  iconAlign="left"
                  iconName="upload"
                  variant="normal"
                  >
                  Choose File
                </Button>
                <input
                  ref={hiddenArchFileInput}
                  onChange={handleArchFileChange}
                  type="file"
                  accept="image/*"
                  style={{display: "none"}}
                  />
                {showArchUploadProgress && (
                    <ProgressBar
                      status="in-progress"
                      value={archUploadProgress}
                      additionalInfo=""
                      description=""
                      label=""
                      />
                )}
              </FormField>
              <FormField
                className="mt-20"
                description="Use 2~ 5 paragraph to summary how these components work together."
                label="First call Deck Description"
                errorText={
                  (curUseCase?.useCaseDesc?.length || 0) > LengthLimitUseCase.architectDesc
                    ? `Architect description must be less than ${LengthLimitUseCase.architectDesc} characters.`
                      : ""
                }
                >
                <Textarea
                  placeholder="This is a long text."
                  value={curUseCase?.architectDesc || ""}
                  onChange={(event) => {
                    if (event.detail.value.length > LengthLimitUseCase.architectDesc) {
                      addInputError(UseCaseInptValidErrorType.architectDescLengthError);
                    } else {
                      removeInputError(UseCaseInptValidErrorType.architectDescLengthError);
                    }
                    changeArchDesc(event.detail.value);
                  }}
                  />
              </FormField>
            </div>
          </Container>
        </div>
        <div className="mt-20">
          <Container header={<Header variant="h2">Service Group</Header>}>
            {loadingGroup ? (
                <Spinner/>
            ) : (
                <FormField
                  className="mt-20"
                  description="Choose one service group which the Use Case used."
                  errorText={
                    showGroupError ? "Must choose one service group" : ""
                  }
                  >
                  <div className="flex flex-wrap">
                    <div className="mt-5 w-25">
                      <RadioGroup
                          onChange={({detail}) => {
                            console.info("detail:", detail);
                            groupList.forEach((element) => {
                              if (element.groupId === detail.value) {
                                setChosenGroup({
                                  groupId: element.groupId,
                                  groupName: element.groupName,
                                  categoryId: element.categoryId
                                });
                              }
                            });
                          }}
                          value={chosenGroup.groupId || null}
                          items={radioItemList}
                      />
                    </div>
                  </div>
                </FormField>
            )
            }
          </Container>
        </div>
      </div>
  );
};


export default StepUseCaseDetails;