import { UseCaseAbstract } from '../../../API';
import React, { useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { ColumnLayout, Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import ValueWithLabel from '../../../common/ValueWithLabel';
import { handleNullValue } from '../../../assets/js/utils';
import ArchImage from '../../swa/comps/step/comps/ArchImage';
import { Spinner } from '@amzn/awsui-components-react/polaris';

interface OverViewProps {
  useCaseId: string;
  loading: boolean;
  curUseCase: UseCaseAbstract | undefined;
}

const OverView: React.FC<OverViewProps> = (props: OverViewProps) => {
  const {curUseCase, loading} = props;

  const downloadFile = async (fileKey: string) => {
    // Creates download url that expires in 10 minutes/ 600 seconds
    const downloadUrl = await Storage.get(fileKey, {expires: 600});
    window.open(downloadUrl.toString(), "_blank");
  };

  return (
      <div className="mt-10">
        <Container header={<Header variant="h2">Overview</Header>}>
          {loading ? (
              <Spinner size="normal"/>
          ) : (
              <div>
                <ColumnLayout columns={3} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Service Group">
                      {handleNullValue(
                          curUseCase?.workload
                              ?.map((element) => element?.groupName)
                              .join(", ")
                      )}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Industry Category">
                      {handleNullValue(
                          curUseCase?.industries
                              ?.map((element) => element?.industryName)
                              .join(", ")
                      )}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                {curUseCase?.workload?.map((element, index) => {
                  return (
                      <ColumnLayout key={index} columns={1} variant="text-grid">
                        <SpaceBetween size="l">
                          <ValueWithLabel label={element?.groupName || ""}>
                            {handleNullValue(
                                element?.serviceList
                                    ?.map((element) => element?.svcName)
                                    .join(", ")
                            )}
                          </ValueWithLabel>
                        </SpaceBetween>
                      </ColumnLayout>
                  );
                })}

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Use Case Description">
                      {handleNullValue(curUseCase?.useCaseDesc)}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>
                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="First call Deck">
                      {curUseCase?.architectDiagrams?.map((element, index) => {
                        return (
                            <div className="mt-20 w-70p" key={index}>
                              <ArchImage s3Key={element?.architectFileUrl || ""}/>
                            </div>
                        );
                      })}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="First call Deck Description">
                      <p>{handleNullValue(curUseCase?.architectDesc)}</p>
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>

                <ColumnLayout columns={1} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Attachments">
                      {curUseCase?.attachments
                          ? curUseCase.attachments.map((element, index) => {
                            return (
                                <span
                                    key={index}
                                    onClick={() => {
                                      downloadFile(element?.attachmentUrl || "");
                                    }}
                                >
                              <Link key={index} className="mr-10">
                                {element?.attachmentFilename}
                              </Link>
                            </span>
                            );
                          })
                          : "-"}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>
              </div>
          )}
        </Container>
      </div>
  );
};

export default OverView;