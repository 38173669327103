import React, { useContext, useEffect, useState } from "react";
import { AppLayout, Spinner } from "@amzn/awsui-components-react";
import { Button, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";
import { POC_NOT_FOUND_ID, SIDE_MENU_IS_OPEN } from "assets/js/const";
import StepPoCOpp from "../comps/step/StepPoCOpp";
import StepPoCDetails from "../comps/step/StepPoCDetails";
import StepPoCTags from "../comps/step/StepPoCTags";
import { PoC, PoCInput, SubmitMode, UserRole } from "API";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { getPoCById } from "graphql/queries";
import { submitPoC } from "graphql/mutations";
import AppContext from "context/AppContext";
import { isValidOppId } from "assets/js/utils";

interface EditPoCProps {
  pocId: string;
}

export interface EditPoCInput extends PoCInput {
  pocStatus?: SubmitMode;
}

const EditPoCContent: React.FC<EditPoCProps> = (props: EditPoCProps) => {
  const history = useHistory();
  const appConfig = useContext(AppContext);
  const {pocId} = props;
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [editPoCInfo, setEditPoCInfo] = useState<EditPoCInput>({
    pocId: pocId,
    mode: SubmitMode.Draft,
    contributorId: appConfig.userId,
    opptyId: "",
    attachments: [],
    pocName: "",
    pocSubject: "",
    userScenarioDesc: "",
    pocScope: "",
    architectDesc: "",
    architectFilename: "",
    architectImgUrl: "",
    industryTagList: [],
    sourceCodeUrl: "",
    technicalTagList: [],
    userGuideUrl: "",
    efforts: 0,
  });

  const [showOppIdError, setShowOppIdError] = useState(false);
  const [showNameEmptyError, setShowNameEmptyError] = useState(false);
  const [showEffortsInvalidError, setShowEffortsInvalidError] = useState(false);
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [pocInputErrorArr, setPocInputErrorArr] = useState<string[]>([]);

  const updatePoC = async (mode: SubmitMode) => {
    if (pocInputErrorArr && pocInputErrorArr.length > 0) {
      return;
    }

    // validate oppId first
    if (!isValidOppId(editPoCInfo.opptyId)) {
      setShowOppIdError(true);
      setActiveStepIndex(0);
      return;
    }

    // Validate Input
    // setShowNameEmptyError while poc Name is Empty
    if (!editPoCInfo?.pocName?.trim()) {
      setShowNameEmptyError(true);
      setActiveStepIndex(1);
      return;
    }

    if (editPoCInfo.efforts < 1) {
      setShowEffortsInvalidError(true);
      setActiveStepIndex(1);
      return;
    }

    const updatePoCInfoParam = JSON.parse(JSON.stringify(editPoCInfo));
    updatePoCInfoParam.mode = mode;
    console.info("updatePoCInfoParam:", updatePoCInfoParam);

    // encode assets input
    updatePoCInfoParam.pocName = encodeURIComponent(updatePoCInfoParam.pocName);
    updatePoCInfoParam.pocSubject = encodeURIComponent(
        updatePoCInfoParam.pocSubject
    );
    updatePoCInfoParam.userScenarioDesc = encodeURIComponent(
        updatePoCInfoParam.userScenarioDesc
    );
    updatePoCInfoParam.pocScope = encodeURIComponent(
        updatePoCInfoParam.pocScope
    );
    updatePoCInfoParam.architectDesc = encodeURIComponent(
        updatePoCInfoParam.architectDesc
    );
    updatePoCInfoParam.architectFilename = encodeURIComponent(
        updatePoCInfoParam.architectFilename
    );

    // Attachment Refine
    const updateAttachments: any = [];
    editPoCInfo?.attachments?.forEach((element) => {
      updateAttachments.push({
        attachmentFilename: encodeURIComponent(
            element?.attachmentFilename || ""
        ),
        attachmentUrl: element?.attachmentUrl,
        attachmentSize: element?.attachmentSize,
        attachmentDesc: encodeURIComponent(element?.attachmentDesc || ""),
      });
    });
    updatePoCInfoParam.attachments = updateAttachments;
    if (mode === SubmitMode.Draft) {
      setLoadingSaveDraft(true);
    }
    if (mode === SubmitMode.Submit) {
      setLoadingSubmit(true);
    }
    // Add Contributor to parameter
    updatePoCInfoParam.contributorId = appConfig.userId;
    // Remove useless properties
    delete updatePoCInfoParam?.pocStatus;
    delete updatePoCInfoParam?.pocVersion;
    delete updatePoCInfoParam?.contributor;
    delete updatePoCInfoParam?.createDateTime;
    delete updatePoCInfoParam?.submitDateTime;
    delete updatePoCInfoParam?.sfdcAccountId;
    delete updatePoCInfoParam?.sfdcAccountName;
    delete updatePoCInfoParam?.sfdcAccountUrl;
    delete updatePoCInfoParam?.opptyName;
    delete updatePoCInfoParam?.opptyUrl;
    delete updatePoCInfoParam?.opptyStatus;
    delete updatePoCInfoParam?.territory;
    delete updatePoCInfoParam?.deliverableUrl;
    delete updatePoCInfoParam?.reviewStatus;
    delete updatePoCInfoParam?.bestPracticeStatus;
    delete updatePoCInfoParam?.nominatedBy;
    delete updatePoCInfoParam?.nominationDateTime;
    delete updatePoCInfoParam?.assigner;
    delete updatePoCInfoParam?.assignee;
    delete updatePoCInfoParam?.assignDateTime;
    delete updatePoCInfoParam?.__typename;

    try {
      const createPoCRes = await appSyncRequest(submitPoC, {
        input: updatePoCInfoParam,
      });
      console.info("createPoCRes:", createPoCRes);
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      history.push({
        pathname: appConfig.userRole === UserRole.Contributor ? "/my-poc" : "/",
      });
    } catch (error) {
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      console.error(error);
    }
  };

  const getPoCInfoById = async () => {
    try {
      setLoadingData(true);
      const pocData: any = await appSyncRequest(getPoCById, {pocId: pocId});
      const pocInfo: PoC = pocData?.data?.getPoCById;
      if (
          pocInfo.pocId === POC_NOT_FOUND_ID ||
          pocInfo.contributor?.userId !== appConfig.userId
      ) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        console.info("pocData:", pocData);
        if (pocData?.data?.getPoCById) {
          pocData.data.getPoCById.pocName = decodeURIComponent(
              pocData.data.getPoCById.pocName.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.userScenarioDesc = decodeURIComponent(
              pocData.data.getPoCById.userScenarioDesc.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.pocScope = decodeURIComponent(
              pocData.data.getPoCById.pocScope.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.architectDesc = decodeURIComponent(
              pocData.data.getPoCById.architectDesc.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.architectFilename = decodeURIComponent(
              pocData.data.getPoCById.architectFilename.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.attachments.forEach((element: any) => {
            element.attachmentFilename = decodeURIComponent(
                element.attachmentFilename.replace(/\+/g, " ")
            );
            element.attachmentDesc = decodeURIComponent(
                element.attachmentDesc.replace(/\+/g, " ")
            );
          });
          setEditPoCInfo(pocData.data.getPoCById);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPoCInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.info("editPoCInfo:", editPoCInfo);
  }, [editPoCInfo]);

  return (
      <div>
        {loadingData ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div className="gsal-review-page">
              <div className="gsal-step-nav">
                <nav>
                  <ul>
                    <li>
                      <small>Step 1</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          // check efforts
                          if (editPoCInfo.efforts < 1) {
                            setShowEffortsInvalidError(true);
                            setActiveStepIndex(1);
                            return;
                          }
                          setActiveStepIndex(0);
                        }}
                        className={activeStepIndex !== 0 ? "link" : ""}
                    >
                      Opportunity info
                    </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 2</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          if (!isValidOppId(editPoCInfo.opptyId)) {
                            setShowOppIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                            return;
                          }
                          setActiveStepIndex(1);
                        }}
                        className={activeStepIndex !== 1 ? "link" : ""}
                    >
                      PoC details
                    </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 3</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          if (!isValidOppId(editPoCInfo.opptyId)) {
                            setShowOppIdError(true);
                            setActiveStepIndex(0);
                            return;
                          }
                          if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                            return;
                          }
                          setActiveStepIndex(2);
                        }}
                        className={activeStepIndex !== 2 ? "link" : ""}
                    >
                      Tags & attachments
                    </span>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="gsal-review-content">
                {activeStepIndex === 0 && (
                    <StepPoCOpp
                        curPoc={editPoCInfo}
                        showOppIdError={showOppIdError}
                        changePoCOppId={(id) => {
                          setShowOppIdError(false);
                          setEditPoCInfo((prev) => {
                            return {...prev, opptyId: id};
                          });
                        }}
                    />
                )}
                {activeStepIndex === 1 && (
                    <StepPoCDetails
                        curPoc={editPoCInfo}
                        addInputError={(error) => {
                          if (!pocInputErrorArr.includes(error)) {
                            setPocInputErrorArr((prev) => {
                              return [...prev, error];
                            });
                          }
                        }}
                        removeInputError={(error) => {
                          setPocInputErrorArr((prev) => {
                            return prev.filter((e) => e !== error);
                          });
                        }}
                        changeIsUploading={(uploading) => {
                          setIsFileUploading(uploading);
                        }}
                        setShowNameEmptyError={(showError) => {
                          console.info("showError:", showError);
                        }}
                        showEffortsInvalidError={showEffortsInvalidError}
                        showNameEmptyError={showNameEmptyError}
                        changePocName={(pocName) => {
                          setShowNameEmptyError(false);
                          setEditPoCInfo((prev) => {
                            return {...prev, pocName: pocName, pocSubject: pocName};
                          });
                        }}
                        changePocUserSenario={(userSenario) => {
                          setEditPoCInfo((prev) => {
                            return {...prev, userScenarioDesc: userSenario};
                          });
                        }}
                        changePocScope={(pocScope) => {
                          setEditPoCInfo((prev) => {
                            return {...prev, pocScope: pocScope};
                          });
                        }}
                        changeUserGuide={(userGuide) => {
                          setEditPoCInfo((prev) => {
                            return {...prev, userGuideUrl: userGuide};
                          });
                        }}
                        changeSourceCode={(sourceCode) => {
                          setEditPoCInfo((prev) => {
                            return {...prev, sourceCodeUrl: sourceCode};
                          });
                        }}
                        changeArchImgInfo={(archInfo) => {
                          setEditPoCInfo((prev) => {
                            return {
                              ...prev,
                              architectFilename: archInfo.architectFilename,
                              architectImgUrl: archInfo.architectImgUrl,
                            };
                          });
                        }}
                        changeArchDesc={(archDesc) => {
                          setEditPoCInfo((prev) => {
                            return {...prev, architectDesc: archDesc};
                          });
                        }}
                        changeEfforts={(efforts) => {
                          if (efforts > 0) {
                            setShowEffortsInvalidError(false);
                          } else {
                            setShowEffortsInvalidError(true);
                          }
                          setEditPoCInfo((prev) => {
                            return {...prev, efforts: efforts};
                          });
                        }}
                    />
                )}
                {activeStepIndex === 2 && (
                    <StepPoCTags
                        curPoc={editPoCInfo}
                        addInputError={(error) => {
                          if (!pocInputErrorArr.includes(error)) {
                            setPocInputErrorArr((prev) => {
                              return [...prev, error];
                            });
                          }
                        }}
                        removeInputError={(error) => {
                          setPocInputErrorArr((prev) => {
                            return prev.filter((e) => e !== error);
                          });
                        }}
                        changeIsUploading={(uploading) => {
                          setIsFileUploading(uploading);
                        }}
                        changeIndustryTagList={(tags) => {
                          setEditPoCInfo((prev) => {
                            return {...prev, industryTagList: tags};
                          });
                        }}
                        changeTechTagList={(tags) => {
                          setEditPoCInfo((prev) => {
                            return {...prev, technicalTagList: tags};
                          });
                        }}
                        addNewAttachment={(item) => {
                          setEditPoCInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            const prevAttachment = prevObj.attachments;
                            prevAttachment.push(item);
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        removeAttachment={(index) => {
                          if (
                              editPoCInfo.attachments &&
                              editPoCInfo.attachments.length > 0
                          ) {
                            const tmpAttacment = [...editPoCInfo.attachments];
                            tmpAttacment.splice(index, 1);
                            setEditPoCInfo((prev: any) => {
                              const prevObj = JSON.parse(JSON.stringify(prev));
                              return {
                                ...prevObj,
                                ...{
                                  attachments: tmpAttacment,
                                },
                              };
                            });
                          }
                        }}
                        changeAttachmentDesc={(index, desc) => {
                          setEditPoCInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            prevObj.attachments[index].attachmentDesc = desc;
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        uploadAttachment={(upProgress, index) => {
                          setEditPoCInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            if (upProgress >= 100) {
                              prevObj.attachments[index].progress = upProgress;
                              prevObj.attachments[index].showProgress = false;
                            } else {
                              prevObj.attachments[index].progress = upProgress;
                              prevObj.attachments[index].showProgress = true;
                            }
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                        uploadAttachmentDone={(index, name, size, key) => {
                          setEditPoCInfo((prev: any) => {
                            const prevObj = JSON.parse(JSON.stringify(prev));
                            prevObj.attachments[index].attachmentFilename = name;
                            prevObj.attachments[index].attachmentSize = size;
                            prevObj.attachments[index].attachmentUrl = key;
                            const prevAttachment = prevObj.attachments;
                            return {
                              ...prevObj,
                              ...{
                                attachments: prevAttachment,
                              },
                            };
                          });
                        }}
                    />
                )}
                <div className="mt-20 pb-30 clearfix">
                  <SpaceBetween className="fr" direction="horizontal" size="xs">
                    <Button
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: `/`,
                          });
                        }}
                    >
                      Cancel
                    </Button>

                    {activeStepIndex === 0 &&
                        editPoCInfo.pocStatus !== SubmitMode.Submit && (
                            <Button
                                disabled={loadingSubmit || isFileUploading}
                                loading={loadingSaveDraft}
                                onClick={() => {
                                  updatePoC(SubmitMode.Draft);
                                }}
                            >
                              Save Draft
                            </Button>
                        )}

                    {activeStepIndex === 0 && (
                        <Button
                            disabled={
                                loadingSubmit || loadingSaveDraft || isFileUploading
                            }
                            variant="primary"
                            onClick={() => {
                              if (!isValidOppId(editPoCInfo.opptyId)) {
                                setShowOppIdError(true);
                                setActiveStepIndex(0);
                                return;
                              }
                              if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                                return;
                              }
                              setActiveStepIndex(1);
                            }}
                        >
                          Next
                        </Button>
                    )}

                    {activeStepIndex > 0 && (
                        <Button
                            disabled={
                                loadingSubmit || loadingSaveDraft || isFileUploading
                            }
                            onClick={() => {
                              if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                                return;
                              }
                              // check efforts
                              if (editPoCInfo.efforts < 1) {
                                setShowEffortsInvalidError(true);
                                setActiveStepIndex(1);
                                return;
                              }
                              setActiveStepIndex((prev) => {
                                return prev - 1;
                              });
                            }}
                        >
                          Previous
                        </Button>
                    )}
                    {(activeStepIndex === 1 || activeStepIndex === 2) &&
                        editPoCInfo.pocStatus !== SubmitMode.Submit && (
                            <Button
                                disabled={loadingSubmit || isFileUploading}
                                loading={loadingSaveDraft}
                                onClick={() => {
                                  updatePoC(SubmitMode.Draft);
                                }}
                            >
                              Save Draft
                            </Button>
                        )}
                    {activeStepIndex === 1 && (
                        <Button
                            disabled={
                                loadingSubmit || loadingSaveDraft || isFileUploading
                            }
                            variant="primary"
                            onClick={() => {
                              if (pocInputErrorArr && pocInputErrorArr.length > 0) {
                                return;
                              }
                              // check efforts
                              if (editPoCInfo.efforts < 1) {
                                setShowEffortsInvalidError(true);
                                setActiveStepIndex(1);
                                return;
                              }
                              setActiveStepIndex(2);
                            }}
                        >
                          Next
                        </Button>
                    )}
                    {activeStepIndex === 2 && (
                        <Button
                            disabled={loadingSaveDraft || isFileUploading}
                            loading={loadingSubmit}
                            variant="primary"
                            onClick={() => {
                              updatePoC(SubmitMode.Submit);
                            }}
                        >
                          Submit
                        </Button>
                    )}
                  </SpaceBetween>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

interface MatchParams {
  id: string;
}

const EditPoC: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setnavigationOpen] = useState(false);
  // const [helpInfo, setHelpInfo] = useState();
  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const id: string = props.match.params.id;

  const pocReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: id,
      href: "/poc/detail/" + id,
    },
    {
      text: "Edit",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocReviewBreadcrumbs}/>}
          content={<EditPoCContent pocId={id}/>}
          // tools={helpInfo}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default EditPoC;
