/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ReviewerInput = {
  pocIds: Array< string >,
  assignerId: string,
  assigneeId: string,
};

export type StatusResponse = {
  __typename: "StatusResponse",
  status?: ResponseCode | null,
};

export enum ResponseCode {
  Success = "Success",
  InputError = "InputError",
  PoCNotFound = "PoCNotFound",
  Fail = "Fail",
}


export type SWAReviewerInput = {
  swaIds: Array< string >,
  assignerId: string,
  assigneeId: string,
};

export type NominateInput = {
  pocId: string,
  nominatedBy: string,
};

export type ReviewInput = {
  pocId: string,
  mode: SubmitMode,
  isNecessary: boolean,
  isRepeatable: boolean,
  isPackageUp: boolean,
  comments: string,
  scoreResList: Array< CriteriaScoreInput >,
};

export enum SubmitMode {
  Draft = "Draft",
  Submit = "Submit",
  Abandon = "Abandon",
}


export type CriteriaScoreInput = {
  criteriaName?: CriteriaType | null,
  score?: number | null,
  comments?: string | null,
};

export enum CriteriaType {
  RequirementsSpecification = "RequirementsSpecification",
  PoCPlan = "PoCPlan",
  TestReport = "TestReport",
  PoCConclusion = "PoCConclusion",
}


export type SWAReviewInput = {
  swaId: string,
  mode: SubmitMode,
  isPass: boolean,
  comments: string,
};

export type SWAStatusInput = {
  swaId: string,
  swaStatus: string,
};

export type BestPracticeAssetsInput = {
  pocId: string,
  submitter: string,
  submitterRole: UserRole,
  mode: SubmitMode,
  technicalTagList?: Array< TagInput | null > | null,
  industryTagList?: Array< TagInput | null > | null,
  sourceCodeUrl?: string | null,
  userGuideUrl?: string | null,
  pocName?: string | null,
  userScenarioDesc?: string | null,
  pocScope?: string | null,
  architectImgUrl?: string | null,
  architectDesc?: string | null,
  architectFilename?: string | null,
  attachments?: Array< AttachmentInput | null > | null,
};

export enum UserRole {
  Administrator = "Administrator",
  Manager = "Manager",
  Reviewer = "Reviewer",
  Contributor = "Contributor",
  SO_TFC_leader = "SO_TFC_leader",
}


export type TagInput = {
  tagId?: string | null,
  tagName?: string | null,
};

export type AttachmentInput = {
  attachmentFilename?: string | null,
  attachmentUrl?: string | null,
  attachmentDesc?: string | null,
  attachmentSize?: number | null,
  pocId: string,
};

export type PoCInput = {
  pocId?: string | null,
  mode: SubmitMode,
  contributorId: string,
  efforts: number,
  opptyId: string,
  opptyName?: string | null,
  opptyStatus?: OpptyStatus | null,
  pocSubject?: string | null,
  pocDesc?: string | null,
  deliverableUrl?: string | null,
  technicalTagList?: Array< TagInput | null > | null,
  industryTagList?: Array< TagInput | null > | null,
  sourceCodeUrl?: string | null,
  userGuideUrl?: string | null,
  pocName?: string | null,
  userScenarioDesc?: string | null,
  pocScope?: string | null,
  architectImgUrl?: string | null,
  architectDesc?: string | null,
  architectFilename?: string | null,
  attachments?: Array< PoCAttachmentInput | null > | null,
};

export enum OpptyStatus {
  TechnicalValidation = "TechnicalValidation",
  ClosedLost = "ClosedLost",
  Committed = "Committed",
  Launched = "Launched",
  Prospect = "Prospect",
  Qualified = "Qualified",
  Undefined = "Undefined",
}


export type PoCAttachmentInput = {
  attachmentFilename?: string | null,
  attachmentUrl?: string | null,
  attachmentDesc?: string | null,
  attachmentSize?: number | null,
};

export type PoCStatusResponse = {
  __typename: "PoCStatusResponse",
  status?: ResponseCode | null,
  pocId?: string | null,
};

export type ReviewTaskInput = {
  startDate?: string | null,
  endDate?: string | null,
  numberOfPoCs?: number | null,
};

export type ReviewTaskStatusResponse = {
  __typename: "ReviewTaskStatusResponse",
  status?: ResponseCode | null,
  reviewTaskId?: string | null,
  errMsg?: string | null,
};

export type SwaReviewTaskInput = {
  startDate?: string | null,
  endDate?: string | null,
  numberOfSWAs?: number | null,
};

export type WithdrawPoCInput = {
  pocId: string,
};

export type SWAInput = {
  swaId?: string | null,
  sfdcAccountId: string,
  opptyId?: string | null,
  swaSubject?: string | null,
  mode: SubmitMode,
  contributorId: string,
  efforts?: number | null,
  launchDate?: string | null,
  categoryInUseList?: Array< CategoryInput | null > | null,
  svcInUseList?: Array< SvcInput | null > | null,
  swaDesc?: string | null,
  customerBenefit?: string | null,
  linkUrl?: string | null,
  industryTagList?: Array< TagInput | null > | null,
  architectDesc?: string | null,
  architectDiagrams?: Array< ArchitectDiagramsInput | null > | null,
  attachments?: Array< PoCAttachmentInput | null > | null,
};

export type CategoryInput = {
  categoryId?: string | null,
  categoryName?: string | null,
};

export type SvcInput = {
  svcId?: string | null,
  categoryId?: string | null,
  svcName?: string | null,
};

export type ArchitectDiagramsInput = {
  architectFileName?: string | null,
  architectFileUrl?: string | null,
};

export type SWAStatusResponse = {
  __typename: "SWAStatusResponse",
  status?: ResponseCode | null,
  swaId?: string | null,
};

export type UseCaseInput = {
  mode: SubmitMode,
  useCaseId?: string | null,
  contributorId: string,
  useCaseName?: string | null,
  useCaseDesc?: string | null,
  sfdcAccountId?: string | null,
  svcInUseList?: Array< GroupSvcInput | null > | null,
  groupInUseList?: Array< GroupInput | null > | null,
  industryInUseList?: Array< IndustryInput | null > | null,
  architectDesc?: string | null,
  architectDiagrams?: Array< ArchitectDiagramsInput | null > | null,
  attachments?: Array< PoCAttachmentInput | null > | null,
};

export type GroupSvcInput = {
  svcId?: string | null,
  groupId?: string | null,
  svcName?: string | null,
};

export type GroupInput = {
  groupId?: string | null,
  categoryId?: string | null,
  groupName?: string | null,
};

export type IndustryInput = {
  industryId?: string | null,
  industryName?: string | null,
};

export type UseCaseStatusResponse = {
  __typename: "UseCaseStatusResponse",
  status?: ResponseCode | null,
  useCaseId?: string | null,
};

export type ResultInput = {
  useCaseId?: string | null,
  customerId?: string | null,
  customerName?: string | null,
  contributorId?: string | null,
  isInterested?: string | null,
  reason?: string | null,
  resultStatus: SubmitMode,
};

export type ResultResponse = {
  __typename: "ResultResponse",
  status?: ResponseCode | null,
  resultId?: string | null,
};

export type UseCaseStatusInput = {
  useCaseId: string,
  useCaseStatus: string,
};

export type Amazonian = {
  __typename: "Amazonian",
  userId?: string | null,
  name?: string | null,
  role?: string | null,
  supervisorId?: string | null,
  supervisorName?: string | null,
  managerId?: string | null,
  managerName?: string | null,
  teamName?: string | null,
  teamDetail?: string | null,
};

export type Svc = {
  __typename: "Svc",
  svcId?: string | null,
  categoryId?: string | null,
  svcName?: string | null,
};

export type AccountId = {
  __typename: "AccountId",
  sfdcAccountId?: string | null,
};

export type Category = {
  __typename: "Category",
  categoryId?: string | null,
  categoryName?: string | null,
};

export type TagList = {
  __typename: "TagList",
  technicalTagList?:  Array<Tag | null > | null,
  industryTagList?:  Array<Tag | null > | null,
};

export type Tag = {
  __typename: "Tag",
  tagId: string,
  tagName?: string | null,
};

export type PoCFilterInput = {
  assignee?: string | null,
  teamId?: string | null,
  pocIds?: Array< string | null > | null,
  opptyId?: string | null,
  orderBy?: string | null,
  pocStatus?: SubmitMode | null,
  reviewStatus?: ReviewStatus | null,
  callFromAssignedToMe?: boolean | null,
  bestPracticeStatus?: BestPracticeStatus | null,
  supervisorId?: string | null,
  managerId?: string | null,
  contributorId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  reviewTaskId?: string | null,
  curPage: number,
  pageSize: number,
};

export enum ReviewStatus {
  No = "No",
  Assigned = "Assigned",
  Overdue = "Overdue",
  Done = "Done",
  Undefined = "Undefined",
}


export enum BestPracticeStatus {
  No = "No",
  Nominated = "Nominated",
  Filled = "Filled",
  Yes = "Yes",
  Undefined = "Undefined",
}


export type PoCList = {
  __typename: "PoCList",
  totalCount?: number | null,
  pocs?:  Array<PoCAbstract | null > | null,
};

export type PoCAbstract = {
  __typename: "PoCAbstract",
  pocId: string,
  contributor?: Amazonian | null,
  assignee?: Amazonian | null,
  submitDateTime?: string | null,
  pocSubject?: string | null,
  totalScore?: number | null,
  reviewStatus?: ReviewStatus | null,
  bestPracticeStatus?: BestPracticeStatus | null,
  assignDateTime?: string | null,
  pocStatus?: SubmitMode | null,
  pocVersion?: string | null,
  efforts?: number | null,
  pocName?: string | null,
  opptyId?: string | null,
};

export type PoC = {
  __typename: "PoC",
  pocId: string,
  contributor?: Amazonian | null,
  submitDateTime?: string | null,
  // basic information
  sfdcAccountId?: string | null,
  sfdcAccountName?: string | null,
  sfdcAccountUrl?: string | null,
  opptyId?: string | null,
  opptyName?: string | null,
  opptyUrl?: string | null,
  opptyStatus?: OpptyStatus | null,
  territory?: string | null,
  pocSubject?: string | null,
  pocDesc?: string | null,
  deliverableUrl?: string | null,
  reviewStatus?: ReviewStatus | null,
  bestPracticeStatus?: BestPracticeStatus | null,
  pocStatus?: SubmitMode | null,
  pocVersion?: string | null,
  efforts?: number | null,
  nominatedBy?: string | null,
  nominationDateTime?: string | null,
  // best practice assets
  technicalTagList?:  Array<Tag | null > | null,
  industryTagList?:  Array<Tag | null > | null,
  sourceCodeUrl?: string | null,
  userGuideUrl?: string | null,
  pocName?: string | null,
  userScenarioDesc?: string | null,
  pocScope?: string | null,
  architectImgUrl?: string | null,
  architectDesc?: string | null,
  architectFilename?: string | null,
  attachments?:  Array<Attachment | null > | null,
  // review detail
  assigner?: Amazonian | null,
  assignee?: Amazonian | null,
  assignDateTime?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  attachmentId: string,
  attachmentFilename?: string | null,
  attachmentUrl?: string | null,
  attachmentDesc?: string | null,
  attachmentSize?: number | null,
  pocId: string,
};

export type ReviewResponse = {
  __typename: "ReviewResponse",
  pocId: string,
  submitDatetime?: string | null,
  isNecessary?: boolean | null,
  isRepeatable?: boolean | null,
  isPackageUp?: boolean | null,
  comments?: string | null,
  totalScore?: number | null,
  scoreRank?: string | null,
  scoreResList?:  Array<CriteriaScore | null > | null,
};

export type CriteriaScore = {
  __typename: "CriteriaScore",
  criteriaName?: CriteriaType | null,
  score?: number | null,
  comments?: string | null,
};

export type SWAReviewResponse = {
  __typename: "SWAReviewResponse",
  swaId: string,
  submitDatetime?: string | null,
  isPass?: boolean | null,
  comments?: string | null,
};

export type BestPracticeFiterInput = {
  orderBy?: string | null,
  pocPattern?: string | null,
  technicalTagList?: Array< TagInput | null > | null,
  industryTagList?: Array< TagInput | null > | null,
  curPage: number,
  pageSize: number,
};

export type BestPracticeList = {
  __typename: "BestPracticeList",
  totalCount?: number | null,
  bestPractices?:  Array<BestPracticeAbstract | null > | null,
};

export type BestPracticeAbstract = {
  __typename: "BestPracticeAbstract",
  pocId?: string | null,
  pocName?: string | null,
  contributorId?: string | null,
  contributorName?: string | null,
  submitDateTime?: string | null,
  pocDesc?: string | null,
  userScenarioDesc?: string | null,
  pocScope?: string | null,
  technicalTagList?:  Array<Tag | null > | null,
  industryTagList?:  Array<Tag | null > | null,
};

export type GsalConf = {
  __typename: "GsalConf",
  techTagLimit?: number | null,
  industryTagLimit?: number | null,
  firstLineManagerNominateCountPerMonth?: number | null,
};

export type ReviewTaskFilterInput = {
  curPage: number,
  pageSize: number,
};

export type ReviewTaskList = {
  __typename: "ReviewTaskList",
  totalCount?: number | null,
  tasks?:  Array<ReviewTaskAbstract | null > | null,
};

export type ReviewTaskAbstract = {
  __typename: "ReviewTaskAbstract",
  reviewTaskId: string,
  startDate?: string | null,
  endDate?: string | null,
  numberOfPoCs?: number | null,
  createBy?: Amazonian | null,
  createDateTime?: string | null,
  taskStatus?: string | null,
};

export type SWAReviewTaskList = {
  __typename: "SWAReviewTaskList",
  totalCount?: number | null,
  tasks?:  Array<SWAReviewTaskAbstract | null > | null,
};

export type SWAReviewTaskAbstract = {
  __typename: "SWAReviewTaskAbstract",
  reviewTaskId: string,
  startDate?: string | null,
  endDate?: string | null,
  numberOfSWAs?: number | null,
  createBy?: Amazonian | null,
  createDateTime?: string | null,
  taskStatus?: string | null,
};

export type ExportPoCsReportFilterInput = {
  bucketName: string,
  prefix: string,
  startDateTime: string,
  endDateTime: string,
};

export type ExportResponse = {
  __typename: "ExportResponse",
  status?: ResponseCode | null,
  url?: string | null,
};

export type PocsByAosInput = {
  pocIds?: Array< string | null > | null,
  keyword?: string | null,
  opptyId?: string | null,
};

export type PoCAosList = {
  __typename: "PoCAosList",
  totalCount?: number | null,
  pocs?:  Array<PoCByAosResponse | null > | null,
};

export type PoCByAosResponse = {
  __typename: "PoCByAosResponse",
  pocId?: string | null,
  pocName?: string | null,
  pocDesc?: string | null,
  userScenarioDesc?: string | null,
  architectDesc?: string | null,
};

export type SWAsByAosInput = {
  queryType: QueryType,
  swaIds?: Array< string | null > | null,
  keyword?: string | null,
  sfdcAccountId?: string | null,
};

export enum QueryType {
  POC = "POC",
  SWA = "SWA",
}


export type SWAAosList = {
  __typename: "SWAAosList",
  totalCount?: number | null,
  swas?:  Array<SWAByAosResponse | null > | null,
};

export type SWAByAosResponse = {
  __typename: "SWAByAosResponse",
  swaId?: string | null,
  swaSubject?: string | null,
  sfdcAccountId?: string | null,
  swaDesc?: string | null,
  customerBenefit?: string | null,
  architectDesc?: string | null,
};

export type CategoryList = {
  __typename: "CategoryList",
  totalCount?: number | null,
  categoryList?:  Array<Category | null > | null,
};

export type SvcList = {
  __typename: "SvcList",
  totalCount?: number | null,
  svcList?:  Array<Svc | null > | null,
};

export type SWAFilterInput = {
  assignee?: string | null,
  teamId?: string | null,
  reviewStatus?: ReviewStatus | null,
  callFromAssignedToMe?: boolean | null,
  orderBy?: string | null,
  supervisorId?: string | null,
  managerId?: string | null,
  contributorId?: Array< string | null > | null,
  svcId?: string | null,
  sfdcAccountId?: string | null,
  categoryId?: string | null,
  teamName?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  swaIds?: Array< string | null > | null,
  swaStatus?: SubmitMode | null,
  reviewTaskId?: string | null,
  curPage: number,
  pageSize: number,
};

export type SWAList = {
  __typename: "SWAList",
  totalCount?: number | null,
  swas?:  Array<SWAAbstract | null > | null,
};

export type SWAAbstract = {
  __typename: "SWAAbstract",
  swaId: string,
  contributor?: Amazonian | null,
  sfdcAccountId?: string | null,
  opptyId?: string | null,
  swaSubject?: string | null,
  architectDesc?: string | null,
  swaDesc?: string | null,
  customerBenefit?: string | null,
  swaStatus?: SubmitMode | null,
  launchDate?: string | null,
  efforts?: number | null,
  createDateTime?: string | null,
  submitDateTime?: string | null,
  updateDateTime?: string | null,
  linkUrl?: string | null,
  workload?:  Array<Workload | null > | null,
  architectDiagrams?:  Array<ArchitectureDiagram | null > | null,
  attachments?:  Array<SWAAttachment | null > | null,
  tagList?:  Array<Tag | null > | null,
  assigner?: Amazonian | null,
  assignee?: Amazonian | null,
  assignDateTime?: string | null,
  reviewStatus?: string | null,
  isPass?: boolean | null,
};

export type Workload = {
  __typename: "Workload",
  categoryId?: string | null,
  workload?: string | null,
  serviceList?:  Array<Svc | null > | null,
};

export type ArchitectureDiagram = {
  __typename: "ArchitectureDiagram",
  architectDiagramId: string,
  architectFilename?: string | null,
  architectFileUrl?: string | null,
};

export type SWAAttachment = {
  __typename: "SWAAttachment",
  attachmentId: string,
  attachmentFilename?: string | null,
  attachmentUrl?: string | null,
  attachmentDesc?: string | null,
  attachmentSize?: number | null,
  swaId: string,
};

export type ExportSWAsReportFilterInput = {
  bucketName: string,
  prefix: string,
  startDateTime: string,
  endDateTime: string,
};

export type ExportMissingSWAsReportFilterInput = {
  bucketName: string,
  prefix: string,
  startDateTime: string,
  endDateTime: string,
};

export type Industry = {
  __typename: "Industry",
  industryId?: string | null,
  industryName?: string | null,
};

export type Group = {
  __typename: "Group",
  groupId?: string | null,
  categoryId?: string | null,
  groupName?: string | null,
};

export type Campaign = {
  __typename: "Campaign",
  campaignId?: string | null,
  groupId?: string | null,
  campaignName?: string | null,
};

export type UseCaseAbstract = {
  __typename: "UseCaseAbstract",
  useCaseId: string,
  contributor?: Amazonian | null,
  useCaseName?: string | null,
  useCaseDesc?: string | null,
  sfdcAccountId?: string | null,
  architectDesc?: string | null,
  useCaseStatus?: SubmitMode | null,
  workload?:  Array<UseCaseWorkload | null > | null,
  industries?:  Array<Industry | null > | null,
  architectDiagrams?:  Array<ArchitectureDiagram | null > | null,
  attachments?:  Array<UseCaseAttachment | null > | null,
  createDateTime?: string | null,
  submitDateTime?: string | null,
  updateDateTime?: string | null,
};

export type UseCaseWorkload = {
  __typename: "UseCaseWorkload",
  groupId?: string | null,
  groupName?: string | null,
  serviceList?:  Array<GroupSvc | null > | null,
};

export type GroupSvc = {
  __typename: "GroupSvc",
  svcId?: string | null,
  groupId?: string | null,
  svcName?: string | null,
};

export type UseCaseAttachment = {
  __typename: "UseCaseAttachment",
  attachmentId: string,
  attachmentFilename?: string | null,
  attachmentUrl?: string | null,
  attachmentDesc?: string | null,
  attachmentSize?: number | null,
  useCaseId: string,
};

export type UseCaseFilterInput = {
  orderBy?: string | null,
  supervisorId?: string | null,
  managerId?: string | null,
  teamId?: string | null,
  teamName?: string | null,
  contributorIds?: Array< string | null > | null,
  useCaseIds?: Array< string | null > | null,
  useCaseStatus?: SubmitMode | null,
  startDate?: string | null,
  endDate?: string | null,
  curPage: number,
  pageSize: number,
};

export type UseCaseList = {
  __typename: "UseCaseList",
  totalCount?: number | null,
  useCases?:  Array<UseCaseAbstract | null > | null,
};

export type CustomersFilterInput = {
  groupId?: string | null,
  accountIds?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  curPage: number,
  pageSize: number,
};

export type CustomerList = {
  __typename: "CustomerList",
  totalCount?: number | null,
  customers?:  Array<CustomerAbstract | null > | null,
};

export type CustomerAbstract = {
  __typename: "CustomerAbstract",
  accountId?: string | null,
  accountName?: string | null,
  groupId?: string | null,
  territory?: string | null,
  createDateTime?: string | null,
};

export type CustomersByAosInput = {
  accountIds?: Array< string | null > | null,
  groupId?: string | null,
  keyword?: string | null,
};

export type CustomerAosList = {
  __typename: "CustomerAosList",
  totalCount?: number | null,
  customers?:  Array<CustomerByAosResponse | null > | null,
};

export type CustomerByAosResponse = {
  __typename: "CustomerByAosResponse",
  accountId?: string | null,
  accountName?: string | null,
  groupId?: string | null,
};

export type ResultFilterInput = {
  mode?: string | null,
  orderBy?: string | null,
  useCaseId?: string | null,
  contributorId?: string | null,
  teamId?: string | null,
  managerId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  curPage: number,
  pageSize: number,
};

export type ResultList = {
  __typename: "ResultList",
  totalCount?: number | null,
  results?:  Array<ResultAbstract | null > | null,
};

export type ResultAbstract = {
  __typename: "ResultAbstract",
  useCaseId?: string | null,
  customerId?: string | null,
  customerName?: string | null,
  contributorName?: string | null,
  contributorId?: string | null,
  isInterested?: EngageResult | null,
  reason?: string | null,
  createDateTime?: string | null,
  updateDateTime?: string | null,
};

export enum EngageResult {
  Yes = "Yes",
  No = "No",
}


export type CustomerEngagedFilterInput = {
  orderBy?: string | null,
  customerId?: string | null,
  contributorId?: string | null,
  teamId?: string | null,
  managerId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  curPage: number,
  pageSize: number,
};

export type EngageCustomerList = {
  __typename: "EngageCustomerList",
  totalCount?: number | null,
  results?:  Array<EngageCustomerAbstract | null > | null,
};

export type EngageCustomerAbstract = {
  __typename: "EngageCustomerAbstract",
  useCaseId?: string | null,
  useCaseName?: string | null,
  groupId?: string | null,
  groupName?: string | null,
  contributorId?: string | null,
  contributorName?: string | null,
  customerId?: string | null,
  customerName?: string | null,
  isInterested?: EngageResult | null,
  reason?: string | null,
  createDateTime?: string | null,
};

export type ExportResultsReportFilterInput = {
  bucketName: string,
  prefix: string,
  startDateTime: string,
  endDateTime: string,
};

export type EngagementInput = {
  useCaseId?: string | null,
  customerId?: string | null,
};

export type AssignReviewerMutationVariables = {
  input: ReviewerInput,
};

export type AssignReviewerMutation = {
  assignReviewer?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type AssignSWAReviewerMutationVariables = {
  input: SWAReviewerInput,
};

export type AssignSWAReviewerMutation = {
  assignSWAReviewer?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type NominatePoCMutationVariables = {
  input: NominateInput,
};

export type NominatePoCMutation = {
  nominatePoC?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: ReviewInput,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type UpdateSWAReviewMutationVariables = {
  input: SWAReviewInput,
};

export type UpdateSWAReviewMutation = {
  updateSWAReview?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type UpdateSWAStatusMutationVariables = {
  input: SWAStatusInput,
};

export type UpdateSWAStatusMutation = {
  updateSWAStatus?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type UpdateBestPracticeAssetsMutationVariables = {
  input: BestPracticeAssetsInput,
};

export type UpdateBestPracticeAssetsMutation = {
  updateBestPracticeAssets?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type SubmitPoCMutationVariables = {
  input: PoCInput,
};

export type SubmitPoCMutation = {
  submitPoC?:  {
    __typename: "PoCStatusResponse",
    status?: ResponseCode | null,
    pocId?: string | null,
  } | null,
};

export type SubmitGsalConfMutationVariables = {
  techTagLimit: number,
  industryTagLimit: number,
  firstLineManagerNominateCountPerMonth: number,
};

export type SubmitGsalConfMutation = {
  submitGsalConf?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type CreateReviewTaskMutationVariables = {
  input: ReviewTaskInput,
};

export type CreateReviewTaskMutation = {
  createReviewTask?:  {
    __typename: "ReviewTaskStatusResponse",
    status?: ResponseCode | null,
    reviewTaskId?: string | null,
    errMsg?: string | null,
  } | null,
};

export type CreateSwaReviewTaskMutationVariables = {
  input: SwaReviewTaskInput,
};

export type CreateSwaReviewTaskMutation = {
  createSwaReviewTask?:  {
    __typename: "ReviewTaskStatusResponse",
    status?: ResponseCode | null,
    reviewTaskId?: string | null,
    errMsg?: string | null,
  } | null,
};

export type WithdrawPoCMutationVariables = {
  input: WithdrawPoCInput,
};

export type WithdrawPoCMutation = {
  withdrawPoC?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type SubmitSWAMutationVariables = {
  input: SWAInput,
};

export type SubmitSWAMutation = {
  submitSWA?:  {
    __typename: "SWAStatusResponse",
    status?: ResponseCode | null,
    swaId?: string | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CategoryInput,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type CreateSvcMutationVariables = {
  input: SvcInput,
};

export type CreateSvcMutation = {
  createSvc?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type SubmitUseCaseMutationVariables = {
  input: UseCaseInput,
};

export type SubmitUseCaseMutation = {
  submitUseCase?:  {
    __typename: "UseCaseStatusResponse",
    status?: ResponseCode | null,
    useCaseId?: string | null,
  } | null,
};

export type SubmitInterestOrNotMutationVariables = {
  input: ResultInput,
};

export type SubmitInterestOrNotMutation = {
  submitInterestOrNot?:  {
    __typename: "ResultResponse",
    status?: ResponseCode | null,
    resultId?: string | null,
  } | null,
};

export type UpdateUseCaseStatusMutationVariables = {
  input: UseCaseStatusInput,
};

export type UpdateUseCaseStatusMutation = {
  updateUseCaseStatus?:  {
    __typename: "StatusResponse",
    status?: ResponseCode | null,
  } | null,
};

export type GetAssigneesQueryVariables = {
  role: UserRole,
  managerId: string,
};

export type GetAssigneesQuery = {
  getAssignees?:  Array< {
    __typename: "Amazonian",
    userId?: string | null,
    name?: string | null,
    role?: string | null,
    supervisorId?: string | null,
    supervisorName?: string | null,
    managerId?: string | null,
    managerName?: string | null,
    teamName?: string | null,
    teamDetail?: string | null,
  } | null > | null,
};

export type GetContributorsQueryVariables = {
  contributorName: string,
};

export type GetContributorsQuery = {
  getContributors?:  Array< {
    __typename: "Amazonian",
    userId?: string | null,
    name?: string | null,
    role?: string | null,
    supervisorId?: string | null,
    supervisorName?: string | null,
    managerId?: string | null,
    managerName?: string | null,
    teamName?: string | null,
    teamDetail?: string | null,
  } | null > | null,
};

export type GetServicesQuery = {
  getServices?:  Array< {
    __typename: "Svc",
    svcId?: string | null,
    categoryId?: string | null,
    svcName?: string | null,
  } | null > | null,
};

export type GetAccountIdsQueryVariables = {
  contributorId: string,
};

export type GetAccountIdsQuery = {
  getAccountIds?:  Array< {
    __typename: "AccountId",
    sfdcAccountId?: string | null,
  } | null > | null,
};

export type GetCategoriesQuery = {
  getCategories?:  Array< {
    __typename: "Category",
    categoryId?: string | null,
    categoryName?: string | null,
  } | null > | null,
};

export type GetAllTagsQuery = {
  getAllTags?:  {
    __typename: "TagList",
    technicalTagList?:  Array< {
      __typename: "Tag",
      tagId: string,
      tagName?: string | null,
    } | null > | null,
    industryTagList?:  Array< {
      __typename: "Tag",
      tagId: string,
      tagName?: string | null,
    } | null > | null,
  } | null,
};

export type GetAssignedToMeQueryVariables = {
  userId: string,
};

export type GetAssignedToMeQuery = {
  getAssignedToMe?: number | null,
};

export type GetSWAAssignedToMeQueryVariables = {
  userId: string,
};

export type GetSWAAssignedToMeQuery = {
  getSWAAssignedToMe?: number | null,
};

export type GetPoCsQueryVariables = {
  input: PoCFilterInput,
};

export type GetPoCsQuery = {
  getPoCs?:  {
    __typename: "PoCList",
    totalCount?: number | null,
    pocs?:  Array< {
      __typename: "PoCAbstract",
      pocId: string,
      contributor?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      assignee?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      submitDateTime?: string | null,
      pocSubject?: string | null,
      totalScore?: number | null,
      reviewStatus?: ReviewStatus | null,
      bestPracticeStatus?: BestPracticeStatus | null,
      assignDateTime?: string | null,
      pocStatus?: SubmitMode | null,
      pocVersion?: string | null,
      efforts?: number | null,
      pocName?: string | null,
      opptyId?: string | null,
    } | null > | null,
  } | null,
};

export type GetPoCByIdQueryVariables = {
  pocId: string,
};

export type GetPoCByIdQuery = {
  getPoCById?:  {
    __typename: "PoC",
    pocId: string,
    contributor?:  {
      __typename: "Amazonian",
      userId?: string | null,
      name?: string | null,
      role?: string | null,
      supervisorId?: string | null,
      supervisorName?: string | null,
      managerId?: string | null,
      managerName?: string | null,
      teamName?: string | null,
      teamDetail?: string | null,
    } | null,
    submitDateTime?: string | null,
    // basic information
    sfdcAccountId?: string | null,
    sfdcAccountName?: string | null,
    sfdcAccountUrl?: string | null,
    opptyId?: string | null,
    opptyName?: string | null,
    opptyUrl?: string | null,
    opptyStatus?: OpptyStatus | null,
    territory?: string | null,
    pocSubject?: string | null,
    pocDesc?: string | null,
    deliverableUrl?: string | null,
    reviewStatus?: ReviewStatus | null,
    bestPracticeStatus?: BestPracticeStatus | null,
    pocStatus?: SubmitMode | null,
    pocVersion?: string | null,
    efforts?: number | null,
    nominatedBy?: string | null,
    nominationDateTime?: string | null,
    // best practice assets
    technicalTagList?:  Array< {
      __typename: "Tag",
      tagId: string,
      tagName?: string | null,
    } | null > | null,
    industryTagList?:  Array< {
      __typename: "Tag",
      tagId: string,
      tagName?: string | null,
    } | null > | null,
    sourceCodeUrl?: string | null,
    userGuideUrl?: string | null,
    pocName?: string | null,
    userScenarioDesc?: string | null,
    pocScope?: string | null,
    architectImgUrl?: string | null,
    architectDesc?: string | null,
    architectFilename?: string | null,
    attachments?:  Array< {
      __typename: "Attachment",
      attachmentId: string,
      attachmentFilename?: string | null,
      attachmentUrl?: string | null,
      attachmentDesc?: string | null,
      attachmentSize?: number | null,
      pocId: string,
    } | null > | null,
    // review detail
    assigner?:  {
      __typename: "Amazonian",
      userId?: string | null,
      name?: string | null,
      role?: string | null,
      supervisorId?: string | null,
      supervisorName?: string | null,
      managerId?: string | null,
      managerName?: string | null,
      teamName?: string | null,
      teamDetail?: string | null,
    } | null,
    assignee?:  {
      __typename: "Amazonian",
      userId?: string | null,
      name?: string | null,
      role?: string | null,
      supervisorId?: string | null,
      supervisorName?: string | null,
      managerId?: string | null,
      managerName?: string | null,
      teamName?: string | null,
      teamDetail?: string | null,
    } | null,
    assignDateTime?: string | null,
  } | null,
};

export type GetPoCReviewByIdQueryVariables = {
  pocId: string,
};

export type GetPoCReviewByIdQuery = {
  getPoCReviewById?:  {
    __typename: "ReviewResponse",
    pocId: string,
    submitDatetime?: string | null,
    isNecessary?: boolean | null,
    isRepeatable?: boolean | null,
    isPackageUp?: boolean | null,
    comments?: string | null,
    totalScore?: number | null,
    scoreRank?: string | null,
    scoreResList?:  Array< {
      __typename: "CriteriaScore",
      criteriaName?: CriteriaType | null,
      score?: number | null,
      comments?: string | null,
    } | null > | null,
  } | null,
};

export type GetSWAReviewByIdQueryVariables = {
  swaId: string,
};

export type GetSWAReviewByIdQuery = {
  getSWAReviewById?:  {
    __typename: "SWAReviewResponse",
    swaId: string,
    submitDatetime?: string | null,
    isPass?: boolean | null,
    comments?: string | null,
  } | null,
};

export type GetPoCBestPracticeListQueryVariables = {
  input: BestPracticeFiterInput,
};

export type GetPoCBestPracticeListQuery = {
  getPoCBestPracticeList?:  {
    __typename: "BestPracticeList",
    totalCount?: number | null,
    bestPractices?:  Array< {
      __typename: "BestPracticeAbstract",
      pocId?: string | null,
      pocName?: string | null,
      contributorId?: string | null,
      contributorName?: string | null,
      submitDateTime?: string | null,
      pocDesc?: string | null,
      userScenarioDesc?: string | null,
      pocScope?: string | null,
      technicalTagList?:  Array< {
        __typename: "Tag",
        tagId: string,
        tagName?: string | null,
      } | null > | null,
      industryTagList?:  Array< {
        __typename: "Tag",
        tagId: string,
        tagName?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetGsalConfQuery = {
  getGsalConf?:  {
    __typename: "GsalConf",
    techTagLimit?: number | null,
    industryTagLimit?: number | null,
    firstLineManagerNominateCountPerMonth?: number | null,
  } | null,
};

export type GetCrossTeamReviewTasksQueryVariables = {
  input: ReviewTaskFilterInput,
};

export type GetCrossTeamReviewTasksQuery = {
  getCrossTeamReviewTasks?:  {
    __typename: "ReviewTaskList",
    totalCount?: number | null,
    tasks?:  Array< {
      __typename: "ReviewTaskAbstract",
      reviewTaskId: string,
      startDate?: string | null,
      endDate?: string | null,
      numberOfPoCs?: number | null,
      createBy?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      createDateTime?: string | null,
      taskStatus?: string | null,
    } | null > | null,
  } | null,
};

export type GetSWACrossTeamReviewTasksQueryVariables = {
  input: ReviewTaskFilterInput,
};

export type GetSWACrossTeamReviewTasksQuery = {
  getSWACrossTeamReviewTasks?:  {
    __typename: "SWAReviewTaskList",
    totalCount?: number | null,
    tasks?:  Array< {
      __typename: "SWAReviewTaskAbstract",
      reviewTaskId: string,
      startDate?: string | null,
      endDate?: string | null,
      numberOfSWAs?: number | null,
      createBy?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      createDateTime?: string | null,
      taskStatus?: string | null,
    } | null > | null,
  } | null,
};

export type ExportPoCsReportResolverQueryVariables = {
  input: ExportPoCsReportFilterInput,
};

export type ExportPoCsReportResolverQuery = {
  exportPoCsReportResolver?:  {
    __typename: "ExportResponse",
    status?: ResponseCode | null,
    url?: string | null,
  } | null,
};

export type GetPoCsByAosQueryVariables = {
  input: PocsByAosInput,
};

export type GetPoCsByAosQuery = {
  getPoCsByAos?:  {
    __typename: "PoCAosList",
    totalCount?: number | null,
    pocs?:  Array< {
      __typename: "PoCByAosResponse",
      pocId?: string | null,
      pocName?: string | null,
      pocDesc?: string | null,
      userScenarioDesc?: string | null,
      architectDesc?: string | null,
    } | null > | null,
  } | null,
};

export type GetSWAsByAosQueryVariables = {
  input: SWAsByAosInput,
};

export type GetSWAsByAosQuery = {
  getSWAsByAos?:  {
    __typename: "SWAAosList",
    totalCount?: number | null,
    swas?:  Array< {
      __typename: "SWAByAosResponse",
      swaId?: string | null,
      swaSubject?: string | null,
      sfdcAccountId?: string | null,
      swaDesc?: string | null,
      customerBenefit?: string | null,
      architectDesc?: string | null,
    } | null > | null,
  } | null,
};

export type QueryCategoryQueryVariables = {
  curPage: number,
  pageSize: number,
};

export type QueryCategoryQuery = {
  queryCategory?:  {
    __typename: "CategoryList",
    totalCount?: number | null,
    categoryList?:  Array< {
      __typename: "Category",
      categoryId?: string | null,
      categoryName?: string | null,
    } | null > | null,
  } | null,
};

export type QuerySvcQueryVariables = {
  categoryIds?: Array< string | null > | null,
  curPage: number,
  pageSize: number,
};

export type QuerySvcQuery = {
  querySvc?:  {
    __typename: "SvcList",
    totalCount?: number | null,
    svcList?:  Array< {
      __typename: "Svc",
      svcId?: string | null,
      categoryId?: string | null,
      svcName?: string | null,
    } | null > | null,
  } | null,
};

export type GetSWAsQueryVariables = {
  input: SWAFilterInput,
};

export type GetSWAsQuery = {
  getSWAs?:  {
    __typename: "SWAList",
    totalCount?: number | null,
    swas?:  Array< {
      __typename: "SWAAbstract",
      swaId: string,
      contributor?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      sfdcAccountId?: string | null,
      opptyId?: string | null,
      swaSubject?: string | null,
      architectDesc?: string | null,
      swaDesc?: string | null,
      customerBenefit?: string | null,
      swaStatus?: SubmitMode | null,
      launchDate?: string | null,
      efforts?: number | null,
      createDateTime?: string | null,
      submitDateTime?: string | null,
      updateDateTime?: string | null,
      linkUrl?: string | null,
      workload?:  Array< {
        __typename: "Workload",
        categoryId?: string | null,
        workload?: string | null,
        serviceList?:  Array< {
          __typename: "Svc",
          svcId?: string | null,
          categoryId?: string | null,
          svcName?: string | null,
        } | null > | null,
      } | null > | null,
      architectDiagrams?:  Array< {
        __typename: "ArchitectureDiagram",
        architectDiagramId: string,
        architectFilename?: string | null,
        architectFileUrl?: string | null,
      } | null > | null,
      attachments?:  Array< {
        __typename: "SWAAttachment",
        attachmentId: string,
        attachmentFilename?: string | null,
        attachmentUrl?: string | null,
        attachmentDesc?: string | null,
        attachmentSize?: number | null,
        swaId: string,
      } | null > | null,
      tagList?:  Array< {
        __typename: "Tag",
        tagId: string,
        tagName?: string | null,
      } | null > | null,
      assigner?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      assignee?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      assignDateTime?: string | null,
      reviewStatus?: string | null,
      isPass?: boolean | null,
    } | null > | null,
  } | null,
};

export type GetSWAByIdQueryVariables = {
  swaId: string,
};

export type GetSWAByIdQuery = {
  getSWAById?:  {
    __typename: "SWAAbstract",
    swaId: string,
    contributor?:  {
      __typename: "Amazonian",
      userId?: string | null,
      name?: string | null,
      role?: string | null,
      supervisorId?: string | null,
      supervisorName?: string | null,
      managerId?: string | null,
      managerName?: string | null,
      teamName?: string | null,
      teamDetail?: string | null,
    } | null,
    sfdcAccountId?: string | null,
    opptyId?: string | null,
    swaSubject?: string | null,
    architectDesc?: string | null,
    swaDesc?: string | null,
    customerBenefit?: string | null,
    swaStatus?: SubmitMode | null,
    launchDate?: string | null,
    efforts?: number | null,
    createDateTime?: string | null,
    submitDateTime?: string | null,
    updateDateTime?: string | null,
    linkUrl?: string | null,
    workload?:  Array< {
      __typename: "Workload",
      categoryId?: string | null,
      workload?: string | null,
      serviceList?:  Array< {
        __typename: "Svc",
        svcId?: string | null,
        categoryId?: string | null,
        svcName?: string | null,
      } | null > | null,
    } | null > | null,
    architectDiagrams?:  Array< {
      __typename: "ArchitectureDiagram",
      architectDiagramId: string,
      architectFilename?: string | null,
      architectFileUrl?: string | null,
    } | null > | null,
    attachments?:  Array< {
      __typename: "SWAAttachment",
      attachmentId: string,
      attachmentFilename?: string | null,
      attachmentUrl?: string | null,
      attachmentDesc?: string | null,
      attachmentSize?: number | null,
      swaId: string,
    } | null > | null,
    tagList?:  Array< {
      __typename: "Tag",
      tagId: string,
      tagName?: string | null,
    } | null > | null,
    assigner?:  {
      __typename: "Amazonian",
      userId?: string | null,
      name?: string | null,
      role?: string | null,
      supervisorId?: string | null,
      supervisorName?: string | null,
      managerId?: string | null,
      managerName?: string | null,
      teamName?: string | null,
      teamDetail?: string | null,
    } | null,
    assignee?:  {
      __typename: "Amazonian",
      userId?: string | null,
      name?: string | null,
      role?: string | null,
      supervisorId?: string | null,
      supervisorName?: string | null,
      managerId?: string | null,
      managerName?: string | null,
      teamName?: string | null,
      teamDetail?: string | null,
    } | null,
    assignDateTime?: string | null,
    reviewStatus?: string | null,
    isPass?: boolean | null,
  } | null,
};

export type ExportSWAsReportResolverQueryVariables = {
  input: ExportSWAsReportFilterInput,
};

export type ExportSWAsReportResolverQuery = {
  exportSWAsReportResolver?:  {
    __typename: "ExportResponse",
    status?: ResponseCode | null,
    url?: string | null,
  } | null,
};

export type ExportMissingSWAsReportResolverQueryVariables = {
  input: ExportMissingSWAsReportFilterInput,
};

export type ExportMissingSWAsReportResolverQuery = {
  exportMissingSWAsReportResolver?:  {
    __typename: "ExportResponse",
    status?: ResponseCode | null,
    url?: string | null,
  } | null,
};

export type GetIndustriesQuery = {
  getIndustries?:  Array< {
    __typename: "Industry",
    industryId?: string | null,
    industryName?: string | null,
  } | null > | null,
};

export type GetGroupsQueryVariables = {
  categoryId?: string | null,
};

export type GetGroupsQuery = {
  getGroups?:  Array< {
    __typename: "Group",
    groupId?: string | null,
    categoryId?: string | null,
    groupName?: string | null,
  } | null > | null,
};

export type GetCampaignsQueryVariables = {
  groupIds?: Array< string | null > | null,
};

export type GetCampaignsQuery = {
  getCampaigns?:  Array< {
    __typename: "Campaign",
    campaignId?: string | null,
    groupId?: string | null,
    campaignName?: string | null,
  } | null > | null,
};

export type GetUseCaseByIdQueryVariables = {
  useCaseId: string,
};

export type GetUseCaseByIdQuery = {
  getUseCaseById?:  {
    __typename: "UseCaseAbstract",
    useCaseId: string,
    contributor?:  {
      __typename: "Amazonian",
      userId?: string | null,
      name?: string | null,
      role?: string | null,
      supervisorId?: string | null,
      supervisorName?: string | null,
      managerId?: string | null,
      managerName?: string | null,
      teamName?: string | null,
      teamDetail?: string | null,
    } | null,
    useCaseName?: string | null,
    useCaseDesc?: string | null,
    sfdcAccountId?: string | null,
    architectDesc?: string | null,
    useCaseStatus?: SubmitMode | null,
    workload?:  Array< {
      __typename: "UseCaseWorkload",
      groupId?: string | null,
      groupName?: string | null,
      serviceList?:  Array< {
        __typename: "GroupSvc",
        svcId?: string | null,
        groupId?: string | null,
        svcName?: string | null,
      } | null > | null,
    } | null > | null,
    industries?:  Array< {
      __typename: "Industry",
      industryId?: string | null,
      industryName?: string | null,
    } | null > | null,
    architectDiagrams?:  Array< {
      __typename: "ArchitectureDiagram",
      architectDiagramId: string,
      architectFilename?: string | null,
      architectFileUrl?: string | null,
    } | null > | null,
    attachments?:  Array< {
      __typename: "UseCaseAttachment",
      attachmentId: string,
      attachmentFilename?: string | null,
      attachmentUrl?: string | null,
      attachmentDesc?: string | null,
      attachmentSize?: number | null,
      useCaseId: string,
    } | null > | null,
    createDateTime?: string | null,
    submitDateTime?: string | null,
    updateDateTime?: string | null,
  } | null,
};

export type GetUseCasesQueryVariables = {
  input: UseCaseFilterInput,
};

export type GetUseCasesQuery = {
  getUseCases?:  {
    __typename: "UseCaseList",
    totalCount?: number | null,
    useCases?:  Array< {
      __typename: "UseCaseAbstract",
      useCaseId: string,
      contributor?:  {
        __typename: "Amazonian",
        userId?: string | null,
        name?: string | null,
        role?: string | null,
        supervisorId?: string | null,
        supervisorName?: string | null,
        managerId?: string | null,
        managerName?: string | null,
        teamName?: string | null,
        teamDetail?: string | null,
      } | null,
      useCaseName?: string | null,
      useCaseDesc?: string | null,
      sfdcAccountId?: string | null,
      architectDesc?: string | null,
      useCaseStatus?: SubmitMode | null,
      workload?:  Array< {
        __typename: "UseCaseWorkload",
        groupId?: string | null,
        groupName?: string | null,
        serviceList?:  Array< {
          __typename: "GroupSvc",
          svcId?: string | null,
          groupId?: string | null,
          svcName?: string | null,
        } | null > | null,
      } | null > | null,
      industries?:  Array< {
        __typename: "Industry",
        industryId?: string | null,
        industryName?: string | null,
      } | null > | null,
      architectDiagrams?:  Array< {
        __typename: "ArchitectureDiagram",
        architectDiagramId: string,
        architectFilename?: string | null,
        architectFileUrl?: string | null,
      } | null > | null,
      attachments?:  Array< {
        __typename: "UseCaseAttachment",
        attachmentId: string,
        attachmentFilename?: string | null,
        attachmentUrl?: string | null,
        attachmentDesc?: string | null,
        attachmentSize?: number | null,
        useCaseId: string,
      } | null > | null,
      createDateTime?: string | null,
      submitDateTime?: string | null,
      updateDateTime?: string | null,
    } | null > | null,
  } | null,
};

export type GetCustomersQueryVariables = {
  input: CustomersFilterInput,
};

export type GetCustomersQuery = {
  getCustomers?:  {
    __typename: "CustomerList",
    totalCount?: number | null,
    customers?:  Array< {
      __typename: "CustomerAbstract",
      accountId?: string | null,
      accountName?: string | null,
      groupId?: string | null,
      territory?: string | null,
      createDateTime?: string | null,
    } | null > | null,
  } | null,
};

export type GetCustomersByAosQueryVariables = {
  input?: CustomersByAosInput | null,
};

export type GetCustomersByAosQuery = {
  getCustomersByAos?:  {
    __typename: "CustomerAosList",
    totalCount?: number | null,
    customers?:  Array< {
      __typename: "CustomerByAosResponse",
      accountId?: string | null,
      accountName?: string | null,
      groupId?: string | null,
    } | null > | null,
  } | null,
};

export type GetInterestResultsByUseCaseIdQueryVariables = {
  input?: ResultFilterInput | null,
};

export type GetInterestResultsByUseCaseIdQuery = {
  getInterestResultsByUseCaseId?:  {
    __typename: "ResultList",
    totalCount?: number | null,
    results?:  Array< {
      __typename: "ResultAbstract",
      useCaseId?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      contributorName?: string | null,
      contributorId?: string | null,
      isInterested?: EngageResult | null,
      reason?: string | null,
      createDateTime?: string | null,
      updateDateTime?: string | null,
    } | null > | null,
  } | null,
};

export type GetEngagedCustomersQueryVariables = {
  input?: CustomerEngagedFilterInput | null,
};

export type GetEngagedCustomersQuery = {
  getEngagedCustomers?:  {
    __typename: "EngageCustomerList",
    totalCount?: number | null,
    results?:  Array< {
      __typename: "EngageCustomerAbstract",
      useCaseId?: string | null,
      useCaseName?: string | null,
      groupId?: string | null,
      groupName?: string | null,
      contributorId?: string | null,
      contributorName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      isInterested?: EngageResult | null,
      reason?: string | null,
      createDateTime?: string | null,
    } | null > | null,
  } | null,
};

export type ExportResultReportResolverQueryVariables = {
  input: ExportResultsReportFilterInput,
};

export type ExportResultReportResolverQuery = {
  exportResultReportResolver?:  {
    __typename: "ExportResponse",
    status?: ResponseCode | null,
    url?: string | null,
  } | null,
};

export type GetEngagementByIdQueryVariables = {
  input?: EngagementInput | null,
};

export type GetEngagementByIdQuery = {
  getEngagementById?:  {
    __typename: "ResultAbstract",
    useCaseId?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    contributorName?: string | null,
    contributorId?: string | null,
    isInterested?: EngageResult | null,
    reason?: string | null,
    createDateTime?: string | null,
    updateDateTime?: string | null,
  } | null,
};
