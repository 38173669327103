import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { Spinner } from "@amzn/awsui-components-react";

interface ArchImageProps {
  s3Key: string;
}

const ArchImage: React.FC<ArchImageProps> = (props: ArchImageProps) => {
  const {s3Key} = props;
  const [loadingImage, setLoadingImage] = useState(false);
  const [archImageUrl, setArchImageUrl] = useState("");

  useEffect(() => {
    if (s3Key) {
      setLoadingImage(true);
      Storage.get(s3Key, {expires: 300}).then((res) => {
        console.info("showshowshowshowres:", res);
        setArchImageUrl(res.toString());
        setLoadingImage(false);
      });
    }
  }, [s3Key]);

  return (
      <div>
        {loadingImage ? (
            <Spinner/>
        ) : (
            <img
                onLoad={() => {
                  // setShowArchUploadProgress(false);
                }}
                alt=""
                width="100%"
                src={archImageUrl}
            />
        )}
      </div>
  );
};

export default ArchImage;
