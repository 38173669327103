import React, { useCallback, useEffect, useState } from 'react';
import {
  AppLayout,
  Box,
  Button,
  CollectionPreferences,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';

import { CTR_POC_LIST_PAGE_SIZE_ID, CTR_POC_LIST_VISIBLE_COLUMNS_ID, SIDE_MENU_IS_OPEN } from 'assets/js/const';
import BreadCrumb from '../../common/BreadCrumb';
import SideNav from '../../common/SideNav';
import { RouteComponentProps } from 'react-router-dom';
import { ReviewStatus, SWAAbstract } from '../../API';
import { appSyncRequest } from '../../assets/js/request';
import { getSWAs } from 'graphql/queries';
import { format } from 'date-fns';
import PoCReviewStatus from '../comps/PoCReviewStatus'
import { pocPageConfig } from '../swa/pocListConfig';
import { pocVisibleConfig } from '../poc/pocListConfig'
import SWAReviewResult from '../comps/SWAReviewResult'

interface CrossTeamReviewSWAsContentProps {
  ctReviewId: string;
}

const CorssTeamReviewSWAsContent: React.FC<CrossTeamReviewSWAsContentProps> = (
    props: CrossTeamReviewSWAsContentProps
) => {
  const {ctReviewId} = props;
  const [loadingData, setLoadingData] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [swaList, setSWAList] = useState<SWAAbstract[]>([]);

  // Pagination
  const [pageSize, setPageSize] = useState(
      parseInt(localStorage.getItem(CTR_POC_LIST_PAGE_SIZE_ID) || "10") || 10
  );

  const [visibleColumnsList, setVisibleColumnsList] = useState<any>(
      localStorage.getItem(CTR_POC_LIST_VISIBLE_COLUMNS_ID)?.split(",") || [
        "swaId",
        "contributor",
        "managerName",
        "subject",
        "submitDateTime",
        "reviewer",
        "reviewStatus",
        "isPass",
      ]
  );

  const getCrossTeamSWAList = useCallback(async () => {
    try {
      setSWAList([]);
      setLoadingData(true);
      const swaListDta: any = await appSyncRequest(getSWAs, {
        input: {
          reviewTaskId: ctReviewId,
          curPage: curPage,
          pageSize: pageSize,
        },
      });
      setSWAList(swaListDta?.data?.getSWAs?.swas || []);
      setTotalCount(swaListDta?.data?.getSWAs?.totalCount || 0);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage]);

  useEffect(() => {
    getCrossTeamSWAList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCrossTeamSWAList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize]);

  return (
      <div>
        <Table
            trackBy="swaId"
            loading={loadingData}
            stickyHeader={true}
            resizableColumns={true}
            columnDefinitions={[
              {
                minWidth: 200,
                id: "swaId",
                header: "SWA ID",
                cell: (item) => (
                    <Link
                        href={`/admin/swa-create-cross-team-review/${ctReviewId}/swa/${item.swaId}`}
                    >
                      {item.swaId}
                    </Link>
                ),
              },
              {
                id: "contributor",
                header: "SA",
                cell: (e) => e.contributor?.name,
              },
              {
                id: "managerName",
                header: "Manager",
                cell: (e) => e.contributor?.managerName,
              },
              {
                id: "subject",
                header: "Subject",
                cell: (e) => e.swaSubject,
              },
              {
                id: "submitDateTime",
                header: "Created",
                width: 120,
                cell: (e) => {
                  return e.submitDateTime
                      ? format(
                          new Date(e.submitDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
              {
                id: "reviewer",
                header: "Reviewer",
                cell: (e) => e.assignee?.name,
              },
              {
                id: "assignDateTime",
                header: "Assigned",
                width: 120,
                cell: (e) => {
                  return e.assignDateTime
                      ? format(
                          new Date(e.assignDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
              {
                id: "reviewStatus",
                header: "Review",
                width: 120,
                cell: (e) => (
                    <PoCReviewStatus
                        reviewStatus={e.reviewStatus || ReviewStatus.No}
                    />
                ),
              },
              {
                id: "isPass",
                header: "IsPass",
                width: 120,
                cell: (e) => (
                    <SWAReviewResult
                        isPass={e.isPass === true}
                    />
                ),
              },
            ]}
            items={swaList}
            loadingText="Loading SWAs"
            visibleColumns={visibleColumnsList}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No PoCs</b>
                <Box padding={{bottom: "s"}} variant="p" color="inherit">
                  No SWAs to display.
                </Box>
              </Box>
            }
            header={
              <Header
                  actions={
                    <SpaceBetween size="xs" direction="horizontal">
                      <Button
                          loading={loadingData}
                          disabled={loadingData}
                          iconName="refresh"
                          onClick={() => {
                            if (curPage === 1) {
                              getCrossTeamSWAList();
                            } else {
                              setCurPage(1);
                            }
                          }}
                      ></Button>
                    </SpaceBetween>
                  }
              >
                Cross-team reviews
              </Header>
            }
            preferences={
              <CollectionPreferences
                  title="Preferences"
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
                  preferences={{
                    pageSize: pageSize,
                    visibleContent: visibleColumnsList,
                  }}
                  onConfirm={(event) => {
                    localStorage.setItem(
                        CTR_POC_LIST_VISIBLE_COLUMNS_ID,
                        event.detail.visibleContent?.join(",") || ""
                    );
                    localStorage.setItem(
                        CTR_POC_LIST_PAGE_SIZE_ID,
                        event.detail.pageSize?.toString() || "10"
                    );
                    setCurPage(1);
                    setPageSize(event.detail.pageSize || 10);
                    setVisibleColumnsList(event.detail.visibleContent);
                  }}
                  pageSizePreference={pocPageConfig}
                  visibleContentPreference={pocVisibleConfig}
              />
            }
            pagination={
              <Pagination
                  currentPageIndex={curPage}
                  pagesCount={Math.ceil(totalCount / pageSize)}
                  onChange={(event) => {
                    setCurPage(event.detail.currentPageIndex);
                  }}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                  }}
              />
            }
        />
      </div>
  );
};

interface MatchParams {
  id: string;
}

const CrossTeamReviewSWAs: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const id = props.match.params.id;
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const swaReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Cross-team reviews(SWA)",
      href: "/admin/swa-cross-team-review",
    },
    {
      text: id.toUpperCase(),
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={swaReviewBreadcrumbs}/>}
          content={<CorssTeamReviewSWAsContent ctReviewId={id}/>}
          toolsOpen={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav defaultActiveHref="/admin/swa-cross-team-review"/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CrossTeamReviewSWAs;




