import React from "react";
import { Box } from "@amzn/awsui-components-react/polaris";

interface ValueWithLabelProps {
  label: string;
  children: any;
}

const ValueWithLabel: React.FC<ValueWithLabelProps> = (
    props: ValueWithLabelProps
) => {
  return (
      <div>
        <Box margin={{bottom: "xxxs"}} color="text-label">
          {props.label}
        </Box>
        <div className="word-break">{props.children}</div>
      </div>
  );
};

export default ValueWithLabel;
