import React from "react";

import { Container, Header } from "@amzn/awsui-components-react/polaris";

import { CriteriaScore, ReviewResponse } from "API";
import ScoreItem from "./ScoreItem";
import { PoCScoreList } from "assets/js/const";

interface StepScoreProps {
  setHelpPanel: (item: any) => void;
  currentReview: ReviewResponse | undefined;
  changeReviewScore: (item: any) => void;
  setLengthError: (isError: boolean) => void;
  showScoreError: boolean;
  pocId: string;
}

const StepScore: React.FC<StepScoreProps> = (props: StepScoreProps) => {
  const {
    currentReview,
    showScoreError,
    setLengthError,
    changeReviewScore,
    setHelpPanel,
  } = props;

  return (
      <div className="mt-10">
        <Container header={<Header variant="h2">PoC Score</Header>}>
          <div>
            <div>
              {PoCScoreList.map((scoreItem, index) => {
                return (
                    <ScoreItem
                        showScoreError={showScoreError}
                        setLengthError={(isError) => {
                          setLengthError(isError);
                        }}
                        clickItemTitleInfo={(item) => {
                          setHelpPanel(item);
                        }}
                        key={index}
                        changeScore={(score) => {
                          if (currentReview) {
                            const tmpData = JSON.parse(JSON.stringify(currentReview));
                            const idx = tmpData.scoreResList.findIndex(
                                (element: CriteriaScore) => {
                                  return element.criteriaName === scoreItem;
                                }
                            );
                            tmpData.scoreResList[idx]["score"] = score;
                            changeReviewScore(tmpData);
                          }
                        }}
                        changeComment={(comment) => {
                          if (currentReview) {
                            const tmpData = JSON.parse(JSON.stringify(currentReview));
                            const idx = tmpData.scoreResList.findIndex(
                                (element: CriteriaScore) => {
                                  return element.criteriaName === scoreItem;
                                }
                            );
                            tmpData.scoreResList[idx]["comments"] = comment;
                            changeReviewScore(tmpData);
                          }
                        }}
                        scoreData={currentReview?.scoreResList?.find((element) => {
                          return element?.criteriaName === scoreItem;
                        })}
                        title={scoreItem}
                    />
                );
              })}
            </div>
          </div>
        </Container>
      </div>
  );
};

export default StepScore;
