import React, { useContext, useEffect, useState } from "react";
import { DateRangePicker, PropertyFilter, PropertyFilterProps, } from "@amzn/awsui-components-react/polaris";
import { appSyncRequest } from "assets/js/request";
import { getAssignees, getContributors, getPoCsByAos } from "graphql/queries";

import AppContext from "context/AppContext";
import {
  AdminPocMutilFilterList,
  ContributorPocMutilFilterList,
  MultiSearchI18NString,
  PoCMultiFilterBPStatus,
  PoCMultiFilterReviewStatus,
  TimePickI18NString,
} from "assets/js/const";
import { converAmazonianToFilter, convertToAbsoluteRange, isValidRangeFunction, } from "assets/js/utils";
import { UserRole } from "API";
import { PageType } from "types";

export interface FilterTypes {
  bestPracticeStatus?: string;
  reviewStatus?: string;
  supervisorId?: string;
  contributorId?: string;
  managerId?: string;
  pocIds?: string;
}

export interface TimeRangeTypes {
  startTime: any;
  endTime: any;
}

interface AdvancedFilterProps {
  pageType: string;
  changeFilters: (filters: FilterTypes) => void;
  changeTimeRange: (time: TimeRangeTypes) => void;
  setStartLoading: (loading: boolean) => void;
}

const AdvancedFilter: React.FC<AdvancedFilterProps> = ({
                                                         pageType,
                                                         changeFilters,
                                                         changeTimeRange,
                                                         setStartLoading,
                                                       }: AdvancedFilterProps) => {
  const appConfig = useContext(AppContext);
  const [timeRange, setTimeRange] = useState<any>();
  const [query, setQuery] = useState<any>({
    tokens: [],
    operation: "and",
  });
  const [managerList, setManagerList] = useState([]);
  const [contributorList, setContributorList] = useState([]);
  // const [freeText, setfreeText] = useState("");

  // Get All Manager List
  const getManagerList = async () => {
    try {
      if (appConfig && appConfig.userRole) {
        const assigneeList: any = await appSyncRequest(getAssignees, {
          role: "Administrator",
          managerId: "",
        });
        setManagerList(assigneeList?.data?.getAssignees || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get All Contributer List
  const getContributorList = async () => {
    try {
      if (appConfig && appConfig.userRole) {
        const constributerList: any = await appSyncRequest(getContributors, {
          contributorName: "",
        });
        setContributorList(constributerList?.data?.getContributors || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getManagerList();
    getContributorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertArrayToObject = (array: any, key: string) =>
      array.reduce(
          (obj: any, item: any) => ({
            ...obj,
            [item[key]]: item,
          }),
          {}
      );

  const getValueByKey = (list: any, key: string) => {
    const obj = convertArrayToObject(list, "propertyKey");
    return obj[key]?.value || "";
  };

  const changeSearchInputValue = (detail: PropertyFilterProps.Query) => {
    console.info("detail:", detail);
    // Make Every Condition Only One
    let bpValue = "";
    let reviewValue = "";
    let contributorValue = "";
    let managerValue = "";
    if (detail.tokens) {
      bpValue = getValueByKey(detail.tokens, "bestPracticeStatus");
      reviewValue = getValueByKey(detail.tokens, "reviewStatus");
      contributorValue = getValueByKey(detail.tokens, "contributorId");
      managerValue = getValueByKey(detail.tokens, "managerId");
    }

    let enteredText = "";
    if (detail.tokens) {
      detail.tokens.forEach((element) => {
        if (!element.propertyKey && element.value) {
          enteredText = element.value;
        }
      });
    }

    const searchCondition: any = {
      operation: "and",
      tokens: [],
    };
    if (bpValue) {
      searchCondition.tokens.push({
        propertyKey: "bestPracticeStatus",
        operator: "=",
        value: bpValue,
      });
    }
    if (reviewValue) {
      searchCondition.tokens.push({
        propertyKey: "reviewStatus",
        operator: "=",
        value: reviewValue,
      });
    }
    if (managerValue) {
      searchCondition.tokens.push({
        propertyKey: "managerId",
        operator: "=",
        value: managerValue,
      });
    }
    if (contributorValue) {
      searchCondition.tokens.push({
        propertyKey: "contributorId",
        operator: "=",
        value: contributorValue,
      });
    }
    if (enteredText) {
      searchCondition.tokens.push({
        propertyKey: "",
        operator: ":",
        value: enteredText,
      });
    }
    if (enteredText) {
      setStartLoading(true);
      changeHandler(enteredText);
    } else {
      changeFilters({
        bestPracticeStatus: bpValue,
        reviewStatus: reviewValue,
        contributorId: contributorValue,
        managerId: managerValue,
      });
    }
    setQuery(searchCondition);
  };

  const changeHandler = async (text: string) => {
    console.info("freeText:", text);
    const aosRes: any = await appSyncRequest(getPoCsByAos, {
      input: {
        keyword: text,
      },
    });
    console.info("aosRes:", aosRes);
    changeFilters({
      // pocIds: aosRes?.data?.getPoCsByAos?.pocs?.[0]?.pocId || "",
      pocIds:
          aosRes?.data?.getPoCsByAos?.pocs
              ?.map((item: any) => item.pocId)
              .join(",") || "",
    });
  };

  return (
      <div className="search-action flex flex-row">
        <div className="poc-search flex-1">
          <PropertyFilter
              onChange={({detail}) => {
                changeSearchInputValue(detail);
              }}
              query={query}
              hideOperations={true}
              i18nStrings={MultiSearchI18NString}
              filteringOptions={[
                ...PoCMultiFilterBPStatus,
                ...PoCMultiFilterReviewStatus,
                ...converAmazonianToFilter(managerList, "managerId"),
                ...converAmazonianToFilter(contributorList, "contributorId"),
              ]}
              filteringProperties={
                pageType === PageType.ALL_POC
                    ? AdminPocMutilFilterList
                    : appConfig.userRole === UserRole.Contributor
                        ? ContributorPocMutilFilterList
                        : AdminPocMutilFilterList
              }
          />
        </div>
        <div className="date-range">
          <DateRangePicker
              locale="en-US"
              isDateEnabled={(time) => {
                if (new Date().getTime() >= new Date(time).getTime()) {
                  return true;
                } else {
                  return false;
                }
              }}
              isValidRange={isValidRangeFunction}
              onChange={(event: any) => {
                console.info("event:", event);
                if (!event.detail.value) {
                  changeTimeRange({
                    startTime: "",
                    endTime: "",
                  });
                } else {
                  if (event.detail?.value?.type === "absolute") {
                    changeTimeRange({
                      startTime: event.detail.value.startDate,
                      endTime: event.detail.value.endDate,
                    });
                  } else {
                    const relativeTime = convertToAbsoluteRange(event.detail.value);
                    changeTimeRange({
                      startTime: relativeTime.start,
                      endTime: relativeTime.end,
                    });
                  }
                }
                setTimeRange(event.detail.value);
              }}
              value={timeRange}
              relativeOptions={[
                {
                  key: "previous-24-hours",
                  amount: 24,
                  unit: "hour",
                  type: "relative",
                },
                {
                  key: "previous-1-week",
                  amount: 7,
                  unit: "day",
                  type: "relative",
                },
                {
                  key: "previous-30-days",
                  amount: 30,
                  unit: "day",
                  type: "relative",
                },
              ]}
              i18nStrings={TimePickI18NString}
              placeholder="Filter by created date range"
          />
        </div>
      </div>
  );
};

export default AdvancedFilter;
