import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, } from "react-router-dom";
import { Button, Link, Spinner } from "@amzn/awsui-components-react";
import Amplify, { Auth, DataStore, Hub } from "aws-amplify";
import Axios from "axios";

import Authentication from "common/Authentication";
import Header from "common/Header";
import PocList from "./pages/poc/PocList";
import PocDetail from "./pages/detail/PocDetail";
import PocReview from "./pages/detail/sub/PocReview";
// import PocAssets from "./pages/detail/sub/PocAssets";
import SWAList from "./pages/swa/SWAList";

import BestPractice from "./pages/library/BestPractice";
import BestPracticeDetail from "pages/library/BestPracticeDetail";
import { AppContextType } from "types";

import AppContext from "context/AppContext";
import { UserRole } from "API";
import { AMPLIFY_CONFIG_JSON, GSAL_VERSION, USER_REDIRECT_URL, } from "assets/js/const";
import CreatePoC from "pages/poc/create/CreatePoC";
import EditPoC from "pages/poc/edit/EditPoC";
import ReportDownload from "pages/admin/ReportDownload";
import CrossTeamReview from "pages/admin/CrossTeamReview";
import SWACrossTeamReview from 'pages/admin/SWACrossTeamReview'
import SWACreateCrossTeamReview from 'pages/admin/SWACreateCrossTeamReview'
import CrossTeamReviewSWAs from 'pages/admin/CrossTeamReviewSWAs'
import SystemSettings from "pages/admin/SystemSettings";
import CreateCrossTeamReview from "pages/admin/CreateCrossTeamReview";
import CrossTeamReviewPoCs from "pages/admin/CrossTeamReviewPoCs";
import CrossTeamPocDetail from "pages/admin/CrossTeamPocDetail";
import CreateSWA from "pages/swa/create/CreateSWA";
import SWADetail from "pages/detail/SWADetail";
import EditSWA from "pages/swa/edit/EditSWA";
import crossTeamSWADetail from './pages/admin/CrossTeamSWADetail';
import SWAReview from './pages/detail/sub/SWAReview';
import CreateUseCase from './pages/usecase/create/CreateUseCase';
import UseCaseList from './pages/usecase/UseCaseList'
import UseCaseDetail from './pages/detail/UseCaseDetail'
import EditUseCase from './pages/usecase/edit/EditUseCase'
import CustomerList from './pages/usecase/CustomerList'
import CustomerEngage from './pages/usecase/create/CustomerEngage'
import EngagedCustomerList from './pages/usecase/EngagedCustomerList'

interface AppProps {
  oidcProviderName: string;
}

const RedirectMap: any = {
  Administrator: "/all-poc",
  Manager: "/assign-to-me",
  Reviewer: "/assign-to-me",
  Contributor: "/stars/best-practice",
  SO_TFC_leader: "/usecase-all",
};

const AppRouter: React.FC<AppProps> = (props: AppProps) => {
  const [appConfig, setAppConfig] = useState<AppContextType>({
    userRole: "",
    userId: "",
  });
  const [userRole, setUserRole] = useState("");
  const {oidcProviderName} = props;

  const [isAuthenticated, setIAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const getAuthUser = async () => {
    setIsAuthenticating(true);
    await Auth.currentAuthenticatedUser()
        .then((data) => {
          const {
            signInUserSession: {
              idToken: {payload},
            },
          } = data;
          let tmpUserRole = "";
          // let tmpUserRole = UserRole.Contributor; // Set Default Role is Contributor
          if (payload["cognito:groups"]) {
            console.info("data:", payload["cognito:groups"]);
            console.info("PAYLOAD:", payload["cognito:groups"]);
            if (payload["cognito:groups"].includes(UserRole.Administrator)) {
              tmpUserRole = UserRole.Administrator;
            }
            if (payload["cognito:groups"].includes(UserRole.Contributor)) {
              tmpUserRole = UserRole.Contributor;
            }
            if (payload["cognito:groups"].includes(UserRole.Manager)) {
              tmpUserRole = UserRole.Manager;
            }
            if (payload["cognito:groups"].includes(UserRole.Reviewer)) {
              tmpUserRole = UserRole.Reviewer;
            }
            if (payload["cognito:groups"].includes(UserRole.SO_TFC_leader)) {
              tmpUserRole = UserRole.SO_TFC_leader;
            }
          }
          // tmpUserRole = "Contributor";
          setUserRole(tmpUserRole);
          setIAuthenticated(true);

          //create pinpoint endpoint for the user's email address
          if (data.attributes.email) {
            // Set User Identity
            const indentityObj = JSON.parse(data.attributes.identities)?.[0];
            // setUserIndentity(indentityObj);
            setAppConfig({
              userRole: tmpUserRole,
              userId: indentityObj.userId,
            });
          }
        })
        .catch(() => {
          setIAuthenticated(false);
          if (window.location.search) {
            setIsAuthenticating(true);
          }
        })
        .finally(() => {
          setIsAuthenticating(false);
        });
  };

  useEffect(() => {
    // Get Version From Localstorage
    const curUserVersion = localStorage.getItem(GSAL_VERSION);
    if (curUserVersion !== process.env.REACT_APP_GSAL_VERSION) {
      localStorage.removeItem(AMPLIFY_CONFIG_JSON);
      localStorage.setItem(GSAL_VERSION, process.env.REACT_APP_GSAL_VERSION);
    }
    getAuthUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  Hub.listen("auth", (data) => {
    // console.log("data.payload.event:", data.payload);
    if (!isAuthenticated && data?.payload?.data?.url?.indexOf("?code=") < 0) {
      localStorage.setItem(USER_REDIRECT_URL, data?.payload?.data?.url || "");
    }
    switch (data.payload.event) {
      case "signIn":
        console.debug(data.payload.event);
        setIAuthenticated(true);
        if (localStorage.getItem(USER_REDIRECT_URL)) {
          setTimeout(() => {
            window.location.href =
                localStorage.getItem(USER_REDIRECT_URL) || "/";
          }, 100);
        }
        break;
      case "signIn_failure":
        console.debug(data.payload.event);
        setIAuthenticated(false);
        break;
      case "oAuthSignOut":
        console.log("clear");
        DataStore.clear();
        break;
      default:
        break;
    }
  });

  return isAuthenticated && !isAuthenticating ? (
      <Router>
        <AppContext.Provider value={appConfig}>
          <div
              id="b"
              className={
                process.env.REACT_APP_SHOW_TOP_BANNER === "show"
                    ? "poc-app-wrapper poc-top-banner"
                    : "poc-app-wrapper"
              }
          >
            {process.env.REACT_APP_SHOW_TOP_BANNER === "show" && (
                <div className="top-banner-content">
                  Hi, Everybody, this is the GSAL gamma environment. Please feel
                  free to test.
                </div>
            )}
            <Header/>
            <div id="app" className="poc-main">
              <Switch>
                <Route exact path="/">
                  <Redirect to={RedirectMap[userRole]}/>
                </Route>
                {userRole === UserRole.Contributor && (
                    <Route exact path="/my-poc" component={PocList}/>
                )}
                {/* {userRole !== UserRole.Contributor && ( */}
                <Route exact path="/all-poc" component={PocList}/>
                {/* )} */}
                {userRole !== UserRole.Contributor && (
                    <Route exact path="/assign-to-me" component={PocList}/>
                )}
                {userRole !== UserRole.Contributor && (
                    <Route exact path="/swa-assign-to-me" component={SWAList}/>
                )}
                {userRole !== UserRole.Contributor && (
                    <Route exact path="/my-team" component={PocList}/>
                )}
                <Route exact path="/my-swa" component={SWAList}/>
                {userRole !== UserRole.Contributor && (
                    <Route exact path="/swa-my-team" component={SWAList}/>
                )}
                {/* {userRole !== UserRole.Contributor && ( */}
                <Route exact path="/swa-all" component={SWAList}/>
                {/* )} */}
                <Route exact path="/swa/create" component={CreateSWA}/>
                <Route exact path="/swa-all/detail/:id" component={SWADetail}/>
                <Route exact path="/swa-assign-to-me/detail/:id" component={SWADetail}/>
                <Route exact path="/swa-my-team/detail/:id" component={SWADetail}/>
                <Route exact path="/my-swa/detail/:id" component={SWADetail}/>
                <Route exact path="/swa/detail/:id/:tab" component={SWAList}/>
                <Route exact path="/swa/edit/:id" component={EditSWA}/>
                <Route exact path="/swa/:id/review" component={SWAReview}/>
                <Route exact path="/usecase/create" component={CreateUseCase}/>
                <Route exact path="/usecase-all" component={UseCaseList}/>
                <Route exact path="/my-usecase" component={UseCaseList}/>
                {userRole !== UserRole.Contributor && (
                    <Route exact path="/usecase-my-team" component={UseCaseList}/>
                )}
                <Route exact path="/usecase-all/detail/:id" component={UseCaseDetail}/>
                <Route exact path="/usecase-my-team/detail/:id" component={UseCaseDetail}/>
                <Route exact path="/my-usecase/detail/:id" component={UseCaseDetail}/>
                <Route exact path="/my-engagement/detail/:id" component={UseCaseDetail}/>
                <Route exact path="/engagement-my-team/detail/:id" component={UseCaseDetail}/>
                <Route exact path="/usecase/detail/:id/:tab" component={UseCaseList}/>
                <Route exact path="/usecase/edit/:id" component={EditUseCase}/>
                <Route exact path="/usecase-all/:id/:group_id/customer" component={CustomerList}/>
                <Route exact path="/usecase-all/:id/:name/engage" component={CustomerEngage}/>
                <Route exact path="/my-engagement/:id/:name/engage" component={CustomerEngage}/>
                <Route exact path="/engagement-my-team/:id/:name/engage" component={CustomerEngage}/>
                <Route exact path="/my-engagement" component={EngagedCustomerList}/>
                <Route exact path="/engagement-my-team" component={EngagedCustomerList}/>
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/report-download"
                        component={ReportDownload}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/cross-team-review"
                        component={CrossTeamReview}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/create-cross-team-review"
                        component={CreateCrossTeamReview}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/create-cross-team-review/:id"
                        component={CrossTeamReviewPoCs}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/create-cross-team-review/:ctReviewId/poc/:id"
                        component={CrossTeamPocDetail}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/swa-cross-team-review"
                        component={SWACrossTeamReview}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/swa-create-cross-team-review"
                        component={SWACreateCrossTeamReview}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/swa-create-cross-team-review/:id"
                        component={CrossTeamReviewSWAs}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/swa-create-cross-team-review/:ctReviewId/swa/:id"
                        component={crossTeamSWADetail}
                    />
                )}
                {userRole === UserRole.Administrator && (
                    <Route
                        exact
                        path="/admin/system-settings"
                        component={SystemSettings}
                    />
                )}
                <Route exact path="/poc/create" component={CreatePoC}/>
                <Route exact path="/all-poc/detail/:id" component={PocDetail}/>
                <Route exact path="/assign-to-me/detail/:id" component={PocDetail}/>
                <Route exact path="/my-team/detail/:id" component={PocDetail}/>
                <Route exact path="/my-poc/detail/:id" component={PocDetail}/>
                <Route exact path="/poc/edit/:id" component={EditPoC}/>
                <Route exact path="/poc/detail/:id/:tab" component={PocDetail}/>
                <Route exact path="/poc/:id/review" component={PocReview}/>
                <Route
                    exact
                    path="/stars/best-practice"
                    component={BestPractice}
                />
                <Route
                    exact
                    path="/stars/best-practice/:id"
                    component={BestPracticeDetail}
                />
                <Route
                    exact
                    path="/error/access-denied"
                    render={() => (
                        <div className="not-found">
                          <h1>Sorry, you have no permission to access this page</h1>
                          <Link href="/">
                            <Button>Home</Button>
                          </Link>
                        </div>
                    )}
                />
                <Route
                    render={() => (
                        <div className="not-found">
                          <h1>Sorry, we can not find this page</h1>
                          <Link href="/">
                            <Button>Home</Button>
                          </Link>
                        </div>
                    )}
                />
              </Switch>
            </div>
          </div>
        </AppContext.Provider>
      </Router>
  ) : (
      <div className="custom-auth__box__container">
        <Authentication
            isAuthenticating={isAuthenticating}
            isLoadingState={isAuthenticated}
            onSignIn={() =>
                Auth.federatedSignIn({customProvider: oidcProviderName})
            }
        />
      </div>
  );
};

const App: React.FC = (): JSX.Element => {
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [oidcProvider, setOidcProvider] = useState("");

  useEffect(() => {
    if (localStorage.getItem(AMPLIFY_CONFIG_JSON)) {
      const jsonObj = JSON.parse(
          localStorage.getItem(AMPLIFY_CONFIG_JSON) || ""
      );
      Amplify.configure(jsonObj);
    } else {
      const timeStamp = new Date().getTime();
      setLoadingConfig(true);
      Axios.get(`/aws-exports.json?timestamp=${timeStamp}`).then((res) => {
        console.info("res.data.data:", res.data);
        Amplify.configure(res.data);
        localStorage.setItem(AMPLIFY_CONFIG_JSON, JSON.stringify(res.data));
        setOidcProvider(res.data.oidcProviderName);
        setLoadingConfig(false);
      });
    }
  }, []);

  if (loadingConfig) {
    return (
        <div className="text-center app-loading">
          <Spinner size="normal"/> GCR SA Assets Library is Loading
        </div>
    );
  }
  return <AppRouter oidcProviderName={oidcProvider}/>;
};

export default App;
