export const usecasePageConfig = {
  title: "Select page size",
  options: [
    {value: 1, label: "1 Use Case"},
    {value: 2, label: "2 Use Cases"},
    {value: 5, label: "5 Use Cases"},
    {value: 10, label: "10 Use Cases"},
    {value: 20, label: "20 Use Cases"},
  ],
};

export const usecaseVisibleConfig = {
  title: "Select visible content",
  options: [
    {
      label: "Use Case Properties",
      options: [
        {
          id: "usecaseId",
          label: "Use Case ID",
          editable: false,
        },
        {id: "contributor", label: "WWSO GTM"},
        {id: "managerName", label: "Manager"},
        {id: "subject", label: "Subject"},
        {id: "productGroup", label: "Product Group"},
        {id: "submitDateTime", label: "Created"},
      ],
    },
  ],
};

