import React, { useCallback, useContext, useEffect, useState } from 'react';
import { appSyncRequest } from '../../../assets/js/request';
import { getInterestResultsByUseCaseId } from '../../../graphql/queries';
import { EngageResult, ResultAbstract, UserRole } from '../../../API';
import AppContext from '../../../context/AppContext';
import { Header, Spinner } from '@amzn/awsui-components-react';
import { Container, Table } from '@amzn/awsui-components-react/polaris';
import CustomerEngageResults from '../../comps/CustomerEngageResults';
import { DEFAULT_PAGE_SIZE, USECASE_LIST_PAGE_SIZE_ID } from '../../../assets/js/const';

interface EngagedCustomerProps {
  useCaseId: string;
}

const EngagedCustomer: React.FC<EngagedCustomerProps> = (props: EngagedCustomerProps) => {
  const { useCaseId } = props;
  const appConfig = useContext(AppContext);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [engagedResults, setEngagedResults] = useState<ResultAbstract[]>([]);

  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(
      parseInt(
          localStorage.getItem(USECASE_LIST_PAGE_SIZE_ID) || DEFAULT_PAGE_SIZE.toString()) || DEFAULT_PAGE_SIZE
  );

  const getEngagedResults = useCallback(async () => {
    try {
      setLoadingCustomer(true);
      const resultsRes: any = await appSyncRequest(getInterestResultsByUseCaseId, {
        input: {
          useCaseId: props.useCaseId,
          contributorId: appConfig.userRole === UserRole.Contributor ? appConfig.userId : undefined,
          managerId: appConfig.userRole === UserRole.Manager ? appConfig.userId : undefined,
          curPage: curPage,
          pageSize: pageSize,
        }
      });
      console.info("resultsRes:", resultsRes);
      setLoadingCustomer(false);
      setEngagedResults(resultsRes?.data?.getInterestResultsByUseCaseId?.results || []);
    } catch (error) {
      setLoadingCustomer(false);
      console.error(error);
    }
  }, [useCaseId]);

  useEffect(() => {
    getEngagedResults();
  }, []);

  return (
      <div>
        <Container header={<Header variant="h2">Customer Engaged Results</Header>}>
          {loadingCustomer ? (
              <Spinner size="normal"/>
          ) : (
              <div>
                <div>
                  <Table
                      trackBy="customerId"
                      stickyHeader={true}
                      resizableColumns={true}
                      columnDefinitions={[
                        {
                          id: "CustomerName",
                          header: "Customer Name",
                          width: 250,
                          cell: (item) => item.customerName,
                        },
                        {
                          id: "contributorId",
                          header: "Contributor",
                          width: 200,
                          cell: (item) => item.contributorId,
                        },
                        {
                          id: "isInterested",
                          header: "Is Interested",
                          width: 200,
                          cell: (item) => (
                              <CustomerEngageResults isInterested={item.isInterested || EngageResult.No}/>
                          ),
                        },
                        {
                          id: "reason",
                          header: "Reason",
                          width: 500,
                          cell: (item) => item.reason,
                        }
                      ]}
                      items={ engagedResults || []}
                      />
                </div>
              </div>
          )}
        </Container>
      </div>
  );
};

export default EngagedCustomer;

