/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAssignees = /* GraphQL */ `
  query GetAssignees($role: UserRole!, $managerId: ID!) {
    getAssignees(role: $role, managerId: $managerId) {
      userId
      name
      role
      supervisorId
      supervisorName
      managerId
      managerName
      teamName
      teamDetail
      __typename
    }
  }
`;
export const getContributors = /* GraphQL */ `
  query GetContributors($contributorName: String!) {
    getContributors(contributorName: $contributorName) {
      userId
      name
      role
      supervisorId
      supervisorName
      managerId
      managerName
      teamName
      teamDetail
      __typename
    }
  }
`;
export const getServices = /* GraphQL */ `
  query GetServices {
    getServices {
      svcId
      categoryId
      svcName
      __typename
    }
  }
`;
export const getAccountIds = /* GraphQL */ `
  query GetAccountIds($contributorId: String!) {
    getAccountIds(contributorId: $contributorId) {
      sfdcAccountId
      __typename
    }
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories {
    getCategories {
      categoryId
      categoryName
      __typename
    }
  }
`;
export const getAllTags = /* GraphQL */ `
  query GetAllTags {
    getAllTags {
      technicalTagList {
        tagId
        tagName
        __typename
      }
      industryTagList {
        tagId
        tagName
        __typename
      }
      __typename
    }
  }
`;
export const getAssignedToMe = /* GraphQL */ `
  query GetAssignedToMe($userId: ID!) {
    getAssignedToMe(userId: $userId)
  }
`;
export const getSWAAssignedToMe = /* GraphQL */ `
  query GetSWAAssignedToMe($userId: ID!) {
    getSWAAssignedToMe(userId: $userId)
  }
`;
export const getPoCs = /* GraphQL */ `
  query GetPoCs($input: PoCFilterInput!) {
    getPoCs(input: $input) {
      totalCount
      pocs {
        pocId
        contributor {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        assignee {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        submitDateTime
        pocSubject
        totalScore
        reviewStatus
        bestPracticeStatus
        assignDateTime
        pocStatus
        pocVersion
        efforts
        pocName
        opptyId
        __typename
      }
      __typename
    }
  }
`;
export const getPoCById = /* GraphQL */ `
  query GetPoCById($pocId: ID!) {
    getPoCById(pocId: $pocId) {
      pocId
      contributor {
        userId
        name
        role
        supervisorId
        supervisorName
        managerId
        managerName
        teamName
        teamDetail
        __typename
      }
      submitDateTime
      sfdcAccountId
      sfdcAccountName
      sfdcAccountUrl
      opptyId
      opptyName
      opptyUrl
      opptyStatus
      territory
      pocSubject
      pocDesc
      deliverableUrl
      reviewStatus
      bestPracticeStatus
      pocStatus
      pocVersion
      efforts
      nominatedBy
      nominationDateTime
      technicalTagList {
        tagId
        tagName
        __typename
      }
      industryTagList {
        tagId
        tagName
        __typename
      }
      sourceCodeUrl
      userGuideUrl
      pocName
      userScenarioDesc
      pocScope
      architectImgUrl
      architectDesc
      architectFilename
      attachments {
        attachmentId
        attachmentFilename
        attachmentUrl
        attachmentDesc
        attachmentSize
        pocId
        __typename
      }
      assigner {
        userId
        name
        role
        supervisorId
        supervisorName
        managerId
        managerName
        teamName
        teamDetail
        __typename
      }
      assignee {
        userId
        name
        role
        supervisorId
        supervisorName
        managerId
        managerName
        teamName
        teamDetail
        __typename
      }
      assignDateTime
      __typename
    }
  }
`;
export const getPoCReviewById = /* GraphQL */ `
  query GetPoCReviewById($pocId: ID!) {
    getPoCReviewById(pocId: $pocId) {
      pocId
      submitDatetime
      isNecessary
      isRepeatable
      isPackageUp
      comments
      totalScore
      scoreRank
      scoreResList {
        criteriaName
        score
        comments
        __typename
      }
      __typename
    }
  }
`;
export const getSWAReviewById = /* GraphQL */ `
  query GetSWAReviewById($swaId: ID!) {
    getSWAReviewById(swaId: $swaId) {
      swaId
      submitDatetime
      isPass
      comments
      __typename
    }
  }
`;
export const getPoCBestPracticeList = /* GraphQL */ `
  query GetPoCBestPracticeList($input: BestPracticeFiterInput!) {
    getPoCBestPracticeList(input: $input) {
      totalCount
      bestPractices {
        pocId
        pocName
        contributorId
        contributorName
        submitDateTime
        pocDesc
        userScenarioDesc
        pocScope
        technicalTagList {
          tagId
          tagName
          __typename
        }
        industryTagList {
          tagId
          tagName
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getGsalConf = /* GraphQL */ `
  query GetGsalConf {
    getGsalConf {
      techTagLimit
      industryTagLimit
      firstLineManagerNominateCountPerMonth
      __typename
    }
  }
`;
export const getCrossTeamReviewTasks = /* GraphQL */ `
  query GetCrossTeamReviewTasks($input: ReviewTaskFilterInput!) {
    getCrossTeamReviewTasks(input: $input) {
      totalCount
      tasks {
        reviewTaskId
        startDate
        endDate
        numberOfPoCs
        createBy {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        createDateTime
        taskStatus
        __typename
      }
      __typename
    }
  }
`;
export const getSWACrossTeamReviewTasks = /* GraphQL */ `
  query GetSWACrossTeamReviewTasks($input: ReviewTaskFilterInput!) {
    getSWACrossTeamReviewTasks(input: $input) {
      totalCount
      tasks {
        reviewTaskId
        startDate
        endDate
        numberOfSWAs
        createBy {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        createDateTime
        taskStatus
        __typename
      }
      __typename
    }
  }
`;
export const exportPoCsReportResolver = /* GraphQL */ `
  query ExportPoCsReportResolver($input: ExportPoCsReportFilterInput!) {
    exportPoCsReportResolver(input: $input) {
      status
      url
      __typename
    }
  }
`;
export const getPoCsByAos = /* GraphQL */ `
  query GetPoCsByAos($input: PocsByAosInput!) {
    getPoCsByAos(input: $input) {
      totalCount
      pocs {
        pocId
        pocName
        pocDesc
        userScenarioDesc
        architectDesc
        __typename
      }
      __typename
    }
  }
`;
export const getSWAsByAos = /* GraphQL */ `
  query GetSWAsByAos($input: SWAsByAosInput!) {
    getSWAsByAos(input: $input) {
      totalCount
      swas {
        swaId
        swaSubject
        sfdcAccountId
        swaDesc
        customerBenefit
        architectDesc
        __typename
      }
      __typename
    }
  }
`;
export const queryCategory = /* GraphQL */ `
  query QueryCategory($curPage: Int!, $pageSize: Int!) {
    queryCategory(curPage: $curPage, pageSize: $pageSize) {
      totalCount
      categoryList {
        categoryId
        categoryName
        __typename
      }
      __typename
    }
  }
`;
export const querySvc = /* GraphQL */ `
  query QuerySvc($categoryIds: [ID], $curPage: Int!, $pageSize: Int!) {
    querySvc(
      categoryIds: $categoryIds
      curPage: $curPage
      pageSize: $pageSize
    ) {
      totalCount
      svcList {
        svcId
        categoryId
        svcName
        __typename
      }
      __typename
    }
  }
`;
export const getSWAs = /* GraphQL */ `
  query GetSWAs($input: SWAFilterInput!) {
    getSWAs(input: $input) {
      totalCount
      swas {
        swaId
        contributor {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        sfdcAccountId
        opptyId
        swaSubject
        architectDesc
        swaDesc
        customerBenefit
        swaStatus
        launchDate
        efforts
        createDateTime
        submitDateTime
        updateDateTime
        linkUrl
        workload {
          categoryId
          workload
          serviceList {
            svcId
            categoryId
            svcName
            __typename
          }
          __typename
        }
        architectDiagrams {
          architectDiagramId
          architectFilename
          architectFileUrl
          __typename
        }
        attachments {
          attachmentId
          attachmentFilename
          attachmentUrl
          attachmentDesc
          attachmentSize
          swaId
          __typename
        }
        tagList {
          tagId
          tagName
          __typename
        }
        assigner {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        assignee {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        assignDateTime
        reviewStatus
        isPass
        __typename
      }
      __typename
    }
  }
`;
export const getSWAById = /* GraphQL */ `
  query GetSWAById($swaId: ID!) {
    getSWAById(swaId: $swaId) {
      swaId
      contributor {
        userId
        name
        role
        supervisorId
        supervisorName
        managerId
        managerName
        teamName
        teamDetail
        __typename
      }
      sfdcAccountId
      opptyId
      swaSubject
      architectDesc
      swaDesc
      customerBenefit
      swaStatus
      launchDate
      efforts
      createDateTime
      submitDateTime
      updateDateTime
      linkUrl
      workload {
        categoryId
        workload
        serviceList {
          svcId
          categoryId
          svcName
          __typename
        }
        __typename
      }
      architectDiagrams {
        architectDiagramId
        architectFilename
        architectFileUrl
        __typename
      }
      attachments {
        attachmentId
        attachmentFilename
        attachmentUrl
        attachmentDesc
        attachmentSize
        swaId
        __typename
      }
      tagList {
        tagId
        tagName
        __typename
      }
      assigner {
        userId
        name
        role
        supervisorId
        supervisorName
        managerId
        managerName
        teamName
        teamDetail
        __typename
      }
      assignee {
        userId
        name
        role
        supervisorId
        supervisorName
        managerId
        managerName
        teamName
        teamDetail
        __typename
      }
      assignDateTime
      reviewStatus
      isPass
      __typename
    }
  }
`;
export const exportSWAsReportResolver = /* GraphQL */ `
  query ExportSWAsReportResolver($input: ExportSWAsReportFilterInput!) {
    exportSWAsReportResolver(input: $input) {
      status
      url
      __typename
    }
  }
`;
export const exportMissingSWAsReportResolver = /* GraphQL */ `
  query ExportMissingSWAsReportResolver(
    $input: ExportMissingSWAsReportFilterInput!
  ) {
    exportMissingSWAsReportResolver(input: $input) {
      status
      url
      __typename
    }
  }
`;
export const getIndustries = /* GraphQL */ `
  query GetIndustries {
    getIndustries {
      industryId
      industryName
      __typename
    }
  }
`;
export const getGroups = /* GraphQL */ `
  query GetGroups($categoryId: String) {
    getGroups(categoryId: $categoryId) {
      groupId
      categoryId
      groupName
      __typename
    }
  }
`;
export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($groupIds: [String]) {
    getCampaigns(groupIds: $groupIds) {
      campaignId
      groupId
      campaignName
      __typename
    }
  }
`;
export const getUseCaseById = /* GraphQL */ `
  query GetUseCaseById($useCaseId: ID!) {
    getUseCaseById(useCaseId: $useCaseId) {
      useCaseId
      contributor {
        userId
        name
        role
        supervisorId
        supervisorName
        managerId
        managerName
        teamName
        teamDetail
        __typename
      }
      useCaseName
      useCaseDesc
      sfdcAccountId
      architectDesc
      useCaseStatus
      workload {
        groupId
        groupName
        serviceList {
          svcId
          groupId
          svcName
          __typename
        }
        __typename
      }
      industries {
        industryId
        industryName
        __typename
      }
      architectDiagrams {
        architectDiagramId
        architectFilename
        architectFileUrl
        __typename
      }
      attachments {
        attachmentId
        attachmentFilename
        attachmentUrl
        attachmentDesc
        attachmentSize
        useCaseId
        __typename
      }
      createDateTime
      submitDateTime
      updateDateTime
      __typename
    }
  }
`;
export const getUseCases = /* GraphQL */ `
  query GetUseCases($input: UseCaseFilterInput!) {
    getUseCases(input: $input) {
      totalCount
      useCases {
        useCaseId
        contributor {
          userId
          name
          role
          supervisorId
          supervisorName
          managerId
          managerName
          teamName
          teamDetail
          __typename
        }
        useCaseName
        useCaseDesc
        sfdcAccountId
        architectDesc
        useCaseStatus
        workload {
          groupId
          groupName
          serviceList {
            svcId
            groupId
            svcName
            __typename
          }
          __typename
        }
        industries {
          industryId
          industryName
          __typename
        }
        architectDiagrams {
          architectDiagramId
          architectFilename
          architectFileUrl
          __typename
        }
        attachments {
          attachmentId
          attachmentFilename
          attachmentUrl
          attachmentDesc
          attachmentSize
          useCaseId
          __typename
        }
        createDateTime
        submitDateTime
        updateDateTime
        __typename
      }
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($input: CustomersFilterInput!) {
    getCustomers(input: $input) {
      totalCount
      customers {
        accountId
        accountName
        groupId
        territory
        createDateTime
        __typename
      }
      __typename
    }
  }
`;
export const getCustomersByAos = /* GraphQL */ `
  query GetCustomersByAos($input: CustomersByAosInput) {
    getCustomersByAos(input: $input) {
      totalCount
      customers {
        accountId
        accountName
        groupId
        __typename
      }
      __typename
    }
  }
`;
export const getInterestResultsByUseCaseId = /* GraphQL */ `
  query GetInterestResultsByUseCaseId($input: ResultFilterInput) {
    getInterestResultsByUseCaseId(input: $input) {
      totalCount
      results {
        useCaseId
        customerId
        customerName
        contributorName
        contributorId
        isInterested
        reason
        createDateTime
        updateDateTime
        __typename
      }
      __typename
    }
  }
`;
export const getEngagedCustomers = /* GraphQL */ `
  query GetEngagedCustomers($input: CustomerEngagedFilterInput) {
    getEngagedCustomers(input: $input) {
      totalCount
      results {
        useCaseId
        useCaseName
        groupId
        groupName
        contributorId
        contributorName
        customerId
        customerName
        isInterested
        reason
        createDateTime
        __typename
      }
      __typename
    }
  }
`;
export const exportResultReportResolver = /* GraphQL */ `
  query ExportResultReportResolver($input: ExportResultsReportFilterInput!) {
    exportResultReportResolver(input: $input) {
      status
      url
      __typename
    }
  }
`;
export const getEngagementById = /* GraphQL */ `
  query GetEngagementById($input: EngagementInput) {
    getEngagementById(input: $input) {
      useCaseId
      customerId
      customerName
      contributorName
      contributorId
      isInterested
      reason
      createDateTime
      updateDateTime
      __typename
    }
  }
`;
