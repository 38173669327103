export const pocPageConfig = {
  title: "Select page size",
  options: [
    {value: 1, label: "1 PoC"},
    {value: 2, label: "2 PoCs"},
    {value: 5, label: "5 PoCs"},
    {value: 10, label: "10 PoCs"},
    {value: 20, label: "20 PoCs"},
  ],
};

export const pocVisibleConfig = {
  title: "Select visible content",
  options: [
    {
      label: "PoC Properties",
      options: [
        {
          id: "pocId",
          label: "Poc ID",
          editable: false,
        },
        {id: "contributor", label: "SA"},
        {id: "managerName", label: "Manager"},
        {id: "subject", label: "Subject"},
        {id: "submitDateTime", label: "Created"},
        {id: "reviewer", label: "Reviewer"},
        {id: "assignDateTime", label: "Assigned"},
        {id: "reviewStatus", label: "Review"},
        {id: "score", label: "Score"},
        {id: "bestPracticeStatus", label: "Best Practice"},
        {id: "opptyId", label: "Opportunity ID"},
      ],
    },
  ],
};

export const swaPageConfig = {
  title: "Select page size",
  options: [
    {value: 1, label: "1 SWA"},
    {value: 2, label: "2 SWAs"},
    {value: 5, label: "5 SWAs"},
    {value: 10, label: "10 SWAs"},
    {value: 20, label: "20 SWAs"},
  ],
};

export const swaVisibleConfig = {
  title: "Select visible content",
  options: [
    {
      label: "SWA Properties",
      options: [
        {
          id: "swaId",
          label: "SWA ID",
          editable: false,
        },
        {id: "contributor", label: "SA"},
        {id: "managerName", label: "Manager"},
        {id: "subject", label: "Subject"},
        {id: "productGroup", label: "Product Group"},
        {id: "sfdcAccountId", label: "Account ID"},
        {id: "opptyId", label: "Opportunity ID"},
        {id: "submitDateTime", label: "Created"},
      ],
    },
  ],
};

export const crossTeamReviewPageConfig = {
  title: "Select page size",
  options: [
    {value: 1, label: "1 Task"},
    {value: 2, label: "2 Tasks"},
    {value: 5, label: "5 Tasks"},
    {value: 10, label: "10 Tasks"},
    {value: 20, label: "20 Tasks"},
  ],
};

export const crossTeamReviewVisibleConfig = {
  title: "Select visible content",
  options: [
    {
      label: "Cross-team reviews Properties",
      options: [
        {
          id: "reviewTaskId",
          label: "ID",
          editable: false,
        },
        {id: "startDate", label: "Start date"},
        {id: "endDate", label: "End date"},
        {id: "numberOfSWAs", label: "# of SWAs"},
        {id: "createBy", label: "Created by"},
        {id: "created", label: "Created"},
        {id: "status", label: "Status"},
      ],
    },
  ],
};
