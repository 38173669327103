import React, { useContext, useEffect, useState } from "react";
import { SideNavigation } from "@amzn/awsui-components-react";
import AppContext from "context/AppContext";
import { getAssignedToMe, getSWAAssignedToMe } from "graphql/queries";
import { UserRole } from "API";
import { appSyncRequest } from "assets/js/request";
import { GET_SUPPORT_LINK } from "assets/js/const";

interface SideNavProps {
  defaultActiveHref?: string;
}

const SideNav: React.FC<SideNavProps> = (props: SideNavProps): JSX.Element => {
  const {defaultActiveHref} = props;
  console.info("defaultActiveHref:", defaultActiveHref);
  const [activeHref, setActiveHref] = useState(defaultActiveHref);
  const [assignToMeNum, setAssignToMeNum] = useState(0);
  const [assignToMeNumSWA, setAssignToMeNumSWA] = useState(0);
  const appConfig = useContext(AppContext);
  console.info("appConfig:", appConfig);
  let navItem: any[] = [
    {
      type: "section",
      text: "PoC",
      items: [
        {
          type: "link",
          text: "PoC Best Practice",
          href: "/stars/best-practice",
        },
        {type: "link", text: "All PoC", href: "/all-poc"},
        {
          type: "link",
          // text: "Assigned to me",
          text:
              assignToMeNum > 0
                  ? `Assigned to me (${assignToMeNum})`
                  : "Assigned to me",
          href: "/assign-to-me",
          // info: <Badge color="red">{assignToMeNum}</Badge>,
        },
        {type: "link", text: "My team's PoC", href: "/my-team"},
      ],
    },
  ];

  if (appConfig.userRole === UserRole.SO_TFC_leader) {
    navItem = [
      {
        type: "section",
        text: "Use Case",
        items: [
          {type: "link", text: "All Use Case", href: "/usecase-all"},
          {type: "link", text: "My Use Case", href: "/my-usecase"},
        ],
      },
    ];
  }

  if (
      appConfig.userRole === UserRole.Manager ||
      appConfig.userRole === UserRole.Reviewer
  ) {
    navItem = [
      ...navItem,
      {type: "divider"},
      {
        type: "section",
        text: "SWA",
        items: [
          {type: "link", text: "All SWA", href: "/swa-all"},
          {
            type: "link",
            // text: "Assigned to me",
            text:
                assignToMeNum > 0
                    ? `Assigned to me (${assignToMeNumSWA})`
                    : "Assigned to me",
            href: "/swa-assign-to-me",
            // info: <Badge color="red">{assignToMeNum}</Badge>,
          },
          {type: "link", text: "My team's SWA", href: "/swa-my-team"},
        ],
      },
      {type:"divider"},
      {
        type: "section",
        text: "Use Case",
        items: [
          {type: "link", text: "All Use Case", href: "/usecase-all"},
          {type: "link", text: "Customer Engagement", href: "/engagement-my-team"},
        ],
      },
    ];
  }

  if (appConfig.userRole === UserRole.Administrator) {
    navItem = [
      ...navItem,
      {type: "divider"},
      {
        type: "section",
        text: "SWA",
        items: [
          {type: "link", text: "All SWA", href: "/swa-all"},
          {
            type: "link",
            // text: "Assigned to me",
            text:
                assignToMeNum > 0
                    ? `Assigned to me (${assignToMeNumSWA})`
                    : "Assigned to me",
            href: "/swa-assign-to-me",
            // info: <Badge color="red">{assignToMeNum}</Badge>,
          },
        ],
      },
      {type: "divider"},
      {
        type: "section",
        text: "Use Case",
        items: [
          {type: "link", text: "All Use Case", href: "/usecase-all"},
        ],
      },
    ];
  }

  if (appConfig.userRole === UserRole.Contributor) {
    navItem = [
      {
        type: "section",
        text: "PoC",
        items: [
          {
            type: "link",
            text: "PoC Best Practice",
            href: "/stars/best-practice",
          },
          {type: "link", text: "All PoC", href: "/all-poc"},
          {type: "link", text: "My PoC", href: "/my-poc"},
        ],
      },
      {type: "divider"},
      {
        type: "section",
        text: "SWA",
        items: [
          {type: "link", text: "All SWA", href: "/swa-all"},
          {type: "link", text: "My SWA", href: "/my-swa"},
        ],
      },
      {type:"divider"},
      {
        type: "section",
        text: "Use Case",
        items: [
          {type: "link", text: "All Use Case", href: "/usecase-all"},
          {type: "link", text: "Customer Engagement", href: "/my-engagement"},
        ],
      },
    ];
  }

  if (appConfig.userRole === UserRole.Administrator) {
    navItem = [
      ...navItem,
      {type: "divider"},
      {
        type: "section",
        text: "Administration",
        items: [
          {
            type: "link",
            text: "Report Download",
            href: "/admin/report-download",
          },
          {
            type: "link",
            text: "Cross-team review(PoC)",
            href: "/admin/cross-team-review",
          },
          {
            type: "link",
            text: "Cross-team review(SWA)",
            href: "/admin/swa-cross-team-review",
          },
          {
            type: "link",
            text: "System Settings",
            href: "/admin/system-settings",
          },
        ],
      },
    ];
  }

  navItem = [
    ...navItem,
    {type: "divider"},
    {
      type: "link",
      text: "Documentation",
      href: process.env.REACT_APP_GSAL_DOCS_LINK,
      external: true,
    },
    {
      type: "link",
      text: "Support",
      href: GET_SUPPORT_LINK,
      external: true,
    },
  ];

  const getAssignToMe = async () => {
    try {
      // setloadingData(true);
      const assignToMe: any = await appSyncRequest(getAssignedToMe, {
        userId: appConfig.userId,
      });
      console.info("assignToMe:", assignToMe);
      setAssignToMeNum(assignToMe?.data?.getAssignedToMe);
      // setloadingData(false);
      // Set Industry Default Checked
    } catch (error) {
      console.error(error);
    }
  };

  const getSWAAssignToMe = async () => {
    try {
      // setloadingData(true);
      const assignToMeSWA: any = await appSyncRequest(getSWAAssignedToMe, {
        userId: appConfig.userId,
      });
      console.info("assignToMe:", assignToMeSWA);
      setAssignToMeNumSWA(assignToMeSWA?.data?.getSWAAssignedToMe);
      // setloadingData(false);
      // Set Industry Default Checked
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAssignToMe();
    getSWAAssignToMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <SideNavigation
          activeHref={activeHref}
          header={{href: "/", text: "Quick Searches"}}
          onFollow={(event) => {
            if (!event.detail.external) {
              // event.preventDefault();
              console.info("event.detail.href:", event.detail.href);
              setActiveHref(event.detail.href);
            }
          }}
          items={navItem}
      />
  );
};

export default SideNav;
