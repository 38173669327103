import { Container, FormField, Header, Input, } from "@amzn/awsui-components-react";
import React from "react";

import EXAMPLE_IMG from "assets/images/sfdc-opportunity.png";
import { PoCInput } from "API";

interface StepOppProps {
  curPoc: PoCInput;
  showOppIdError: boolean;
  changePoCOppId: (id: string) => void;
}

const StepOpp: React.FC<StepOppProps> = (props: StepOppProps) => {
  const {curPoc, showOppIdError, changePoCOppId} = props;
  return (
      <div>
        <Header variant="h1">Opportunity</Header>
        <div className="mt-10">
          <Container header={<Header variant="h2">Salesforce</Header>}>
            <FormField
                label="Opportunity ID"
                description="Input the 18 digits ID of Salesforce opportunity."
                errorText={
                  showOppIdError ? "Invalid opportunity ID, please check." : ""
                }
            >
              <Input
                  value={curPoc.opptyId || ""}
                  placeholder="1114z000025UezRAAS"
                  onChange={(event) => changePoCOppId(event.detail.value)}
              />
            </FormField>

            <FormField
                className="mt-20"
                label="Example"
                description="Find the opportunity ID in the address bar, and the ID is after Opportunity."
            >
              <div className="mt-10">
                <img
                    width="100%"
                    style={{maxWidth: 720}}
                    src={EXAMPLE_IMG}
                    alt="example"
                />
              </div>
            </FormField>
          </Container>
        </div>
      </div>
  );
};

export default StepOpp;
