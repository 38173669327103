import React from "react";
import { BreadcrumbGroup } from "@amzn/awsui-components-react/polaris";

interface BreadCrumbProps {
  resourcesBreadcrumbs: any[];
}

const BreadCrumb: React.FC<BreadCrumbProps> = (
    props: BreadCrumbProps
): JSX.Element => {
  const {resourcesBreadcrumbs} = props;
  return (
      <BreadcrumbGroup items={resourcesBreadcrumbs} ariaLabel="Breadcrumbs"/>
  );
};

export default BreadCrumb;
