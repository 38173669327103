/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from "react";
import { AppLayout } from "@amzn/awsui-components-react";
import {
  Box,
  Button,
  Cards,
  CollectionPreferences,
  Header,
  Link,
  Pagination,
} from "@amzn/awsui-components-react/polaris";

import { getPoCBestPracticeList } from "graphql/queries";

import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";
import { BestPracticeAbstract } from "API";
import SearchOptions from "./comps/SearchOptions";
import { BP_PAGE_SIZE_ID, BP_VISIBLE_COLUMNS_ID, SIDE_MENU_IS_OPEN, } from "assets/js/const";
import { appSyncRequest } from "assets/js/request";

const BestPracticeContent: React.FC = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [bestPracticeList, setBestPracticeList] = useState<BestPracticeAbstract[]>([]);

  const [visibleColumnsList, setVisibleColumnsList] = useState<any>(
      localStorage.getItem(BP_VISIBLE_COLUMNS_ID)?.split(",") || [
        "sa",
        "description",
        "tag",
      ]
  );
  const [pageSize, setPageSize] = useState(
      parseInt(localStorage.getItem(BP_PAGE_SIZE_ID) || "9") || 9
  );

  const [tagInputs, setTagInputs] = useState<any[] | undefined>();
  const [tagIndustryList, setTagIndustryList] = useState([]);
  const [tagTechList, setTagTechList] = useState([]);
  const [searchPoCPattern, setSearchPoCPattern] = useState("");

  const getBestPracticeList = useCallback(async () => {
    try {
      setLoadingData(true);
      const bpListDta: any = await appSyncRequest(getPoCBestPracticeList, {
        input: {
          pocPattern: searchPoCPattern ? searchPoCPattern : undefined,
          technicalTagList:
              tagTechList && tagTechList.length > 0 ? tagTechList : undefined,
          industryTagList:
              tagIndustryList && tagIndustryList.length > 0
                  ? tagIndustryList
                  : undefined,
          curPage: curPage,
          pageSize: pageSize,
        },
      });
      if (bpListDta?.data?.getPoCBestPracticeList?.bestPractices) {
        bpListDta.data.getPoCBestPracticeList.bestPractices.forEach(
            (element: any) => {
              element.pocName = decodeURIComponent(
                  element.pocName.replace(/\+/g, " ")
              );
            }
        );
        setBestPracticeList(
            bpListDta.data.getPoCBestPracticeList.bestPractices
        );
      } else {
        setBestPracticeList([]);
      }

      setTotalCount(bpListDta?.data?.getPoCBestPracticeList?.totalCount || 0);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
  }, [curPage, pageSize, tagTechList, tagIndustryList, searchPoCPattern]);

  useEffect(() => {
    console.info(
        curPage,
        pageSize,
        tagTechList,
        tagIndustryList,
        searchPoCPattern
    );
    getBestPracticeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, tagTechList, tagIndustryList, searchPoCPattern]);

  useEffect(() => {
    console.info("tagInputs:", tagInputs);
    if (tagInputs && tagInputs.length >= 0) {
      console.info("tagInputs.length:", tagInputs.length);
      const tmpIndustryList: any = [];
      const tmpTechList: any = [];
      tagInputs.forEach((element: any) => {
        if (element.type === "tech") {
          tmpTechList.push({
            tagId: element.value,
            tagName: element.label,
          });
        }
        if (element.type === "industry") {
          tmpIndustryList.push({
            tagId: element.value,
            tagName: element.label,
          });
        }
      });
      setTagIndustryList(tmpIndustryList);
      setTagTechList(tmpTechList);
    }
  }, [tagInputs]);

  return (
      <div>
        <div>
          <Cards
              cardDefinition={{
                header: (e) => (
                    <div>
                      <Link
                          className="bt-title"
                          href={`/stars/best-practice/${e.pocId}`}
                      >
                        {e.pocName}
                      </Link>
                    </div>
                ),
                sections: [
                  {
                    id: "sa",
                    header: "",
                    content: (e) => (
                        <div className="bt-sa">
                          Built by {e.contributorName}({e.contributorId})
                        </div>
                    ),
                  },
                  {
                    id: "description",
                    header: "",
                    content: (e) => e.userScenarioDesc,
                  },
                  {
                    id: "tag",
                    header: "",
                    content: (e) => (
                        <div>
                          {e.industryTagList?.map((element, index) => {
                            return (
                                <span className="bt-tag" key={index}>
                          {element?.tagName}
                        </span>
                            );
                          })}
                          {e.technicalTagList?.map((element, index) => {
                            return (
                                <span className="bt-tag" key={index}>
                          {element?.tagName}
                        </span>
                            );
                          })}
                        </div>
                    ),
                  },
                ],
              }}
              loading={loadingData}
              cardsPerRow={[{cards: 1}, {minWidth: 400, cards: 3}]}
              items={bestPracticeList}
              loadingText="Loading Best Practices"
              visibleSections={visibleColumnsList}
              // selectionType="multi"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No Best Practices</b>
                  <Box padding={{bottom: "s"}} variant="p" color="inherit">
                    No Best Practices to display.
                  </Box>
                </Box>
              }
              filter={
                <SearchOptions
                    changeTextInput={(pocPattern) => {
                      // console.info("pocPattern:", pocPattern);
                      setSearchPoCPattern(pocPattern);
                    }}
                    changeTagInput={(options) => {
                      setTagInputs(options);
                    }}
                />
              }
              header={
                <Header
                    actions={
                      <Button
                          loading={loadingData}
                          disabled={loadingData}
                          iconName="refresh"
                          onClick={() => {
                            getBestPracticeList();
                          }}
                      >
                        Refresh
                      </Button>
                    }
                >
                  PoC Best Practice Library
                </Header>
              }
              pagination={
                <Pagination
                    onChange={(e) => {
                      setCurPage(e.detail.currentPageIndex);
                    }}
                    currentPageIndex={curPage}
                    pagesCount={Math.ceil(totalCount / pageSize)}
                />
              }
              preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={{
                      pageSize: pageSize,
                      visibleContent: visibleColumnsList,
                    }}
                    pageSizePreference={{
                      title: "Select page size",
                      options: [
                        {value: 3, label: "3 best practice"},
                        {value: 9, label: "9 best practice"},
                        {value: 12, label: "12 best practice"},
                        {value: 15, label: "15 best practice"},
                        {value: 30, label: "30 best practice"},
                      ],
                    }}
                    visibleContentPreference={{
                      title: "Select visible content",
                      options: [
                        {
                          label: "Best practice properties",
                          options: [
                            {
                              id: "sa",
                              label: "SA",
                            },
                            {id: "description", label: "Description"},
                            {id: "tag", label: "Tag"},
                          ],
                        },
                      ],
                    }}
                    onConfirm={(event) => {
                      localStorage.setItem(
                          BP_VISIBLE_COLUMNS_ID,
                          event.detail.visibleContent?.join(",") || ""
                      );
                      localStorage.setItem(
                          BP_PAGE_SIZE_ID,
                          event.detail.pageSize?.toString() || "9"
                      );
                      setCurPage(1);
                      setPageSize(event.detail.pageSize || 9);
                      setVisibleColumnsList(event.detail.visibleContent);
                    }}
                />
              }
          />
        </div>
      </div>
  );
};

const BestPractice: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );

  const bestPracticeBreadcrumbs = [
    {
      text: "Home",
      href: "/stars/best-practice",
    },
    {
      text: "Best Practice Library",
      href: "/stars/best-practice",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={
            <BreadCrumb resourcesBreadcrumbs={bestPracticeBreadcrumbs}/>
          }
          content={<BestPracticeContent/>}
          navigation={<SideNav defaultActiveHref="/stars/best-practice"/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default BestPractice;
