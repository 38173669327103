import React, { useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/AppContext'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { ReviewStatus, SWAAbstract, SWAReviewResponse } from '../../../API'
import { appSyncRequest } from '../../../assets/js/request'
import { getSWAById, getSWAReviewById } from '../../../graphql/queries'
import { POC_NOT_FOUND_ID, SIDE_MENU_IS_OPEN } from '../../../assets/js/const'
import { AppLayout, Button, Spinner } from '@amzn/awsui-components-react'
import { Header, SpaceBetween } from '@amzn/awsui-components-react/polaris'
import StepPass from './review/StepPass'
import BreadCrumb from '../../../common/BreadCrumb'
import SideNav from '../../../common/SideNav'
import { updateSWAReview } from '../../../graphql/mutations'


interface SWAReviewContentProps {
  swaId: string;
}

const SWAReviewContent: React.FC<SWAReviewContentProps> = (
    props: SWAReviewContentProps
) => {
  const {swaId} = props;
  const appConfig = useContext(AppContext);

  const [currentReview, setCurrentReview] = useState<SWAReviewResponse>();

  const [loadingResult, setLoadingResult] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [resultLengthError, serResultLengthError] = useState(false);


  const history = useHistory();

  const UpdateSWAReview = useCallback(
      async (modeType: string) => {
        if (resultLengthError) {
          return;
        }
        if (modeType === "Submit") {

        }
        //Remove useless properties
        delete currentReview?.submitDatetime;

        // Deep copy param object
        const curReviewObj = JSON.parse(JSON.stringify(currentReview));
        // Encode review comments
        curReviewObj.comments = encodeURIComponent(curReviewObj.comments);
        curReviewObj.mode = modeType;
        curReviewObj.swaId = swaId;
        if (modeType === "Draft") {
          setDraftLoading(true);
        } else {
          setSubmitLoading(true);
        }

        try {
          const swaReviewData = await appSyncRequest(updateSWAReview, {
            input: curReviewObj,
          });
          setDraftLoading(false);
          setSubmitLoading(false);
          console.info("swaReviewData:", swaReviewData);
          history.push({
            pathname: `/swa-assign-to-me/detail/${swaId}`,
          });
        } catch (error) {
          setDraftLoading(false);
          setSubmitLoading(false);
          console.error(error);
        }
      },
      [currentReview, resultLengthError, history, swaId]
  );


  const getSWAReviewInfoById = useCallback(async () => {
    try {
      setLoadingResult(true);
      const reviewData: any = await appSyncRequest(getSWAReviewById, {
        swaId: swaId,
      });
      console.info("reviewData:", reviewData);
      setLoadingResult(false);
      if (reviewData?.data?.getSWAReviewById) {
        reviewData.data.getSWAReviewById.comments = decodeURIComponent(
            reviewData?.data?.getSWAReviewById?.comments?.replace(/\+/g, " ")
        );
      }
      setCurrentReview(reviewData?.data?.getSWAReviewById);
    } catch (error) {
      setLoadingResult(false);
      console.error(error);
    }
  }, [swaId]);

  // Get SWA by Id or redirect no permission page
  const getSWAInfoById = async () => {
    try {
      setLoadingResult(true);
      const swaData: any = await appSyncRequest(getSWAById, {swaId: swaId});
      console.info("swaData:", swaData);
      const swaInfo: SWAAbstract = swaData?.data?.getSWAById;
      if (swaInfo.swaId === POC_NOT_FOUND_ID) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        if (swaInfo.reviewStatus !== ReviewStatus.Done) {
          if (swaInfo.assignee?.userId === appConfig.userId) {
            getSWAReviewInfoById();
          } else {
            history.push({
              pathname: "/error/access-denied",
            });
          }
        } else {
          history.push({
            pathname: "/error/access-denied",
          });
        }
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSWAInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div>
        {loadingResult ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div className="gsal-review-page">
              <div className="gsal-review-content">
                <Header variant="h1">Review {swaId.toUpperCase()}</Header>
                <div>
                  <StepPass
                      currentReview={currentReview}
                      changeReviewPass={(item) => {
                        console.info("item:", item)
                        setCurrentReview(item);
                      }}
                      setLengthError={(isError) => {
                        serResultLengthError(isError);
                      }}
                      swaId={swaId}
                  />
                </div>
                <div className="mt-20 pb-30 clearfix">
                  <SpaceBetween size="xs" className="fr" direction="horizontal">
                    <Button
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: `/swa-assign-to-me/detail/${swaId}`,
                          });
                        }}
                    >
                      Cancel
                    </Button>
                    <Button
                        disabled={loadingResult || submitLoading}
                        loading={draftLoading}
                        onClick={() => {
                          UpdateSWAReview("Draft");
                        }}
                    >
                      Save Draft
                    </Button>
                    <Button
                        disabled={loadingResult || draftLoading}
                        loading={submitLoading}
                        variant="primary"
                        onClick={() => {
                          UpdateSWAReview("Submit");
                        }}
                    >
                      Submit
                    </Button>
                  </SpaceBetween>
                </div>

              </div>
            </div>
        )}
      </div>
  );
};

interface MatchParams {
  id: string;
}

const SWAReview: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setNavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );

  const id: string = props.match.params.id;

  const swaReviewBreadcrubs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Assigned to me(SWA)",
      href: "/swa-assign-to-me",
    },
    {
      text: id.toUpperCase(),
      href: "/swa-assign-to-me/detail/" + id,
    },
    {
      text: "Review",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={swaReviewBreadcrubs}/>}
          content={
            <SWAReviewContent
                swaId={id}
            />
          }
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setNavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default SWAReview;
