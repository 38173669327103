import React, { useEffect, useState } from "react";
import {
  AppLayout,
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react";
import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";
import { appSyncRequest } from "assets/js/request";
import { getGsalConf } from "graphql/queries";
import { useHistory } from "react-router-dom";
import { submitGsalConf } from "graphql/mutations";
import { SubmitGsalConfMutationVariables } from "API";
import { isAPostiveNumber } from "assets/js/utils";

export interface SystemSettingsProps {
  techTagLimit: string;
  industryTagLimit: string;
  firstLineManagerNominateCountPerMonth: string;
}

interface SysSettingPageProps {
  hideBanner: () => void;
  showSuccessBanner: () => void;
}

const SystemSettingsContent: React.FC<SysSettingPageProps> = (
    props: SysSettingPageProps
) => {
  const {showSuccessBanner, hideBanner} = props;
  const history = useHistory();
  const [systemSettings, setSystemSettings] = useState<SystemSettingsProps>({
    techTagLimit: "",
    industryTagLimit: "",
    firstLineManagerNominateCountPerMonth: "",
  });
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [industryTagError, setIndustryTagError] = useState(false);
  const [techTagError, setTechTagError] = useState(false);
  const [nomiNumError, setNomiNumError] = useState(false);

  const getSystemSettings = async () => {
    setLoadingConfig(true);
    const settingsData: any = await appSyncRequest(getGsalConf, {});
    setLoadingConfig(false);
    if (settingsData?.data?.getGsalConf) {
      setSystemSettings(settingsData.data.getGsalConf);
    }
    console.info("settingsData:", settingsData);
  };

  const updateSystemSettings = async () => {
    if (industryTagError || techTagError || nomiNumError) {
      return;
    }

    setLoadingUpdate(true);
    const systemSettingsParam: SubmitGsalConfMutationVariables = {
      firstLineManagerNominateCountPerMonth: parseInt(
          systemSettings.firstLineManagerNominateCountPerMonth
      ),
      industryTagLimit: parseInt(systemSettings.industryTagLimit),
      techTagLimit: parseInt(systemSettings.techTagLimit),
    };
    try {
      const createPoCRes = await appSyncRequest(
          submitGsalConf,
          systemSettingsParam
      );
      console.info("createPoCRes:", createPoCRes);
      setLoadingUpdate(false);
      showSuccessBanner();
    } catch (error) {
      setLoadingUpdate(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getSystemSettings();
  }, []);

  useEffect(() => {
    console.info("systemSettings:", systemSettings);
    if (!isAPostiveNumber(systemSettings.industryTagLimit)) {
      setIndustryTagError(true);
    } else {
      setIndustryTagError(false);
    }
    if (!isAPostiveNumber(systemSettings.techTagLimit)) {
      setTechTagError(true);
    } else {
      setTechTagError(false);
    }
    if (
        !isAPostiveNumber(systemSettings.firstLineManagerNominateCountPerMonth)
    ) {
      setNomiNumError(true);
    } else {
      setNomiNumError(false);
    }
  }, [systemSettings]);

  return (
      <div>
        {loadingConfig ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div>
              <Container header={<Header variant="h2">System Settings</Header>}>
                <div>
                  <FormField
                      description="Allow user to choose maximum number tags of industry."
                      label="Maximum tag number of industry"
                      errorText={
                        industryTagError
                            ? "Must be a positive integer greater than 0"
                            : ""
                      }
                  >
                    <Input
                        className="m-w-45"
                        value={systemSettings.industryTagLimit}
                        type="number"
                        onChange={(event) => {
                          hideBanner();
                          setIndustryTagError(false);
                          setSystemSettings((prev) => {
                            return {
                              ...prev,
                              industryTagLimit: event.detail.value,
                            };
                          });
                        }}
                    />
                  </FormField>

                  <FormField
                      className="mt-20"
                      description="Allow user to choose maximum number tags of technology"
                      label="Maximum tag number of technology "
                      errorText={
                        techTagError
                            ? "Must be a positive integer greater than 0"
                            : ""
                      }
                  >
                    <Input
                        className="m-w-45"
                        value={systemSettings.techTagLimit}
                        type="number"
                        onChange={(event) => {
                          hideBanner();
                          setTechTagError(false);
                          setSystemSettings((prev) => {
                            return {
                              ...prev,
                              techTagLimit: event.detail.value,
                            };
                          });
                        }}
                    />
                  </FormField>

                  <FormField
                      className="mt-20"
                      description="Allow first line manager to nominate PoC maximum number per month."
                      label="Maximum nominate number"
                      errorText={
                        nomiNumError
                            ? "Must be a positive integer greater than 0"
                            : ""
                      }
                  >
                    <Input
                        className="m-w-45"
                        value={systemSettings.firstLineManagerNominateCountPerMonth}
                        type="number"
                        onChange={(event) => {
                          hideBanner();
                          setNomiNumError(false);
                          setSystemSettings((prev) => {
                            return {
                              ...prev,
                              firstLineManagerNominateCountPerMonth:
                              event.detail.value,
                            };
                          });
                        }}
                    />
                  </FormField>
                </div>
              </Container>
              <div className="mt-10">
                <div className="mt-20 pb-30 clearfix">
                  <SpaceBetween className="fr" direction="horizontal" size="xs">
                    <Button
                        variant="link"
                        onClick={() => {
                          history.push({pathname: "/"});
                        }}
                    >
                      Cancel
                    </Button>
                    <Button
                        loading={loadingUpdate}
                        variant="primary"
                        onClick={() => {
                          updateSystemSettings();
                        }}
                    >
                      Save
                    </Button>
                  </SpaceBetween>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

const SystemSettings: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const [notificationList, setNotificationList] = useState<any[]>([]);

  const pocReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "System Settings",
      href: "",
    },
  ];

  const saveSuccessNotification: FlashbarProps.MessageDefinition = {
    type: "success",
    content: `System settings update successfully.`,
    dismissible: true,
    onDismiss: () => {
      setNotificationList([]);
    },
  };

  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocReviewBreadcrumbs}/>}
          content={
            <SystemSettingsContent
                showSuccessBanner={() => {
                  setNotificationList([saveSuccessNotification]);
                }}
                hideBanner={() => {
                  setNotificationList([]);
                }}
            />
          }
          notifications={<Flashbar items={notificationList}/>}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav defaultActiveHref="/admin/system-settings"/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default SystemSettings;
