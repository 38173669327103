import React from "react";
import { StatusIndicator } from "@amzn/awsui-components-react/polaris";
import { SubmitMode } from "API";

interface SWAStatusProp {
  SWAStatus: SubmitMode | undefined | null;
}

const SWAStatus: React.FC<SWAStatusProp> = (props: SWAStatusProp) => {
  const {SWAStatus} = props;
  return (
      <div>
        {SWAStatus === SubmitMode.Submit && (
            <StatusIndicator type="success">Submit</StatusIndicator>
        )}
        {SWAStatus === SubmitMode.Draft && (
            <StatusIndicator type="stopped">Draft</StatusIndicator>
        )}
        {SWAStatus === SubmitMode.Abandon && (
            <StatusIndicator type="warning">Abandon</StatusIndicator>
        )}
      </div>
  );
};

export default SWAStatus;
