import React from "react";
import { StatusIndicator } from "@amzn/awsui-components-react/polaris";
import { EngageResult } from "API";

interface CustomerEngagedResultsProp {
  isInterested: EngageResult;
}

const CustomerEngageResults: React.FC<CustomerEngagedResultsProp> = (
    props: CustomerEngagedResultsProp
) => {
  const { isInterested } = props;
  return (
      <div>
        {isInterested === EngageResult.Yes && (
            <StatusIndicator type="success">Yes</StatusIndicator>
        )}
        {isInterested === EngageResult.No && (
            <StatusIndicator type="stopped">No</StatusIndicator>
        )}
      </div>
  );
};

export default CustomerEngageResults;
