import {
  GroupSvc,
  GroupSvcInput,
  Industry,
  Svc,
  UseCaseInput
} from 'API';
import React, { useEffect, useState } from 'react';
import { Header } from '@amzn/awsui-components-react/polaris';
import { Checkbox, Container, FormField, Spinner } from '@amzn/awsui-components-react';
import { appSyncRequest } from 'assets/js/request';
import { querySvc } from 'graphql/queries';

export interface IndustryType extends Industry {
  checked: boolean;
}

interface ServiceType extends GroupSvc {
  checked: boolean;
}

interface WorkloadServiceListType {
  groupId: string;
  groupName: string;
  serviceList: ServiceType[];
}

interface StepAccountInfoProps {
  curUseCase: UseCaseInput;
  changeServiceList: (svcList: GroupSvcInput[]) => void;
  setServiceIsLoading: (loading: boolean) => void;
  showServiceError: boolean;
}

const StepAccountInfo: React.FC<StepAccountInfoProps> = (props: StepAccountInfoProps) => {
  const {
      curUseCase,
      changeServiceList,
      setServiceIsLoading,
      showServiceError,
  } = props;

  const [loadingServiceList, setLoadingServiceList] = useState(false);
  const [workLoadServiceList, setWorkLoadServiceList] = useState<WorkloadServiceListType[]>([]);

  const getServiceList = async () => {
    setLoadingServiceList(true);
    setServiceIsLoading(true);
    const serviceListData: any = await appSyncRequest(querySvc, {
      curPage: 1,
      pageSize: 500,
      category: curUseCase.groupInUseList?.map(
          (element) => element?.categoryId
      ),
    });

    console.info("serviceListData:", serviceListData);
    const tmpSvcList: Svc[] = serviceListData?.data?.querySvc?.svcList || [];
    const tmpGroupInUseList = curUseCase.groupInUseList || [];
    const tmpGroupServiceList: WorkloadServiceListType[] = [];
    const tmpSvcInUseList = curUseCase.svcInUseList || [];
    tmpGroupInUseList.forEach((group) => {
      const svcListInCat: ServiceType[] = [];
      tmpSvcList.forEach((svc) => {
        if (svc.categoryId === group?.categoryId) {
          svcListInCat.push({
            __typename: "GroupSvc",
            svcName: svc.svcName,
            svcId: svc.svcId,
            groupId: group?.groupId,
            checked: tmpSvcInUseList.map((inUseSvc) => inUseSvc?.svcId).indexOf(svc.svcId) >= 0,
          });
        }
      });

      tmpGroupServiceList.push({
        groupId: group?.groupId || "",
        groupName: group?.groupName || "",
        serviceList: svcListInCat,
      });
    });
    console.info("tmpGroupServiceList:", tmpGroupServiceList);
    setWorkLoadServiceList(tmpGroupServiceList);
    setLoadingServiceList(false);
    setServiceIsLoading(false);
  };

  useEffect(() => {
    if (curUseCase.groupInUseList && curUseCase.groupInUseList.length > 0) {
      getServiceList();
    }
  }, [curUseCase.groupInUseList]);

  useEffect(() => {
    console.info("workLoadServiceList:", workLoadServiceList);
    const tmpSvcArr: GroupSvcInput[] = [];
    workLoadServiceList.forEach((element) => {
      if (element.serviceList) {
        element.serviceList.forEach((svc) => {
          if (svc.checked) {
            tmpSvcArr.push({
              groupId: element.groupId,
              svcId: svc.svcId,
              svcName: svc.svcName,
            });
          }
        });
      }
    });
    changeServiceList(tmpSvcArr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workLoadServiceList]);

  return (
      <div>
        <Header variant="h1">Services</Header>
        <div className="mt-10">
          <div className="mt-20">
            <Container header={<Header variant="h2">Service</Header> }>
              {loadingServiceList ? (
                  <Spinner/>
              ) : (
                  <div>
                    {workLoadServiceList.map((element, index) => {
                      return (
                          <div key={index}>
                            <div className="mt-10" style={{fontSize: 18}}>
                              {element.groupName}
                            </div>
                            <FormField
                                description="Select services the Use Case used."
                                errorText={
                                  showServiceError
                                      ? "Please select serval services."
                                      : ""
                                }
                            >
                              <div className="mb-10 flex flex-wrap">
                                {element.serviceList.map((svc, svcIndex) => {
                                  return (
                                      <div key={index} className="mt-5 w-25">
                                        <Checkbox
                                            onChange={(event) => {
                                              console.info("event:", event);
                                              setWorkLoadServiceList((prev) => {
                                                const tmpList = JSON.parse(
                                                    JSON.stringify(prev)
                                                );
                                                tmpList[index].serviceList[svcIndex].checked = event.detail.checked;
                                                return tmpList;
                                              });
                                            }}
                                            checked={svc.checked || false}
                                        >
                                          <div className="char-capital">{svc.svcName}</div>
                                        </Checkbox>
                                      </div>
                                  );
                                })}
                              </div>
                            </FormField>
                          </div>
                      );
                    })}
                  </div>
              )}
            </Container>
          </div>
        </div>
      </div>
  );
};

export default StepAccountInfo;