import React, { useContext, useEffect, useState } from "react";
import { AppLayout, Box, FormField, Input, Modal, SpaceBetween, } from "@amzn/awsui-components-react";
import { Storage } from "aws-amplify";
import { Button, Container, Grid, Header, Link, Spinner, TextContent, } from "@amzn/awsui-components-react/polaris";
import { appSyncRequest } from "assets/js/request";
import { getPoCById } from "graphql/queries";

import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { PoC, UserRole } from "API";
import { PHONE_TOOL_LINK, SIDE_MENU_IS_OPEN } from "assets/js/const";
import { withdrawPoC } from "graphql/mutations";
import AppContext from "context/AppContext";
// import ArchImg from "assets/images/arch.jpg";
// Mock Data

interface BPDetailContentProps {
  pocId: string;
  changePocName: (name: string) => void;
}

const BestPracticeDetailContent: React.FC<BPDetailContentProps> = (
    props: BPDetailContentProps
) => {
  const appConfig = useContext(AppContext);
  const history = useHistory();
  const {pocId, changePocName} = props;
  const [loadingData, setLoadingData] = useState(false);
  const [curPoc, setCurPoc] = useState<PoC>();
  const [archImgUrl, setArchImgUrl] = useState("");
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawPoCName, setWithdrawPoCName] = useState("");
  const [withdrawing, setWithdrawing] = useState(false);

  const getPoCInfoById = async () => {
    try {
      setLoadingData(true);
      const pocData: any = await appSyncRequest(getPoCById, {pocId: pocId});
      console.info("pocData:", pocData);
      if (pocData?.data?.getPoCById) {
        pocData.data.getPoCById.pocName = decodeURIComponent(
            pocData.data.getPoCById.pocName.replace(/\+/g, " ")
        );
        pocData.data.getPoCById.userScenarioDesc = decodeURIComponent(
            pocData.data.getPoCById.userScenarioDesc.replace(/\+/g, " ")
        );
        pocData.data.getPoCById.pocScope = decodeURIComponent(
            pocData.data.getPoCById.pocScope.replace(/\+/g, " ")
        );
        pocData.data.getPoCById.architectDesc = decodeURIComponent(
            pocData.data.getPoCById.architectDesc.replace(/\+/g, " ")
        );
        pocData.data.getPoCById.architectFilename = decodeURIComponent(
            pocData.data.getPoCById.architectFilename.replace(/\+/g, " ")
        );
        pocData.data.getPoCById.attachments.forEach((element: any) => {
          element.attachmentFilename = decodeURIComponent(
              element.attachmentFilename.replace(/\+/g, " ")
          );
          element.attachmentDesc = decodeURIComponent(
              element.attachmentDesc.replace(/\+/g, " ")
          );
        });
        setCurPoc(pocData.data.getPoCById);
      }
      // setCurPoc(pocData?.data?.getPoCById);

      if (pocData?.data?.getPoCById) {
        changePocName(pocData.data.getPoCById.pocName);
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFile = async (fileKey: string) => {
    // Creates download url that expires in 5 minutes/ 300 seconds
    const downloadUrl = await Storage.get(fileKey, {expires: 600});
    // window.location.href = downloadUrl.toString();
    window.open(downloadUrl.toString(), "_blank");
  };

  // Confirm to withdraw a PoC
  const confirmWithdrawPoC = async () => {
    try {
      setWithdrawing(true);
      const nominateData = await appSyncRequest(withdrawPoC, {
        input: {pocId: pocId},
      });
      setWithdrawing(false);
      setWithdrawOpen(false);
      console.info("nominateData:", nominateData);
      history.push({
        pathname: "/stars/best-practice",
      });
    } catch (error) {
      setWithdrawing(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getPoCInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show Arch Image When Archimage Change
  useEffect(() => {
    if (curPoc?.architectImgUrl) {
      Storage.get(curPoc.architectImgUrl, {expires: 300}).then((res) => {
        console.info("res:", res);
        setArchImgUrl(res.toString());
      });
    }
    return () => {
    };
  }, [curPoc?.architectImgUrl]);

  return (
      <div>
        {loadingData ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div>
              <div>
                <Modal
                    onDismiss={() => setWithdrawOpen(false)}
                    visible={withdrawOpen}
                    closeAriaLabel="Close"
                    size="medium"
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button
                              variant="link"
                              onClick={() => setWithdrawOpen(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                              loading={withdrawing}
                              onClick={() => {
                                confirmWithdrawPoC();
                              }}
                              disabled={
                                withdrawPoCName
                                    .trim()
                                    .localeCompare(curPoc?.pocName?.trim() || "") === 0
                                    ? false
                                    : true
                              }
                              variant="primary"
                          >
                            Withdraw PoC
                          </Button>
                        </SpaceBetween>
                      </Box>
                    }
                    header="Withdraw Best Practice"
                >
                  <FormField
                      label="To confirm withdraw, enter the name of the PoC in the text input field."
                      description=""
                  >
                    <Input
                        value={withdrawPoCName}
                        onChange={(event) => {
                          setWithdrawPoCName(event.detail.value);
                        }}
                    />
                  </FormField>
                </Modal>
                <div className="poc-withdraw-button">
                  {appConfig.userRole === UserRole.Administrator && (
                      <Button
                          onClick={() => {
                            setWithdrawOpen(true);
                          }}
                      >
                        Withdraw Best Practice
                      </Button>
                  )}
                </div>
                <TextContent>
                  <h3>What does this PoC do?</h3>
                  <p>{curPoc?.userScenarioDesc}</p>
                </TextContent>
                <Grid gridDefinition={[{colspan: 8}, {colspan: 4}]}>
                  <div>
                    <div className="mt-10">
                      {archImgUrl && (
                          <img
                              alt={curPoc?.architectFilename || "Architecture diagram"}
                              className="arch-img"
                              src={archImgUrl || ""}
                          />
                      )}
                    </div>
                    <TextContent className="mt-10">
                      <h3>{curPoc?.pocName} architect</h3>
                      <p>{curPoc?.architectDesc}</p>
                    </TextContent>
                  </div>
                  <div>
                    <div className="mt-10">
                      <Container
                          header={<Header variant="h2">{curPoc?.pocName}</Header>}
                      >
                        <TextContent>
                          <p>
                            Author: {curPoc?.contributor?.name} (
                            <Link
                                href={`${PHONE_TOOL_LINK}/${curPoc?.contributor?.userId}`}
                                external={true}
                            >
                              {curPoc?.contributor?.userId}
                            </Link>
                            )
                          </p>
                          <p>Created: {curPoc?.submitDateTime}</p>
                          {curPoc?.sourceCodeUrl && (
                              <p>
                                <Link
                                    href={`${curPoc?.sourceCodeUrl}`}
                                    external={true}
                                >
                                  Source Code
                                </Link>
                              </p>
                          )}
                          {curPoc?.userGuideUrl && (
                              <Link target="_blank" href={`${curPoc?.userGuideUrl}`}>
                                <Button
                                    className="w-100 mt-10 mb-10 text-center"
                                    variant="primary"
                                >
                                  View user guide
                                </Button>
                              </Link>
                          )}
                          <p>Attachments</p>
                          {curPoc?.attachments?.map((file, index) => {
                            return (
                                <p key={index}>
                            <span
                                onClick={() => {
                                  downloadFile(file?.attachmentUrl || "");
                                }}
                            >
                              <Link>{file?.attachmentFilename}</Link>
                            </span>{" "}
                                  - {file?.attachmentDesc}
                                </p>
                            );
                          })}
                          <div className="mt-10">
                            {curPoc?.industryTagList?.map((element, index) => {
                              return (
                                  <span className="bt-tag" key={index}>
                              {element?.tagName}
                            </span>
                              );
                            })}
                            {curPoc?.technicalTagList?.map((element, index) => {
                              return (
                                  <span className="bt-tag" key={index}>
                              {element?.tagName}
                            </span>
                              );
                            })}
                          </div>
                        </TextContent>
                      </Container>
                    </div>
                  </div>
                </Grid>
              </div>
            </div>
        )}
      </div>
  );
};

interface MatchParams {
  id: string;
}

const BestPracticeDetail: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const id: string = props.match.params.id;
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [pocName, setPocName] = useState("");
  const bestPracticeDetailBreadcrumbs = [
    {
      text: "Home",
      href: "/stars/best-practice",
    },
    {
      text: "Best Practice Library",
      href: "/stars/best-practice",
    },
    {
      text: pocName,
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={
            <BreadCrumb resourcesBreadcrumbs={bestPracticeDetailBreadcrumbs}/>
          }
          content={
            <BestPracticeDetailContent
                changePocName={(name: string) => {
                  setPocName(name);
                }}
                pocId={id}
            />
          }
          navigation={<SideNav defaultActiveHref="/stars/best-practice"/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default BestPracticeDetail;
