import React from "react";
import { StatusIndicator } from "@amzn/awsui-components-react/polaris";
import { ReviewStatus } from "API";

interface PoCReviewStatusProp {
  reviewStatus: string;
}

const PoCReviewStatus: React.FC<PoCReviewStatusProp> = (
    props: PoCReviewStatusProp
) => {
  const {reviewStatus} = props;
  return (
      <div>
        {reviewStatus === ReviewStatus.Assigned && (
            <StatusIndicator type="in-progress">Assigned</StatusIndicator>
        )}
        {reviewStatus === ReviewStatus.Done && (
            <StatusIndicator type="success">Done</StatusIndicator>
        )}
        {reviewStatus === ReviewStatus.Overdue && (
            <StatusIndicator type="warning">Overdue</StatusIndicator>
        )}
        {reviewStatus === ReviewStatus.No && (
            <StatusIndicator type="stopped">No</StatusIndicator>
        )}
      </div>
  );
};

export default PoCReviewStatus;
