import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppLayout, FlashbarProps } from "@amzn/awsui-components-react";
import { format } from "date-fns";
import { appSyncRequest } from "assets/js/request";
import { getUseCases } from "graphql/queries";
import {
  Box,
  Button,
  CollectionPreferences,
  Flashbar,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react/polaris";

import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";

import { SubmitMode, UseCaseAbstract, UserRole } from "API";
import { usecasePageConfig, usecaseVisibleConfig } from "./useCaseListConfig";
import {
  DEFAULT_PAGE_SIZE,
  SIDE_MENU_IS_OPEN,
  USECASE_LIST_PAGE_SIZE_ID,
  USECASE_LIST_VISIBLE_COLUMNS_ID,
} from "assets/js/const";
import { PageType } from "types";
import AppContext from "context/AppContext";
import AdvancedFilter, { TimeRangeTypes } from './comps/AdvancedFilter';

interface UseCaseListContentProps {
  isMyTeam?: boolean;
  isMyUseCase?: boolean;
  pageType: string;
  addFlashNotification: any;
}

const UseCaseListContent: React.FC<UseCaseListContentProps> = (
    props: UseCaseListContentProps
): JSX.Element => {
  let isItemSelected = 1;
  const {isMyUseCase, isMyTeam, pageType } = props;
  const appConfig = useContext(AppContext);
  const [loadingData, setLoadingData] = useState(false);
  const [useCaseList, setuseCaseList] = useState<UseCaseAbstract[]>([]);
  const [viewDetailDisable, setViewDetailDisable] = useState(true);
  const [customerEngageDisable, setCustomerEngageDisable] = useState(true);


  const [selectedItems, setSelectedItems] = useState<UseCaseAbstract[]>([]);
  const [selectedOneItem, setSelectedOneItem] = useState<UseCaseAbstract>();

  const [timeRangeSearch, setTimeRangeSearch] = useState<TimeRangeTypes>();
  // Pagination
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(
      parseInt(
          localStorage.getItem(USECASE_LIST_PAGE_SIZE_ID) || DEFAULT_PAGE_SIZE.toString()) || DEFAULT_PAGE_SIZE
  );
  const [totalCount, setTotalCount] = useState(0);

  const [visibleColumnsList, setVisibleColumnsList] = useState<any>(
      localStorage.getItem(USECASE_LIST_VISIBLE_COLUMNS_ID)?.split(",") || [
        "useCaseId",
        "contributor",
        "managerName",
        "subject",
        "submitDateTime",
        "serviceGroup",
        "createDateTime",
      ]
  );

  const history = useHistory();

  useEffect(() => {
    console.info("selectedItems:", selectedItems);
    if (selectedItems && selectedItems.length === 1) {
      setViewDetailDisable(false);
      setCustomerEngageDisable(false);
      setSelectedOneItem(selectedItems[0]);
    } else {
      setViewDetailDisable(true);
      setCustomerEngageDisable(true);
      setSelectedOneItem(undefined);
    }
  }, [selectedItems]);

  const goToDetail = () => {
    history.push({
      pathname: `/${pageType}/detail/${selectedOneItem?.useCaseId}`,
    });
  };

  const goToCustomer = () => {
    history.push({
      pathname: `/${pageType}/${selectedOneItem?.useCaseId}/${selectedOneItem?.workload?.map((element) => element?.groupId)[0]}/customer`,
    });
  };

  const getUseCaseList = useCallback(async () => {
    try {
      setuseCaseList([]);
      setLoadingData(true);
      let contributorValues: string[] = [];
      const useCaseListDta: any = await appSyncRequest(getUseCases, {
        input: {
          teamId: pageType === "usecase-my-team" ? appConfig.userId : undefined,
          useCaseStatus: pageType === "my-usecase" ? undefined : SubmitMode.Submit,
          useCaseIds: undefined,
          supervisorId:
              isMyTeam && appConfig.userRole === UserRole.Reviewer
                  ? appConfig.userId
                  : undefined,
          managerId:
              isMyTeam && appConfig.userRole === UserRole.Manager
                  ? appConfig.userId
                  : undefined,
          contributorIds: isMyUseCase
              ? appConfig.userId
              : contributorValues ||
              undefined,
          useCaseId:
              undefined,
          startDate:
              (timeRangeSearch?.startTime &&
                  format(
                      new Date(timeRangeSearch?.startTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          endDate:
              (timeRangeSearch?.endTime &&
                  format(
                      new Date(timeRangeSearch?.endTime),
                      "yyyy-MM-dd HH:mm:ss"
                  )) ||
              undefined,
          curPage: curPage,
          pageSize: pageSize,
        },
      });
      setuseCaseList(useCaseListDta?.data?.getUseCases?.useCases || []);
      setTotalCount(useCaseListDta?.data?.getUseCases?.totalCount || 0);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, pageType, timeRangeSearch]);

  useEffect(() => {
    console.info("pageType Changed:", pageType);
    setCurPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  useEffect(() => {
    getUseCaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize, pageType, timeRangeSearch]);

  // @ts-ignore
  // @ts-ignore
  return (
      <>
        <Table
            trackBy="useCaseId"
            stickyHeader={true}
            resizableColumns={true}
            ariaLabels={{
              selectionGroupLabel: "Items selection",
              allItemsSelectionLabel: ({selectedItems}) =>
                  `${selectedItems?.length} ${
                      selectedItems?.length === 1 ? "item" : "items"
                  } selected`,
              itemSelectionLabel: ({selectedItems}, item) => {
                isItemSelected = selectedItems.filter(
                    (i) => i.useCaseId === item.useCaseId
                )?.length;
                return `${item.useCaseId} is ${isItemSelected ? "" : "not"} selected`;
              },
            }}
            columnDefinitions={[
              {
                minWidth: 240,
                id: "useCaseId",
                header: "Use Case ID",
                cell: (item) => (
                    <Link href={`/${pageType}/detail/${item.useCaseId}`}>{item.useCaseId}</Link>
                ),
              },
              {
                minWidth: 80,
                id: "contributor",
                header: "WWSO GTM",
                cell: (e) => e.contributor?.name,
              },
              {
                minWidth: 120,
                id: "managerName",
                header: "Manager",
                cell: (e) => e.contributor?.managerName,
              },
              {
                id: "subject",
                header: "Use Case Name",
                cell: (e) => e.useCaseName,
              },
              {
                id: "serviceGroup",
                header: "Service Group",
                cell: (e) =>
                    e.workload?.map((element) => element?.groupName).join(", "),
              },
              {
                id: "createDateTime",
                header: "Create Time",
                minWidth: 120,
                cell: (e) => {
                  return e.createDateTime
                      ? format(
                          new Date(e.createDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
              {
                id: "submitDateTime",
                header: "Submit Time",
                minWidth: 120,
                cell: (e) => {
                  return e.submitDateTime
                      ? format(
                          new Date(e.submitDateTime.replace(/-/g, "/")),
                          "yyyy-MM-dd"
                      )
                      : "-";
                },
              },
            ]}
            onSelectionChange={({detail}) =>
                setSelectedItems(detail.selectedItems)
            }
            loading={loadingData}
            selectedItems={selectedItems}
            items={useCaseList}
            loadingText="Loading UseCases"
            selectionType="single"
            visibleColumns={visibleColumnsList}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No UseCases</b>
                <Box padding={{bottom: "s"}} variant="p" color="inherit">
                  No UseCases to display.
                </Box>
              </Box>
            }
            filter={
              <AdvancedFilter
                  pageType={pageType}
                  setStartLoading={(loading) => {
                    setLoadingData(loading);
                  }}
                  changeFilters={(filters) => {
                    //setAdvancedSearch(filters);
                    console.info("filters:", filters);
                  }}
                  changeTimeRange={(timeRange) => {
                    setTimeRangeSearch(timeRange);
                  }}
              />
            }
            header={
              <Header
                  counter={
                    selectedItems?.length
                        ? "(" + selectedItems?.length + "/" + useCaseList?.length + ")"
                        : "(" + useCaseList?.length + ")"
                  }
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                          loading={loadingData}
                          disabled={loadingData}
                          iconName="refresh"
                          onClick={() => {
                            getUseCaseList();
                          }}
                      ></Button>
                      <Button
                          disabled={viewDetailDisable}
                          variant="normal"
                          onClick={() => {
                            goToDetail();
                          }}
                      >
                        View details
                      </Button>
                      {((appConfig.userRole === UserRole.Contributor ||
                          appConfig.userRole === UserRole.Manager ||
                          appConfig.userRole === UserRole.Reviewer) && (
                          <Button
                              disabled={customerEngageDisable}
                              variant="primary"
                              onClick={() => {
                                goToCustomer();
                              }}
                          >
                            Customer Engage
                          </Button>
                      ))}
                    </SpaceBetween>
                  }
              >
                Use Case
              </Header>
            }
            pagination={
              <Pagination
                  disabled={loadingData}
                  currentPageIndex={curPage}
                  pagesCount={Math.ceil(totalCount / pageSize)}
                  onChange={(e) => {
                    setCurPage(e.detail.currentPageIndex);
                  }}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                  }}
              />
            }
            preferences={
              <CollectionPreferences
                  title="Preferences"
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
                  preferences={{
                    pageSize: pageSize,
                    visibleContent: visibleColumnsList,
                  }}
                  onConfirm={(event) => {
                    localStorage.setItem(
                        USECASE_LIST_VISIBLE_COLUMNS_ID,
                        event.detail.visibleContent?.join(",") || ""
                    );
                    localStorage.setItem(
                        USECASE_LIST_PAGE_SIZE_ID,
                        event.detail.pageSize?.toString() || DEFAULT_PAGE_SIZE.toString()
                    );
                    setCurPage(1);
                    setPageSize(event.detail.pageSize || DEFAULT_PAGE_SIZE);
                    setVisibleColumnsList(event.detail.visibleContent);
                  }}
                  pageSizePreference={usecasePageConfig}
                  visibleContentPreference={usecaseVisibleConfig}
              />
            }
        />
      </>
  );
};

const UseCaseList: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [notificationList, setNotificationList] = useState<FlashbarProps.MessageDefinition[]>([]);

  const param = useLocation();
  const type: string = param.pathname.replace("/", "");
  const pathStr = param.pathname;

  console.info("PageType: {}", type)

  let useCaseListBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];
  if (type === PageType.ALL_USECASE) {
    useCaseListBreadcrumbs = [
      ...useCaseListBreadcrumbs,
      {
        text: "Use Case",
        href: "/",
      },
    ];
  }
  if (type === "usecase-my-team") {
    useCaseListBreadcrumbs = [
      ...useCaseListBreadcrumbs,
      {
        text: "My team’s Use Case",
        href: "",
      },
    ];
  }
  // show my Use Case in breadcrumb when contributor
  if (pathStr === "/my-usecase") {
    useCaseListBreadcrumbs = [
      ...useCaseListBreadcrumbs,
      {
        text: "My Use Case",
        href: "/",
      },
    ];
  }
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={useCaseListBreadcrumbs}/>}
          notifications={<Flashbar items={notificationList}/>}
          content={
            <UseCaseListContent
                isMyTeam={pathStr === "/usecase-my-team" ? true : false}
                isMyUseCase={pathStr === "/my-usecase" ? true : false}
                pageType={type}
                addFlashNotification={(notification: any) => {
                  setNotificationList(notification);
                }}
            />
          }
          navigation={
            <SideNav
                defaultActiveHref={
                  pathStr === "/my-usecase" ? "/my-usecase" : type ? "/" + type : "/"
                }
            />
          }
          navigationOpen={navigationOpen}
          stickyNotifications
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default UseCaseList;
