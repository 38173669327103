import React, { useCallback, useEffect, useState } from "react";
import {
  AppLayout,
  Box,
  Button,
  CollectionPreferences,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react";
import {
  CROSS_TEAM_REVIEW_PAGE_SIZE_ID,
  CROSS_TEAM_REVIEW_VISIBLE_COLUMNS_ID,
  SIDE_MENU_IS_OPEN,
} from "assets/js/const";
import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";
import { useHistory } from "react-router-dom";
import { ReviewTaskAbstract } from "API";
import { appSyncRequest } from "assets/js/request";
import { getCrossTeamReviewTasks } from "graphql/queries";
import CrossTeamReviewStatus from "pages/comps/CrossTeamReviewStatus";
import { crossTeamReviewPageConfig, crossTeamReviewVisibleConfig, } from "pages/poc/pocListConfig";

const CrossTeamReviewContent: React.FC = () => {
  const history = useHistory();
  const [loadingData, setLoadingData] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(
      parseInt(localStorage.getItem(CROSS_TEAM_REVIEW_PAGE_SIZE_ID) || "10") || 10
  );
  const [totalCount, setTotalCount] = useState(0);
  const [crossTeamReviewList, setCrossTeamReviewList] = useState<ReviewTaskAbstract[]>([]);
  const [visibleColumnsList, setVisibleColumnsList] = useState<any>(
      localStorage.getItem(CROSS_TEAM_REVIEW_VISIBLE_COLUMNS_ID)?.split(",") || [
        "reviewTaskId",
        "startDate",
        "endDate",
        "numberOfPoCs",
        "createBy",
        "created",
        "status",
      ]
  );

  const getReviewTaskList = useCallback(async () => {
    try {
      setLoadingData(true);
      const reviewTaskData: any = await appSyncRequest(
          getCrossTeamReviewTasks,
          {
            input: {
              curPage: curPage,
              pageSize: pageSize,
            },
          }
      );
      setCrossTeamReviewList(
          reviewTaskData?.data?.getCrossTeamReviewTasks?.tasks || []
      );
      setTotalCount(
          reviewTaskData?.data?.getCrossTeamReviewTasks?.totalCount || 0
      );
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage]);

  useEffect(() => {
    getReviewTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReviewTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage, pageSize]);

  return (
      <div>
        <Table
            trackBy="reviewTaskId"
            loading={loadingData}
            columnDefinitions={[
              {
                id: "reviewTaskId",
                header: "ID",
                cell: (item) => (
                    <Link
                        href={`/admin/create-cross-team-review/${item.reviewTaskId}`}
                    >
                      {item.reviewTaskId}
                    </Link>
                ),
              },
              {
                id: "startDate",
                header: "Start date",
                cell: (item) => item.startDate,
              },
              {
                id: "endDate",
                header: "End date",
                cell: (item) => item.endDate,
              },
              {
                id: "numberOfPoCs",
                header: "# of PoCs",
                cell: (item) => item.numberOfPoCs,
              },
              {
                id: "createBy",
                header: "Created by",
                cell: (item) => item.createBy?.name,
              },
              {
                id: "created",
                header: "Created",
                cell: (item) => item.createDateTime,
              },
              {
                id: "status",
                header: "Status",
                cell: (item) => {
                  return (
                      <CrossTeamReviewStatus ctReviewStatus={item.taskStatus || ""}/>
                  );
                },
              },
            ]}
            items={crossTeamReviewList}
            loadingText="Loading Data"
            visibleColumns={visibleColumnsList}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No resources</b>
                <Box padding={{bottom: "s"}} variant="p" color="inherit">
                  No resources to display.
                </Box>
              </Box>
            }
            header={
              <Header
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                          loading={loadingData}
                          disabled={loadingData}
                          iconName="refresh"
                          onClick={() => {
                            if (curPage === 1) {
                              getReviewTaskList();
                            } else {
                              setCurPage(1);
                            }
                          }}
                      ></Button>
                      <Button
                          variant="primary"
                          onClick={() => {
                            history.push({
                              pathname: "/admin/create-cross-team-review",
                            });
                          }}
                      >
                        Start a cross-team review
                      </Button>
                    </SpaceBetween>
                  }
              >
                Cross-team reviews
              </Header>
            }
            preferences={
              <CollectionPreferences
                  title="Preferences"
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
                  preferences={{
                    pageSize: pageSize,
                    visibleContent: visibleColumnsList,
                  }}
                  onConfirm={(event) => {
                    localStorage.setItem(
                        CROSS_TEAM_REVIEW_VISIBLE_COLUMNS_ID,
                        event.detail.visibleContent?.join(",") || ""
                    );
                    localStorage.setItem(
                        CROSS_TEAM_REVIEW_PAGE_SIZE_ID,
                        event.detail.pageSize?.toString() || "10"
                    );
                    setCurPage(1);
                    setPageSize(event.detail.pageSize || 10);
                    setVisibleColumnsList(event.detail.visibleContent);
                  }}
                  pageSizePreference={crossTeamReviewPageConfig}
                  visibleContentPreference={crossTeamReviewVisibleConfig}
              />
            }
            pagination={
              <Pagination
                  currentPageIndex={curPage}
                  pagesCount={Math.ceil(totalCount / pageSize)}
                  onChange={(event) => {
                    setCurPage(event.detail.currentPageIndex);
                  }}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                  }}
              />
            }
        />
      </div>
  );
};

const CrossTeamReview: React.FC = () => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const pocReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Cross-team reviews(PoC)",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocReviewBreadcrumbs}/>}
          content={<CrossTeamReviewContent/>}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav defaultActiveHref="/admin/cross-team-review"/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default CrossTeamReview;
