import React, { useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { AppLayout } from "@amzn/awsui-components-react";
import BreadCrumb from "common/BreadCrumb";
import SideNav from "common/SideNav";
import { SIDE_MENU_IS_OPEN } from "assets/js/const";
import DetailCompUseCase from "./DetailCompUseCase";
import { PageType } from '../../types'

interface MatchParams {
  id: string;
  ctReviewId: string;
}

const UseCaseDetail: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const id: string = props.match.params.id;

  const param = useLocation();
  const type: string = param.pathname.split("/")[1];

  let useCaseDetailBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
  ];
  if (type === PageType.ALL_USECASE) {
    useCaseDetailBreadcrumbs = [
      ...useCaseDetailBreadcrumbs,
      {
        text: "Use Case",
        href: "/usecase-all",
      },
    ];
  }
  if (type === "usecase-my-team") {
    useCaseDetailBreadcrumbs = [
      ...useCaseDetailBreadcrumbs,
      {
        text: "My team's Use Case",
        href: "/usecase-my-team",
      },
    ];
  }
  if (type === "my-usecase") {
    useCaseDetailBreadcrumbs = [
      ...useCaseDetailBreadcrumbs,
      {
        text: "My Use Case",
        href: "/my-usecase",
      },
    ];
  }
  if (type === PageType.MY_ENGAGEMENT) {
    useCaseDetailBreadcrumbs = [
      ...useCaseDetailBreadcrumbs,
      {
        text: "Customer Engagement",
        href: "/my-engagement",
      },
    ];
  }
  if (type === PageType.MY_TEAM_ENGAGAMENT) {
    useCaseDetailBreadcrumbs = [
      ...useCaseDetailBreadcrumbs,
      {
        text: "My team's Customer Engagement",
        href: "/engagement-my-team",
      },
    ];
  }

  useCaseDetailBreadcrumbs = [
    ...useCaseDetailBreadcrumbs,
    {
      text: id.toUpperCase(),
      href: "",
    },
  ];

  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={useCaseDetailBreadcrumbs}/>}
          content={<DetailCompUseCase tabId="overview" useCaseId={id}/>}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default UseCaseDetail;
