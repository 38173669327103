import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { getPoCById } from "graphql/queries";
import { nominatePoC } from "graphql/mutations";

import {
  Box,
  Button,
  ColumnLayout,
  Container,
  FlashbarProps,
  Header,
  Link,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from "@amzn/awsui-components-react/polaris";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import Modal from "@amzn/awsui-components-react/polaris/modal";

import ValueWithLabel from "common/ValueWithLabel";

import Review from "./tab/Review";
import Assets from "./tab/Assets";
import { BestPracticeStatus, PoC, ReviewStatus, SubmitMode, UserRole, } from "API";
import { PHONE_TOOL_LINK, POC_NOT_FOUND_ID } from "assets/js/const";
import { buildOppLink, handleNullValue } from "assets/js/utils";
import AppContext from "context/AppContext";
import PoCStatus from "pages/comps/PoCStatus";

interface DetailContentProps {
  addFlashNotification: (notification: any[]) => void;
  pocId: string;
  tabId: string;
  pageType?: string;
}

const DetailComp: React.FC<DetailContentProps> = (
    props: DetailContentProps
): JSX.Element => {
  const {pocId, tabId, pageType, addFlashNotification} = props;
  const appConfig = useContext(AppContext);
  const history = useHistory();
  // const urlStr = window.location.href.split("?")[1];
  const [visible, setVisible] = useState(false);
  const [activeTabId, setActiveTabId] = useState(tabId || "overview");
  const [loadingData, setLoadingData] = useState(false);
  const [nominating, setNominating] = useState(false);
  const [curPoc, setCurPoc] = useState<PoC>();

  const successNominateNotification: FlashbarProps.MessageDefinition = {
    type: "success",
    content: `Nominate successfully. An email notification has been sent to ${curPoc?.contributor?.name}(${curPoc?.contributor?.userId}).`,
    dismissible: true,
    onDismiss: () => {
      addFlashNotification([]);
    },
  };

  const nominatePoCBP = async () => {
    try {
      setNominating(true);
      const nominateData = await appSyncRequest(nominatePoC, {
        input: {pocId: pocId, nominatedBy: appConfig.userId},
      });
      setNominating(false);
      setVisible(false);
      console.info("nominateData:", nominateData);
      addFlashNotification([successNominateNotification]);
      getPoCInfoById();
    } catch (error) {
      setNominating(false);
      console.error(error);
    }
  };

  const getPoCInfoById = async () => {
    try {
      setLoadingData(true);
      const pocData: any = await appSyncRequest(getPoCById, {pocId: pocId});
      const pocInfo: PoC = pocData?.data?.getPoCById;
      if (pocInfo.pocId === POC_NOT_FOUND_ID) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        console.info("pocData:", pocData);
        if (pocData?.data?.getPoCById) {
          pocData.data.getPoCById.pocName = decodeURIComponent(
              pocData.data.getPoCById.pocName.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.userScenarioDesc = decodeURIComponent(
              pocData.data.getPoCById.userScenarioDesc.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.pocScope = decodeURIComponent(
              pocData.data.getPoCById.pocScope.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.architectDesc = decodeURIComponent(
              pocData.data.getPoCById.architectDesc.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.architectFilename = decodeURIComponent(
              pocData.data.getPoCById.architectFilename.replace(/\+/g, " ")
          );
          pocData.data.getPoCById.attachments.forEach((element: any) => {
            element.attachmentFilename = decodeURIComponent(
                element.attachmentFilename.replace(/\+/g, " ")
            );
            element.attachmentDesc = decodeURIComponent(
                element.attachmentDesc.replace(/\+/g, " ")
            );
          });
          setCurPoc(pocData.data.getPoCById);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getPoCInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div>
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                      variant="link"
                      onClick={() => {
                        setVisible(false);
                      }}
                  >
                    Cancel
                  </Button>
                  <Button
                      disabled={nominating}
                      loading={nominating}
                      variant="primary"
                      onClick={() => {
                        nominatePoCBP();
                      }}
                  >
                    Confirm
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Nominate Best Practice"
        >
          {`PoC Best Practice Library is a collection of high quality, bar-raising
        PoCs. Are you sure you want to nominate “${
              curPoc?.pocSubject || ""
          }” contributed by ${curPoc?.contributor?.name} ( ${
              curPoc?.contributor?.userId
          }) as a PoC Best practice.`}
        </Modal>
        <div className="mb-10">
          <Header
              variant="h1"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {!loadingData &&
                      // Manager can Only Nominate pocs of his team.
                      ((appConfig.userRole === UserRole.Manager &&
                              curPoc?.contributor?.managerId === appConfig.userId) ||
                          (appConfig.userRole === UserRole.Reviewer &&
                              curPoc?.contributor?.supervisorId === appConfig.userId) ||
                          // Administrator can Nominate pocs.
                          appConfig.userRole === UserRole.Administrator) &&
                      curPoc?.bestPracticeStatus !== BestPracticeStatus.Filled &&
                      curPoc?.bestPracticeStatus !== BestPracticeStatus.Nominated &&
                      curPoc?.bestPracticeStatus !== BestPracticeStatus.Yes &&
                      curPoc?.pocStatus === SubmitMode.Submit && (
                          <Button
                              onClick={() => {
                                setVisible(true);
                              }}
                          >
                            Nominate as Best Pratice
                          </Button>
                      )}
                  {appConfig.userId === curPoc?.contributor?.userId && (
                      <Button
                          onClick={() => {
                            history.push({
                              pathname: "/poc/edit/" + pocId,
                            });
                          }}
                      >
                        Edit
                      </Button>
                  )}
                </SpaceBetween>
              }
          >
            {pocId.toUpperCase()}
          </Header>
        </div>
        <Container header={<Header variant="h2">PoC Detail</Header>}>
          {loadingData ? (
              <div>
                <Spinner size="normal"/>
              </div>
          ) : (
              <div>
                <div>
                  <ColumnLayout columns={4} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Subject">
                        {handleNullValue(curPoc?.pocSubject)}
                      </ValueWithLabel>
                      <ValueWithLabel label="Team">
                        {handleNullValue(curPoc?.contributor?.teamName)}
                      </ValueWithLabel>
                      <ValueWithLabel label="Opportunity">
                        {curPoc?.opptyId ? (
                            <Link external href={buildOppLink(curPoc.opptyId)}>
                              {curPoc.opptyId}
                            </Link>
                        ) : (
                            "-"
                        )}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="SA">
                        {curPoc?.contributor?.name} (
                        <Link
                            href={`${PHONE_TOOL_LINK}/${curPoc?.contributor?.userId}`}
                            external={true}
                        >
                          {handleNullValue(curPoc?.contributor?.userId)}
                        </Link>
                        )
                      </ValueWithLabel>
                      <ValueWithLabel label="Supervisor">
                        {curPoc?.contributor?.supervisorId} (
                        <Link
                            href={`${PHONE_TOOL_LINK}/${curPoc?.contributor?.supervisorId}`}
                            external={true}
                        >
                          {curPoc?.contributor?.supervisorId}
                        </Link>
                        )
                      </ValueWithLabel>
                      <ValueWithLabel label="PoC Status">
                        <div>
                          <PoCStatus pocStatus={curPoc?.pocStatus}/>
                        </div>
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Review">
                        {curPoc?.reviewStatus === ReviewStatus.Done ? (
                            <StatusIndicator>Done</StatusIndicator>
                        ) : (
                            <span>{curPoc?.reviewStatus || "-"}</span>
                        )}
                      </ValueWithLabel>
                      <ValueWithLabel label="Manager">
                        {curPoc?.contributor?.managerName} (
                        <Link
                            href={`${PHONE_TOOL_LINK}/${curPoc?.contributor?.managerId}`}
                            external={true}
                        >
                          {curPoc?.contributor?.managerId}
                        </Link>
                        )
                      </ValueWithLabel>

                      <ValueWithLabel label="Efforts">
                        {curPoc?.efforts === undefined ||
                        curPoc?.efforts === null ? (
                            <div>-</div>
                        ) : (
                            <div>
                              {curPoc?.efforts}{" "}
                              {(curPoc?.efforts || 0) > 1 ? "hours" : "hour"}
                            </div>
                        )}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Best Practice">
                        {curPoc?.bestPracticeStatus === BestPracticeStatus.Yes ? (
                            <StatusIndicator>Yes</StatusIndicator>
                        ) : (
                            <div>{curPoc?.bestPracticeStatus || "-"}</div>
                        )}
                      </ValueWithLabel>
                      <ValueWithLabel label="Reviewer">
                        {curPoc && curPoc.assignee && curPoc.assignee.name ? (
                            <div>
                              {curPoc?.assignee?.name}(
                              <Link
                                  href={`${PHONE_TOOL_LINK}/${curPoc?.assignee?.userId}`}
                                  external={true}
                              >
                                {curPoc?.assignee?.userId}
                              </Link>
                              )
                            </div>
                        ) : (
                            "-"
                        )}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                </div>
              </div>
          )}
        </Container>
        <div className="mt-10">
          <Tabs
              onChange={(event) => {
                console.info("event.detail.activeTabId:", event.detail.activeTabId);
                setActiveTabId(event.detail.activeTabId);
                if (pageType !== "CROSS") {
                  window.history.pushState(
                      {},
                      "",
                      `/poc/detail/${pocId}/${event.detail.activeTabId}`
                  );
                }
              }}
              activeTabId={activeTabId}
              tabs={[
                {
                  label: "Overview",
                  id: "overview",
                  content: (
                      <Assets loading={loadingData} pocInfo={curPoc} pocId={pocId}/>
                  ),
                },
                {
                  label: "Score",
                  id: "score",
                  content: (
                      <Review
                          pocId={pocId}
                          assigneeId={curPoc?.assignee?.userId}
                          pocNominateStatus={curPoc?.bestPracticeStatus}
                          pocReviewStatus={curPoc?.reviewStatus}
                      />
                  ),
                },
              ]}
          />
        </div>
      </div>
  );
};

export default DetailComp;
