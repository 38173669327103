import React, { useEffect, useState } from "react";
import { Checkbox, Container, FormField, Header, Spinner, } from "@amzn/awsui-components-react";
import { appSyncRequest } from "assets/js/request";
import { queryCategory } from "graphql/queries";
import { Category, CategoryInput, SWAInput } from "API";

export interface CategoryType extends Category {
  checked?: boolean;
}

interface StepWorkloadProps {
  curSWA: SWAInput;
  showWorkloadError: boolean;
  changeCategoryInUse: (category: CategoryInput[]) => void;
  setWorkloadIsLoading: (loading: boolean) => void;
}

const StepSWAWorkLoad: React.FC<StepWorkloadProps> = (
    props: StepWorkloadProps
) => {
  const {
    curSWA,
    showWorkloadError,
    changeCategoryInUse,
    setWorkloadIsLoading,
  } = props;
  const [loadingWordload, setLoadingWordload] = useState(false);
  const [workLoadList, setWorkLoadList] = useState<CategoryType[]>([]);

  // Get System Settings
  const getWorkloadList = async () => {
    setLoadingWordload(true);
    setWorkloadIsLoading(true);
    const workloadData: any = await appSyncRequest(queryCategory, {
      curPage: 1,
      pageSize: 100,
    });
    console.info("workloadData:", workloadData);
    const tmpCategoryList: CategoryType[] =
        workloadData?.data?.queryCategory?.categoryList || [];
    const workloadCategoryInUseList = curSWA.categoryInUseList || [];
    tmpCategoryList.forEach((element) => {
      if (
          workloadCategoryInUseList
              .map((cele) => cele?.categoryId)
              .indexOf(element.categoryId) >= 0
      ) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
    setWorkLoadList(workloadData?.data?.queryCategory?.categoryList || []);
    setLoadingWordload(false);
    setWorkloadIsLoading(false);
  };

  useEffect(() => {
    getWorkloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.info("workLoadList:", workLoadList);
    const tmpCategoryArr: CategoryInput[] = [];
    workLoadList.forEach((element) => {
      if (element.checked) {
        tmpCategoryArr.push({
          categoryId: element.categoryId,
          categoryName: element.categoryName,
        });
      }
    });
    changeCategoryInUse(tmpCategoryArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workLoadList]);

  return (
      <div>
        <Header variant="h1">Strategical workload</Header>
        <div className="mt-10">
          <Container header={<Header variant="h2">Strategical workload</Header>}>
            {loadingWordload ? (
                <Spinner/>
            ) : (
                <FormField
                    label="Choose Strategical workload "
                    description=" Should be multiple choices since it is possible that for one workload, more than 1 strategical workload is adopted."
                    errorText={
                      showWorkloadError
                          ? "Please select at least one strategical workload"
                          : ""
                    }
                >
                  <div className="mt-10 mb-10">
                    <div className="flex flex-wrap">
                      {workLoadList.map((element, index) => {
                        return (
                            <div key={index} className="mt-5 w-25">
                              <Checkbox
                                  onChange={(event) => {
                                    console.info("event:", event);
                                    setWorkLoadList((prev) => {
                                      const tmpList = JSON.parse(JSON.stringify(prev));
                                      tmpList[index].checked = event.detail.checked;
                                      return tmpList;
                                    });
                                  }}
                                  checked={element.checked || false}
                              >
                                <div className="char-capital">
                                  {element.categoryName}
                                </div>
                              </Checkbox>
                            </div>
                        );
                      })}
                    </div>
                  </div>
                </FormField>
            )}
          </Container>
        </div>
      </div>
  );
};

export default StepSWAWorkLoad;
