import React from "react";
import { Auth } from "aws-amplify";
import { AMPLIFY_CONFIG_JSON } from "assets/js/const";

type propsType = {
  className?: string;
};

const SignOut: React.FC<propsType> = (props: propsType) => {
  const signOut = async () => {
    localStorage.removeItem(AMPLIFY_CONFIG_JSON);
    await Auth.signOut({global: true});
    window.location.reload();
  };

  return (
      <div className={props.className} onClick={signOut}>
        (<span>Sign Out</span>)
      </div>
  );
};

export default SignOut;
