import React from 'react';
import { Container, FormField, Header, RadioGroup, Textarea, } from '@amzn/awsui-components-react/polaris';
import { SWAReviewResponse } from '../../../../API';


interface StepPassProps {
  currentReview: SWAReviewResponse | undefined;
  changeReviewPass: (item: any) => void;
  setLengthError: (isError: boolean) => void;
  swaId: string;
}

const StepPass: React.FC<StepPassProps> = (
    props: StepPassProps
) => {
  const {currentReview, changeReviewPass, setLengthError} = props;

  return (
      <div className="mt-10">
        <Container header={<Header variant="h2">SWA Review</Header>}>
          <FormField
              description="Review and give the result."
              label="Result of the Review?"
          >
            <RadioGroup
                className="gsal-radio-group"
                onChange={({detail}) => {
                  if (currentReview) {
                    const tmpData = JSON.parse(JSON.stringify(currentReview));
                    tmpData.isPass = detail.value;
                    changeReviewPass(tmpData);
                  }
                }}
                value={currentReview?.isPass?.toString() || null}
                items={[
                  {value: "true", label: "Passed"},
                  {value: "false", label: "Not Passed"}
                ]}
            />
          </FormField>
          <div className="mt-30">
            <FormField
                description="The overall comment of the SWA."
                label="Overall Comment"
                errorText={
                  (currentReview?.comments?.length || 0) > 256
                      ? "Comments must be less than 256 characters."
                      : ""
                }
            >
              <Textarea
                  onChange={({detail}) => {
                    if (currentReview) {
                      const tmpData = JSON.parse(JSON.stringify(currentReview));
                      if (detail.value.length > 256) {
                        setLengthError(true);
                      } else {
                        setLengthError(false);
                      }
                      tmpData.comments = detail.value;
                      changeReviewPass(tmpData);
                    }
                  }}
                  value={currentReview?.comments || ""}
                  placeholder="Input the overall comments of the SWA"
              />
            </FormField>
          </div>
        </Container>
      </div>
  );
};

export default StepPass;


