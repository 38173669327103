import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from 'context/AppContext';
import { SubmitMode, UseCaseInput, UserRole } from 'API';
import { AppLayout, Button, SpaceBetween } from '@amzn/awsui-components-react';
import BreadCrumb from 'common/BreadCrumb';
import SideNav from 'common/SideNav';
import { CreateUseCaseStep, SIDE_MENU_IS_OPEN, USER_ACK_USECASE_DEFININATION } from 'assets/js/const';
import { appSyncRequest } from 'assets/js/request';
import { submitUseCase } from 'graphql/mutations';
import StepUseCaseDetails from '../comps/step/StepUseCaseDetails';
import StepAccountInfo from '../comps/step/StepAccountInfo';
import StepUseCaseAttachements from '../comps/step/StepUseCaseAttachements';

const CreateUseCaseContent: React.FC = () => {
  const history = useHistory();
  const appConfig = useContext(AppContext);
  const [activeStepIndex, setActiveStepIndex] = useState(CreateUseCaseStep.UseCaseDetailStepIndex);
  const [createUseCaseInfo, setCreateUseCaseInfo] = useState<UseCaseInput>({
    mode: SubmitMode.Draft,
    contributorId: appConfig.userId,
    useCaseName: "",
    useCaseDesc: "",
    sfdcAccountId: "",
    svcInUseList: [],
    groupInUseList: [],
    industryInUseList: [],
    architectDesc: "",
    architectDiagrams: [],
    attachments: [],
  });

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [showWorkloadRequireError, setShowWorkloadRequireError] = useState(false);
  const [showIndustryError, setShowIndustryError] = useState(false);
  const [showServiceError, setShowServiceError] = useState(false);

  const [workloadIsLoading, setWorkloadIsLoading] = useState(false);
  const [svcIsLoading, setSvcIsLoading] = useState(false);
  const [showGroupError, setShowGroupError] = useState(false);
  const [groupIsLoading, setGroupIsLoading] = useState(false);

  const [showNameEmptyError, setShowNameEmptyError] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [useCaseInputErrorArr, setUseCaseInputErrorArr] = useState<string[]>([]);

  const [showArchImgRequireError, setShowArchImgRequireError] = useState(false);

  const [industryIsLoading, setIndustryIsLoading] = useState(false);
  const [serviceIsLoading, setServiceIsLoading] = useState(false);


  const saveDraftAndNext = async () => {
    const createUseCaseInfoParam = JSON.parse(JSON.stringify(createUseCaseInfo));

    if (useCaseInputErrorArr && useCaseInputErrorArr.length > 0) {
      return;
    }

    if (!createUseCaseInfo?.useCaseName?.trim()) {
      setShowNameEmptyError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    if (!createUseCaseInfo.architectDiagrams || createUseCaseInfo.architectDiagrams?.length <=0) {
      setShowArchImgRequireError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    if (!createUseCaseInfo.groupInUseList || createUseCaseInfo.groupInUseList?.length !== 1) {
      setShowGroupError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    setLoadingCreate(true);
    try {
      console.info("createUseCaseInfoParam: ", createUseCaseInfoParam);
      const createUseCaseRes = await appSyncRequest(submitUseCase, {
        input: createUseCaseInfoParam,
      });
      console.info("createUseCaseRes", createUseCaseRes);
      window.localStorage.removeItem(USER_ACK_USECASE_DEFININATION);
      setLoadingCreate(false);
      setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
      setCreateUseCaseInfo((prev) => {
        return {...prev, useCaseId: createUseCaseRes.data.submitUseCase.useCaseId};
      });
    } catch (error) {
      setLoadingCreate(false);
      console.error(error);
    }
  };

  const updateUseCase = async (mode: SubmitMode) => {
    if (useCaseInputErrorArr && useCaseInputErrorArr.length > 0) {
      return;
    }

    if (!createUseCaseInfo?.useCaseName?.trim()) {
      setShowNameEmptyError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    // Validate Architecture Diagram
    if (!createUseCaseInfo.architectDiagrams || createUseCaseInfo.architectDiagrams?.length <= 0) {
      setShowArchImgRequireError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    // Validate Workload
    if (!createUseCaseInfo.groupInUseList || createUseCaseInfo.groupInUseList?.length <= 0) {
      setShowWorkloadRequireError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
      return;
    }

    if (!createUseCaseInfo.svcInUseList || createUseCaseInfo.svcInUseList?.length <=0) {
      setShowServiceError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
      return;
    }

    if (!createUseCaseInfo.industryInUseList || createUseCaseInfo.industryInUseList?.length <= 0) {
      setShowIndustryError(true);
      setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
      return;
    }

    const updateUseCaseInfoParam = JSON.parse(JSON.stringify(createUseCaseInfo));
    updateUseCaseInfoParam.mode = mode;
    console.info("updateUseCaseInfoParam:", updateUseCaseInfoParam);

    // Encode assets input
    updateUseCaseInfoParam.useCaseName = encodeURIComponent(updateUseCaseInfoParam.useCaseName);
    updateUseCaseInfoParam.useCaseDesc = encodeURIComponent(updateUseCaseInfoParam.useCaseDesc);

    const useCaseArchList: any = [];
    createUseCaseInfo?.architectDiagrams?.forEach((element) => {
      useCaseArchList.push({
        architectFileName: encodeURIComponent(element?.architectFileName || ""),
        architectFileUrl: element?.architectFileUrl,
      });
    });
    updateUseCaseInfoParam.architectDiagrams = useCaseArchList;

    // Attachment Refine
    const updateAttachments: any = [];
    createUseCaseInfo?.attachments?.forEach((element) => {
      updateAttachments.push({
        attachmentFilename: encodeURIComponent(
            element?.attachmentFilename || ""
        ),
        attachmentUrl: element?.attachmentUrl,
        attachmentSize: element?.attachmentSize,
        attachmentDesc: encodeURIComponent(element?.attachmentDesc || ""),
      });
    });
    updateUseCaseInfoParam.attachments = updateAttachments;
    setLoadingSubmit(true);
    try {
      const createUseCaseRes = await appSyncRequest(submitUseCase, {
        input: updateUseCaseInfoParam,
      });
      console.info("createUseCaseRes:", createUseCaseRes);
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      history.push({
        pathname: appConfig.userRole === UserRole.SO_TFC_leader ? "/" : "/",
      });
    } catch (error) {
      setLoadingSaveDraft(false);
      setLoadingSubmit(false);
      console.error(error);
    }
  };

  //check service
  useEffect(() => {
    console.info("createUseCaseInfo:", createUseCaseInfo);
  }, [createUseCaseInfo]);

  return (
      <div>
        <div className="gsal-review-page">
          <div className="gsal-step-nav">
            <nav>
              <ul>
                <li>
                  <small>Step 1</small>
                  <div className="step-name">
                    <span
                      onClick={() => {
                        if (
                            loadingSubmit ||
                            loadingSaveDraft ||
                            isFileUploading
                        ) {
                          return;
                        }
                        setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                      }}
                      className={activeStepIndex !==CreateUseCaseStep.UseCaseDetailStepIndex ? "link" : ""}
                      >
                      Use Case details
                    </span>
                  </div>
                </li>
                <li>
                  <small>Step 2</small>
                  <div className="step-name">
                    <span
                      onClick={() => {
                        if (!createUseCaseInfo.useCaseId) {
                          return;
                        }
                        if (
                            svcIsLoading
                        ) {
                          return;
                        }
                        setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
                      }}
                      className={activeStepIndex !== CreateUseCaseStep.UseCaseInfoStepIndex ? "link" : ""}
                      >
                      Services
                    </span>
                  </div>
                </li>
                <li>
                  <small>Step 3</small>
                  <div className="step-name">
                    <span
                      onClick={() => {
                        if (!createUseCaseInfo.useCaseId) {
                          return;
                        }
                        if (
                            loadingSubmit ||
                            loadingSaveDraft ||
                            isFileUploading ||
                            workloadIsLoading ||
                            svcIsLoading
                        ) {
                          return;
                        }
                        setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
                      }}
                      className={activeStepIndex !== CreateUseCaseStep.UseCaseAttachmentStepIndex ? "link" : ""}
                      >
                      Attachements
                    </span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className="gsal-review-content">
            {activeStepIndex === 0 && (
                <StepUseCaseDetails
                    curUseCase={createUseCaseInfo}
                    showNameEmptyError={showNameEmptyError}
                    showArchDiagramRequireError={showArchImgRequireError}
                    showGroupError={showGroupError}
                    setGroupIsLoading={(loading) => {
                      setGroupIsLoading(loading);
                    }}
                    changeGroupInUse={(groupList) => {
                      if (groupList.length > 0) {
                        setShowGroupError(false);
                      }
                      setCreateUseCaseInfo((prev) => {
                        return {...prev, groupInUseList: groupList};
                      });
                    }}

                    addInputError={(error) => {
                      if (!useCaseInputErrorArr.includes(error)) {
                        setUseCaseInputErrorArr((prev) => {
                          return [...prev, error];
                        });
                      }
                    }}
                    removeInputError={(error) => {
                      setUseCaseInputErrorArr((prev) => {
                        return prev.filter((e) => e!==error);
                      });
                    }}
                    changeIsUploading={(uploading) => {
                      setIsFileUploading(uploading);
                    }}
                    setShowNameEmptyError={(showError) => {
                      setShowNameEmptyError(showError);
                    }}
                    changeUseCaseName={(useCaseName) => {
                      setShowNameEmptyError(false);
                      setCreateUseCaseInfo((prev) => {
                        return {...prev, useCaseName: useCaseName};
                      });
                    }}
                    changeUseCaseDesc={(useCaseDesc) => {
                      setCreateUseCaseInfo((prev) => {
                        return {...prev, useCaseDesc: useCaseDesc};
                      });
                    }}
                    changeArchImgList={(archList) => {
                      if (archList.length > 0) {
                        setShowArchImgRequireError(false);
                      }
                      setCreateUseCaseInfo((prev) => {
                        return {
                          ...prev,
                          architectDiagrams: archList,
                        };
                      });
                    }}
                    changeArchDesc={(archDesc) => {
                      setCreateUseCaseInfo((prev) => {
                        return {...prev, architectDesc: archDesc};
                      });
                    }}
                />
            )}
            {activeStepIndex === 1 && (
                <StepAccountInfo
                    curUseCase={createUseCaseInfo}
                    showServiceError={showServiceError}
                    changeServiceList={(serviceList) => {
                      setCreateUseCaseInfo((prev) => {
                        return {...prev, svcInUseList: serviceList};
                      });
                    }}
                    setServiceIsLoading={(loading) => {
                      setServiceIsLoading(loading);
                    }}
                />
            )}
            {activeStepIndex === 2 && (
                <StepUseCaseAttachements
                    curUseCase={createUseCaseInfo}
                    showIndustryError={showIndustryError}
                    changeIndustryInUse={(industryList) => {
                      setCreateUseCaseInfo((prev) => {
                        return {...prev, industryInUseList: industryList};
                      });
                    }}
                    setIndustryIsLoading={(loading) => {
                      setIndustryIsLoading(loading);
                    }}
                    addNewAttachment={(item) => {
                      setCreateUseCaseInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        const prevAttachment = prevObj.attachments;
                        prevAttachment.push(item);
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    removeAttachment={(index) => {
                      if (
                          createUseCaseInfo.attachments &&
                          createUseCaseInfo.attachments.length > 0
                      ) {
                        const tmpAttacment = [...createUseCaseInfo.attachments];
                        tmpAttacment.splice(index, 1);
                        setCreateUseCaseInfo((prev: any) => {
                          const prevObj = JSON.parse(JSON.stringify(prev));
                          return {
                            ...prevObj,
                            ...{
                              attachments: tmpAttacment,
                            },
                          };
                        });
                      }
                    }}
                    changeAttachmentDesc={(index, desc) => {
                      setCreateUseCaseInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        prevObj.attachments[index].attachmentDesc = desc;
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    uploadAttachment={(upProgress, index) => {
                      setCreateUseCaseInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        if (upProgress >= 100) {
                          prevObj.attachments[index].progress = upProgress;
                          prevObj.attachments[index].showProgress = false;
                        } else {
                          prevObj.attachments[index].progress = upProgress;
                          prevObj.attachments[index].showProgress = true;
                        }
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    uploadAttachmentDone={(index, name, size, key) => {
                      setCreateUseCaseInfo((prev: any) => {
                        const prevObj = JSON.parse(JSON.stringify(prev));
                        prevObj.attachments[index].attachmentFilename = name;
                        prevObj.attachments[index].attachmentSize = size;
                        prevObj.attachments[index].attachmentUrl = key;
                        const prevAttachment = prevObj.attachments;
                        return {
                          ...prevObj,
                          ...{
                            attachments: prevAttachment,
                          },
                        };
                      });
                    }}
                    addInputError={(error) => {
                      if (!useCaseInputErrorArr.includes(error)) {
                        setUseCaseInputErrorArr((prev) => {
                          return [...prev, error];
                        });
                      }}
                    }
                    removeInputError={(error) => {
                      setUseCaseInputErrorArr((prev) => {
                        return prev.filter((e) => e !== error);
                      });
                    }}
                    changeIsUploading={(uploading) => {
                      setIsFileUploading(uploading);
                    }}
                />
            )}
            <div className="mt-20 pb-30 clearfix">
              <SpaceBetween className="fr" direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => {
                    history.push({
                      pathname: '/',
                    })
                  }}
                  >
                  Cancel
                </Button>
                {activeStepIndex === 0 && !createUseCaseInfo.useCaseId  && (
                    <Button
                      loading={loadingCreate}
                      variant="primary"
                      onClick={() => {
                        saveDraftAndNext();
                      }}
                      >
                      Create &amp; Next
                    </Button>
                )}
                {activeStepIndex === CreateUseCaseStep.UseCaseDetailStepIndex && createUseCaseInfo.useCaseId && (
                    <Button
                      disabled={loadingSubmit || loadingSaveDraft || isFileUploading}
                      variant="primary"
                      onClick={() => {
                        if (!createUseCaseInfo?.useCaseName?.trim()) {
                          setShowNameEmptyError(true);
                          setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                          return;
                        }

                        if (!createUseCaseInfo.architectDiagrams || createUseCaseInfo.architectDiagrams?.length <=0) {
                          setShowArchImgRequireError(true);
                          setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                          return;
                        }

                        if (!createUseCaseInfo.groupInUseList || createUseCaseInfo.groupInUseList?.length !== 1 ) {
                          setShowGroupError(true);
                          setActiveStepIndex(CreateUseCaseStep.UseCaseDetailStepIndex);
                          return;
                        }

                        setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
                      }}
                      >
                      Next
                    </Button>
                )}
                {activeStepIndex > CreateUseCaseStep.UseCaseDetailStepIndex && (
                    <Button
                      disabled={loadingSubmit || loadingSaveDraft || isFileUploading}
                      onClick={() => {
                        if (useCaseInputErrorArr.length > 0) {
                          return;
                        }
                        setActiveStepIndex((prev) => {
                          return prev - 1;
                        });
                      }}
                      >
                      Previous
                    </Button>
                )}
                {activeStepIndex === CreateUseCaseStep.UseCaseInfoStepIndex && (
                    <Button
                      disabled={svcIsLoading}
                      variant="primary"
                      onClick={() => {
                        if (!createUseCaseInfo.svcInUseList || createUseCaseInfo.svcInUseList?.length <= 0) {
                          setShowServiceError(true);
                          setActiveStepIndex(CreateUseCaseStep.UseCaseInfoStepIndex);
                          return;
                        }
                        setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
                      }}
                      >
                      Next
                    </Button>
                )}
                {activeStepIndex === CreateUseCaseStep.UseCaseAttachmentStepIndex && (
                    <Button
                      disabled={industryIsLoading || isFileUploading}
                      loading={loadingSubmit}
                      variant="primary"
                      onClick={() => {
                        if (!createUseCaseInfo.industryInUseList || createUseCaseInfo.industryInUseList?.length <= 0) {
                          setShowIndustryError(true);
                          setActiveStepIndex(CreateUseCaseStep.UseCaseAttachmentStepIndex);
                          return;
                        }
                        updateUseCase(SubmitMode.Submit);
                      }}
                      >
                      Submit
                    </Button>
                )}
              </SpaceBetween>
            </div>
          </div>
        </div>
      </div>
  );
};

const CreateUseCase: React.FC = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);

  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const useCaseReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Create a Use Case",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={useCaseReviewBreadcrumbs}/>}
          content={<CreateUseCaseContent/>}
          toolsOpen={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setNavigationOpen(!navigationOpen);
          }}
        />
  );
};

export default CreateUseCase;