import { Container, FormField, Header, Input, } from "@amzn/awsui-components-react";
import React from "react";

import EXAMPLE_IMG_OPP from "assets/images/sfdc-opportunity.png";
import EXAMPLE_IMG_ACCOUNT from "assets/images/sfdc-account.png";

import { SWAInput } from "API";

interface StepOppProps {
  curSWA: SWAInput;
  showAccountIdError: boolean;
  showOppIdError: boolean;
  changeAccountId: (id: string) => void;
  changeSWAOppId: (id: string) => void;
}

const StepAccount: React.FC<StepOppProps> = (props: StepOppProps) => {
  const {
    curSWA,
    showAccountIdError,
    showOppIdError,
    changeAccountId,
    changeSWAOppId,
  } = props;
  return (
      <div>
        <Header variant="h1">Account Info</Header>
        <div className="mt-10">
          <Container header={<Header variant="h2">Salesforce</Header>}>
            <FormField
                label="SFDC Account ID"
                description="Input the 15 or 18 digits ID of Salesforce Account."
                errorText={
                  showAccountIdError ? "Invalid Account ID, please check." : ""
                }
            >
              <Input
                  value={curSWA.sfdcAccountId || ""}
                  placeholder="0015000000poxgcAAA"
                  onChange={(event) => changeAccountId(event.detail.value)}
              />
            </FormField>

            <FormField
                className="mt-20"
                label="Example"
                description="Find the account ID in the address bar, and the ID is after Account."
            >
              <div className="mt-10">
                <img
                    width="100%"
                    style={{maxWidth: 720}}
                    src={EXAMPLE_IMG_ACCOUNT}
                    alt="example"
                />
              </div>
            </FormField>

            <FormField
                className="mt-20"
                label={
                  <span>
                  Opportunity ID
                  </span>
                }
                description="Input the 18 digits ID of Salesforce opportunity."
                errorText={
                  showOppIdError ? "Invalid opportunity ID, please check." : ""
                }
            >
              <Input
                  value={curSWA.opptyId || ""}
                  placeholder="1114z000025UezRAAS"
                  onChange={(event) => changeSWAOppId(event.detail.value)}
              />
            </FormField>

            <FormField
                className="mt-20"
                label="Example"
                description="Find the opportunity ID in the address bar, and the ID is after Opportunity."
            >
              <div className="mt-10">
                <img
                    width="100%"
                    style={{maxWidth: 720}}
                    src={EXAMPLE_IMG_OPP}
                    alt="example"
                />
              </div>
            </FormField>
          </Container>
        </div>
      </div>
  );
};

export default StepAccount;
