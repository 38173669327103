import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, FormField, Modal, Select, SpaceBetween, } from "@amzn/awsui-components-react/polaris";
import { appSyncRequest } from "assets/js/request";
import { getAssignees } from "graphql/queries";
import { assignReviewer } from "graphql/mutations";
// import { ManagerList } from "common/const";
import AppContext from "context/AppContext";
import { Amazonian, PoC } from "API";
import { AutoSuggestStatus } from "types";

interface AssignModalProps {
  addSuccess: () => void;
  hideDialog: () => void;
  selectedItems: PoC[];
  showDialog: boolean;
}

const AssignModal: React.FC<AssignModalProps> = (props: AssignModalProps) => {
  // const [visible, setVisible] = useState(props.showDialog);
  const appConfig = useContext(AppContext);
  const {showDialog, selectedItems, addSuccess, hideDialog} = props;
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [assigneeInvalid, setAssigneeInvalid] = useState(false);
  // const [assignee, setAssignee] = useState("");
  const [loadingStatus, setLoadingStatus] = useState<any>();
  const [assigneeList, setAssigneeList] = useState([]);
  const [pocManagerList, setPocManagerList] = useState<any[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<any>(null);

  // Assign PoC to Reviewer
  const assignPoCReviewer = async () => {
    if (!selectedAssignee) {
      // Set Assignee
      setAssigneeInvalid(true);
      return;
    }
    try {
      setLoadingAssign(true);
      const pocReviewData = await appSyncRequest(assignReviewer, {
        input: {
          pocIds: selectedItems.map((item) => item.pocId),
          assignerId: appConfig.userId,
          assigneeId: selectedAssignee?.value,
        },
      });
      console.info("pocReviewData:", pocReviewData);
      setLoadingAssign(false);
      addSuccess();
      hideDialog();
    } catch (error) {
      setLoadingAssign(false);
      console.error(error);
    }
  };

  const getAssigneeList = useCallback(async () => {
    try {
      if (appConfig && appConfig.userRole) {
        setLoadingStatus(AutoSuggestStatus.loading);
        setAssigneeList([]);
        const assigneeList: any = await appSyncRequest(getAssignees, {
          role: appConfig.userRole,
          managerId: appConfig.userId,
        });
        if (assigneeList?.data?.getAssignees) {
          setLoadingStatus(AutoSuggestStatus.finished);
          const managerList: any = [];
          assigneeList.data.getAssignees.forEach((user: Amazonian) => {
            // hide if the manager in poc's manager
            if (!pocManagerList.includes(user.userId)) {
              managerList.push({
                label: `${user.name}(${user.userId})`,
                value: user.userId,
              });
            }
          });
          setAssigneeList(managerList);
          console.info("managerList:", managerList);
        }
      }
    } catch (error) {
      setLoadingStatus(AutoSuggestStatus.error);
      console.error(error);
    }
  }, [appConfig, pocManagerList]);

  useEffect(() => {
    if (showDialog) {
      setAssigneeInvalid(false);
      setSelectedAssignee(null);
      getAssigneeList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  useEffect(() => {
    // build selected items manager list
    const tmpManagerList: string[] = [];
    selectedItems.forEach((element) => {
      tmpManagerList.push(element.contributor?.managerId || "");
    });
    setPocManagerList(tmpManagerList);
  }, [selectedItems]);

  return (
      <>
        <Modal
            onDismiss={() => {
              hideDialog();
            }}
            visible={showDialog}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                      variant="link"
                      onClick={() => {
                        // setVisible(false);
                        hideDialog();
                      }}
                  >
                    Cancel
                  </Button>
                  <Button
                      loading={loadingAssign}
                      onClick={() => {
                        assignPoCReviewer();
                      }}
                      variant="primary"
                  >
                    Assign
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Assign Reviewer"
        >
          <FormField
              errorText={assigneeInvalid ? "Please select a reviewer." : ""}
          >
            <Select
                selectedOption={selectedAssignee}
                onChange={({detail}: any) => {
                  console.info("detail:", detail);
                  setAssigneeInvalid(false);
                  setSelectedAssignee(detail.selectedOption);
                }}
                filteringType="auto"
                statusType={loadingStatus}
                loadingText="loading"
                options={assigneeList}
                placeholder="Choose an assignee"
                selectedAriaLabel="Selected"
                invalid={assigneeInvalid}
                empty="No assignees found"
            />
          </FormField>
        </Modal>
      </>
  );
};

export default AssignModal;
