import { BestPracticeStatus, CriteriaType, ReviewStatus } from "API";

export const LengthLimit = {
  pocName: 256,
  userScenarioDesc: 1024,
  pocScope: 128,
  userGuideUrl: 1024,
  sourceCodeUrl: 1024,
  architectDesc: 512,
  attachmentDesc: 256,
};

export const LengthLimitSWA = {
  swaSubject: 256,
  swaDesc: 4096,
  architectDesc: 4096,
  customerBenefit: 4096,
  linkUrl: 1024,
};

export const LengthLimitUseCase = {
  useCaseName: 256,
  useCaseDesc: 4096,
  architectDesc: 4096,
};

export const CreateUseCaseStep = {
  UseCaseDetailStepIndex: 0,
  UseCaseInfoStepIndex: 1,
  UseCaseAttachmentStepIndex: 2,
};

export const DEFAULT_PAGE_SIZE = 10;

export const USER_ACK_USECASE_DEFININATION = "__gsal_user_ack_usecase_definination__";
export const USER_ACK_SWA_DEFININATION = "__gsal_user_ack_swa_definination__";
export const GSAL_VERSION = "__gsal_user_current_version__";
export const SIDE_MENU_IS_OPEN = "__gsal_side_menu_open__";
export const USER_REDIRECT_URL = "__gsal_user_redirect_url_link__";
export const AMPLIFY_CONFIG_JSON = "__gsal_amplify_config_json__";
export const PHONE_TOOL_LINK = "https://phonetool.amazon.com/users";
//Old link for GET_SUPPORT_LINK:https://issues.amazon.com/issues/create?template=b97b37d4-127b-4f53-8b30-7977abaef3f7
export const GET_SUPPORT_LINK =
    "https://w.amazon.com/bin/view/GCR-Marketing-Tech/DCI/Projects/GSAL/#HSupport";

export const CROSS_TEAM_REVIEW_VISIBLE_COLUMNS_ID =
    "LAST_MILE_CROSS_TEAM_REVIEW_VISIBLE_COLUMNS_ID";
export const CROSS_TEAM_REVIEW_PAGE_SIZE_ID =
    "LAST_MILE_CROSS_TEAM_REVIEW_PAGE_SIZE_ID";

export const CTR_POC_LIST_VISIBLE_COLUMNS_ID =
    "LAST_MILE_CTR_POC_LIST_VISIBLE_COLUMNS_ID";
export const CTR_POC_LIST_PAGE_SIZE_ID = "LAST_MILE_CTR_POC_LIST_PAGE_SIZE_ID";

export const POC_LIST_VISIBLE_COLUMNS_ID =
    "LAST_MILE_POC_LIST_VISIBLE_COLUMNS_ID";
export const POC_LIST_PAGE_SIZE_ID = "LAST_MILE_POC_LIST_PAGE_SIZE_ID";

export const SWA_LIST_VISIBLE_COLUMNS_ID =
    "LAST_MILE_SWA_LIST_VISIBLE_COLUMNS_ID";
export const SWA_LIST_PAGE_SIZE_ID = "LAST_MILE_SWA_LIST_PAGE_SIZE_ID";

export const USECASE_LIST_VISIBLE_COLUMNS_ID =
    "LAST_MILE_USECASE_LIST_VISIBLE_COLUMNS_ID";
export const USECASE_LIST_PAGE_SIZE_ID = "LAST_MILE_USECASE_LIST_PAGE_SIZE_ID";

export const CUSTOMER_LIST_VISIBLE_COLUMNS_ID = "LAST_MILE_CUSTOMER_LIST_VISIBLE_COLUMNS_ID";
export const CUSTOMER_LIST_PAGE_SIZE_ID = "LAST_MILE_CUSTOMER_LIST_PAGE_SIZE_ID";

export const BP_VISIBLE_COLUMNS_ID = "LAST_MILE_BP_VISIBLE_COLUMNS_ID";
export const BP_PAGE_SIZE_ID = "LAST_MILE_BP_PAGE_SIZE_ID";

export const POC_NOT_FOUND_ID = "PoCNotFound";
export const SWA_NOT_FOUND_ID = "SWANotFound";
export const USECASE_NOT_FOUNT_ID = "UseCaseNotFound";

export const MAX_FILE_SIZE = 200 * 1024 * 1024;
export const ATTACHMENT_FILE_TYPES =
    "image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip";

export const MultiSearchI18NString = {
  filteringAriaLabel: "Your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search PoC with filters",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text: any) => `Use: "${text}"`,
};

export const MultiSWASearchI18NString = {
  filteringAriaLabel: "Your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search SWA with filters",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text: any) => `Use: "${text}"`,
};

export const MultiUseCaseSearchI18NString = {
  filteringAriaLabel: "Your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search Use Case with filters",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text: any) => `Use: "${text}"`,
};

export const MultiCustomerSearchI18NString = {
  filteringAriaLabel: "Your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search Customer with filters",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text: any) => `Use: "${text}"`,
};

export const MultiEngagementSearchI18NString = {
  filteringAriaLabel: "Your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search Customer Engagement with filters",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text: any) => `Use: "${text}"`,
};

export const TimePickI18NString = {
  todayAriaLabel: "Today",
  nextMonthAriaLabel: "Next month",
  previousMonthAriaLabel: "Previous month",
  customRelativeRangeDurationLabel: "Duration",
  customRelativeRangeDurationPlaceholder: "Enter duration",
  customRelativeRangeOptionLabel: "Custom range",
  customRelativeRangeOptionDescription: "Set a custom range in the past",
  customRelativeRangeUnitLabel: "Unit of time",
  formatRelativeRange: (e: any) => {
    const t = 1 === e.amount ? e.unit : e.unit + "s";
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e: any, t: any) => (1 === t ? e : e + "s"),
  dateTimeConstraintText: "Range must be larger or equal than 1 day.",
  relativeModeTitle: "Relative range",
  absoluteModeTitle: "Absolute range",
  relativeRangeSelectionHeading: "Choose a range",
  startDateLabel: "Start date",
  endDateLabel: "End date",
  startTimeLabel: "Start time",
  endTimeLabel: "End time",
  clearButtonLabel: "Clear",
  cancelButtonLabel: "Cancel",
  applyButtonLabel: "Apply",
};

export const PoCMultiFilterBPStatus = [
  {
    propertyKey: "bestPracticeStatus",
    value: BestPracticeStatus.No,
  },
  {
    propertyKey: "bestPracticeStatus",
    value: BestPracticeStatus.Yes,
  },
];

export const PoCMultiFilterReviewStatus = [
  {
    propertyKey: "reviewStatus",
    value: ReviewStatus.Assigned,
  },
  {
    propertyKey: "reviewStatus",
    value: ReviewStatus.Done,
  },
  {
    propertyKey: "reviewStatus",
    value: ReviewStatus.No,
  },
  {
    propertyKey: "reviewStatus",
    value: ReviewStatus.Overdue,
  },
];

export const AdminPocMutilFilterList = [
  {
    key: "bestPracticeStatus",
    // operators: [":"],
    propertyLabel: "Best Practice",
    groupValuesLabel: "Best Practice Status",
  },
  {
    key: "reviewStatus",
    // operators: [":"],
    propertyLabel: "Review",
    groupValuesLabel: "Review Status",
  },
  {
    key: "managerId",
    // operators: [":"],
    propertyLabel: "Manager",
    groupValuesLabel: "Manager Name",
  },
  {
    key: "contributorId",
    // operators: [":"],
    propertyLabel: "SA",
    groupValuesLabel: "SA Name",
  },
];

export const ContributorPocMutilFilterList = [
  {
    key: "bestPracticeStatus",
    // operators: [":"],
    propertyLabel: "Best Practice",
    groupValuesLabel: "Best Practice Status",
  },
  {
    key: "reviewStatus",
    // operators: [":"],
    propertyLabel: "Review",
    groupValuesLabel: "Review Status",
  },
];

export const AdminSWAMutilFilterList = [
  {
    key: "managerId",
    // operators: [":"],
    propertyLabel: "Manager",
    groupValuesLabel: "Manager Name",
  },
  {
    key: "contributorId",
    // operators: [":"],
    propertyLabel: "SA",
    groupValuesLabel: "SA Name",
  },
  {
    key: "svcId",
    // operators: [":"],
    propertyLabel: "Service",
    groupValuesLabel: "Service Name",
  },
  {
    key: "categoryId",
    // operators: [":"],
    propertyLabel: "Category",
    groupValuesLabel: "Category Name",
  },
  {
    key: "sfdcAccountId",
    // operators: [":"],
    propertyLabel: "Account ID",
    groupValuesLabel: "Account ID",
  },
];

export const ManagerSWAMutilFilterList = [
  {
    key: "contributorId",
    // operators: [":"],
    propertyLabel: "SA",
    groupValuesLabel: "SA Name",
  },
  {
    key: "svcId",
    // operators: [":"],
    propertyLabel: "Service",
    groupValuesLabel: "Service Name",
  },
  {
    key: "categoryId",
    // operators: [":"],
    propertyLabel: "Category",
    groupValuesLabel: "Category Name",
  },
  {
    key: "sfdcAccountId",
    // operators: [":"],
    propertyLabel: "Account ID",
    groupValuesLabel: "Account ID",
  },
];

export const ContributorSWAMutilFilterList = [
  {
    key: "svcId",
    // operators: [":"],
    propertyLabel: "Service",
    groupValuesLabel: "Service Name",
  },
  {
    key: "categoryId",
    // operators: [":"],
    propertyLabel: "Category",
    groupValuesLabel: "Category Name",
  },
  {
    key: "sfdcAccountId",
    // operators: [":"],
    propertyLabel: "Account ID",
    groupValuesLabel: "Account ID",
  },
];

export const PoCScoreMap: any = {
  RequirementsSpecification: {
    showName: "Requirements specification",
    weight: 0.2,
    level0: "0. Not provided.",
    level1:
        "1. Entry Level. Include limited information abot the user scenario.",
    level2:
        "2. Needs Improvement. Include user scenario and purpose description.",
    level3:
        "3. Qualified. Include user scenario, purpose and quantified technical indicators description.",
    level4:
        "4. Excellent. Include all of the above and functional description. ",
    level5:
        "5. Bar-raising. Include all of the above and key technical points description. ",
  },
  PoCPlan: {
    showName: "PoC Plan",
    weight: 0.3,
    level0: "0. Not provided. ",
    level1: "1. Entry Level.  Include architecture diagram.",
    level2:
        "2. Needs Improvement. Include architecture diagram and AWS service feature description.",
    level3:
        "3. Qualified. Include all of the above, data source description and tools/scripts description.",
    level4:
        "4. Excellent. Include all of the above and timing/resource planning.",
    level5:
        "5. Bar-raising. Include all of the aboce and assumptions/dependecies/constrains.",
  },
  TestReport: {
    showName: "Test Report",
    weight: 0.3,
    level0: "0. Not provided. ",
    level1: "1. Entry Level.  Include test scenario description.",
    level2:
        "2. Needs Improvement. Include test scenario and results description.",
    level3:
        "3. Qualified. Include test scenario, results and process description.",
    level4:
        "4. Excellent. Include all of the above and step-by-step reproduce guide.",
    level5:
        "5. Bar-raising. Include all of the above, tools/scripts and quantified benchmark.",
  },
  PoCConclusion: {
    showName: "PoC Conclusion",
    weight: 0.2,
    level0: "0. Not provided. ",
    level1: "1. Entry Level.  Include a conclusion",
    level2:
        "2. Needs Improvement.  Include a conclusion and is related to customer’s user scenario.",
    level3:
        "3. Qualified. Include a conclusion, related to customer’s user scenario and limitation description.",
    level4:
        "4. Excellent. Include all of the above and can be a Best Pratice candidate.",
    level5:
        "5. Bar-raising. Include all of the above. The PoC has technical depth or is innovative.",
  },
};

export const PoCScoreList = [
  CriteriaType.RequirementsSpecification,
  CriteriaType.PoCPlan,
  CriteriaType.TestReport,
  CriteriaType.PoCConclusion,
];

export const AttachmentTypeList = [
  {
    label: "PoC Test Data",
    value: "PoC Test Data",
  },
  {
    label: "PoC Test Report",
    value: "PoC Test Report",
  },
  {
    label: "PoC Configuration",
    value: "PoC Configuration",
  },
];

export const SWAAttachmentTypeList = [
  {
    label: "SWA Test Data",
    value: "SWA Test Data",
  },
  {
    label: "SWA Test Report",
    value: "SWA Test Report",
  },
  {
    label: "SWA Configuration",
    value: "SWA Configuration",
  },
];
