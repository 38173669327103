import React, { useCallback, useContext, useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { appSyncRequest } from "assets/js/request";
import { getSWAById, getSWAReviewById } from "graphql/queries";

import {
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react/polaris";

import ValueWithLabel from "common/ValueWithLabel";

import { ReviewStatus, SubmitMode, SWAAbstract, SWAReviewResponse, UserRole } from "API";
import { PHONE_TOOL_LINK, POC_NOT_FOUND_ID } from "assets/js/const";
import { buildAccountLink, buildOppLink, handleNullValue, } from "assets/js/utils";
import AppContext from "context/AppContext";
import ArchImage from "pages/swa/comps/step/comps/ArchImage";
import SWAReviewResult from '../comps/SWAReviewResult'
import { updateSWAStatus } from '../../graphql/mutations'
import { Alert, Box, Checkbox, Modal } from '@amzn/awsui-components-react'
import SWAStatus from '../comps/SWAStatus'

interface DetailContentProps {
  swaId: string;
  pageType?: string;
}

const DetailCompSWA: React.FC<DetailContentProps> = (
    props: DetailContentProps
): JSX.Element => {
  const {swaId} = props;
  const appConfig = useContext(AppContext);
  const history = useHistory();
  // const urlStr = window.location.href.split("?")[1];
  // const [activeTabId, setActiveTabId] = useState(tabId || "overview");
  const [showConfirm, setShowConfirm] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [curSWA, setCurSWA] = useState<SWAAbstract>();
  const [currentReview, setCurrentReview] = useState<SWAReviewResponse>();

  const getSWAInfoById = async () => {
    try {
      setLoadingData(true);
      const swaData: any = await appSyncRequest(getSWAById, {swaId: swaId});
      const swaInfo: SWAAbstract = swaData?.data?.getSWAById;
      if (swaInfo.swaId === POC_NOT_FOUND_ID) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        console.info("swaInfo:", swaInfo);
        if (swaData?.data?.getSWAById) {
          const swaDataObj = swaData.data.getSWAById;
          swaDataObj.swaSubject = decodeURIComponent(
              swaDataObj.swaSubject.replace(/\+/g, " ") || ""
          );
          swaDataObj.swaDesc = decodeURIComponent(
              swaDataObj.swaDesc.replace(/\+/g, " ")
          );
          swaDataObj.architectDesc = decodeURIComponent(
              swaDataObj.architectDesc.replace(/\+/g, " ")
          );
          swaDataObj.architectDiagrams.forEach((element: any) => {
            element.architectFilename = decodeURIComponent(
                element.architectFilename.replace(/\+/g, " ")
            );
          });
          swaDataObj.attachments.forEach((element: any) => {
            element.attachmentFilename = decodeURIComponent(
                element.attachmentFilename.replace(/\+/g, " ")
            );
            element.attachmentDesc = decodeURIComponent(
                element.attachmentDesc.replace(/\+/g, " ")
            );
          });
          setCurSWA(swaData.data.getSWAById);
          console.info("11111:", curSWA?.reviewStatus);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFile = async (fileKey: string) => {
    // Creates download url that expires in 5 minutes/ 300 seconds
    const downloadUrl = await Storage.get(fileKey, {expires: 600});
    // window.location.href = downloadUrl.toString();
    window.open(downloadUrl.toString(), "_blank");
  };

  const getSWAReviewInfoById = useCallback(async () => {
    try {
      const reviewData: any = await appSyncRequest(getSWAReviewById, {
        swaId: swaId,
      });
      console.info("reviewData:", reviewData);
      if (reviewData?.data?.getSWAReviewById) {
        reviewData.data.getSWAReviewById.comments = decodeURIComponent(
            reviewData?.data?.getSWAReviewById?.comments?.replace(/\+/g, " ")
        );
      }
      setCurrentReview(reviewData?.data?.getSWAReviewById);
    } catch (error) {
      console.error(error);
    }
  }, [swaId]);

  const abandonSWAById = useCallback(async () => {
    try {
      const abandonResult: any = await appSyncRequest(updateSWAStatus, {
        input: {
          swaId: swaId,
          swaStatus: "Abandon",
        }
      });
      console.info("abandonResult:", abandonResult);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }, [swaId]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getSWAInfoById();
    getSWAReviewInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div>
        <div className="mb-10">
          <Header
              variant="h1"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {appConfig.userId === curSWA?.contributor?.userId &&
                      curSWA?.swaStatus !== SubmitMode.Abandon && (
                          <Button
                              onClick={() => {
                                history.push({
                                  pathname: "/swa/edit/" + swaId,
                                });
                              }}
                          >
                            Edit
                          </Button>
                      )}
                  {appConfig.userId === curSWA?.contributor?.userId &&
                      curSWA?.swaStatus !== SubmitMode.Abandon &&
                      curSWA?.reviewStatus === null && (
                          <Button
                              onClick={() => {
                                setShowConfirm(true);
                              }}
                              variant="primary"
                          >
                            Abandon
                          </Button>
                      )}
                </SpaceBetween>

              }
          >
            {swaId.toUpperCase()}
            <Modal
                onDismiss={(event) => {
                  if (event.detail.reason === "closeButton") {
                    setShowConfirm(false);
                  }
                  console.info("AAA:", event.detail.reason);
                }}
                visible={showConfirm}
                closeAriaLabel="Close modal"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                          variant="link"
                          onClick={() => {
                            setShowConfirm(false);
                          }}
                      >
                        Cancel
                      </Button>
                      <Button
                          variant="primary"
                          onClick={() => {
                            if (!isAcknowledge) {
                              setShowError(true);
                            } else {
                              abandonSWAById();
                              setShowConfirm(false);
                            }
                          }}
                      >
                        Abandon
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
                header="Abandon SWA"
            >
              <Alert visible={true} header="Abandon SWA">
                <div>
                  If the SWA is abandoned, only Contributor ownself can see it and cannot edit it anymore.
                </div>
                <div className="mt-20">
                  <Checkbox
                      onChange={({detail}) => {
                        setAcknowledge(detail.checked);
                        setIsAcknowledge(detail.checked);
                        setShowError(false);
                      }}
                      checked={acknowledge}
                  >
                    <b>
                      I acknowledge the SWA can abandon.
                    </b>
                  </Checkbox>
                </div>
              </Alert>
              <Alert className="mt-10" visible={showError} type="error">
                Please acknowledge all checkboxes before proceeding.
              </Alert>
            </Modal>
          </Header>
        </div>

        {loadingData ? (
            <Spinner/>
        ) : (
            <div>
              <Container header={<Header variant="h2">SWA Detail</Header>}>
                <div>
                  <div>
                    <ColumnLayout columns={4} variant="text-grid">
                      <SpaceBetween size="l">
                        <ValueWithLabel label="SWA Subject">
                          {handleNullValue(curSWA?.swaSubject)}
                        </ValueWithLabel>
                        <ValueWithLabel label="Team">
                          {handleNullValue(curSWA?.contributor?.teamName)}
                        </ValueWithLabel>
                        <ValueWithLabel label="Efforts">
                          {curSWA?.efforts === undefined ||
                          curSWA?.efforts === null ? (
                              <div>-</div>
                          ) : (
                              <div>
                                {curSWA?.efforts}{" "}
                                {(curSWA?.efforts || 0) > 1 ? "hours" : "hour"}
                              </div>
                          )}
                        </ValueWithLabel>
                      </SpaceBetween>
                      <SpaceBetween size="l">
                        <ValueWithLabel label="SA">
                          {curSWA?.contributor?.name} (
                          <Link
                              href={`${PHONE_TOOL_LINK}/${curSWA?.contributor?.userId}`}
                              external={true}
                          >
                            {handleNullValue(curSWA?.contributor?.userId)}
                          </Link>
                          )
                        </ValueWithLabel>
                        <ValueWithLabel label="Supervisor">
                          {curSWA?.contributor?.supervisorId} (
                          <Link
                              href={`${PHONE_TOOL_LINK}/${curSWA?.contributor?.supervisorId}`}
                              external={true}
                          >
                            {curSWA?.contributor?.supervisorId}
                          </Link>
                          )
                        </ValueWithLabel>
                        {curSWA?.contributor?.userId === appConfig.userId && (
                            <ValueWithLabel label="SWA Status">
                              <div>
                                <SWAStatus SWAStatus={curSWA?.swaStatus}/>
                              </div>
                            </ValueWithLabel>
                        )}
                      </SpaceBetween>
                      <SpaceBetween size="l">
                        <ValueWithLabel label="Account ID">
                          {curSWA?.sfdcAccountId ? (
                              <Link
                                  external
                                  href={buildAccountLink(curSWA.sfdcAccountId)}
                              >
                                {curSWA.sfdcAccountId}
                              </Link>
                          ) : (
                              "-"
                          )}
                        </ValueWithLabel>
                        <ValueWithLabel label="Manager">
                          {curSWA?.contributor?.managerName} (
                          <Link
                              href={`${PHONE_TOOL_LINK}/${curSWA?.contributor?.managerId}`}
                              external={true}
                          >
                            {curSWA?.contributor?.managerId}
                          </Link>
                          )
                        </ValueWithLabel>
                      </SpaceBetween>
                      <SpaceBetween size="l">
                        <ValueWithLabel label="Launch Date">
                          {curSWA?.launchDate || "-"}
                        </ValueWithLabel>
                        <ValueWithLabel label="Opportunity">
                          {curSWA?.opptyId ? (
                              <Link external href={buildOppLink(curSWA.opptyId)}>
                                {curSWA.opptyId}
                              </Link>
                          ) : (
                              "-"
                          )}
                        </ValueWithLabel>
                      </SpaceBetween>
                    </ColumnLayout>
                  </div>
                </div>
              </Container>
              <div className="mt-10">
                <Container header={<Header
                    variant="h2"
                    actions={
                        curSWA?.reviewStatus !== ReviewStatus.Done &&
                        curSWA?.assignee?.userId === appConfig.userId &&
                        (appConfig.userRole === UserRole.Manager ||
                            appConfig.userRole === UserRole.Reviewer) && (
                            <Button
                                onClick={() => {
                                  history.push({
                                    pathname: "/swa/" + swaId + "/review",
                                  });
                                }}
                                variant="primary"
                            >
                              Review
                            </Button>
                        )
                    }
                >
                  Overview
                </Header>}>
                  <ColumnLayout columns={4} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Strategical workload">
                        {handleNullValue(
                            curSWA?.workload
                                ?.map((element) => element?.workload)
                                .join(", ")
                        )}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Industry Category">
                        {handleNullValue(
                            curSWA?.tagList
                                ?.map((element) => element?.tagName)
                                .join(", ")
                        )}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Http Link">
                        {curSWA?.linkUrl ? (
                            <Link external href={curSWA.linkUrl}>
                              Link
                            </Link>
                        ) : (
                            "-"
                        )}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Review Result">
                        {
                          <div>
                            {curSWA?.reviewStatus === ReviewStatus.Done ? (
                                <SWAReviewResult
                                    isPass={curSWA?.isPass === true}
                                />
                            ) : "-"
                            }
                          </div>
                        }
                      </ValueWithLabel>
                      <ValueWithLabel label="Review Comments">
                        {handleNullValue(currentReview?.comments)}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>

                  {curSWA?.workload?.map((element, index) => {
                    return (
                        <ColumnLayout key={index} columns={1} variant="text-grid">
                          <SpaceBetween size="l">
                            <ValueWithLabel label={element?.workload || ""}>
                              {handleNullValue(
                                  element?.serviceList
                                      ?.map((element) => element?.svcName)
                                      .join(", ")
                              )}
                            </ValueWithLabel>
                          </SpaceBetween>
                        </ColumnLayout>
                    );
                  })}

                  <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="SWA Description">
                        {handleNullValue(curSWA?.swaDesc)}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                  <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Architect Diagram">
                        {curSWA?.architectDiagrams?.map((element, index) => {
                          return (
                              <div className="mt-20 w-70p" key={index}>
                                <ArchImage s3Key={element?.architectFileUrl || ""}/>
                              </div>
                          );
                        })}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>

                  <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Architect Diagram Description">
                        <p>{handleNullValue(curSWA?.architectDesc)}</p>
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>

                  <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Attachments">
                        {curSWA?.attachments
                            ? curSWA.attachments.map((element, index) => {
                              return (
                                  <span
                                      key={index}
                                      onClick={() => {
                                        downloadFile(element?.attachmentUrl || "");
                                      }}
                                  >
                              <Link key={index} className="mr-10">
                                {element?.attachmentFilename}
                              </Link>
                            </span>
                              );
                            })
                            : "-"}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                </Container>
              </div>
            </div>
        )}
      </div>
  );
};

export default DetailCompSWA;
