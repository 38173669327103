import React from "react";
import { StatusIndicator } from "@amzn/awsui-components-react/polaris";
import { SubmitMode } from "API";

interface PoCStatusProp {
  pocStatus: SubmitMode | undefined | null;
}

const PoCStatus: React.FC<PoCStatusProp> = (props: PoCStatusProp) => {
  const {pocStatus} = props;
  return (
      <div>
        {pocStatus === SubmitMode.Submit && (
            <StatusIndicator type="success">Submit</StatusIndicator>
        )}
        {pocStatus === SubmitMode.Draft && (
            <StatusIndicator type="warning">Draft</StatusIndicator>
        )}
      </div>
  );
};

export default PoCStatus;
