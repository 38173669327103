import React, { useEffect, useState } from "react";
import { Multiselect, TextFilter } from "@amzn/awsui-components-react/polaris";
import { TagType } from "pages/detail/comps/TechIndustry";
import { appSyncRequest } from "assets/js/request";
import { getAllTags } from "graphql/queries";

interface SearchOptionsProps {
  changeTextInput: (text: string) => void;
  changeTagInput: (options: any) => void;
}

const SearchOptions: React.FC<SearchOptionsProps> = ({
                                                       changeTextInput,
                                                       changeTagInput,
                                                     }: SearchOptionsProps) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [industryList, setIndustryList] = useState<any[]>([]);
  const [technicalList, setTechnicalList] = useState<any[]>([]);
  const [pocPattern, setPocPattern] = useState("");

  const getTagList = async () => {
    try {
      const tagList: any = await appSyncRequest(getAllTags);
      console.info("tagList:", tagList);
      const tmpIndustryTagList: any = [];
      const tmpTechList: any = [];
      if (
          tagList &&
          tagList.data &&
          tagList.data.getAllTags &&
          tagList.data.getAllTags.industryTagList &&
          tagList.data.getAllTags.industryTagList.length > 0
      ) {
        tagList.data.getAllTags.industryTagList.forEach((element: TagType) => {
          tmpIndustryTagList.push({
            type: "industry",
            label: element.tagName,
            value: element.tagId,
          });
        });
      }

      if (
          tagList &&
          tagList.data &&
          tagList.data.getAllTags &&
          tagList.data.getAllTags.technicalTagList &&
          tagList.data.getAllTags.technicalTagList.length > 0
      ) {
        tagList.data.getAllTags.technicalTagList.forEach((element: TagType) => {
          // element.tagId
          tmpTechList.push({
            type: "tech",
            label: element.tagName,
            value: element.tagId,
          });
        });
      }

      // Set Technical Default Checked
      setIndustryList(tmpIndustryTagList);
      setTechnicalList(tmpTechList);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTagList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div className="flex bp-filter">
        <div className="bp-search">
          <TextFilter
              filteringText={pocPattern}
              filteringPlaceholder="Search PoC Best Practice Library"
              onChange={({detail}) => {
                setPocPattern(detail.filteringText);
              }}
              onDelayedChange={({detail}) => {
                changeTextInput(detail.filteringText);
              }}
          />
        </div>
        <div className="bp-select">
          <Multiselect
              selectedOptions={selectedOptions}
              onChange={({detail}: any) => {
                changeTagInput(detail.selectedOptions);
                console.info("detail:", detail);
                setSelectedOptions(detail.selectedOptions);
              }}
              deselectAriaLabel={(e) => "Remove " + e.label}
              options={[
                {
                  label: "Technology",
                  options: technicalList,
                },
                {
                  label: "Industry",
                  options: industryList,
                },
              ]}
              placeholder="Choose Categories"
              selectedAriaLabel="Selected"
          />
        </div>
      </div>
  );
};

export default SearchOptions;
