import React, { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { AppLayout } from "@amzn/awsui-components-react";
import { Button, Header, SpaceBetween, Spinner, } from "@amzn/awsui-components-react/polaris";

import { appSyncRequest } from "assets/js/request";
import { getPoCById, getPoCReviewById } from "graphql/queries";

import { updateReview } from "graphql/mutations";

import SideNav from "common/SideNav";
import BreadCrumb from "common/BreadCrumb";

import StepScore from "./review/StepScore";
import StepOverview from "./review/StepOverview";
import { PoC, ReviewResponse, ReviewStatus } from "API";
import AppContext from "context/AppContext";
import { POC_NOT_FOUND_ID, SIDE_MENU_IS_OPEN } from "assets/js/const";

interface PocReviewContentProps {
  setHelpPanel: (item: any) => void;
  pocId: string;
}

const PocReviewContent: React.FC<PocReviewContentProps> = (
    props: PocReviewContentProps
) => {
  const {pocId, setHelpPanel} = props;
  const appConfig = useContext(AppContext);

  const [currentReview, setCurrentReview] = useState<ReviewResponse>();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [loadingScore, setLoadingScore] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [scoreLengthError, setScoreLengthError] = useState(false);
  const [showReviewScoreError, setShowReviewScoreError] = useState(false);

  const history = useHistory();

  const updatePocReview = useCallback(
      async (modeType: string) => {
        // if has length error return
        if (scoreLengthError) {
          return;
        }
        // if type == submit and has review review score less than 0, show Error and show Score page
        if (modeType === "Submit") {
          if (currentReview && currentReview.scoreResList) {
            // Find the minvalue of the score list whether it less than 0
            const minValue = Math.min.apply(
                Math,
                currentReview.scoreResList.map((o: any) => {
                  return o.score;
                })
            );
            if (minValue < 0) {
              // alert("AA:" + minValue);
              setShowReviewScoreError(true);
              setActiveStepIndex(0);
              return;
            }
          }
        }
        // Remove useless properties
        delete currentReview?.submitDatetime;
        delete currentReview?.totalScore;
        delete currentReview?.scoreRank;
        // Deep copy param object
        const curReviewObj = JSON.parse(JSON.stringify(currentReview));
        // endode review comments
        curReviewObj.comments = encodeURIComponent(curReviewObj.comments);
        curReviewObj.scoreResList.forEach((element: any) => {
          element.comments = encodeURIComponent(element.comments);
        });
        // Set Is parameters to boolean
        // curReviewObj.isNecessary = Boolean(curReviewObj.isNecessary);
        // curReviewObj.isRepeatable = Boolean(curReviewObj.isRepeatable);
        // curReviewObj.isPackageUp = Boolean(curReviewObj.isPackageUp);
        // Set Save Mode
        curReviewObj.mode = modeType;
        curReviewObj.pocId = pocId;
        if (modeType === "Draft") {
          setDraftLoading(true);
        } else {
          setSubmitLoading(true);
        }
        try {
          const pocReviewData = await appSyncRequest(updateReview, {
            input: curReviewObj,
          });
          setDraftLoading(false);
          setSubmitLoading(false);
          console.info("pocReviewData:", pocReviewData);
          history.push({
            pathname: `/poc/detail/${pocId}`,
          });
        } catch (error) {
          setDraftLoading(false);
          setSubmitLoading(false);
          console.error(error);
        }
      },
      [currentReview, scoreLengthError, history, pocId]
  );

  const getPoCReviewInfoById = useCallback(async () => {
    try {
      setLoadingScore(true);
      const reviewData: any = await appSyncRequest(getPoCReviewById, {
        pocId: pocId,
      });
      console.info("reviewData:", reviewData);
      setLoadingScore(false);
      if (reviewData?.data?.getPoCReviewById) {
        reviewData.data.getPoCReviewById.comments = decodeURIComponent(
            reviewData?.data?.getPoCReviewById?.comments?.replace(/\+/g, " ")
        );
        reviewData?.data?.getPoCReviewById?.scoreResList.forEach(
            (element: any) => {
              element.comments = decodeURIComponent(
                  element.comments.replace(/\+/g, " ")
              );
            }
        );
      }
      setCurrentReview(reviewData?.data?.getPoCReviewById);
    } catch (error) {
      setLoadingScore(false);
      // setCurrentReview(initReiewObj);
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pocId]);

  // useEffect(() => {
  //   getPoCReviewInfoById();
  // }, [getPoCReviewInfoById]);

  // Get Poc By Id or redirect no permission page
  const getPoCInfoById = async () => {
    try {
      setLoadingScore(true);
      const pocData: any = await appSyncRequest(getPoCById, {pocId: pocId});
      console.info("pocData:", pocData);
      // setCurPoc();
      // setLoadingScore(false);
      const pocInfo: PoC = pocData?.data?.getPoCById;
      if (pocInfo.pocId === POC_NOT_FOUND_ID) {
        history.push({
          pathname: "/error/not-found",
        });
      } else {
        if (pocInfo.reviewStatus !== ReviewStatus.Done) {
          if (pocInfo.assignee?.userId === appConfig.userId) {
            getPoCReviewInfoById();
          } else {
            history.push({
              pathname: "/error/access-denied",
            });
          }
        } else {
          history.push({
            pathname: "/error/access-denied",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPoCInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div>
        {loadingScore ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div className="gsal-review-page">
              <div className="gsal-step-nav">
                <nav>
                  <ul>
                    <li>
                      <small>Step 1</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          setActiveStepIndex(0);
                        }}
                        className={activeStepIndex !== 0 ? "link" : ""}
                    >
                      PoC Score
                    </span>
                      </div>
                    </li>
                    <li>
                      <small>Step 2</small>
                      <div className="step-name">
                    <span
                        onClick={() => {
                          setActiveStepIndex(1);
                        }}
                        className={activeStepIndex !== 1 ? "link" : ""}
                    >
                      PoC Overview
                    </span>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="gsal-review-content">
                <Header variant="h1">Review {pocId.toUpperCase()}</Header>
                <div>
                  {activeStepIndex === 0 && (
                      <StepScore
                          showScoreError={showReviewScoreError}
                          pocId={pocId}
                          currentReview={currentReview}
                          setLengthError={(isError) => {
                            setScoreLengthError(isError);
                          }}
                          changeReviewScore={(item) => {
                            console.info("item:", item);
                            setCurrentReview(item);
                          }}
                          setHelpPanel={(item) => {
                            console.info("click info");
                            setHelpPanel(item);
                          }}
                      />
                  )}
                  {activeStepIndex === 1 && (
                      <StepOverview
                          pocId={pocId}
                          currentReview={currentReview}
                          setLengthError={(isError) => {
                            setScoreLengthError(isError);
                          }}
                          changeReviewOverview={(item) => {
                            console.info("item:", item);
                            setCurrentReview(item);
                          }}
                      />
                  )}
                </div>
                <div className="mt-20 pb-30 clearfix">
                  <SpaceBetween className="fr" direction="horizontal" size="xs">
                    <Button
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: `/poc/detail/${pocId}`,
                          });
                        }}
                    >
                      Cancel
                    </Button>
                    {activeStepIndex > 0 && (
                        <Button
                            disabled={loadingScore || submitLoading}
                            loading={draftLoading}
                            onClick={() => {
                              if (scoreLengthError) {
                                return;
                              }
                              setActiveStepIndex(0);
                            }}
                        >
                          Previous
                        </Button>
                    )}

                    <Button
                        disabled={loadingScore || submitLoading}
                        loading={draftLoading}
                        onClick={() => {
                          updatePocReview("Draft");
                        }}
                    >
                      Save Draft
                    </Button>

                    {activeStepIndex < 1 && (
                        <Button
                            disabled={loadingScore || draftLoading}
                            loading={submitLoading}
                            variant="primary"
                            onClick={() => {
                              // if has length error return
                              if (scoreLengthError) {
                                return;
                              }
                              setActiveStepIndex(1);
                            }}
                        >
                          Next
                        </Button>
                    )}

                    {activeStepIndex === 1 && (
                        <Button
                            disabled={loadingScore || draftLoading}
                            loading={submitLoading}
                            variant="primary"
                            onClick={() => {
                              updatePocReview("Submit");
                            }}
                        >
                          Submit
                        </Button>
                    )}
                  </SpaceBetween>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

interface MatchParams {
  id: string;
}

const PocReview: React.FC<RouteComponentProps<MatchParams>> = (
    props: RouteComponentProps<MatchParams>
) => {
  const [navigationOpen, setnavigationOpen] = useState(
      localStorage.getItem(SIDE_MENU_IS_OPEN) === "false" ? false : true
  );
  const id: string = props.match.params.id;
  const [helpInfo, setHelpInfo] = useState();
  const [showHelpInfo, setShowHelpInfo] = useState(false);

  const pocReviewBreadcrumbs = [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Assigned to me(PoC)",
      href: "/assign-to-me",
    },
    {
      text: id.toUpperCase(),
      href: "/assign-to-me/detail/" + id,
    },
    {
      text: "Review",
      href: "",
    },
  ];
  return (
      <AppLayout
          breadcrumbs={<BreadCrumb resourcesBreadcrumbs={pocReviewBreadcrumbs}/>}
          content={
            <PocReviewContent
                pocId={id}
                setHelpPanel={(item: any) => {
                  setShowHelpInfo(true);
                  // setShowHelpInfo(!showHelpInfo);
                  setHelpInfo(item);
                }}
            />
          }
          tools={helpInfo}
          toolsOpen={showHelpInfo}
          // toolsHide={showHelpInfo}
          onToolsChange={({detail}) => setShowHelpInfo(detail.open)}
          navigation={<SideNav/>}
          stickyNotifications
          navigationOpen={navigationOpen}
          onNavigationChange={() => {
            localStorage.setItem(SIDE_MENU_IS_OPEN, String(!navigationOpen));
            setnavigationOpen(!navigationOpen);
          }}
      />
  );
};

export default PocReview;
