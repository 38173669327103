import React from "react";
import { StatusIndicator } from "@amzn/awsui-components-react/polaris";

interface CrossTeamReviewStatusProp {
  ctReviewStatus: string;
}

enum CTReviewStatus {
  Completed = "Completed",
  InProgress = "InProgress",
}

const CrossTeamReviewStatus: React.FC<CrossTeamReviewStatusProp> = (
    props: CrossTeamReviewStatusProp
) => {
  const {ctReviewStatus} = props;
  return (
      <div>
        {ctReviewStatus === CTReviewStatus.InProgress && (
            <StatusIndicator type="in-progress">In Progress</StatusIndicator>
        )}
        {ctReviewStatus === CTReviewStatus.Completed && (
            <StatusIndicator type="success">Completed</StatusIndicator>
        )}
      </div>
  );
};

export default CrossTeamReviewStatus;
