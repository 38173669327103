import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  Table,
} from "@amzn/awsui-components-react/polaris";
import { appSyncRequest } from "assets/js/request";
import { getPoCReviewById } from "graphql/queries";
import ValueWithLabel from "common/ValueWithLabel";
import { PoCScoreList, PoCScoreMap } from "assets/js/const";
import { handleNullValue } from "assets/js/utils";
import { BestPracticeStatus, ReviewResponse, ReviewStatus, UserRole, } from "API";
import AppContext from "context/AppContext";

interface ScoreProps {
  pocId: string;
  assigneeId: string | null | undefined;
  pocNominateStatus: BestPracticeStatus | null | undefined;
  pocReviewStatus: ReviewStatus | null | undefined;
}

const Review: React.FC<ScoreProps> = (props: ScoreProps) => {
  const {pocId, assigneeId, pocReviewStatus} = props;
  const appConfig = useContext(AppContext);
  const history = useHistory();
  const [loadingScore, setLoadingScore] = useState(false);
  const [currentReview, setCurrentReview] = useState<ReviewResponse>();

  const getPoCReviewInfoById = useCallback(async () => {
    try {
      setLoadingScore(true);
      const reviewData: any = await appSyncRequest(getPoCReviewById, {
        pocId: pocId,
      });
      console.info("reviewData:", reviewData);
      setLoadingScore(false);
      setCurrentReview(reviewData?.data?.getPoCReviewById);
    } catch (error) {
      setLoadingScore(false);
      console.error(error);
    }
  }, [pocId]);

  useEffect(() => {
    getPoCReviewInfoById();
  }, [getPoCReviewInfoById]);

  return (
      <Container
          header={
            <Header
                actions={
                    !loadingScore &&
                    pocReviewStatus !== ReviewStatus.Done &&
                    assigneeId === appConfig.userId &&
                    (appConfig.userRole === UserRole.Manager ||
                        appConfig.userRole === UserRole.Reviewer) && (
                        <Button
                            onClick={() => {
                              history.push({
                                pathname: "/poc/" + pocId + "/review",
                              });
                            }}
                            variant="primary"
                        >
                          Review
                        </Button>
                    )
                }
                variant="h2"
            >
              Score
            </Header>
          }
      >
        {loadingScore ? (
            <div>
              <Spinner size="normal"/>
            </div>
        ) : (
            <div>
              <div className="flex">
                <div className="left flex-2">
                  <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Is the PoC necessary?">
                        {currentReview
                            ? currentReview?.isNecessary
                                ? "Yes"
                                : "No"
                            : "-"}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Is the PoC repeatable?">
                        {currentReview
                            ? currentReview?.isRepeatable
                                ? "Yes"
                                : "No"
                            : "-"}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                  <ColumnLayout columns={1} variant="text-grid">
                    <ValueWithLabel label="General Comments">
                      <p className="word-break">
                        {
                          // currentReview?.comments
                          handleNullValue(
                              decodeURIComponent(
                                  currentReview?.comments?.replace(/\+/g, " ") || ""
                              )
                          )
                        }
                      </p>
                    </ValueWithLabel>
                  </ColumnLayout>
                </div>
                <div className="right flex-1">
                  <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l" className="border-left-c">
                      <ValueWithLabel label="Is the PoC packaged up?">
                        {currentReview
                            ? currentReview?.isPackageUp
                                ? "Yes"
                                : "No"
                            : "-"}
                      </ValueWithLabel>
                      <ValueWithLabel label="Total Score">
                        {(currentReview?.totalScore || -1) >= 0 &&
                        pocReviewStatus === ReviewStatus.Done ? (
                            <span>
                        {currentReview?.totalScore?.toFixed(1)} ({" "}
                              {handleNullValue(currentReview?.scoreRank)} )
                      </span>
                        ) : (
                            <span>-</span>
                        )}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                </div>
              </div>
              <div className="mt-20">
                <Table
                    // trackBy="pocIncludes"
                    columnDefinitions={[
                      {
                        id: "pocIncludes",
                        header: "PoC includes",
                        width: 250,
                        cell: (e) => PoCScoreMap[e]?.showName,
                      },
                      {
                        id: "weight",
                        header: "Weight",
                        width: 200,
                        cell: (e) => {
                          return `${PoCScoreMap[e].weight * 100}%`;
                        },
                      },
                      {
                        id: "provided",
                        header: "Provided",
                        width: 200,
                        cell: (e) => {
                          if (
                              !currentReview ||
                              !currentReview.scoreResList ||
                              currentReview.scoreResList.length <= 0
                          ) {
                            return "-";
                          }
                          if (
                              currentReview?.scoreResList?.find((element) => {
                                return element?.criteriaName === e;
                              })?.score === -1
                          ) {
                            return "-";
                          }
                          return (currentReview?.scoreResList?.find((element) => {
                            return element?.criteriaName === e;
                          })?.score as number) > 0 ? (
                              <StatusIndicator type="success">Yes</StatusIndicator>
                          ) : (
                              <StatusIndicator type="error">No</StatusIndicator>
                          );
                        },
                      },

                      {
                        id: "score",
                        header: "Score",
                        width: 200,
                        cell: (e) => {
                          const tmpScore = currentReview?.scoreResList?.find(
                              (element) => {
                                return element?.criteriaName === e;
                              }
                          )?.score;
                          return (tmpScore as number) >= 0 ? tmpScore : "-";
                        },
                      },
                      {
                        id: "comment",
                        header: "Comment",
                        cell: (e) => {
                          return decodeURIComponent(
                              currentReview?.scoreResList
                                  ?.find((element) => {
                                    return element?.criteriaName === e;
                                  })
                                  ?.comments?.replace(/\+/g, " ") || "-"
                          );
                        },
                      },
                    ]}
                    items={PoCScoreList || []}
                />
              </div>
            </div>
        )}
      </Container>
  );
};

export default Review;
