import React from 'react';
import { StatusIndicator } from '@amzn/awsui-components-react';

interface SWAReviewResultProp {
  isPass: boolean;
}

const SWAReviewResult: React.FC<SWAReviewResultProp> = (
    props: SWAReviewResultProp
) => {
  const { isPass } = props;
  return (
      <div>
        {isPass === true&&(
            <StatusIndicator type="success">Passed</StatusIndicator>
        )}
        {isPass===false&&(
            <StatusIndicator type="stopped">Not Passed</StatusIndicator>
        )}
      </div>
  );
};

export default SWAReviewResult;